import React from "react";
import { ResponsiveContainer, BarChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Bar} from 'recharts';

const Barchart = (props) => {
const {datosGrafica} = props;
  return (
    <ResponsiveContainer width="100%" height={250}>
      <BarChart width={730} height={250} data={datosGrafica}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="tipo" />
        <YAxis />
        <Tooltip />
        <Legend verticalAlign="top" height={36} iconType="circle" />
        <Bar dataKey="mujeres" fill="#c05d61"  name="Mujeres" />
        <Bar dataKey="hombres" fill="#ff8c8c" name="Hombres" />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default Barchart;
