import { Checkbox, Col, Form, Input, Row } from "antd";
import React, { useEffect, useRef } from "react";
import { getState } from "../../../../api/requestPub";
import { soloDigitos, numeroInt } from "../../../../utils/rules";
import { calle, cp } from "./regex";
import { txtNombre } from "../../../../utils/regex";

export default function Adress(props) {
  const { setJuntas, disabled } = props;
  const { getFieldDecorator, handleChange, datos, changeCheckbox } = props;
  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);
  useEffect(() => {
    //Obtiene los estados
    getState()
      .then((res) => {
        if (isMounted.current) {
          setJuntas((val) => ({ ...val, estados: res.data }));
        }
      })
      .catch((error) => {
        console.log("Ocurrio un error", error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Form>
      <h3>Domicilio</h3>
      <Row gutter={24}>
        <Col span={12} xxl={8} xl={8} lg={8} md={8} sm={24} xs={24}>
          <Form.Item label="Calle">
            {getFieldDecorator("calle", {
              initialValue: datos.calle,
              rules: calle,
            })(
              <Input
                //placeholder="Calle"
                onChange={handleChange("calle")}
                maxLength={50}
              />
            )}
          </Form.Item>
        </Col>
        <Col span={4} xxl={4} xl={4} lg={4} md={4} sm={24} xs={24}>
          <Form.Item>
            <br />
            {getFieldDecorator("numero", {
              valuePropName: "checked",
              initialValue: datos.numero,
            })(
              <Checkbox onChange={changeCheckbox("numero")}>
                Sin número
              </Checkbox>
            )}
          </Form.Item>
        </Col>
        <Col span={4} xxl={4} xl={4} lg={4} md={4} sm={24} xs={24}>
          <Form.Item label="No. exterior">
            {getFieldDecorator("numeroExt", {
              initialValue: datos.numeroExt,
              getValueFromEvent: (event, previo) => {
                return numeroInt.test(event.target.value)
                  ? event.target.value
                  : previo;
              },
            })(
              <Input
                //placeholder="No. exterior"
                disabled={disabled.num}
                onChange={handleChange("numeroExt")}
                maxLength={20}
              />
            )}
          </Form.Item>
        </Col>
        <Col span={4} xxl={4} xl={4} lg={4} md={4} sm={24} xs={24}>
          <Form.Item label="No interior">
            {getFieldDecorator("numeroInt", {
              initialValue: datos.numeroInt,
              getValueFromEvent: (event, previo) => {
                return numeroInt.test(event.target.value)
                  ? event.target.value
                  : previo;
              },
            })(
              <Input
                //placeholder="No interior"
                disabled={disabled.num}
                onChange={handleChange("numeroInt")}
                maxLength={20}
              />
            )}
          </Form.Item>
        </Col>
      </Row>

      <Form.Item label="Código postal" style={{ width: 100 }}>
        {getFieldDecorator("codigoPostal", {
          initialValue: datos.codigoPostal,
          rules: cp,
          getValueFromEvent: (event, previo) => {
            return soloDigitos.test(event.target.value)
              ? event.target.value
              : previo;
          },
        })(
          <Input
            //placeholder="09690"
            onChange={handleChange("codigoPostal")}
            maxLength={5}
          />
        )}
      </Form.Item>

      <Row gutter={24}>
        <Col span={8} xxl={8} xl={8} lg={8} md={8} sm={24} xs={24}>
          <Form.Item label="Estado / Provincia / Región">
            {getFieldDecorator("estadoExt", {
              initialValue: datos.estadoExt,
              rules: calle,
              getValueFromEvent: (event, previo) => {
                return txtNombre.test(event.target.value)
                  ? event.target.value.replace("-", "")
                  : previo;
              },
            })(
              <Input
                //placeholder="Calle"
                onChange={handleChange("estadoExt")}
                maxLength={40}
              />
            )}
          </Form.Item>
        </Col>
        <Col span={8} xxl={8} xl={8} lg={8} md={8} sm={24} xs={24}>
          <Form.Item label="Ciudad">
            {getFieldDecorator("ciudad", {
              initialValue: datos.ciudad,
              rules: calle,
              getValueFromEvent: (event,previo) => {
                return txtNombre.test(event.target.value)
                  ? event.target.value.replace("-", "")
                  : previo;
              },
            })(
              <Input
                //placeholder="Calle"
                onChange={handleChange("ciudad")}
                maxLength={40}
              />
            )}
          </Form.Item>
        </Col>
        <Col span={8} xxl={8} xl={8} lg={8} md={8} sm={24} xs={24}>
          <Form.Item label="País">
            <label>{datos.pais}</label>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}
