import React, { useState, useEffect } from "react";
import { Row, Col, Button } from "antd";
import { withRouter, Link } from "react-router-dom";
import useAuth from "../../../hooks/useAuth";
import { getRequests } from "../../../api/registerAdmin.js";
import { ReactComponent as New } from "../../../assets/img/newConvocation.svg";
import Table from "../../../components/Admin/Register/Table";
import { esJuntaLocal } from "../../../components/Generals/functions";
import { rol_captura, rol_consulta, rol_modifica, rol_descarga, rol_borrar } from "../../../utils/RegisterAdmin/roles";
import "./Requests.scss";

function Requests(props) {
  // Table //
  const [requests, setRequests] = useState({
    list: [],
    loading: false,
  });
  const [rolModulo, setRolModulo] = useState({
    captura: false,
    modifica: false,
    consulta: false,
    descarga: false,
    borrar: false,
  });

  const {
    user: { rolUsuario, infoMenu },
  } = useAuth();
  const { proceso } = props;

  useEffect(() => {
  if (proceso.distrito !== undefined && proceso.distrito !== null) {
    //Valida los permisos que tienen el usuario
    let captura = false;
    let modifica = false;
    let consulta = false;
    let descarga = false;
    let borrar = false;
    //Devuelve un true si cuenta con los permisos
    captura = rol_captura.includes(rolUsuario);
    modifica = rol_modifica.includes(rolUsuario);
    consulta = rol_consulta.includes(rolUsuario);
    descarga = rol_descarga.includes(rolUsuario);
    borrar = rol_borrar.includes(rolUsuario);

    if (esJuntaLocal(rolUsuario, proceso.distrito.idDistrito)) {
      captura = false;
      modifica = false;
      borrar = false;
    }
    
    setRolModulo(() => ({ captura, modifica, consulta, descarga, borrar })); //Se almacenan en el hook
    setRequests({ loading: true }); //Se habilita el componente de loading
    getRequests(proceso, infoMenu.listaDetalles).then((res) => {
      const dataSource = [];
      res.data.map((reg, index) => {
        return dataSource.push({
          key: index,
          indice: index + 1,
          ...reg,
        });
      });
      setRequests({ list: dataSource, loading: false });  
    });
  }
  }, [rolUsuario, proceso, infoMenu.listaDetalles]);

  return (
    <div>
      <Row>
        <h1>Solicitudes</h1>
        <Col span={4}>
          <div>
            <Button className="convocation btn_secundario">
              <Link to={"/admin/solicitudes/registro"}>
                <New />
                <br />
                Nueva Solicitud
              </Link>
            </Button>
          </div>
        </Col>
      </Row>
      <Row className="rowListRequests">
        <Col span={24}>
          <div>
            <h3>Lista de Solicitudes</h3>
          </div>
        </Col>
      </Row>
      <Row className="rowTableRequests">
        <Col span={24}>
          <div>
            <Table
              data={requests.list}              
              rolModulo={rolModulo}
              loading={requests.loading}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default withRouter(Requests);