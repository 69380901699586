import React, { useState, useEffect } from "react";
import { Layout } from "antd";
//Componentes
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import Sider from "../components/Sider/Sider2";
import Proceso from "../components/Proceso_Menu/Proceso_Menu";
//Logos
import { ReactComponent as Logo_Header } from "../assets/img/ID_observadores.svg";
import { Route, Switch, Redirect } from "react-router-dom";
//Se importa el context para obtener la información del usuario
import useAuth from "../hooks/useAuth";
//pages
import AdminLogin from "../pages/Admin/Login/Login";
import { removeItem, logout } from "../api/auth";
import BlockLoader from "../components/Generals/Loader";
import { ACCESS_TOKEN } from "../utils/constanst";
//Auth
import { refreshAccessTokenApi, getAccessTokenApi } from "../api/auth";

export default function LayoutAdmin(props) {
  const { routes, history } = props; //Se obtienes las rutas por props
  const { Content } = Layout; //Se obtiene el componente hijo de Layout
  const [menu, setMenu] = useState();
  const [loader, setLoader] = useState({ loading: false, message: "" }); //State para mostrar el loader
  //Hook para obtener la información del popover (proceso electoral, entidad y distrito)
  const [proceso, setProceso] = useState({});
  const {
    user: { user, isLoading, infoMenu },
    setUser,
  } = useAuth();
  //Hook que permite limpiar la selección del menú
  const [menuSelect, setMenuSelect] = useState([]);

  const obtieneMenu = (mensaje) => {
    setMenu(mensaje);
  };

  //Verifica si el usuario existe si no lo redirecciona al login
  if (!user && !isLoading) {
    removeItem();
    return (
      <>
        <Route path="/admin" component={AdminLogin} />
        <Redirect to="/admin" />
      </>
    );
  }
  if (user && !isLoading) {
    //Si existe renderiza el Layout principal
    return (
      // Es para usar el loader en la aplicación interna
      <BlockLoader loading={loader.loading} message={loader.message}>
        <Layout style={{ minHeight: "100vh" }}>
          {/**Se importa el header y se le envía el logo correspondiente */}
          <Header
            Logo={Logo_Header}
            user={user}
            history={history}
            setUser={setUser}
            setMenuSelect={setMenuSelect}
          />
          <Layout>
            <RefreshToken setUser={setUser} history={history} user={user} />
            {/* <Sider /> */}
            {menu && menu.length > 0 && (
              <Sider
                menu={menu}
                menuSelect={menuSelect}
                setMenuSelect={setMenuSelect}
              />
            )}
            <Layout className="layout">
              <Proceso
                funcion={obtieneMenu}
                infoMenu={infoMenu}
                setProceso={setProceso}
                proceso={proceso}
                history={history}
                setMenuSelect={setMenuSelect}
              />
              <Content className="content">
                {/**Contenido, se vuelven a iterar las rutas para poder navegar entre ellas */}
                <LoadRoutes
                  routes={routes}
                  proceso={proceso}
                  setLoader={setLoader}
                />
              </Content>
            </Layout>
          </Layout>
          {/**Footer con la versión del sistema */}
          <Footer />
        </Layout>
      </BlockLoader>
    );
  }
  return null;
}
//Función que itera las rutas individualmente
function LoadRoutes({ routes, proceso, setLoader }) {
  return (
    <Switch>
      {routes.map((route, index) => (
        <Route
          key={index}
          path={route.path}
          exact={route.exact}
          //component={(props) => route.component}
          render={(props) => (
            <route.component
              proceso={proceso}
              setLoader={setLoader}
              //Se imporatan los permisos de consulta
              permission={route.permissions}
            />
          )}
        />
      ))}
    </Switch>
  );
}
//Función que refresca el token cada vez que cambia de pantalla
const RefreshToken = (props) => {
  const { user, setUser, history } = props;
  useEffect(() => {
    const jwt = localStorage.getItem(ACCESS_TOKEN);
    refreshAccessTokenApi(jwt, setUser, history, user);
    if (!getAccessTokenApi()) {
      console.log("Token expirado");
      logout(setUser, history, user);
    }
  });
  return <></>;
};
