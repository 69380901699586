import { Card, Col, Form, Icon, message, Row, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  getEntitiesOnline,
  getOnlyLessons,
  getMaterial,
  getMaterialConsulta,
  getDownload,
} from "../../../../api/courses";
import Loading from "../../../../components/Generals/Loading";
// import { Back } from "../../../../components/User/CourseOnline/Generals";
import MainMaterial from "../../../../components/User/CourseOnline/MainMaterial";
// import { RenderDocument } from "../../../../components/User/CourseOnline/Material";
import useAuth from "../../../../hooks/useAuth";
import {
  activeScreenMat,
  addEntityMat,
  cleanEntityMat,
  setMenuMaterial,
} from "../../../../redux/actions/entity";
import {
  ACT_FINAL,
  ACT_VALORACION,
  MAT_CONSULTA,
  MSN_GUARDAR,
} from "../../../../utils/constanst";
import { required } from "../../../../utils/rules";
import "./Courses.scss";

/**
 * Pantalla de Materiales de capacitación
 * Componente que muestra todos los materiales para su respectiva consulta
 * path: /ciudadano/material
 */
function Material(props) {
  // console.log(props);
  const { history, setLoader } = props;
  const { Option } = Select;
  const { getFieldDecorator } = props.form; //Método para validar los inputs
  const [entities, setEntities] = useState([]); //Hook para administrar el texto del proceso electoral
  const [lessons, setLessons] = useState([]); //Hook para administrar el texto del proceso electoral
  const [material, setMaterial] = useState([]);
  const [consulta, setConsulta] = useState([]);
  const [loading, setLoading] = useState({ consulta: false, material: false });
  const [data, setData] = useState({});
  const [lesson, setLesson] = useState({});
  const dispatch = useDispatch(); //Se declara el dispatch para acceder al store de Redux
  const {
    ciudadano: { ciudadano },
  } = useAuth();

  useEffect(() => {
    window.scrollTo(0, 0); // para posicionar el scroll al inicio
    //Se obtiene la entidad o entidades a observar por parte del ciudadano
    getEntitiesOnline(ciudadano.id)
      .then((res) => {
        setEntities(res.data);
      })
      .catch((e) => {
        console.log("Error", e);
      });
    dispatch(activeScreenMat());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (value, event) => {
    props.form.setFieldsValue({ idLeccion: undefined });
    setMaterial([]);
    setData({ idEntidad: value });
    dispatch(addEntityMat(parseInt(value), event.props.children));
    getOnlyLessons(value)
      .then((res) => {
        setLessons(res.data);
        dispatch(setMenuMaterial(res.data));
      })
      .catch((e) => {
        console.log(e);
      });
    setLoading({ consulta: true }); //Se activa el loading
    //Se hace la petición para obtener los materiales de consulta con el idLeccion 12
    getMaterialConsulta(value, MAT_CONSULTA)
      .then((res) => {
        // console.log("Hizo pet material");
        setConsulta(res.data);
        setLoading({ consulta: false });
      })
      .catch((e) => {
        setLoading({ consulta: false });
        console.log(e);
      });
  };

  const handleLessons = (value) => {
    setLesson(getIndex(lessons, parseInt(value)));
    setData({ ...data, idLeccion: value });
    setLoading({ material: true });
    getMaterial(data.idEntidad, value)
      .then((res) => {
        setMaterial(res.data);
        setLoading({ material: false });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getIndex = (array, idLeccion) => {
    return array.find((obj) => obj.idLeccion === idLeccion);
  };
  //Función que descarga un documento cuando el archivo viene en base64
  const download = (file, name) => {
    // console.log(file);
    setLoader({ loading: true, message: MSN_GUARDAR }); //Se desactiva el loader
    getDownload(file)
      .then((res) => {
        if (res.data) {
          setLoader({ loading: false, message: "" }); //Se desactiva el loader
          const link = document.createElement("a");
          link.href = URL.createObjectURL(
            new Blob([res.data], { type: "application/pdf" })
          );
          link.setAttribute("download", name);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      })
      .catch((error) => {
        setLoader({ loading: false, message: "" }); //Se desactiva el loader
        message.error("El archivo no se encuentra disponible");
      });

    // let a = document.createElement("a"); //Create <a>
    // a.href = `data:application/pdf;base64,${file}`; //Image Base64 Goes here
    // a.download = `${name.trim()}.pdf`; //File name Here
    // a.click(); //Downloaded file
    // window.open("data:application/pdf;base64, " + encodeURIComponent(file));
  };

  const Text = ({ idLesson }) => {
    if (idLesson !== ACT_FINAL && idLesson !== ACT_VALORACION) {
      return (
        <>
          <h1>Contenido de la lección</h1>
          {idLesson === 1 && (
            <p>
              ¡Vamos a comenzar con tu capacitación! ¿Estás listo/a? Haz clic en
              el siguiente video.
            </p>
          )}
        </>
      );
    }
    return null;
  };

  const backScreen = () => {
    dispatch(cleanEntityMat());
    history.goBack();
  };
  // console.log(history.goBack());
  return (
    <>
      {/* <Back path={(e) => history.goBack()} /> */}
      <button onClick={(e) => backScreen()} className="btnMaterial">
        <Icon type="play-circle" theme="filled" rotate="180" /> Regresar
      </button>
      <h1 style={{ marginTop: 30 }}>Materiales de capacitación</h1>
      <hr />
      <Row gutter={24}>
        <Col span={8} xxl={8} xl={8} lg={8} md={8} sm={24} xs={24}>
          <Form.Item label="Ver materiales de la entidad">
            {getFieldDecorator("idEntidad", {
              rules: required,
            })(
              <Select
                style={{ width: "100%" }}
                showSearch
                optionFilterProp="children"
                placeholder="Selecciona"
                // onChange={handleChange}
                onSelect={(value, e) => handleChange(value, e)}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {entities.map((item) => (
                  <Option key={item.idEntidad}>{item.entidad}</Option>
                ))}
              </Select>
            )}
          </Form.Item>
        </Col>
      </Row>
      <h2>Material de consulta</h2>
      {loading.consulta ? (
        <Loading />
      ) : (
        <Row gutter={24}>
          {consulta.map((item, i) => (
            <Col span={8} xxl={8} xl={8} lg={8} md={8} sm={24} xs={24} key={i}>
              <div className="site-card-consulta ">
                <Card bordered={false}>
                  <Col span={4} xxl={4} xl={4} lg={4} md={4} sm={24} xs={24}>
                    <Icon
                      type="download"
                      className="btnDownload"
                      onClick={(e) => {
                        download(item.url, item.material);
                      }}
                    />
                  </Col>
                  <Col
                    span={20}
                    xxl={20}
                    xl={20}
                    lg={20}
                    md={20}
                    sm={24}
                    xs={24}
                  >
                    {item.material}
                  </Col>
                </Card>
              </div>
            </Col>
          ))}
        </Row>
      )}

      <h2 style={{ marginTop: 20 }}>Materiales por curso</h2>
      <Row gutter={24}>
        <Col span={8} xxl={8} xl={8} lg={8} md={8} sm={24} xs={24}>
          <Form.Item label="Lección">
            {getFieldDecorator("idLeccion", {
              rules: required,
            })(
              <Select
                style={{ width: "100%" }}
                showSearch
                optionFilterProp="children"
                placeholder="Selecciona"
                onChange={handleLessons}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {lessons.map((item, i) => (
                  <Option key={item.idLeccion}>
                    {i + 1}.- {item.leccion}
                  </Option>
                ))}
              </Select>
            )}
          </Form.Item>
        </Col>
      </Row>
      {loading.material ? (
        <Loading />
      ) : (
        <>
          {material.length > 0 && (
            <>
              <h1>Introducción</h1>
              {(data.idLeccion === '8' && data.idEntidad === '13') ? (
                <>
                  <p>En esta lección te damos a conocer el sistema de medios de
                  impugnación en materia electoral, es decir su interposición,
                  resolución e impacto en los procesos electorales tanto
                  federales como locales y autoridades que los conocen y
                  resuelven. Así como las causales de nulidad en la elección
                  federal y local.
                  </p>
                </>
              ) : (
                <>
                  <p className="texto_parrafo">{lesson.introduccion}</p>
                </>
              )}
              <h1>Objetivo de aprendizaje</h1>
              <p className="texto_parrafo">{lesson.objetivo}</p>
              <Text idLesson={data.idLeccion} />
            </>
          )}

          {/* {material.map((doc, i) => (
            <Row gutter={24} key={i} justify="center" className="material">
              <Col span={24} xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                <RenderDocument doc={doc} />
              </Col>
            </Row>
          ))} */}
          <MainMaterial idLesson={parseInt(data.idLeccion)} data={material} />
          <br />
          <br />
        </>
      )}
    </>
  );
}
export default withRouter(Form.create()(Material));
