import React, { useState, useEffect } from "react";
import { Row, Col, Radio, Icon, DatePicker, Checkbox, Button, message } from "antd";
import { withRouter, Link } from "react-router-dom";
import useAuth from "../../../../hooks/useAuth";
import { Ast } from "../../../../utils/Reports/functions";
import { getReporteCursos } from "../../../../api/reports";
import Table from "../../../../components/Admin/Reports/TableCourses";
import "./Courses.scss";
import moment from "moment";
import { descargaExcel } from "../../../../components/Admin/Reports/CoursesXls";

function Courses(props) {
  const dateFormat = 'DD/MM/YYYY';
  const { proceso } = props;
  const { user: { rolUsuario } } = useAuth();
  const [reload, setReload] = useState(false);
  const [calendario, setCalendario] = useState({ fecha: "", hora: "" }); 
  const [tipoModalidad, setTipoModalidad] = useState(0);
  const [tipoListado, setTipoListado] = useState(0);
  const [tipoParticipante, setTipoParticipante] = useState(0);
  const [fechas, setFechas] = useState({ desde: null, hasta: null, todos:true });
  const [tipoUsuario, setTipoUsuario] = useState();
  const [courses, setCourses] = useState({ list: [], loading: false }); 
  const [ocultar, setOcultar] = useState(false); // ocultar el radioButton tipoListado
  const [entidad, setEntidad] = useState({ id: null, nombre: null }); 
  const [junta, setJunta] = useState({ id: null, nombre: null });
  const [total, setTotal] = useState(0);
  const [texto, setTexto] = useState();

  useEffect(() => {
    if (proceso.proceso !== undefined) {
      const fechaHora = new Date();
      moment.locale("es");
      setCalendario({fecha:moment(fechaHora).format("DD/MMMM/YYYY"), hora:moment(fechaHora).format("HH:mm:ss") });
      const usuario = rolUsuario.substring(rolUsuario.lastIndexOf(".")+1,rolUsuario.length);
      if (proceso.estado !== undefined && proceso.estado !== null && proceso.estado.idEstado !== 0) {
        setEntidad({ id: proceso.estado.idEstado, nombre: proceso.estado.nombreEstado });
        if (proceso.distrito !== undefined && proceso.distrito !== null && proceso.distrito.idDistrito !== 0) {
          setJunta({ id: proceso.distrito.idDistrito, nombre: `${proceso.distrito.idDistrito}. ${proceso.distrito.nombreDistrito}`  });
        } 
      } else {
        setEntidad({ id: null, nombre: null });
        setOcultar(true);
      }
      setTipoUsuario(usuario);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [proceso]);

  useEffect(() => {
    setReload(true);
  }, [entidad, junta]);

  useEffect(() => {
    if (fechas.desde !== null && fechas.hasta !== null) {
      if (moment(fechas.desde, dateFormat) > moment(fechas.hasta, dateFormat)) {
        message.info( "La fecha inicial tiene que ser menor a la fecha final." );
      } else {
        setReload(true);
      }
    }
  }, [fechas]);

  useEffect(() => {
    if (reload) {
      setCourses({...courses, loading: true });
      if (entidad.id === null) {
        setOcultar(true);
      } else {
        if (tipoModalidad === 1 && entidad.id !== null && junta.id === null) {
          setOcultar(true);
        } else {
          setOcultar(false);
        }
        if (tipoModalidad === 1 && junta.id !== null) {
          setOcultar(true);
        }
      }
      const fechaHora = new Date();
      moment.locale("es");
      setCalendario({fecha:moment(fechaHora).format("DD/MMMM/YYYY"), hora:moment(fechaHora).format("HH:mm:ss") });
      setTexto('Cursos');
      setTotal(0);
      getReporteCursos(proceso.proceso.idProcesoElectoral, proceso.proceso.idDetalleProceso, 
        entidad.id, junta.id, tipoModalidad, tipoListado, tipoParticipante, fechas.desde, fechas.hasta, fechas.todos).then((res) => {
        if (res.data.cursos !== null) {
          if (res.data.cursos.presenciales !== null) {
            actualizaTotal(res.data.cursos.presenciales);
            setCourses({ list: res.data.cursos.presenciales, loading: false }); 
          } else {
            actualizaTotal(res.data.cursos.linea);
            setCourses({ list: res.data.cursos.linea, loading: false }); 
          }
        } else {
          setCourses({ list: [], loading: false }); 
        }
      })
      .catch((error) => {
        setCourses({ list: [], loading: false }); 
      });
    }
    setReload(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload]);

  function actualizaTotal(list) {
    let totalPantalla = 0;
    if (tipoUsuario === "OC" && entidad.id === null) {
      if (tipoModalidad === 0) {
        for (let i = 0; i < list.length; i++) {
         totalPantalla = totalPantalla + Number(list[i].impartidoTotal);
        }
        setTexto('Cursos presenciales');
      } else {
        for (let i = 0; i < list.length; i++) {
          totalPantalla = totalPantalla + Number(list[i].inscritoTotal);
         }
         setTexto('Inscritos a cursos en línea');
      }
    } else {
      if (entidad.id !== null && junta.id == null) {
        if (tipoModalidad === 1) {
          for (let i = 0; i < list.length; i++) {
            totalPantalla = totalPantalla + Number(list[i].inscritoTotal);
          }
          setTexto('Inscritos a cursos en línea');
        } else {
          if (tipoListado === 0) {
            for (let i = 0; i < list.length; i++) {
              totalPantalla = totalPantalla + Number(list[i].impartidoTotal);
            }
            setTexto('Cursos presenciales');
          } else {
            if (tipoParticipante === 0) {
              totalPantalla = list.length;
              setTexto('Inscritos a curso presencial');
            } else {
              totalPantalla = list.length;
              setTexto('Solicitudes sin inscribir');
            }
          }
        }
      } else {
        if (tipoModalidad === 0) {
          if (tipoListado === 0) {
            totalPantalla = list.length;
            setTexto('Cursos presenciales');
          } else {
            if (tipoParticipante === 0) {
              totalPantalla = list.length;
              setTexto('Inscritos a curso presencial');
            } else {
              totalPantalla = list.length;
              setTexto('Solicitudes sin inscribir');
            }
          }
        } else {
          totalPantalla = list.length;
          setTexto('Inscritos a curso en línea');
        }
      }
    }

    setTotal(totalPantalla);
  }

  const onChangeModalidad = (e) => {
    setTipoModalidad(e.target.value);
    setReload(true);
  };

  const onChangeListado = (e) => {
    setTipoListado(e.target.value);
    setReload(true);
  };

  const onChangeParticipante = (e) => {
    setTipoParticipante(e.target.value);
    if (e.target.value === 1) {
      setFechas({ desde: null, hasta: null, todos:true });
    }
    setReload(true);
  };

  const onChangeDesde = (e, texto) => {
    if (e !== undefined) {
      setFechas({...fechas, desde: texto, todos: false});
    }
  };

  const onChangeHasta = (e, texto) => {
    if (e !== undefined) {
      setFechas({...fechas, hasta: texto, todos: false});
    }
  };

  const onChangeTodos = (e) => {
    if (e.target.checked) {
      setFechas({ ...fechas, todos:true });
      setReload(true);
    } else {
      setFechas({ ...fechas, todos:false });
    }
  };

  function regresaNacional() {
    setEntidad({id:null, nombre: null});
    setJunta({id:null, nombre: null});
    setTipoModalidad(0);
    setTipoListado(0);
    setTipoParticipante(0);
    setFechas({ ...fechas, todos:true });
    setReload(true);
  }

  function regresaEntidad() {
    setJunta({id:null, nombre: null});
    setTipoModalidad(0);
    setTipoListado(0);
    setTipoParticipante(0);
    setFechas({ ...fechas, todos:true });
    setReload(true);
  }

  function EtiquetaVinculo() {
    if (courses.list !== null && courses.list.length > 0) {
      if (entidad.id === null) {
        return ( <div>Nacional</div> ); 
      } else {
        if (tipoUsuario === "OC") {
          if (junta.id === null) {
            return ( <div><Button className="buttonReports" onClick={() => regresaNacional()} type="link">Nacional</Button> | {entidad.nombre}</div> );
          } else {
            return (
              <div><Button className="buttonReports" onClick={() => regresaNacional()} type="link">Nacional</Button> | 
              <Button className="buttonReports" onClick={() => regresaEntidad()} type="link">{entidad.nombre}</Button> | {junta.nombre}</div>
            ); 
          }
        }
        if (tipoUsuario === "JL") {
          if (junta.id === null) {
            return ( <div>Nacional | {entidad.nombre}</div> );
          } else {
            return ( <div>Nacional | <Button className="buttonReports" onClick={() => regresaEntidad()} type="link">{entidad.nombre}</Button> | {junta.nombre}</div> ); 
          }
        }
        if (tipoUsuario === "JD") {
          return ( <div>Nacional | {entidad.nombre} | {junta.nombre}</div> ); 
        }
      }
    } else {
      return ( <div></div> );
    }
  }

  const downloadExcel = () => {
    descargaExcel("Cursos", "Reporte de Cursos", entidad.nombre, junta.nombre, courses.list,
    tipoModalidad, tipoListado, tipoParticipante, calendario);
  }

  function RadioGroupListado() {
    return (
    <div id="radioGroupListado">
      <Row className="rowList">
          <Col span={12}>
            <Ast /> <strong>Información a mostrar</strong>
          </Col>
      </Row>
      <Row>
          <Col span={12} className="colSpace">
            <Radio.Group value={tipoListado} onChange={onChangeListado}>
                <Radio value={0}>Listado de cursos</Radio>
                <Radio value={1}>Listado de participantes</Radio>
            </Radio.Group>
          </Col>
      </Row>
      {tipoListado === 0 ? null : RadioGroupParticipante()}
    </div>
    );
  };

  function RadioGroupParticipante() {
    return (
    <div id="radioGroupParticipante">
      <Row className="rowList">
          <Col span={12}>
            <Ast /> <strong>Mostrar participantes</strong>
          </Col>
      </Row>
      <Row>
          <Col span={12} className="colSpace">
            <Radio.Group value={tipoParticipante} onChange={onChangeParticipante}>
                <Radio value={0}>Inscritos a curso</Radio>
                <Radio value={1}>Sin inscribir a curso</Radio>
            </Radio.Group>
          </Col>
      </Row>
      {tipoParticipante === 0 ? null : FechasValidacion()}
    </div>
    );
  };

  function FechasValidacion() {
    return (
    <div id="fechasValidacion">
      <Row className="rowList">
          <Col span={12}>
            <Ast /> <strong>Fechas de validación de la solicitud</strong>
          </Col>
      </Row>
      <Row className="rowSpace">
        <Col span={3} style={{ paddingRight: "20px" }}>
          <Ast /> Desde
          <DatePicker format={dateFormat} onChange={onChangeDesde} />
        </Col>
        <Col span={3} style={{ paddingRight: "20px" }}>
          <Ast /> Hasta
          <DatePicker format={dateFormat} onChange={onChangeHasta} />
        </Col>
        <Col span={4} style={{ paddingRight: "20px" }}>
          <Checkbox style={{marginTop:"20px"}} checked={fechas.todos} onChange={onChangeTodos} > Todos </Checkbox>
        </Col>
      </Row>
    </div>
    );
  };

  return (
    <div>
      <Row className="backReportes">
        <Link to={"/admin/reportes"} className="back">
          <Icon type="play-circle" theme="filled" rotate="180" /> Volver a "Reportes" 
        </Link>
      </Row>
      <Row>
        <Col span={12}>
          <h1>Cursos</h1>
          <small>Los datos con ( <Ast /> ) son requeridos</small>
        </Col>
        <Col span={12}>
          <div style={{ float:"right", fontWeight:"bold", fontSize:"12px" }}>
            Última actualización
          </div>
          <div style={{ fontSize:"12px", position:"absolute", marginTop:"20px", right:"0" }}>
            <Icon type="calendar" /> {calendario.fecha} <Icon type="clock-circle" /> {calendario.hora}
          </div>
        </Col>
      </Row>
      <Row className="rowList">
          <Col span={12}>
            <Ast /> <strong>Modalidad del curso</strong>
          </Col>
      </Row>
      <Row>
          <Col span={12} className="colSpace">
            <Radio.Group value={tipoModalidad} onChange={onChangeModalidad}>
                <Radio value={0}>Presencial</Radio>
                <Radio value={1}>En línea</Radio>
            </Radio.Group>
          </Col>
      </Row>
      {ocultar ? null : RadioGroupListado()}
      {(tipoModalidad === 1 && junta.id !== null) ? FechasValidacion() : null}
      <Row  className="rowList">
        <Col span={12} className="colSpace">
          <EtiquetaVinculo />
        </Col>
        <Col span={12} className="colSpaceTotal">
          <div><h1 style={{marginBottom: "0px"}}>{total}</h1></div>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <div><strong>Descarga el reporte:</strong> 
            <Button onClick={() => downloadExcel()} className="btn_descarga_link" type="link" >
              <Icon type="file-excel" />Excel
            </Button>
          </div>
        </Col>
        <Col span={12} className="colSpaceTotal">
          <div>{texto}</div>
        </Col>
      </Row>
      <Row>
        <Col span={24} className="colSpace">
          <div>
            <Table
              data={courses.list}
              loading={courses.loading}
              setEntidad={setEntidad}
              setJunta={setJunta}
              tipoModalidad={tipoModalidad}
              tipoListado={tipoListado}
              tipoParticipante={tipoParticipante}
              entidad={entidad}
              junta={junta}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default withRouter(Courses);