import React from "react";
import { Tabs } from "antd";
import Requirements from "../SectionRequirements/Requirements";
import Documents from "../SectionRequirements/Documents";

function callback(key) {
  console.log(key);
}

const ContentRequirements = () => {
  const { TabPane } = Tabs;
  return (
    <div style={{ padding: "20px" }} className="interioresPortal">
      <h1>Documentos y requisitos</h1>
      {/* <hr></hr>
      <p>
        Los siguientes documentos y requistos te permiten llevar tu proceso de acreditación mediante este portal.
      </p>
      <p>
        Tu solicitud se captura en línea, y al hacerlo se genera el escrito de protesta.
      </p>
      <p>
        Si deseas participar como Observadora u Observador Electoral debes cumplir con lo siguiente:
      </p> */}
      <div>
        <Tabs defaultActiveKey="1" onChange={callback}>
          <TabPane tab="Requisitos" key="1">
            <Requirements />
          </TabPane>
          <TabPane tab="Documentos" key="2">
            <Documents />
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
};

export default ContentRequirements;
