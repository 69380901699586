import React, { useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import {
  Icon,
  Form,
  Select,
  DatePicker,
  Button,
  Alert,
  Modal,
  Input,
} from "antd";
import { Ast } from "../../../utils/Action/function";
import useAuth from "../../../hooks/useAuth";
import { formatDate, placeholderDate } from "../../../utils/constanst";
import locale from "antd/es/date-picker/locale/es_ES";
import TextArea from "antd/lib/input/TextArea";
import {
  rol_captura,
  rol_modifica,
  rol_JL,
  rol_JD,
  rol_OPL,
  rol_OC,
} from "../../../utils/Action/roles";
import { getTypeAction, getGroupAction } from "../../../api/action";
import moment from "moment";
import { ModalConfirm } from "../../../utils/Action/function";

const { Option } = Select;

function FormAction(props) {
  const {
    user: { user, rolUsuario },
  } = useAuth();
  const {
    history,
    proceso: { proceso, estado, distrito },
  } = props;
  const { getFieldDecorator } = props.form;
  const [btnGuardar, setBtnGuardar] = useState(false);
  const typeMedia = [];
  const { data, status } = props.location;
  const [rolModulo, setRolModulo] = useState({ modifica: false });
  const [select, setSelect] = useState([]);
  const [action, setAcction] = useState([]);
  const [character, setCharacter] = useState(4000);
  //Para habilitar los inputs
  const [disabled, setDisabled] = useState({
    input: false,
    titulo: false,
    title: false,
    buttonUpd: false,
  });
  //Variable que contiene el numero maximo de caracteres
  const maxChar = 4000;
  //Función para contar los caracteres restantes
  const wordCount = (e) => {
    const charCount = e.target.value.length;
    const charLength = maxChar - charCount;
    setCharacter(charLength);
  };

  useEffect(() => {
    getGroupAction()
      .then((res) => {
        setAcction(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  //Función para llenar el combo de medios de comunicación
  useEffect(() => {
    //Obtencion del rol del usuario
    //valida los permisos que tiene el usuario
    let captura = false;
    let modifica = false;
    captura = rol_captura.includes(rolUsuario);
    modifica = rol_modifica.includes(rolUsuario);

    if (history.location.pathname === "/admin/accion/registro") {
      if (!captura) {
        history.push("/admin/accion");
      }
    } else {
      if (history.location.pathname === "/admin/accion/modificar") {
        if (data === undefined) {
          history.push("/admin/accion");
        } else {
          setRolModulo(() => ({ modifica }));
          getAction();
        }
      }
    }
    getTypeAction().then((res) => {
      setSelect(res.data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Función que llena el formulario si recibe una acción de promoción.
  const getAction = () => {
    if (status !== undefined) {
      status
        ? setDisabled({
            input: true,
            titulo: true,
            title: true,
            buttonUpd: true,
          })
        : setDisabled({ title: true });

      props.form.setFieldsValue({
        //Se llenan los inputs del formulario
        idRegistro: data.idRegistro,
        idComunicacion: data.cmediosComunicacion.idComunicacion,
        fechaRealizacion: moment(data.fechaRealizacion),
        descripcion: data.descripcion,
        idGrupo: data.idGrupo,
      });
      //Se resta el numero de caracteres
      setCharacter(maxChar - data.descripcion.length);
    }
  };

  //Función que llena el select de los medios de comunicación
  for (let i = 0; i < select.length; i++) {
    typeMedia.push(
      <Option key={select[i].idComunicacion} value={select[i].idComunicacion}>
        {select[i].medio}
      </Option>
    );
  }

  //Función que guarda la promoción
  const addAction = (event) => {
    event.preventDefault();
    if (proceso !== undefined && proceso !== null) {
      //se valida el proceso
      props.form.validateFieldsAndScroll((err, values) => {
        if (!err) {
          //Obtencion del rol del usuario
          // eslint-disable-next-line default-case
          switch (rolUsuario) {
            case "OBSERVADORES11.ADMIN.PARAM.CAPTURA.OC":
              values.rolCaptura = rol_OC;
              break;
            case "OBSERVADORES11.ADMIN.CAPTURA.OC":
              values.rolCaptura = rol_OC;
              break;
            case "OBSERVADORES11.CAPTURA.JL":
              values.rolCaptura = rol_JL;
              break;
            case "OBSERVADORES.CAPTURA.OPLE.JL":
              values.rolCaptura = rol_OPL;
              break;
            case "OBSERVADORES11.CAPTURA.JD":
              values.rolCaptura = rol_JD;
              break;
          }
          if (estado !== undefined && estado !== null) {
            values.idEstado = estado.idEstado;
          } else {
            values.idEstado = 0;
          }
          if (distrito !== undefined && distrito !== null) {
            values.idDistrito = distrito.idDistrito;
          } else {
            values.idDistrito = 0;
          }

          values.idProcesoElectoral = proceso.idProcesoElectoral;
          values.idDetalleProceso = proceso.idDetalleProceso;
          values.usuario = user.sub;          
          setBtnGuardar(true);
          ModalConfirm(values, history, setBtnGuardar);
        }
      });
    } else {
      Modal.error({
        content: "Selecciona un proceso electoral",
      });
    }
  };

  function disabledDate(current) {
    return current > moment().endOf("day");
  }

  return (
    <div style={{ marginLeft: "20px" }}>
      <Link to={"/admin/accion"} className="back">
        <Icon type="play-circle" theme="filled" rotate="180" /> Volver a
        Acciones de difusión
      </Link>
      <div style={{ marginTop: "30px" }}>
        {disabled.titulo ? (
          <h1>Acciones de difusión realizadas</h1>
        ) : (
          <>
            <h1>
              {disabled.title
                ? "Editar acción de difusión"
                : "Nueva acción de difusión"}
            </h1>
            <p>
              Los datos con ( <Ast /> ) son requeridos
            </p>
          </>
        )}
      </div>
      <Form onSubmit={addAction}>
        <Form.Item>
          {getFieldDecorator("idRegistro")(<Input disabled hidden />)}
        </Form.Item>
        <Form.Item>
          <div>{!disabled.input && <Ast />} Medio de comunicación usado </div>
          {getFieldDecorator("idComunicacion", {
            rules: [{ required: true, message: "Dato Requerido" }],
          })(
            <Select
              placeholder="Selecciona"
              style={{ width: 315 }}
              disabled={disabled.input}
            >
              {typeMedia}
            </Select>
          )}
        </Form.Item>

        <Form.Item>
          <div>{!disabled.input && <Ast />} Fecha de realización</div>
          {getFieldDecorator("fechaRealizacion", {
            rules: [{ required: true, message: "Dato Requerido" }],
          })(
            <DatePicker
              locale={locale}
              placeholder={placeholderDate}
              format={formatDate}
              disabled={disabled.input}
              disabledDate={disabledDate}
            />
          )}
        </Form.Item>
        <Form.Item>
          <Alert
            description="Ingrese información relevante sobre la acción de difusión (qué, cómo, dónde se realizó y grupo al que va dirigido la acción)."
            type="info"
            showIcon
          />
          <div>{!disabled.input && <Ast />} Descripción </div>
          {getFieldDecorator("descripcion", {
            rules: [{ required: true, message: "Dato Requerido" }],
          })(
            <TextArea
              rows={4}
              maxLength={4000}
              style={{ width: 600 }}
              onChange={wordCount}
              disabled={disabled.input}
            ></TextArea>
          )}
          <p>Caracteres restantes: {character} </p>
        </Form.Item>
        <Form.Item>
          <div>{!disabled.input && <Ast />} Grupo al que va dirigido la acción </div>
          {getFieldDecorator("idGrupo", {
            rules: [{ required: true, message: "Dato Requerido" }],
          })(
            <Select
              placeholder="Selecciona"
              style={{ width: 315 }}
              disabled={disabled.input}
            >
              {action.map((item) => (
                <Option key={item.id} value={item.id}>
                  {item.grupo}
                </Option>
              ))}
            </Select>
          )}
        </Form.Item>
        <Form.Item style={{ textAlign: "center" }}>
          {disabled.buttonUpd ? (
            rolModulo.modifica && (
              <Button
                type="primary"
                onClick={() => {
                  setDisabled({
                    input: false,
                    title: true,
                  });
                }}
              >
                Modificar
              </Button>
            )
          ) : (
            <>
              {rolUsuario === "OBSERVADORES11.CAU.OC" ? (
                <>
                  <Button type="default">
                    <Link to={"/admin/accion"}>Cancelar</Link>
                  </Button>
                </>
              ) : (
                <>
                  <Button type="default" disabled={btnGuardar}>
                    <Link to={"/admin/accion"}>Cancelar</Link>
                  </Button>{" "}
                  <Button
                    type="primary"
                    htmlType="submit"
                    disabled={btnGuardar}
                  >
                    Guardar
                  </Button>
                </>
              )}
            </>
          )}
        </Form.Item>
      </Form>
    </div>
  );
}

export default withRouter(Form.create()(FormAction));
