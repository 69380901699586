import cred_mod_C from "../../assets/img/Examples/cred_mod_C.png";
import cred_mod_C_secc from "../../assets/img/Examples/cred_mod_C_secc.png";
import cred_mod_D from "../../assets/img/Examples/cred_mod_D.png";
import cred_mod_D_secc from "../../assets/img/Examples/cred_mod_D_secc.png";
//Fotos
import foto_fem from "../../assets/img/Examples/retratoFEM.png";
import foto_mas from "../../assets/img/Examples/retratoMASC.png";


export const fotos = [
    {
      id: 1,
      url: foto_fem,
      alt: "Ejemplo",
    },
    {
      id: 2,
      url: foto_mas,
      alt: "Ejemplo",
    }
  ];

export const credenciales = [
  {
    id: 1,
    url: cred_mod_C,
    alt: "Credencial",
  },
  {
    id: 2,
    url: cred_mod_C_secc,
    alt: "Sección",
  },

  {
    id: 3,
    url: cred_mod_D,
    alt: "Credencial",
  },
  {
    id: 4,
    url: cred_mod_D_secc,
    alt: "Sección",
  },
];
