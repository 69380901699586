import React from "react";
import { ReactComponent as Aguascalientes } from "../../../assets/img/CourseOnline/estados/01.svg";
import { ReactComponent as BCalifornia } from "../../../assets/img/CourseOnline/estados/02.svg";
import { ReactComponent as BCaliforniaSur } from "../../../assets/img/CourseOnline/estados/03.svg";
import { ReactComponent as Campeche } from "../../../assets/img/CourseOnline/estados/04.svg";
import { ReactComponent as Coahuila } from "../../../assets/img/CourseOnline/estados/05.svg";
import { ReactComponent as Colima } from "../../../assets/img/CourseOnline/estados/06.svg";
import { ReactComponent as Chiapas } from "../../../assets/img/CourseOnline/estados/07.svg";
import { ReactComponent as Chihuahua } from "../../../assets/img/CourseOnline/estados/08.svg";
import { ReactComponent as Df } from "../../../assets/img/CourseOnline/estados/09.svg";
import { ReactComponent as Durango } from "../../../assets/img/CourseOnline/estados/10.svg";
import { ReactComponent as Guanajuato } from "../../../assets/img/CourseOnline/estados/11.svg";
import { ReactComponent as Guerrero } from "../../../assets/img/CourseOnline/estados/12.svg";
import { ReactComponent as Hidalgo } from "../../../assets/img/CourseOnline/estados/13.svg";
import { ReactComponent as Jalisco } from "../../../assets/img/CourseOnline/estados/14.svg";
import { ReactComponent as Mexico } from "../../../assets/img/CourseOnline/estados/15.svg";
import { ReactComponent as Michoacan } from "../../../assets/img/CourseOnline/estados/16.svg";
import { ReactComponent as Morelos } from "../../../assets/img/CourseOnline/estados/17.svg";
import { ReactComponent as Nayarit } from "../../../assets/img/CourseOnline/estados/18.svg";
import { ReactComponent as NLeaon } from "../../../assets/img/CourseOnline/estados/19.svg";
import { ReactComponent as Oaxaca } from "../../../assets/img/CourseOnline/estados/20.svg";
import { ReactComponent as Puebla } from "../../../assets/img/CourseOnline/estados/21.svg";
import { ReactComponent as Queretaro } from "../../../assets/img/CourseOnline/estados/22.svg";
import { ReactComponent as QRoo } from "../../../assets/img/CourseOnline/estados/23.svg";
import { ReactComponent as SPotosi } from "../../../assets/img/CourseOnline/estados/24.svg";
import { ReactComponent as Sinaloa } from "../../../assets/img/CourseOnline/estados/25.svg";
import { ReactComponent as Sonora } from "../../../assets/img/CourseOnline/estados/26.svg";
import { ReactComponent as Tabasco } from "../../../assets/img/CourseOnline/estados/27.svg";
import { ReactComponent as Tamaulipas } from "../../../assets/img/CourseOnline/estados/28.svg";
import { ReactComponent as Tlaxcala } from "../../../assets/img/CourseOnline/estados/29.svg";
import { ReactComponent as Veracruz } from "../../../assets/img/CourseOnline/estados/30.svg";
import { ReactComponent as Yucatan } from "../../../assets/img/CourseOnline/estados/31.svg";
import { ReactComponent as Zacatecas } from "../../../assets/img/CourseOnline/estados/32.svg";
/**
 * Funcíon que muestra la imagen de acuedo al idEntidad
 * @param {*} idEntidad
 */
const Images = ({ id }) => {
  switch (id) {
    case 1:
      return <Aguascalientes />;
    case 2:
      return <BCalifornia />;
    case 3:
      return <BCaliforniaSur />;
    case 4:
      return <Campeche />;
    case 5:
      return <Coahuila />;
    case 6:
      return <Colima />;
    case 7:
      return <Chiapas />;
    case 8:
      return <Chihuahua />;
    case 9:
      return <Df />;
    case 10:
      return <Durango />;
    case 11:
      return <Guanajuato />;
    case 12:
      return <Guerrero />;
    case 13:
      return <Hidalgo />;
    case 14:
      return <Jalisco />;
    case 15:
      return <Mexico />;
    case 16:
      return <Michoacan />;
    case 17:
      return <Morelos />;
    case 18:
      return <Nayarit />;
    case 19:
      return <NLeaon />;
    case 20:
      return <Oaxaca />;
    case 21:
      return <Puebla />;
    case 22:
      return <Queretaro />;
    case 23:
      return <QRoo />;
    case 24:
      return <SPotosi />;
    case 25:
      return <Sinaloa />;
    case 26:
      return <Sonora />;
    case 27:
      return <Tabasco />;
    case 28:
      return <Tamaulipas />;
    case 29:
      return <Tlaxcala />;
    case 30:
      return <Veracruz />;
    case 31:
      return <Yucatan />;
    case 32:
      return <Zacatecas />;

    default:
      return null;
  }
};

export default Images;
