import React from 'react';
import { Row, Form, Input, Button, Col, Modal, Icon } from 'antd';
import { basePath } from "../../../api/config";
import axios from "axios";
import './Recovery.scss';

const Recovery = (props) => {
    const { getFieldDecorator } = props.form;

    //Modales 
    function error(mensaje) {
        Modal.error({
          title: "Recuperación de Contraseña",
          content: (
            <div>
              <p>{mensaje}</p>
            </div>
          ),
          okText: "Ok",
        });
      }
    
      function sucess() {
        Modal.success({
          title: "Recuperación de Contraseña",
          content: (
            <div>
              <span style={{ textAlign: "center" }}>
                <Icon type="check-circle" />
              </span>
              <p>Enviamos un correo con tu contraseña</p>
              <br />
              <p>
              Te recomendamos revisar la <strong>bandeja de entrada, spam o correo no deseado</strong> desde tu correo electrónico.
              </p>
            </div>
          ),
          okText: "Ok",
        });
      }

     //Función para procesar los datos del registro.
  const recovery = (e) => {
    e.preventDefault();
    props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const url = `${basePath}/recoveryPassword`;
        axios.post(url, values).then((response) => {
          const { code } = response.data;
          if (code === 400 || code === 500) {
            //Modal con el error
            const mensaje = response.data.causa;
            error(mensaje);
          } else {
            //const mensaje = response.data.message;
            sucess();
          }
        });
      }
    });
    props.form.resetFields();
  };


    return (
        <div className="recovery">
            <Row>
                <Col span={6}></Col>
                <Col span={12}>
                    <h1>Recupera tu contraseña</h1>
                    <p>Ingresa tu correo electrónico para recuperar tu contraseña</p>
                    <Form onSubmit={recovery}>
                        <Form.Item label="Correo electrónico" hasFeedback>
                            {getFieldDecorator("email", {
                                rules: [
                                    {
                                        type: "email",
                                        message: "El correo no es válido.",
                                    },
                                    { required: true, message: "Dato requerido." },
                                ],
                            })(<Input autoComplete="off" placeholder="nombre@dominio.com" />)}
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit" style={{ width: '100%' }}>
                                Enviar
                            </Button>
                        </Form.Item>
                    </Form>
                </Col>
            <Col span={6}></Col>
            </Row>
        </div >

    );
}

const registerRecovery = Form.create()(Recovery);
export default registerRecovery;