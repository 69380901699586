import React, {useEffect} from "react";
import { Divider, Col } from "antd";
import Back from "../../../components/User/Public/Home/Back";

const Glosario = () => {
  function toTop() {
    window.scrollTo(0, 0);
}

  useEffect(() => {
    toTop();
  }, []);
  
  return (
    <div>
      <Back />
      <br />
      <br />
      {/* Letras */}
      <a href="#div_A">A</a>&nbsp;&nbsp;&nbsp;
      <a href="#div_C">C</a>&nbsp;&nbsp;&nbsp;
      <a href="#div_D">D</a>&nbsp;&nbsp;&nbsp;
      <a href="#div_E">E</a>&nbsp;&nbsp;&nbsp;
      <a href="#div_I">I</a>&nbsp;&nbsp;&nbsp;
      <a href="#div_L">L</a>&nbsp;&nbsp;&nbsp;
      <a href="#div_O">O</a>&nbsp;&nbsp;&nbsp;
      <a href="#div_P">P</a>&nbsp;&nbsp;&nbsp;
      <a href="#div_R">R</a>&nbsp;&nbsp;&nbsp;
      {/* Secciones */}
      <div id="div_A">
        <Divider orientation="left">A</Divider>
        <h2>Acreditación</h2>
        <Col span={16}>
          <p>
            Certificación o aprobación que brinda el Instituto Nacional
            Electoral (INE) a los ciudadanos, en este caso, para ser
            Observadores Electorales durante la Jornada Electoral. Para lo cual,
            se requiere cumplir con ciertos requisitos establecidos y validados
            por el INE, de tal forma que los ciudadanos dispongan de las
            capacidades y facultades necesarias para desempeñarse como
            Observadoras u observadores.
          </p>
        </Col>
      </div>
      <div id="div_C">
        <Divider orientation="left">C</Divider>
        <h2>Casilla</h2>
        <Col span={16}>
          <p>
            Son los espacios asignados para que los ciudadanos acudan a votar.
            Generalmente se localizan en edificios públicos, escuelas o casas
            particulares. En cada casilla se instala una mesa directiva de
            casilla que cuenta con la presencia de representantes de los
            Partidos Políticos, quienes vigilan la legalidad e imparcialidad del
            trabajo de los funcionarios que la componen. Se instala una casilla
            por cada 750 electores de la lista nominal de una sección electoral.
          </p>
        </Col>
      </div>
      <div id="div_D">
        <Divider orientation="left">D</Divider>
        <h2>Distrito</h2>
        <Col span={16}>
          <p>
            Es la división geográfica en que se organiza el territorio de un
            país con fines electorales. En él, se consideran los domicilios de
            los votantes para formar secciones electorales donde se instala
            proporcionalmente un número de casillas para depositar el voto el
            día de la Jornada Electoral. México está dividido en 300 distritos
            electorales.
          </p>
        </Col>
      </div>
      <div id="div_E">
        <Divider orientation="left">E</Divider>
        <h2>Escrito bajo protesta</h2>
        <Col span={16}>
          <p>
            Documento necesario para realizar registro para ser Observador
            Electoral, donde las y los ciudadanos manifiestan que cumplen con
            los requisitos establecidos en el artículo 217, numeral 1, inciso d)
            de la LGIPE.
          </p>
        </Col>
      </div>
      <div id="div_I">
        <Divider orientation="left">I</Divider>
        <h2>Informe de observadores</h2>
        <Col span={16}>
          <p>
            Documento que deberán entregar los observadores electorales
            debidamente acreditados, en el cual indicarán lo observado en el PE
            en el que hayan participado, mismo que se entrega en formato digital
            (editable) al INE o los OPL.
          </p>
        </Col>
      </div>
      <div id="div_L">
        <Divider orientation="left">L</Divider>
        <h2>Lista Nominal</h2>
        <Col span={16}>
          <p>
            Es la relación que contiene nombre y fotografía de los ciudadanos
            registrados en el Padrón Electoral y con Credencial para Votar
            vigente.
          </p>
        </Col>
      </div>
      <div id="div_O">
        <Divider orientation="left">O</Divider>
        <h2>Observador Electoral</h2>
        <Col span={16}>
          <p>
            Son ciudadanos/as mexicanos, facultados por la ley para observar los
            actos de preparación y desarrollo del Proceso Electoral, así como
            los actos de la jornada electoral.
          </p>
        </Col>
      </div>
      <div id="div_P">
        <Divider orientation="left">P</Divider>
        <h2>Proceso Electoral Federal</h2>
        <Col span={16}>
          <p>
            El proceso electoral federal en México se concibe como el conjunto
            ordenado y secuencial de actos y actividades regulados por la
            Constitución y la ley electoral que realizan las autoridades, los
            partidos políticos y los ciudadanos con el propósito de renovar
            periódicamente a los integrantes de los poderes Legislativo y
            Ejecutivo de la Unión.
          </p>
        </Col>
      </div>
      <div id="div_R">
        <Divider orientation="left">R</Divider>
        <h2>Representante ante casilla</h2>
        <Col span={16}>
          <p>
            Son ciudadanos registrados por un partido político ante el INE o
            ante el Organismo Público Local (OPL) de la entidad, para que el día
            de la elección lo representen en la casilla. Vigilan que las
            actividades de la Jornada Electoral se desarrollen de acuerdo con lo
            que establece la ley.
          </p>
        </Col>
      </div>
    </div>
  );
};

export default Glosario;
