import React, { useState, useEffect, useRef } from "react";
import { Button } from "antd";
import { Link, withRouter, Redirect } from "react-router-dom";
import Table from "../../../components/Admin/Organitation/Table";
import { ReactComponent as New } from "../../../assets/img/new_org_icon.svg";
import { getOrganitation } from "../../../api/organitation";
import useAuth from "../../../hooks/useAuth";
import { rol_captura, rol_consulta } from "../../../utils/Organitation/roles";
import { rol_modifica, rol_borrar } from "../../../utils/Organitation/roles";

function Organitation(props) {
  const isMounted = useRef(true);
  const [reload, setReload] = useState(false);
  const [organitation, setOrganitation] = useState({
    list: [],
    loading: false,
  });
  const {
    user: { rolUsuario },
  } = useAuth();
  const {
    proceso: { proceso },
    permission,
  } = props;
  const [rolModulo, setRolModulo] = useState({
    captura: false,
    modifica: false,
    consulta: false,
    borrar: false,
  });

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    //Valida los permisos que tienen el usuario
    let captura = false;
    let modifica = false;
    let consulta = false;
    let borrar = false;
    //Devuelve un true si cuenta con los permisos
    captura = rol_captura.includes(rolUsuario);
    modifica = rol_modifica.includes(rolUsuario);
    consulta = rol_consulta.includes(rolUsuario);
    borrar = rol_borrar.includes(rolUsuario);
    setRolModulo(() => ({ captura, modifica, consulta, borrar })); //Se almacenan en el hook
  }, [rolUsuario]);

  useEffect(() => {
    if (proceso) {
      setOrganitation({ loading: true }); //Se habilita el componente de loading
      const { idProcesoElectoral, idDetalleProceso } = proceso;
      //Se realiza la petición
      getOrganitation(true, idProcesoElectoral, idDetalleProceso)
        .then((res) => {
          if (isMounted.current) {
            const dataSource = [];
            res.data.map((org, index) => {
              return dataSource.push({
                key: index,
                indice: index + 1,
                ...org,
              });
            });
            setOrganitation({ list: dataSource, loading: false }); //Se almacenan en el hook
          }
        })
        .catch((error) => {
          console.log(error);
        });

      setReload(false);
    }
  }, [reload, proceso]);

  //Se valida que el usuario tenga permiso de consultar
  if (!permission.includes(rolUsuario)) {
    return <Redirect to={"/admin/home"} />;
  }

  return (
    <div style={{ marginTop: "15px", marginLeft: "20px" }}>
      {rolModulo.captura && (
        <>
          <h1>Registro de organizaciones</h1>
          <div>
            <Button className="convocation btn_secundario">
              <Link to={"/admin/organizacion/registro"}>
                <New />
                <br />
                Nueva organización
              </Link>
            </Button>
          </div>
        </>
      )}

      <div style={{ marginTop: "30px" }}>
        <h3>Lista de organizaciones</h3>
        <Table
          data={organitation.list}
          setReload={setReload}
          rolModulo={rolModulo}
          loading={organitation.loading}
          rolUsuario={rolUsuario}
        />
      </div>
    </div>
  );
}

export default withRouter(Organitation);
