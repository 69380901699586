import { types } from "../types/types";
/**
 * Acción que guarda la entidad o entidades a observar que seleccionó el observador
 */
export const entities = (state) => ({
  type: types.entidades,
  payload: state,
});

/**
 * Acción que limpia el store de las entidades a observar
 */
export const cleanEntities = (state) => ({
  type: types.limpiarEntidad,
  payload: state,
});

/**
 * Acción que guarda el temario en el store menu/temario
 */
export const setMenu = (state) => ({
  type: types.temario,
  payload: state,
});

/**
 * Acción que limpia el store de las entidades a observar
 */
export const cleanMenu = (state) => ({
  type: types.limpiarTemario,
  payload: state,
});

/**
 * Acción que guarda la última lección del ciudadano
 */
export const lastLesson = (state) => ({
  type: types.ultimaLeccion,
  payload: state,
});

/**
 * Acción que limpia la última lección del ciudadano
 */
export const cleanlastLesson = (state) => ({
  type: types.limpiarultimaLeccion,
  payload: state,
});

export const openModalAction = (state) => ({
  type: types.modal,
  payload: state,
});

export const closeModalAction = (state) => ({
  type: types.cerraModal,
  payload: state,
});
