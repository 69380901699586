import React, { useState, useEffect } from "react";
import { Input, Table } from "antd";
import { withRouter } from "react-router-dom";
import moment from "moment";

function TableParticipantes(props) {
    const {
        data, //Se recibe el array de los participantes
    } = props; //Variables que se reciben por los props
    const [dataTable, setDataTable] =  useState([]);   // Lista de participantes filtrado
    const [valueNombre, setValueNombre] = useState(""); //Hook para el filtrado de nombre en la tabla de participantes
    const [valclave, setValClave] = useState(""); //Hook para el filtrado de clave en la tabla de participantes

    useEffect(() => {
        setDataTable(data);
    }, [data]);

    //Función que filtra los registros por el nombre
    const FilterByNameInput = (
        <>
        Nombre
        <Input
            placeholder="Nombre"
            value={valueNombre}
            onChange={(e) => {
            const currValue = e.target.value;
            setValueNombre(currValue);
            const filteredData = data.filter((entry) =>
                entry.observadores.nombreCompleto
                .toString()
                .toLowerCase()
                .includes(currValue.toLowerCase())
            );
            setDataTable(filteredData);
            }}
        />
        </>
    );
    //Función que filtra los registros por clave de elector
    const FilterByClaveInput = (
        <>
        Clave de Elector
        <Input
            placeholder="Clave de Elector"
            valclave={valclave}
            onChange={(e) => {
            const currValue = e.target.value;
            setValClave(currValue);
            const filteredData = data.filter((entry) =>
                entry.observadores.claveElector
                .toString()
                .toLowerCase()
                .includes(currValue.toLowerCase())
            );
            setDataTable(filteredData);
            }}
        />
        </>
    );

    const columns = [
        {
            title: FilterByNameInput,
            dataIndex: "observadores.nombreCompleto",
            key: "nombreCompleto",
            width: 280,
        },
        {
            title: FilterByClaveInput,
            dataIndex: "observadores.claveElector",
            key: "claveElector",
            width: 280,
        },
        {
            title: "Folio",
            dataIndex: "observadores.folio",
            key: "folio",
            width: 120,
            align: "center",
        },
        {
            title: "Fecha de Validación",
            dataIndex: "observadores.fechaEstatus",
            key: "fechaEstatus",
            width: 120,
            align: "center",
            render: (record) => moment(record).format("DD/MM/YYYY"), //Formato a la fecha
        },
    ];

    const rowSelection = {
        onSelectAll: (selected, selectedRows) => {
            const datos = [];
            if(selectedRows.length > 0) { //Seleccionaron todos
                for (let i = 0; i < dataTable.length; i++) {
                    dataTable[i].participanteSeleccionado = true;
                    datos.push(dataTable[i]);
                };
            } else {
                for (let i = 0; i < dataTable.length; i++) {
                    dataTable[i].participanteSeleccionado = false;
                    datos.push(dataTable[i]);
                };
            }
            setDataTable(datos);
        },
        onSelect: (record) => {
            const datos = [];
            for (let i = 0; i < dataTable.length; i++) {
                if (dataTable[i].observadores.idObservador === record.observadores.idObservador) {
                    let value = dataTable[i].participanteSeleccionado;
                    dataTable[i].participanteSeleccionado = !value;
                }
                datos.push(dataTable[i]);
            }
            setDataTable(datos);
        },
        getCheckboxProps: record => ({
            checked: record.participanteSeleccionado === true,
        }),
    };

    return (
        <Table
            dataSource={dataTable}
            columns={columns}
            rowSelection={rowSelection}
            pagination={{ pageSize: 10 }} 
            scroll={{ x: 900, y: 200 }}
        />
    );
}
export default withRouter(TableParticipantes);