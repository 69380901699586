import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import routes from "./config/routes"; //Se importan todas las rutas del sistema
import AuthProvider from "./api/AuthProvider"; //Se importa la configuración del provider
import { ConfigProvider } from "antd";
import esES from "antd/es/locale/es_ES";
//Redux - Se importa la librería de redux y el store
import { Provider } from "react-redux";
import store from "./redux/store";

function App() {
  return (
    //Se pone el AuthProvider como componente raiz para pasar la información a todos los hijos
    <Provider store={store}>
      <ConfigProvider locale={esES}>
        <AuthProvider>
          <Router>
            <Switch>
              {routes.map((route, i) => (
                <RoutesWithSubRoutes key={i} {...route} />
              ))}
            </Switch>
          </Router>
        </AuthProvider>
      </ConfigProvider>
    </Provider>
  );
}
//Función que itera las rutas
function RoutesWithSubRoutes(route) {
  return (
    <Route
      path={route.path}
      exact={route.exact}
      render={(props) => <route.component routes={route.routes} {...props} />}
    />
  );
}
export default App;
