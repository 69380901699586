import React, { useEffect, useRef } from "react";
import BlockUi from "react-block-ui";
import logo from "../../assets/img/loader/loader.gif";
import "react-block-ui/style.css";

export default function Loader(props) {
  const { children, loading, message } = props;
  const isMounted = useRef(true); //Referencia que permite verificar si el componente esta visible

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  //Función para mostrar el loader
  return (
    <BlockUi
      id="example"
      tag="div"
      blocking={isMounted && loading}
      loader={
        <>
          <img src={logo} style={{ width: "200px" }} alt="load" />
          <p>{message && message}</p>
        </>
      }
    >
      {children}
    </BlockUi>
  );
}
