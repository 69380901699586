import { Modal } from "antd";
import React from "react";
import { actualizaEstadoSolicitud } from "../../api/status";

export const Ast = () => {
    return <span style={{ color: "#f5222d" }}>*</span>;
};

export function actualizaEstado(idObservador, idEstatus, idMotivo, justificacion, usuario,idProceso, history) {
    actualizaEstadoSolicitud(idObservador, idEstatus, idMotivo, justificacion, usuario, idProceso).then((res) => {
      if(res.data.code === 200) {
        console.log("Exitoso");
        history.push({pathname:"/admin/estadoSolicitud", alertConfirmacion:true})
      } else {
        Modal.error({
          title: "Atención",
          content: res.data.message,
          onOk() {
            history.push({pathname:"/admin/estadoSolicitud", alertConfirmacion:false})
          }
        });
      }
    });
  }
