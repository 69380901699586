import React from "react";
import { Row } from "antd";
//import { ReactComponent as SolicitudNva } from "../../../assets/img/homeInt/solicitudNva_icon.svg";
//import { ReactComponent as SolicitudObsAtend } from "../../../assets/img/homeInt/solicitudObsAtend_icon.svg";
import { ReactComponent as SolicitudRevisar } from "../../../assets/img/homeInt/solicitudRevisar_icon.svg";

const RequestStatus = (props) => {
const {conteo} = props;

  return (
    <div>
{/*       <Row>
        <div>
          <SolicitudNva />000 Solicitudes nuevas
        </div>
      </Row> */}
      <Row>
        <div>
          <SolicitudRevisar/>&nbsp;&nbsp; {conteo} Solicitudes sin revisión
        </div>
      </Row>
{/*       <Row>
        <div>
          <SolicitudObsAtend/>000 Solicitudes con observaciones atendidas
        </div>
      </Row> */}
    </div>
  );
};

export default RequestStatus;
