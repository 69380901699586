import React from "react";
import { Row, Col, Form, Radio, Skeleton } from "antd";
import Viewer from "../../../Generals/Viewer";
import "./Steps.scss";
import { radioButton } from "../../../../utils/rules";
import { AlertInfo } from "../../../../utils/Validations/functions";

export default function Documents(props) {
  //Se reciben los valores por props
  const { setScreen, getFieldDecorator, setDatos, disabled } = props;
  const { datos, changeRadio, user, screen, alert } = props;

  //Almacenamiento de imagenes
  let front = [{ src: user !== null ? user.docs[0].imagen : [] }];
  let back = [{ src: user !== null ? user.docs[1].imagen : [] }];
  let photo = [{ src: user !== null ? user.docs[2].imagen : [] }];

  //Funciones para hablitar el botón de enviar observaciones
  const onFront = (e) => {
    !e.target.value
      ? setScreen({ ...screen, frente: false })
      : setScreen({ ...screen, frente: true });
    setDatos({ ...datos, frente: e.target.value });
  };

  const onReverse = (e) => {
    !e.target.value
      ? setScreen({ ...screen, vuelta: false })
      : setScreen({ ...screen, vuelta: true });
    setDatos({ ...datos, vuelta: e.target.value });
  };
  //////////////////////////////////////////////////////
  return (
    <>
      {user !== null ? (
        <>
          <Row gutter={24}>
            <Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24}>
              <div
                style={
                  screen.image ? { pointerEvents: "none", opacity: "0.4" } : {}
                }
              >
                <div id="credFrente" className="credFrente" />
                <Viewer container={"credFrente"} image={front} />
              </div>
            </Col>
            <Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24}>
              <h4>Credencial de elector (frente)</h4>
              <AlertInfo disable={alert.frente} />
              <Form.Item label="¿Los datos en la imagen son legibles?">
                {getFieldDecorator("frente", {
                  rules: radioButton,
                  initialValue: datos.frente,
                })(
                  <Radio.Group onChange={onFront} disabled={disabled.frente}>
                    <Radio value={true}>Sí</Radio>
                    <Radio value={false}>No</Radio>
                  </Radio.Group>
                )}
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={24} style={{ marginTop: 100 }}>
            <Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24}>
              <div
                style={
                  screen.image ? { pointerEvents: "none", opacity: "0.4" } : {}
                }
              >
                <div id="credVuelta" className="credVuelta" />
                <Viewer container={"credVuelta"} image={back} />
              </div>
            </Col>
            <Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24}>
              <h4>Credencial de elector (vuelta)</h4>
              <AlertInfo disable={alert.vuelta} />
              <Form.Item label="¿Los datos en la imagen son legibles?">
                {getFieldDecorator("vuelta", {
                  rules: radioButton,
                  initialValue: datos.vuelta,
                })(
                  <Radio.Group onChange={onReverse} disabled={disabled.vuelta}>
                    <Radio value={true}>Sí</Radio>
                    <Radio value={false}>No</Radio>
                  </Radio.Group>
                )}
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={24} style={{ marginTop: 100 }}>
            <Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24}>
              <div
                style={
                  screen.image ? { pointerEvents: "none", opacity: "0.4" } : {}
                }
              >
                <div id="foto" className="foto" />
                <Viewer container={"foto"} image={photo} />
              </div>
            </Col>
            <Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24}>
              <h4>Fotografía</h4>
              <AlertInfo disable={alert.foto} />
              <Form.Item label="¿La fotografía cumple con los siguientes requisitos?">
                <ul>
                  <li>Rostro y mirada al frente</li>
                  <li>Fotografía nítida y enfocada</li>
                  <li>Fondo claro</li>
                  <li>Sin accesorios o lentes</li>
                </ul>
                {getFieldDecorator("foto", {
                  rules: radioButton,
                  initialValue: datos.foto,
                })(
                  <Radio.Group
                    onChange={changeRadio("foto")}
                    disabled={disabled.foto}
                  >
                    <Radio value={true}>Sí</Radio>
                    <Radio value={false}>No</Radio>
                  </Radio.Group>
                )}
              </Form.Item>
            </Col>
          </Row>
        </>
      ) : (
        <Skeleton loading={true} active paragraph={{ rows: 30 }} />
      )}
    </>
  );
}
