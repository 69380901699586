import ExcelJS from 'exceljs/dist/es5/exceljs.browser.js'
import { saveAs } from 'file-saver'

//Estilos generales
const fuenteTitulo = {
  name: "Arial",
  size: 10,
  bold: true
};
const fuenteDatos = {
  name:"Arial",
  size: 10
}
const estiloGris = {
  type: "pattern",
  pattern: "solid",
  fgColor: { argb: "CCC2C2C2" },
  bgColor: { argb: "CCC2C2C2" }
};
const bordesTitulos = {
    top: {style:'thin'},
    left: {style:'thin'},
    bottom: {style:'thin'},
    right: {style:'thin'}
};
const bordesOrg = {
    top: {style:'thin'},
    left: {style:'thin'},
    right: {style:'thin'}
};
const bordesOrg2 = {
    bottom: {style:'thin'}
};

export async function descargaExcel(sheetName, nombreReporte, datos, sistema) {
  //Creación del workbook
  const wb = new ExcelJS.Workbook()
  wb.creator = 'Sistema de Observadores Electorales 11.0';
  wb.lastModifiedBy = 'Sistema de Observadores Electorales';
  wb.created = new Date();
  //Creación de nueva hoja
  const sheet = wb.addWorksheet(sheetName);
  /*sheet.views = [
    {state: 'frozen', xSplit: 1}  // para fijar la primera columna
    ];*/
  //Datos generales del reporte
  sheet.mergeCells("A1:Q1");
  sheet.getCell("A1").value = "Proceso Electoral Federal 2020 - 2021";
  sheet.getCell("A1").font = fuenteTitulo;
  sheet.getCell("A1").alignment = { horizontal: "center"};
  sheet.mergeCells("A2:Q2");
  sheet.getCell("A2").value = nombreReporte;
  sheet.getCell("A2").font = fuenteTitulo;
  sheet.getCell("A2").alignment = { horizontal: "center"};
  var fila;

  sheet.unMergeCells("A1");
  sheet.mergeCells("A1:J1");
  sheet.getCell("A1").value = "Proceso Electoral Federal 2020 - 2021";
  sheet.unMergeCells("A2");
  sheet.mergeCells("A2:J2");
  sheet.getCell("A2").value = nombreReporte;

  const rowFechaGeneracion = sheet.getRow(4);
  rowFechaGeneracion.values = [ "Fecha y horario de generación:", `${sistema.fecha} ${sistema.hora}` ]
  sheet.getCell("A4").font = fuenteTitulo;
  sheet.getCell("A4").fill = estiloGris;
  sheet.getCell("B4").font = fuenteDatos;

  const rowTituloSolicitudes = sheet.getRow(5);
  rowTituloSolicitudes.values = ["", "Solicitudes registradas", "","","","","", "Solicitudes validadas", "","","","","", "Solicitudes capacitadas",
  "","","","","","","", "Solicitudes acreditadas", "","","","","", "Informes capturados" ]

    sheet.getCell("B5").alignment = { horizontal: "center"}; // Solicitudes registradas
    sheet.getCell("B5").fill = estiloGris;
    sheet.getCell("B5").font = fuenteTitulo;
    sheet.getCell("B5").border = bordesTitulos;
    sheet.mergeCells("B5:G5");
    sheet.getCell("H5").alignment = { horizontal: "center"}; // Solicitudes validadas
    sheet.getCell("H5").fill = estiloGris;
    sheet.getCell("H5").font = fuenteTitulo;
    sheet.getCell("H5").border = bordesTitulos;
    sheet.mergeCells("H5:M5");
    sheet.getCell("N5").alignment = { horizontal: "center"}; // Solicitudes capacitadas
    sheet.getCell("N5").fill = estiloGris;
    sheet.getCell("N5").font = fuenteTitulo;
    sheet.getCell("N5").border = bordesTitulos;
    sheet.mergeCells("N5:U5");
    sheet.getCell("V5").alignment = { horizontal: "center"}; // Solicitudes acreditadas
    sheet.getCell("V5").fill = estiloGris;
    sheet.getCell("V5").font = fuenteTitulo;
    sheet.getCell("V5").border = bordesTitulos;
    sheet.mergeCells("V5:AA5");
    sheet.getCell("AB5").alignment = { horizontal: "center"}; // Informes capturados
    sheet.getCell("AB5").fill = estiloGris;
    sheet.getCell("AB5").font = fuenteTitulo;
    sheet.getCell("AB5").border = bordesTitulos;
    sheet.mergeCells("AB5:AF5");

  const rowTituloOrigen = sheet.getRow(6);
  rowTituloOrigen.values = ["Nombre de la organización", "Origen de la solicitud", "","","", "Sexo", "", "Origen de la solicitud", "","","", "Sexo", "",
  "Origen de la solicitud", "","","", "Modalidad", "", "Sexo", "", "Origen de la solicitud", "","","", "Sexo", "", "Medio de captura", "","", "Sexo", "" ]

  sheet.getCell("A6").alignment = { horizontal: "center"};  // Organización
  sheet.getCell("A6").fill = estiloGris;
  sheet.getCell("A6").font = fuenteTitulo;
  sheet.getCell("A6").border = bordesOrg;
  sheet.getCell("B6").alignment = { horizontal: "center"};  // Recibidas: Origen de la solicitud 
  sheet.getCell("B6").fill = estiloGris;
  sheet.getCell("B6").font = fuenteTitulo;
  sheet.getCell("B6").border = bordesTitulos;
  sheet.mergeCells("B6:E6");
  sheet.getCell("F6").alignment = { horizontal: "center"};  // Sexo
  sheet.getCell("F6").fill = estiloGris;
  sheet.getCell("F6").font = fuenteTitulo;
  sheet.getCell("F6").border = bordesTitulos;
  sheet.mergeCells("F6:G6");
  sheet.getCell("H6").alignment = { horizontal: "center"};  // Validadas: Origen de la solicitud 
  sheet.getCell("H6").fill = estiloGris;
  sheet.getCell("H6").font = fuenteTitulo;
  sheet.getCell("H6").border = bordesTitulos;
  sheet.mergeCells("H6:K6");
  sheet.getCell("L6").alignment = { horizontal: "center"};  // Sexo
  sheet.getCell("L6").fill = estiloGris;
  sheet.getCell("L6").font = fuenteTitulo;
  sheet.getCell("L6").border = bordesTitulos;
  sheet.mergeCells("L6:M6");
  sheet.getCell("N6").alignment = { horizontal: "center"};  // Capacitadas: Origen de la solicitud 
  sheet.getCell("N6").fill = estiloGris;
  sheet.getCell("N6").font = fuenteTitulo;
  sheet.getCell("N6").border = bordesTitulos;
  sheet.mergeCells("N6:Q6");
  sheet.getCell("R6").alignment = { horizontal: "center"};  // Modalidad
  sheet.getCell("R6").fill = estiloGris;
  sheet.getCell("R6").font = fuenteTitulo;
  sheet.getCell("R6").border = bordesTitulos;
  sheet.mergeCells("R6:S6");
  sheet.getCell("T6").alignment = { horizontal: "center"};  // Sexo
  sheet.getCell("T6").fill = estiloGris;
  sheet.getCell("T6").font = fuenteTitulo;
  sheet.getCell("T6").border = bordesTitulos;
  sheet.mergeCells("T6:U6");
  sheet.getCell("V6").alignment = { horizontal: "center"};  // Acreditadas: Origen de la solicitud 
  sheet.getCell("V6").fill = estiloGris;
  sheet.getCell("V6").font = fuenteTitulo;
  sheet.getCell("V6").border = bordesTitulos;
  sheet.mergeCells("V6:Y6");
  sheet.getCell("Z6").alignment = { horizontal: "center"};  // Sexo
  sheet.getCell("Z6").fill = estiloGris;
  sheet.getCell("Z6").font = fuenteTitulo;
  sheet.getCell("Z6").border = bordesTitulos;
  sheet.mergeCells("Z6:AA6");  
  sheet.getCell("AB6").alignment = { horizontal: "center"};  // Medio de captura
  sheet.getCell("AB6").fill = estiloGris;
  sheet.getCell("AB6").font = fuenteTitulo;
  sheet.getCell("AB6").border = bordesTitulos;
  sheet.mergeCells("AB6:AD6");  
  sheet.getCell("AE6").alignment = { horizontal: "center"};  // Sexo
  sheet.getCell("AE6").fill = estiloGris;
  sheet.getCell("AE6").font = fuenteTitulo;
  sheet.getCell("AE6").border = bordesTitulos;
  sheet.mergeCells("AE6:AF6"); 

  const divisiones = [ "A7" ]

     const rowTituloDatos = sheet.getRow(7);
      rowTituloDatos.height = 15;
      rowTituloDatos.values = [
        "", "INE", "OPL", "Portal", "Total", "Mujeres", "Hombres", "INE", "OPL", "Portal", "Total", "Mujeres", "Hombres", "INE", "OPL", "Portal", "Total", 
        "En línea", "Presencial", "Mujeres", "Hombres", "INE", "OPL", "Portal", "Total", "Mujeres", "Hombres", "INE", "Portal", "Total", "Mujeres", "Hombres"
      ];
      rowTituloDatos.font = fuenteTitulo;
      rowTituloDatos.eachCell(function(cell) {
        cell.fill = estiloGris;
        cell.alignment = { vertical: "middle", horizontal: "center", wrapText: true };

        if (divisiones.some(v => (v !== cell.address.toString()))) {
            cell.border = bordesTitulos;
        } else {
            cell.border = bordesOrg2;
        }
      });
      //Datos del reporte
      fila = 8;
      datos.forEach((e) => {
        const rowDatos = sheet.getRow(fila);
        rowDatos.font = fuenteDatos;
        rowDatos.values = [            
          e.organizacion, e.ineRegistrados, e.oplRegistrados, e.portalRegistrados, e.totalRegistrados, e.mujerRegistrados, e.hombreRegistrados, e.ineValidados, e.oplValidados,
          e.portalValidados, e.totalValidados, e.mujerValidados, e.hombreValidados, e.ineCapacitados, e.oplCapacitados, e.portalCapacitados, e.totalCapacitados, 
          e.lineaCapacitados, e.presencialCapacitados, e.mujerCapacitados, e.hombreCapacitados, e.ineAcreditados, e.oplAcreditados, e.portalAcreditados, e.totalAcreditados,
          e.mujerAcreditados, e.hombreAcreditados, e.ineInformes, e.portalInformes, e.totalInformes, e.mujerInformes, e.hombreInformes
        ];
        fila++;
      });
      sheet.getColumn(1).width = 35;
      sheet.getColumn(2).width = 10;
      sheet.getColumn(3).width = 10;
      sheet.getColumn(4).width = 10;
      sheet.getColumn(5).width = 10;
      sheet.getColumn(6).width = 10;
      sheet.getColumn(7).width = 10;
      sheet.getColumn(8).width = 10;
      sheet.getColumn(9).width = 10;
      sheet.getColumn(10).width = 10;
      sheet.getColumn(11).width = 10;
      sheet.getColumn(12).width = 10;
      sheet.getColumn(13).width = 10;
      sheet.getColumn(14).width = 10;
      sheet.getColumn(15).width = 10;
      sheet.getColumn(16).width = 10;
      sheet.getColumn(17).width = 10;
      sheet.getColumn(18).width = 11;
      sheet.getColumn(19).width = 11;
      sheet.getColumn(20).width = 10;
      sheet.getColumn(21).width = 10;
      sheet.getColumn(22).width = 10;
      sheet.getColumn(23).width = 10;
      sheet.getColumn(24).width = 10;
      sheet.getColumn(25).width = 10;
      sheet.getColumn(26).width = 10;
      sheet.getColumn(27).width = 10;
      sheet.getColumn(28).width = 10;
      sheet.getColumn(29).width = 10;
      sheet.getColumn(30).width = 10;
      sheet.getColumn(31).width = 10;
      sheet.getColumn(32).width = 10;

  //Creación del excel
  const buf = await wb.xlsx.writeBuffer();
  saveAs(new Blob([buf]), nombreReporte + ".xlsx");
}