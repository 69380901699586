import { Table } from "antd";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import moment from "moment";

function TableTransp(props) {
    const {
        data,
        loading
    } = props;
    const [dataSource, setDataSource] = useState([]); //hook para almacenar el array

    useEffect(() => {
        setDataSource(data);
    }, [data]);

    const columnas = [
        {
            title: "Ejercicio",
            dataIndex: "ejercicio",
        },
        {
            title: "Fecha de inicio del periodo que se informa",
            dataIndex: "fechaInicio",
        },
        {
            title: "Fecha de término del periodo que se informa",
            dataIndex: "fechaFin",
        },
        {
            title: "Nombre(s)",
            dataIndex: "nombre",
        },
        {
            title: "Primer apellido",
            dataIndex: "aPaterno",
        },
        {
            title: "Segundo apellido",
            dataIndex: "aMaterno",
        },
        {
            title: "Razón social, en su caso",
            dataIndex: "razonSocial",
        },
        {
            title: "Lugar donde se realizan actividades de observación (catálogo)",
            dataIndex: "lugarObservacion",
        },
        {
            title: "Fecha de obtención de la acreditación",
            dataIndex: "fechaAcreditacion",
            render: (record) => moment(record).isValid() ? moment(record).format("DD/MM/YYYY") : " ",//Formato a la fecha y hora
        },
        {
            title: "Hipervínculo formato de solicitud de acreditación",
            dataIndex: "hipervinculo",
            render: (record) => <a style={{display: "table-cell"}} rel="noopener noreferrer" target="_blank" href={record}>{record}</a>,
        },
        {
            title: "Área(s) responsable(s) que genera(n), posee(n), publica(n) y actualizan la información",
            dataIndex: "areaResp",
        },
        {
            title: "Fecha de validación",
            dataIndex: "fechaValidacion",
            render: (record) => moment(record).isValid() ? moment(record).format("DD/MM/YYYY") : " ",//Formato a la fecha y hora
        },
        {
            title: "Fecha de actualización",
            dataIndex: "fechaActualizacion",
        },
        {
            title: "Nota",
            dataIndex: "nota",
            width:250,
        },
        {
            title: "Año",
            dataIndex: "anio",
        },
        {
            title: "Periodo de carga",
            dataIndex: "periodoCarga",
        },
        {
            title: "Nota URL",
            dataIndex: "notaURL",
            width: 250,
        }
    ];
    
    return (
        <>
            <Table columns={columnas}
                dataSource={dataSource}
                loading={loading}
                locale={{ emptyText: "Sin registros" }}
                scroll={{ x:true }}
            />
        </>
    );
}

export default withRouter(TableTransp);
