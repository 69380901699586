import React from "react";
import { GuiaMaterial, MatApoyo } from "../Generals";

export default function Lesson7({ data, setLoading }) {
  return (
    <div className="texto_parrafo">
      <p className="bold">
        ¡Vamos a comenzar con la séptima lección! ¿Estás listo/a? ¿Sabes cómo
        votan las y los mexicanos residentes en el extranjero? Haz clic en el
        siguiente video para que conozcas más de este tema.
      </p>
      {data.length && <MatApoyo array={[data[0]]} />}
      <p className="bold">
        Te invitamos a que revises el material de apoyo y consultes el{" "}
        <GuiaMaterial setLoading={setLoading}>
          {" "}
          CAPÍTULO VII DE TU GUÍA TEMÁTICA PARA CAPACITAR A LAS Y LOS
          OBSERVADORES ELECTORALES
        </GuiaMaterial>{" "}
        que está disponible en los materiales de consulta de este curso.
      </p>
      {data.length && <MatApoyo array={[data[1]]} />}
      <p className="bold">
        Para que continúes con la siguiente lección, no olvides realizar la
        actividad correspondiente, para reforzar lo que aprendiste.
      </p>
    </div>
  );
}
