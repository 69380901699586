import { basePath } from "./config";
import axios from "axios";
import { getAccessTokenApi } from "./auth";

export function save(organitation) {
  const url = `${basePath}/organizacion`;
  const jwt = getAccessTokenApi();
  return axios.post(url, organitation, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: jwt,
    },
  });
}

export function saveOrg(organitation) {
  const url = `${basePath}/organizaciones`;
  const jwt = getAccessTokenApi();
  return axios.post(url, organitation, {
    headers: {Authorization: jwt },
  });
}

export function update(organitation) {
  const url = `${basePath}/organizacion/actualizar`;
  const jwt = getAccessTokenApi();
  return axios.post(url, organitation, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: jwt,
    },
  });
}

export function updateOrg(organitation) {
  const url = `${basePath}/organizaciones/actualizar`;
  const jwt = getAccessTokenApi();
  return axios.post(url, organitation, {
    headers: {Authorization: jwt},
  });
}

export function getOrganitation(status, idProceso, idDetalle) {
  const url = `${basePath}/organizacion/tabla`;
  const jwt = getAccessTokenApi();
  return axios.post(
    url,
    { estatus: status, idProceso: idProceso, idDetalle: idDetalle },
    {
      headers: { Authorization: jwt, Parametrizacion: idProceso },
    }
  );
}

export function getTypeOrganitation() {
  const url = `${basePath}/organizacion/tipos`;
  const jwt = getAccessTokenApi();
  return axios.post(
    url,
    {},
    {
      headers: { Authorization: jwt },
    }
  );
}

export function deleted(id) {
  const url = `${basePath}/organizacion/estatus`;
  const jwt = getAccessTokenApi();
  return axios.post(
    url,
    { idOrganizacion: id },
    {
      headers: { Authorization: jwt },
    }
  );
}

export function getEntidad() {
  const url = `${basePath}/geografico`;
  const jwt = getAccessTokenApi();
  return axios.post(url, {}, { headers: { Authorization: jwt } });
}

export function getDownload(json) {
  const url = `${basePath}/organizacion/descarga`;
  const jwt = getAccessTokenApi();
  return axios.post(url, json, {
    headers: {
      "Content-Type": "application/json",
      Authorization: jwt,
    },
    responseType: "blob",
  });
}
