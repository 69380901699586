import { Col, Row } from "antd";
import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { ReactComponent as LogoLeccion } from "../../../assets/img/CourseOnline/cursoLinea_leccion.svg";

/**
 * Componente que muestra el encabezado de cada lección.
 * Recibe la entidad del curso que selecciono y el titulo.
 * @param {*} entitie
 * @param {*} lesson
 */
export const Title = ({ entitie, lesson, id }) => {
  //Se obtiene el temario del state
  const { temario } = useSelector((state) => state.menu);
  const [title, setTitle] = useState("");

  useEffect(() => {
    setTitle(getIndex());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  //Función que obtiene el índice del titulo de la lección y así poder mostrar el número correspondiente
  const getIndex = () => {
    let index = temario.findIndex((obj) => obj.leccion === lesson);
    return index !== undefined ? index + 1 : "";
  };

  return (
    <>
      <Row className="encabezado" gutter={24}>
        <Col span={2} xxl={2} xl={2} lg={2} md={2} sm={24} xs={24}>
          <LogoLeccion className="title" />
        </Col>
        <Col span={22} xxl={22} xl={22} lg={22} md={22} sm={24} xs={24}>
          <div>
            <span>
              <b>Curso de capacitación virtual-</b>
              {entitie}
            </span>
            <h1>
              {/* {<>Lección {getIndex()}: </>} */}
              {id >= 10 || id === undefined ? (
                <>{lesson}</>
              ) : (
                <>
                  Lección {title}: {lesson.replace("Lección:", "")}
                </>
              )}
            </h1>
          </div>
        </Col>
      </Row>
      <hr />
    </>
  );
};
