import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Form, Radio, Input, Checkbox } from "antd";
import Viewer from "../../../Generals/Viewer";
import "./Steps.scss";
import {
  AlertInfo,
  addCp,
  AlertForeig,
} from "../../../../utils/Validations/functions";
import { getNameState } from "../../../../api/validation";
import { radioButton, observaciones } from "../../../../utils/rules";

export default function Adress(props) {
  const { getFieldDecorator, form, changeRadio } = props;
  const { datos, user, disabled, alert, imagen } = props;
  const [state, setState] = useState({});
  //Destructiring de las propiedades de user
  const { calle, numExterno, numInterno, codigoPostal } = user;
  const isMounted = useRef(true); //Referencia que permite verificar si el componente esta visible
  const SIN_DOMICILIO =
    "El domicilio no es obligatorio en solicitudes de mexicanos residentes en el extranjero.";
  //Se carga la imagen
  const front = [{ src: user.docs[0].imagen }];
  const [block, setBlock] = useState(false);

  //useEffect que valida que el componente este seleccionado de lo contrario cancela cualquier subcripción
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    const { estado, municipio, tipoObservador } = user;
    if (tipoObservador === 0) {
      //console.log("Mexicano ");
      getNameState(estado, municipio)
        .then((res) => {
          if (isMounted.current) {
            setState(res.data);
          }
        })
        .catch((error) => {
          console.log("Ocurrió un error al obtener la dirección", error);
        });
    }
  }, [user]);

  useEffect(() => {
    if (user.tipoObservador === 1) {
      //console.log("Extranjero");
      validate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const validate = () => {
    form.setFieldsValue({
      direccion: true,
    });
    datos.direccion = true;
    setBlock(true);
  };

  //Muestra/Oculta la pregunta si el domicilio no se muestra en la credencial
  const onChange = (e) => {
    //e.target.checked ? (datos.direccion = true, datos.checkbox=true) : (datos.direccion = undefined);
    if (e.target.checked) {
      datos.direccion = true;
      datos.checkbox = true;
    } else {
      datos.direccion = undefined;
      datos.checkbox = e.target.checked;
    }
  };

  return (
    <Row gutter={24}>
      <Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24}>
        <h4>Credencial de elector (frente)</h4>
        <div style={imagen ? { pointerEvents: "none", opacity: "0.4" } : {}}>
          <div id="credFrente" className="credFrente" />
          <Viewer container={"credFrente"} image={front} />
        </div>
      </Col>
      <h4>Datos capturados</h4>
      <Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24}>
        <div className="data">
          <div className="alert">
            {user.tipoObservador === 1 && (
              <>
                <AlertForeig text={SIN_DOMICILIO} />
                <br />
              </>
            )}
            <AlertInfo disable={alert.direccion} />
          </div>
          <Col span={24}>
            {getFieldDecorator("checkbox", {
              valuePropName: "checked",
              initialValue: datos.checkbox,
            })(
              <Checkbox
                onChange={onChange}
                //checked={checked}
                disabled={user.tipoObservador === 1 ? block : disabled.direccion}
              >
                El domicilio no se muestra en la credencial
              </Checkbox>
            )}
           </Col>
          <Col span={24}><Form.Item label="Calle">{calle}</Form.Item></Col>
          <Col span={12}>
            <Form.Item label="No. exterior">{numExterno}</Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="No. interior">{numInterno}</Form.Item>
          </Col>
          <Col span={24}>
            {user.tipoObservador === 0 && (
              <Form.Item label="Colonia">{user.localidad}</Form.Item>
            )}
          </Col>
          <Col span={24}><Form.Item label="Código Postal">{addCp(codigoPostal)}</Form.Item></Col>
            <Col span={12}>
              <Form.Item
                label={user.tipoObservador === 1 ? "Ciudad" : "Municipio"}
              >
                {user.tipoObservador === 1 ? user.ciudad : state.municipio}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Entidad">
                {user.tipoObservador === 1 ? user.estadoExt : state.estado}
              </Form.Item>
            </Col>

          <Col span={24}>
          {!datos.checkbox && (
            <div hidden={user.tipoObservador === 1 ? block : disabled.direccion}>
              <Form.Item label="¿Los datos coinciden con la credencial de elector?">
                {getFieldDecorator("direccion", {
                  rules: radioButton,
                  initialValue: datos.direccion,
                })(
                  <Radio.Group onChange={changeRadio("direccion")}>
                    <Radio value={true}>Sí</Radio>
                    <Radio value={false}>No</Radio>
                  </Radio.Group>
                )}
              </Form.Item>
              {form.getFieldValue("direccion") === false && (
                <Form.Item label="Observaciones">
                  {getFieldDecorator("direccionObs", {
                    rules: observaciones,
                    initialValue: datos.direccionObs,
                  })(
                    <Input.TextArea
                      onChange={changeRadio("direccionObs")}
                      disabled={disabled.direccionObs}
                      maxLength={150}
                    />
                  )}
                </Form.Item>
              )}
            </div>
          )}
          </Col>
        </div>
      </Col>
    </Row>
  );
}
