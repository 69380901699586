import React from "react";
import { Link } from "react-router-dom";
import useAuth from "../../../../hooks/useAuth";
import { Icon } from "antd";

export default function Back() {
  const {
    ciudadano: { ciudadano },
  } = useAuth();

  return (
    <Link to={ciudadano ? "/ciudadano/inicio" : "/"} className="back">
      <Icon type="play-circle" theme="filled" rotate="180" /> Regresar
    </Link>
  );
}
