import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { existConstancy } from "../api/courses";
import { updateConstancy, cleanConstancy } from "../redux/actions/constancy";

export const useConstancy = (id) => {
  const dispatch = useDispatch();

  useEffect(() => {
    existConstancy(id)
      .then((res) => {
        const { constancia } = res.data;
        dispatch(updateConstancy(constancia));
      })
      .catch((e) => {
        console.log("Error consulta-constancia", e);
        dispatch(cleanConstancy());
      });
  }, [id, dispatch]);
};
