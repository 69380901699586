import { Col, Row } from "antd";
import React, { useEffect, useRef } from "react";
import { GuiaMaterial, MatApoyo } from "../Generals";
import { RenderDocument } from "../Material";

export default function Lesson3({ data, setLoading }) {
  const isMounted = useRef(true);
  let copyArray = data;
  let array = copyArray.length && [data[0], data[1]];

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  return (
    <div className="texto_parrafo">
      <p>
        Como lo vimos en la lección anterior, el sistema electoral mexicano
        comprende dos ámbitos: el federal y el local, haz clic en los videos
        para conocer las autoridades que intervienen en cada uno.
      </p>
      {data.length &&
        array.map((doc, i) => (
          <Row gutter={24} key={i} justify="center" className="material">
            <Col span={24} xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
              <RenderDocument doc={doc} />
            </Col>
          </Row>
        ))}
      <br />
      {/* <MatApoyo
        array={[
          { titulo: "Presentación 3. Autoridades Electorales en México" },
        ]}
      /> */}
      <b>
        A continuación, revisa el siguiente material de apoyo para reforzar lo
        que aprendiste.
      </b>
      {data.length && <MatApoyo array={[data[2]]} />}
      <p className="bold">
        Para profundizar tus conocimientos sobre el tema de esta lección puedes
        revisar el{" "}
        <GuiaMaterial setLoading={setLoading}>
          CAPÍTULO III DE TU GUÍA TEMÁTICA PARA CAPACITAR A LAS Y LOS
          OBSERVADORES ELECTORALES
        </GuiaMaterial>{" "}
        que está disponible en los materiales de consulta de este curso. No
        olvides contestar la actividad autoevaluable de esta lección.
      </p>
    </div>
  );
}
