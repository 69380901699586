export const calle = [
  {
    message: "Caracteres inválidos.",
    pattern: /^[a-zA-Z-áéíóúüÁÉÍÓÚÜ0-9]+[^\\_\\!"$%&{}+\]()\\[=?¡^`*¨°|<>]+$/,
  },
  { validateStatus: "error" },
];

export const cp = [
  {
    message: "Código postal inválido.",
    pattern: /^[0-9]+$/,
  },
  { validateStatus: "error" },
  { min: 5, message: "Código postal inválido." },
];

export const clave = /^([A-Z]{5,6}\d{8}[H|M]\d{3})$|^([0-9]{13})$/;