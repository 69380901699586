export const rol_jl_reporte_solicitudes = [
  "OBSERVADORES11.CAPTURA.JL",
  "OBSERVADORES11.CONSULTA.JL",
  "OBSERVADORES.CAPTURA.OPLE.JL",
  "OBSERVADORES.CONSULTA.OPLE.JL",
];

export const rol_jl_reporte_acciones = [
  "OBSERVADORES11.CAPTURA.JL",
  "OBSERVADORES11.CONSULTA.JL",
  "OBSERVADORES.CAPTURA.OPLE.JL",
  "OBSERVADORES.CONSULTA.OPLE.JL",
];
