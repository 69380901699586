import React from "react";
import { Col, Row } from "antd";
import "./Portal.scss";
import { Link } from "react-router-dom";

const Links = (props) => {
  return (
    <div>
      <Row gutter={[24, 24]}>
        <Col xxl={6} xl={6} lg={6} md={12} sm={12} xs={24} className="link">
          <Link to={"/portal/requisitos"} className="link">
            Requisitos
          </Link>
        </Col>
        <Col xxl={6} xl={6} lg={6} md={12} sm={12} xs={24} className="link">
          <Link to={"/portal/normatividad"}>Normatividad</Link>
        </Col>
        <Col xxl={6} xl={6} lg={6} md={12} sm={12} xs={24} className="link">
          <a
            href="https://www.ine.mx/wp-content/uploads/2020/10/deoe-observadores-web-avisoprivacidadintegral.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            Aviso de privacidad
          </a>
        </Col>
        <Col xxl={6} xl={6} lg={6} md={12} sm={12} xs={24} className="link">
          <Link to={"/portal/glosario"}>Glosario</Link>
        </Col>
      </Row>
    </div>
  );
};

export default Links;
