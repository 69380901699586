import React, { useEffect, useState, useRef } from "react";
import { Button } from "antd";
import { Link, Redirect } from "react-router-dom";
import Table from "../../../components/Admin/Convocation/Table";
import { ReactComponent as New } from "../../../assets/img/newConvocation.svg";
import useAuth from "../../../hooks/useAuth";
import "./Convocation.scss";
import { getConvocations } from "../../../api/convocation";
import { rol_captura, rol_modifica } from "../../../utils/Convocation/roles";
import { getTypeUser } from "../../../utils/Convocation/functions";

export default function Convocation(props) {
  const {
    proceso: { proceso, estado, distrito },
    permission,
  } = props;
  const {
    user: { versionUsuario, rolUsuario },
  } = useAuth();
  const isMounted = useRef(true); //Referencia que permite verificar si el componente esta visible
  const [process, setProcess] = useState({});
  const [reload, setReload] = useState(false);
  const [data, setData] = useState({ dataSource: [], loading: false });
  const [rolModulo, setRolModulo] = useState({}); //Hook para almacenar los permisos

  //useEffect que valida que el componente este seleccionado de lo contrario cancela cualquier subcripción
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  //useEffect que valida el rol del usuario
  useEffect(() => {
    //Valida los permisos que tienen el usuario
    let captura = false;
    let modifica = false;
    //Devuelve un true si cuenta con los permisos
    captura = rol_captura.includes(rolUsuario);
    modifica = rol_modifica.includes(rolUsuario);
    isMounted.current && setRolModulo(() => ({ captura, modifica })); //Se almacenan en el hook
  }, [rolUsuario]);
  //Se obtiene la información del popover
  useEffect(() => {
    let idProceso, idDetalle, idEstado, idDistrito, usuario;
    if (proceso) {
      idProceso = proceso.idProcesoElectoral;
      idDetalle = proceso.idDetalleProceso;
      idEstado = estado ? estado.idEstado : 0;
      idDistrito = distrito ? distrito.idDistrito : 0;
      usuario = getTypeUser(versionUsuario);
      isMounted.current &&
        setProcess({ idProceso, idDetalle, idEstado, idDistrito, usuario });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [proceso, estado, distrito]);
  //console.log(process);
  useEffect(() => {
    //Se verifica que este seleccionado un proceso electoral
    if (process.idProceso) {
      setData({ loading: true }); //Mostrar el loading en la tabla
      getConvocations(process)
        .then((res) => {
          //Se verifica que se encuentre en el componente
          if (isMounted.current) {
            const dataSource = [];
            res.data.map((con, index) => {
              return dataSource.push({
                key: index,
                indice: index + 1,
                ...con,
              });
            });
            setData({ dataSource: dataSource, loading: false }); //Se almacenan en el hook
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [process, reload]);

  //Se valida que el usuario tenga permiso de consultar
  if (!permission.includes(rolUsuario)) {
    return <Redirect to={"/admin/home"} />;
  }

  const disableJl = (versionUsuario, idDistrito) =>
    versionUsuario === "JL" && idDistrito !== 0;

  //Función que deshabilita el botón de nueva convocatoria
  const NewConvocation = ({ convocations }) => {
    let disable = false;
    if (convocations !== undefined) {
      if (versionUsuario !== "OC") {
        convocations.length >= 2 ? (disable = true) : (disable = false);
      } else {
        convocations.length >= 1 ? (disable = true) : (disable = false);
      }
    }
    //Se verifica que tenga permisos de capturar o modificar
    if (rolModulo.captura || rolModulo.modifica) {
      return (
        <>
          <h1>Registrar convocatoria</h1>
          <Button
            className="convocation btn_secundario"
            disabled={disable || disableJl(versionUsuario, process.idDistrito)}
          >
            <Link to={"/admin/convocatoria/registro"}>
              <New />
              <br />
              {versionUsuario === "OC"
                ? "Nueva convocatoria"
                : "Convocatoria con datos de contacto"}
            </Link>
          </Button>
        </>
      );
    }
    return null;
  };
  return (
    <div style={{ marginTop: "15px", marginLeft: "20px" }}>
      <>
        <div>
          <NewConvocation convocations={data.dataSource} />
        </div>
      </>

      <div style={{ marginTop: "30px" }}>
        <h3>Lista de convocatorias</h3>
        <Table
          data={data.dataSource}
          loading={data.loading}
          setReload={setReload}
          proceso={proceso ? proceso.nombreProceso : ""}
          rolModulo={rolModulo}
          versionUsuario={versionUsuario}
          rolUsuario={rolUsuario}
          disabled={disableJl(versionUsuario, process.idDistrito)}
        />
      </div>
    </div>
  );
}
