import { useEffect, useState } from "react";

export const useLesson8 = (idEntidad) => {
  const [lesson, setLesson] = useState({});

  useEffect(() => {
    switch (idEntidad) {
      case 1:
        setLesson({
          diputacion: "Diputaciones locales y los Ayuntamientos",
          ley: "el Código Electoral para el Estado de Aguascalientes ",
          tribunal: "Tribunal Electoral del Estado",
        });
        break;
      case 2:
        setLesson({
          diputacion: "gubernatura, diputaciones locales y ayuntamientos",
          ley: "la Ley Electoral del Estado de Baja California ",
          tribunal:
            "Tribunal de Justicia Electoral del Estado de Baja California",
        });
        break;
      case 3:
        setLesson({
          diputacion: "gubernatura, diputaciones locales y ayuntamientos",
          ley:
            "la Ley del Sistema de Medios de Impugnación en Materia Electoral para el Estado de Baja California Sur ",
          tribunal: "Tribunal Estatal Electoral de Baja California Sur",
        });
        break;

      case 4:
        setLesson({
          diputacion: "gubernatura, diputaciones locales y ayuntamientos",
          ley:
            "la Ley de Instituciones y Procedimientos Electorales del Estado de Campeche ",
          tribunal: "Tribunal Electoral del Estado de Campeche",
        });
        break;
        case 5:
          setLesson({
            diputacion: "la elección de ayuntamientos",
            ley:
              "la Ley de Medios de Impugnación en materia Político-Electoral y de Participación Ciudadana para el Estado de Coahuila de Zaragoza ",
            tribunal: "Tribunal Electoral del Estado de Coahuila de Zaragoza",
          });
          break;
          case 6:
            setLesson({
              diputacion: "gubernatura, diputaciones locales y ayuntamientos",
              ley:
                "la Ley Estatal del Sistema de Medios de Impugnación en Materia Electoral ",
              tribunal: "Tribunal Electoral del Estado de Colima",
            });
            break;
      case 7:
        setLesson({
          diputacion: "las elecciones de diputaciones locales y ayuntamientos",
          ley:
            "la Ley de Medios de Impugnación en Materia Electoral del Estado de Chiapas ",
          tribunal: "Tribunal Electoral del Estado de Chiapas",
        });
        break;
      case 8:
        setLesson({
          diputacion: "gubernatura, diputaciones locales y ayuntamientos",
          ley:
            "la Ley Electoral del Estado de Chihuahua ",
          tribunal: "Tribunal Estatal Electoral de Chihuahua",
        });
        break;

      case 9:
        setLesson({
          diputacion:
            "Diputaciones locales, titulares de las alcaldías y de los integrantes de los concejos",
          ley: "la Ley Procesal Electoral de la Ciudad de México ",
          tribunal: "Tribunal Electoral de la Ciudad de México",
        });
        break;
        case 10:
          setLesson({
            diputacion:
              "Diputaciones locales",
            ley: "la Ley de Medios de impugnación en Materia Electoral y de Participación Ciudadana para el Estado de Durango ",
            tribunal: "Tribunal Electoral del Estado",
          });
          break;
      case 11:
        setLesson({
          diputacion: "Diputaciones locales y los Ayuntamientos",
          ley:
            "la Ley de Instituciones y Procedimientos Electorales para el Estado de Guanajuato ",
          tribunal: "Tribunal Estatal Electoral de Guanajuato",
        });
        break;
      case 12:
        setLesson({
          diputacion: "Gubernatura, Diputaciones locales y los Ayuntamientos",
          ley:
            "la Ley Número 456 del Sistema de Medios de Impugnación en Materia Electoral del Estado de Guerrero ",
          tribunal: "Tribunal Electoral del Estado",
        });
        break;
      case 13:
        setLesson({
          diputacion: "diputaciones locales",
          ley: "el Código Electoral del Estado de Hidalgo ",
          tribunal: "Tribunal Electoral del Estado de Hidalgo ",
        });
        break;
      case 14:
        setLesson({
          diputacion: "diputaciones locales y ayuntamientos",
          ley: "el Código Electoral del Estado de Jalisco ",
          tribunal: "Tribunal Electoral del Estado de Jalisco",
        });
        break;
      case 15:
        setLesson({
          diputacion: "diputaciones locales y ayuntamientos",
          ley: "el Código Electoral del Estado de México ",
          tribunal: "Tribunal Electoral del Estado de México",
        });
        break;
      case 16:
        setLesson({
          diputacion: "gubernatura, diputaciones local y ayuntamientos",
          ley:
            "la Ley de Justicia en Materia Electoral y de Participación Ciudadana del Estado de Michoacán de Ocampo ",
          tribunal: "Tribunal Electoral del Estado de Michoacán",
        });
        break;
      case 17:
        setLesson({
          diputacion: "Diputaciones locales y los Ayuntamientos",
          ley:
            "el Código de Instituciones y Procedimientos Electorales para el Estado de Morelos ",
          tribunal: "Tribunal Electoral del Estado de Morelos",
        });
        break;
      case 18:
        setLesson({
          diputacion:
            "Gubernatura, Diputaciones Locales, Presidencias y Sindicaturas Municipales y Regidurías",
          ley: "la Ley de Justicia Electoral del Estado de Nayarit ",
          tribunal: "Tribunal Estatal Electoral Nayarit",
        });
        break;
      case 19:
        setLesson({
          diputacion: "Gubernatura, Diputaciones locales y los Ayuntamientos",
          ley: "la Ley Electoral para el Estado de Nuevo León ",
          tribunal: "Tribunal Electoral del Estado de Nuevo León",
        });
        break;
      case 20:
        setLesson({
          diputacion: "Diputaciones locales y los Ayuntamientos",
          ley:
            "la Ley del Sistema de Medios de Impugnación en Materia Electoral y de Participación Ciudadana de Oaxaca ",
          tribunal: "Tribunal Electoral del Estado de Oaxaca",
        });
        break;
      case 21:
        setLesson({
          diputacion: "Diputaciones locales y los Ayuntamientos",
          ley:
            "el Código de Instituciones y Procesos Electorales del Estado de Puebla ",
          tribunal: "Tribunal Electoral del Estado de Puebla",
        });
        break;
      case 22:
        setLesson({
          diputacion: "Gubernatura, Diputaciones Locales y Ayuntamientos",
          ley:
            "la Ley de Medios de Impugnación en Materia Electoral del estado de Querétaro ",
          tribunal: "Tribunal Electoral del Estado de Querétaro",
        });
        break;
      case 23:
        setLesson({
          diputacion: "Ayuntamientos",
          ley: "Ley Estatal de Medios de Impugnación en Materia Electoral ",
          tribunal: "Tribunal Electoral de Quintana Roo",
        });
        break;
      case 24:
        setLesson({
          diputacion: "Gubernatura, Diputaciones locales y los Ayuntamientos",
          ley: "la Ley de Justicia Electoral del Estado de San Luis Potosí ",
          tribunal: "Tribunal Electoral del Estado",
        });
        break;
      case 25:
        setLesson({
          diputacion: "Gubernatura, Diputaciones locales y los Ayuntamientos",
          ley:
            "Ley del Sistema de Medios de Impugnación en Materia Electoral y de Participación Ciudadana para el Estado de Sinaloa ",
          tribunal: "Tribunal Electoral del Estado de Sinaloa",
        });
        break;
      case 26:
        setLesson({
          diputacion: "Gubernatura, Diputaciones Locales y Ayuntamientos",
          ley:
            "la Ley de Instituciones y Procedimientos Electorales para el Estado de Sonora ",
          tribunal: "Tribunal Estatal Electoral",
        });
        break;
      case 27:
        setLesson({
          diputacion:
            "Diputaciones locales, presidencias municipales y regidurías",
          ley:
            "la Ley de Medios de Impugnación en Materia Electoral del Estado de Tabasco ",
          tribunal: "Tribunal Electoral de Tabasco",
        });
        break;
      case 28:
        setLesson({
          diputacion: "Diputaciones locales y los Ayuntamientos",
          ley: "la Ley de Medios de Impugnación Electorales de Tamaulipas ",
          tribunal: "Tribunal Electoral del Estado de Tamaulipas",
        });
        break;
      case 29:
        setLesson({
          diputacion:
            "Gubernatura, Diputaciones locales, Ayuntamientos y Presidencias de Comunidad",
          ley:
            "la Ley de Medios de Impugnación en Materia Electoral para el Estado de Tlaxcala ",
          tribunal: "Tribunal Electoral de Tlaxcala",
        });
        break;
      case 30:
        setLesson({
          diputacion: "Diputaciones locales y los Ayuntamientos",
          ley:
            "el Código Electoral para el Estado de Veracruz de Ignacio de la Llave ",
          tribunal: "Tribunal Electoral de Veracruz",
        });
        break;
      case 31:
        setLesson({
          diputacion: "Diputaciones locales y los Ayuntamientos",
          ley:
            "la Ley del Sistema de Medios de Impugnación en Materia Electoral del Estado de Yucatán ",
          tribunal: "Tribunal Electoral del Estado de Yucatán",
        });
        break;
      case 32:
        setLesson({
          diputacion: "Gubernatura, Diputaciones Locales y Ayuntamientos",
          ley:
            "la Ley del Sistema de Medios de Impugnación Electoral del Estado de Zacatecas ",
          tribunal: "Tribunal de Justicia Electoral del Estado de Zacatecas",
        });
        break;
      default:
        return setLesson({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return lesson;
};
