export const rol_cau = [
    "OBSERVADORES.CAU.OC", //Cau
    //--------------------------------------
    "OBSERVADORES11.CAU.OC", //Cau
  ];
  
  export const rol_captura = [
    /*
    "OBSERVADORES.ADMIN.PARAM.CAPTURA.OC",
    "OBSERVADORES.ADMIN.CAPTURA.OC",
    "OBSERVADORES.CAPTURA.JL",
    "OBSERVADORES.CAPTURA.JD",
    "OBSERVADORES.CAU.OC", //Cau
    //--------------------------------------
    "OBSERVADORES11.ADMIN.PARAM.CAPTURA.OC",
    "OBSERVADORES11.ADMIN.CAPTURA.OC",
    "OBSERVADORES11.CAPTURA.JL",
    "OBSERVADORES11.CAPTURA.JD",
    "OBSERVADORES11.CAU.OC", //Cau
    */
  ];
  
  export const rol_consulta = [
    "OBSERVADORES.ADMIN.PARAM.CAPTURA.OC",
    "OBSERVADORES.ADMIN.CAPTURA.OC",
    "OBSERVADORES.CONSEJERO.OC",
    "OBSERVADORES.CONSULTA.OC",
    "OBSERVADORES.CAPTURA.JL",
    "OBSERVADORES.CONSULTA.JL",
    "OBSERVADORES.CAPTURA.JD",
    "OBSERVADORES.CONSULTA.JD",
    "OBSERVADORES.CONSULTA.OPLE.JL",
    "OBSERVADORES.CAU.OC", //Cau
    //--------------------------------------
    "OBSERVADORES11.ADMIN.PARAM.CAPTURA.OC",
    "OBSERVADORES11.ADMIN.CAPTURA.OC",
    "OBSERVADORES11.CONSEJERO.OC",
    "OBSERVADORES11.CONSULTA.OC",
    "OBSERVADORES11.CAPTURA.JL",
    "OBSERVADORES11.CONSULTA.JL",
    "OBSERVADORES11.CAPTURA.JD",
    "OBSERVADORES11.CONSULTA.JD",
    "OBSERVADORES11.CONSULTA.OPLE.JL",
    "OBSERVADORES11.CAU.OC", //Cau
  ];
  
  export const rol_modifica = [
    /*
    "OBSERVADORES.ADMIN.PARAM.CAPTURA.OC",
    "OBSERVADORES.ADMIN.CAPTURA.OC",
    "OBSERVADORES.CAPTURA.JL",
    "OBSERVADORES.CAPTURA.JD",
    "OBSERVADORES.CAU.OC", //Cau
    //--------------------------------------
    "OBSERVADORES11.ADMIN.PARAM.CAPTURA.OC",
    "OBSERVADORES11.ADMIN.CAPTURA.OC",
    "OBSERVADORES11.CAPTURA.JL",
    "OBSERVADORES11.CAPTURA.JD",
    "OBSERVADORES11.CAU.OC", //Cau
    */
  ];
  
  export const rol_borrar = [
    /*
    "OBSERVADORES.ADMIN.PARAM.CAPTURA.OC",
    "OBSERVADORES.ADMIN.CAPTURA.OC",
    "OBSERVADORES.CAPTURA.JL",
    "OBSERVADORES.CAPTURA.JD",
    "OBSERVADORES.CAU.OC", //Cau
    //--------------------------------------
    "OBSERVADORES11.ADMIN.PARAM.CAPTURA.OC",
    "OBSERVADORES11.ADMIN.CAPTURA.OC",
    "OBSERVADORES11.CAPTURA.JL",
    "OBSERVADORES11.CAPTURA.JD",
    "OBSERVADORES11.CAU.OC", //Cau
    */
  ];