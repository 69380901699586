import React, { Fragment } from "react";
import { Row, Col, Icon } from "antd";
//import Questions from "../../../components/User/Public/Questions";
import MenuPortal from "../../../components/User/Public/MenuPortal";
import ContentMaterials from "../../../components/User/Public/SectionMaterials/ContentMaterials";
import useAuth from "../../../hooks/useAuth";
import { Link } from "react-router-dom";

const Training = () => {
  const {
    ciudadano: { ciudadano },
  } = useAuth();
  // const data = [
  //   { id: 1, pregunta: "Pregunta 1", respuesta: "Welcome to learning React!" },
  //   {
  //     id: 2,
  //     pregunta: "Pregunta 2",
  //     respuesta: "You can install React from npm.",
  //   },
  //   {
  //     id: 3,
  //     pregunta: "Pregunta 3",
  //     respuesta: "You can install React from npm.",
  //   },
  // ];
  // const sectionTitle = "Preguntas Frecuentes sobre Materiales de Capacitación";

  return (
    <Fragment>
      <Row>
        {!ciudadano && (
          <Col span={6}>
            <MenuPortal />
          </Col>
        )}
        <Col span={ciudadano ? 24 : 18}>
          {ciudadano && (
            <Link to={"/ciudadano/inicio"} className="back">
              <Icon type="play-circle" theme="filled" rotate="180" /> Volver
            </Link>
          )}
          <ContentMaterials />
        </Col>
      </Row>
      <Row>
        {/* <Questions data={data} sectionTitle={sectionTitle} /> */}
      </Row>
    </Fragment>
  );
};

export default Training;
