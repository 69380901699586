import {save} from "../../api/others";

//Función que obtiene los datos
export function sendGluster(file) {
    const formData = new FormData();
    formData.append("documento", file);
    saveGluster(formData);
  }

  //Función que guarda la organización
function saveGluster(archivo) {
    save(archivo)
      .then((res) => {
        if (res.data.code === 200) { 
        } else {
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }