import { basePath } from "./config";
import axios from "axios";
import { getAccessTokenApi, getTokenCiudadano } from "./auth";

export function getEstadoSolicitudes(idProceso, idDetalle, idEstado, idDistrito) {
    const url = `${basePath}/solicitudes/bandejaEntradaEstatus`;
    const jwt = getAccessTokenApi();
    return axios.post(
        url,
        { idProceso: idProceso, idDetalle: idDetalle, idEstado: idEstado, idDistrito: idDistrito, },
        { headers: { Authorization: jwt, Parametrizacion: idProceso } }
    );
}

// export function getCatalogoEstados() {
//     const url = `${basePath}/solicitudes/catalogoEstados`;
//     const jwt = getAccessTokenApi();
//     return axios.post(
//         url,
//         {  },
//         { headers: { Authorization: jwt } }
//     );
// }

export function getCatalogoMotivos(motivo) {
    const url = `${basePath}/solicitudes/catalogoMotivos`;
    const jwt = getAccessTokenApi();
    return axios.post(
        url,
        { motivo: motivo },
        { headers: { Authorization: jwt } }
    );
}

export function actualizaEstadoSolicitud(idObservador, idEstatus, idMotivo, justificacion, usuario, idProceso) {
    const url = `${basePath}/solicitudes/actualizaEstadoSolicitud`;
    const jwt = getAccessTokenApi();
    return axios.post(
        url,
        { idObservador: idObservador, idEstatus: idEstatus, idMotivo: idMotivo, justificacion: justificacion,
            usuario: usuario },
        { headers: { Authorization: jwt, Parametrizacion: idProceso } }
    );
}

export function declinaSolicitudPortal(correo) {
    const url = `${basePath}/solicitudes/declinaSolicitudPortal`;
    const jwt = getTokenCiudadano();
    return axios.post(
        url,
        { correo: correo },
        { headers: { Authorization: jwt, Origen: "portal", Parametrizacion: "0,0" }, }
    );
}
