import React, { useRef, useEffect } from "react";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
  AreaChart,
  Area,
} from "recharts";

const RequestChart = (props) => {
  const { grafica } = props;
  const isMounted = useRef(true);
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);
  return (
    <ResponsiveContainer width="100%" height={250}>
      {/*             <LineChart data={grafica}
                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                <XAxis dataKey="edad" label={{ value: 'Edad', angle: 0, position: 'insideBottomRight' }} padding={{ bottom: 30 }}  />
                <YAxis label={{ value: 'Solicitudes', angle: 0, position: 'insideTopLeft' }} padding={{ top:30 }}  />
                <CartesianGrid strokeDasharray="3 3" />
                <Tooltip />
                <Legend verticalAlign="top" height={36} iconType="square" />
                <Line name="Mujeres (portal)" type="linearClosed" dataKey="portalMujeres" stroke="#400778" />
                <Line name="Mujeres (junta)" type="linearClosed" dataKey="juntaMujeres" stroke="#12D2E0" />
                <Line name="Hombres (portal)" type="linearClosed" dataKey="portalHombres" stroke="#E012A7" />
                <Line name="Hombres (junta)" type="linearClosed" dataKey="juntaHombres"  stroke="#EC89E7" />
            </LineChart> */}

      <AreaChart
        width={730}
        height={250}
        data={grafica}
        margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
      >
        <defs>
          <linearGradient id="portalMujeres" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#400778" stopOpacity={0.8} />
            <stop offset="95%" stopColor="#400778" stopOpacity={0} />
          </linearGradient>
          <linearGradient id="juntaMujeres" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#12D2E0" stopOpacity={0.8} />
            <stop offset="95%" stopColor="#12D2E0" stopOpacity={0} />
          </linearGradient>
          <linearGradient id="portalHombres" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#E012A7" stopOpacity={0.8} />
            <stop offset="95%" stopColor="#E012A7" stopOpacity={0} />
          </linearGradient>
          <linearGradient id="juntaHombres" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#EC89E7" stopOpacity={0.8} />
            <stop offset="95%" stopColor="#EC89E7" stopOpacity={0} />
          </linearGradient>
        </defs>
        <XAxis
          dataKey="edad"
          label={{ value: "Edad", angle: 0, position: "insideBottomRight" }}
          padding={{ bottom: 30 }}
        />
        <YAxis
          label={{ value: "Solicitudes", angle: 0, position: "insideTopLeft" }}
          padding={{ top: 30 }}
        />
        <CartesianGrid strokeDasharray="3 3" />
        <Tooltip />
        <Legend verticalAlign="top" height={36} iconType="square" />
        <Area
          name="Mujeres (portal)"
          type="monotone"
          dataKey="portalMujeres"
          stroke="#400778"
          fillOpacity={1}
          fill="url(#portalMujeres)"
        />
        <Area
          name="Mujeres (junta)"
          type="monotone"
          dataKey="juntaMujeres"
          stroke="#12D2E0"
          fillOpacity={1}
          fill="url(#juntaMujeres)"
        />
        <Area
          name="Hombres (portal)"
          type="monotone"
          dataKey="portalHombres"
          stroke="#E012A7"
          fillOpacity={1}
          fill="url(#portalHombres)"
        />
        <Area
          name="Hombres (junta)"
          type="monotone"
          dataKey="juntaHombres"
          stroke="#EC89E7"
          fillOpacity={1}
          fill="url(#juntaHombres)"
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default RequestChart;
