import React, { useEffect, useRef, useState } from "react";
import { Col, Form, Radio, Row, Select } from "antd";
import { Ast } from "../../../../utils/Request/functions";
import { radioButton, required } from "../../../../utils/rules";
import { getCountries } from "../../../../api/requestPub";

export default function Resident(props) {
  const { getFieldDecorator, handleChange, datos, setDatos } = props;
  const { Option } = Select;
  const isMounted = useRef(true);
  const [countries, setCountries] = useState([]); //Hook para almacenar los paises

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    getCountries()
      .then((res) => {
        isMounted.current && setCountries(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  //Obtiene el id y el nombre del país
  const getNameCountry = (value, event) => {
    //Se almacena la información de ambos estados en el hook principal
    setDatos({
      ...datos,
      pais: event.props.children,
      idPais: value,
    });
  };
  return (
    <>
      <h3>
        <Ast /> Residentes en el extranjero
      </h3>
      <Form.Item label="Lugar donde se emitió la credencial para votar">
        {getFieldDecorator("tipoEmision", {
          rules: radioButton,
          initialValue: datos.tipoEmision,
        })(
          <Radio.Group onChange={handleChange("tipoEmision")}>
            <Radio value={0}>México</Radio>
            <Radio value={1}>Extranjero</Radio>
          </Radio.Group>
        )}
      </Form.Item>
      <Row gutter={24}>
        <Col span={12} xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
          <Form.Item label="País de residencia">
            {getFieldDecorator("idPais", {
              initialValue: datos.idPais,
              rules: required,
            })(
              <Select
                style={{ width: 460 }}
                showSearch
                optionFilterProp="children"
                placeholder="Selecciona"
                //onChange={changeSelect("idPais")}
                onSelect={(value, e) => getNameCountry(value, e)}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {countries.map((item) => (
                  <Option key={item.idPais}>{item.pais}</Option>
                ))}
              </Select>
            )}
          </Form.Item>
        </Col>
      </Row>
    </>
  );
}
