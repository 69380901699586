import { useEffect, useState } from "react";

export const useText = (idEntidad) => {
  const [text, setText] = useState({});
  useEffect(() => {
    switch (idEntidad) {
      case 1:
        setText({
          estado: "de Aguascalientes",
          diputacion: "elegirá 27 Diputaciones Locales, y 11 Ayuntamientos",
          parrafo: "Aguascalientes inició con la primera sesión del Consejo General del Instituto Estatal Electoral de Aguascalientes, en la primera semana de noviembre de 2020 y concluirá también con la declaración de validez de la elección de las diputaciones locales y ayuntamientos o en su caso, con las resoluciones que en última instancia emita el órgano jurisdiccional.",
          numConsejo: "18 Consejos Distritales y 11 Consejos Municipales",
          res:
            " y los Consejos Distritales del Instituto Estatal Electoral de Aguascalientes",
          consejo1: "o del Instituto Estatal Electoral de Aguascalientes ",
          consejo2: "al Instituto Estatal Electoral de Aguascalientes",
          consejo3: "Consejo General del Instituto Estatal Electoral de Aguascalientes",
        });
        break;
      case 2:
        setText({
          estado: "de Baja California",
          diputacion:
            "elegirá una gubernatura, 25 Diputaciones Locales y 5 ayuntamientos",
          parrafo:
            "Baja California inició con la primera sesión del Consejo General del Instituto Estatal Electoral de Baja California, celebrada en el mes de diciembre de 2020 y derivado de que se elegirá Gubernatura el proceso electoral concluirá con el Dictamen y declaración de validez de dicha elección, o en su caso, con las resoluciones que en última instancia emita el órgano jurisdiccional.",
          numConsejo: "17 Consejos Distritales",
          res:
            " y los Consejos Distritales del Instituto Estatal Electoral de Baja California",
          consejo1:
            "o del Instituto Estatal Electoral de Baja California ",
            consejo2: "al Instituto Estatal Electoral de Baja California",
            consejo3:"Consejo General del Instituto Estatal Electoral de Baja California",
        });
        break;

      case 3:
        setText({
          estado: "de Baja California Sur",
          diputacion: "elegirá gubernatura, 21 diputaciones locales y 5 ayuntamientos",
          parrafo:
            "Baja California Sur inició con la primera sesión del Consejo General del Instituto Estatal Electoral de Baja California Sur, celebrada en diciembre de 2020 y derivado de que se elegirá Gubernatura el proceso electoral concluirá con el Dictamen y declaración de validez de dicha elección o en su caso, con las resoluciones que en última instancia emita el órgano jurisdiccional.",
          numConsejo:
            "16 consejos distritales electorales y 5 consejos municipales",
          res:
            " y los Consejos Distritales y los Consejos Municipales del Instituto Estatal Electoral de Baja California Sur",
          consejo1: "o del Instituto Estatal Electoral de Baja California Sur ",
          consejo2: "al Instituto Estatal Electoral de Baja California Sur",
          consejo3: "Consejo General del Instituto Estatal Electoral de Baja California Sur",
        });
        break;

      case 4:
        setText({
          estado: "de Campeche",
          diputacion:
            "elegirá gubernatura, 35 diputaciones locales, 13 ayuntamientos y 22 juntas municipales",
          parrafo:
            "Campeche inició con la primera sesión del Consejo General del Instituto Electoral del Estado de Campeche, celebrada en el mes de enero de 2021 y concluirá con el dictamen y declaración de validez de la elección de Gubernatura.",
          numConsejo:
            "21 consejos distritales y los consejos municipales en los municipios en cuya demarcación territorial exista más de un Distrito Electoral, y en donde lo determine el Consejo General del IEEC",
          res:
            " y los Consejos Distritales y Municipales del Instituto Electoral del Estado de Campeche",
          consejo1: "o del Instituto Electoral del Estado de Campeche ",
          consejo2: "al Instituto Electoral del Estado de Campeche",
          consejo3: "Consejo General del Instituto Electoral del Estado de Campeche",
        });
        break;

      case 5:
        setText({
          estado: "de Coahuila",
          diputacion: "elegirán 38 ayuntamientos",
          parrafo:
            "Coahuila inició con la primera sesión del Consejo General del Instituto Electoral de Coahuila, celebrada en el primer día de enero de 2021 y concluirá con la declaración de validez de la elección, o en su caso, con las resoluciones que en última instancia emita el órgano jurisdiccional.",
          numConsejo: "38 Comités Municipales Electorales",
          res:
            " y los Comités Municipales Electorales del Instituto Electoral de Coahuila",
          consejo1: "o del Instituto Electoral de Coahuila ",
          consejo2: "al Instituto Electoral de Coahuila",
          consejo3: "Consejo General del Instituto Electoral de Coahuila",
        });
        break;
      case 6:
        setText({
          estado: "de Colima",
          diputacion:
            "elegirá una gubernatura, 25 diputaciones locales y 10 ayuntamientos",
          parrafo:
            "Colima inició con la primera sesión del Consejo General del Instituto Electoral del Estado de Colima, celebrada dentro de la primera quincena de octubre 2020 y concluye con la sesión que celebre el Consejo General del IEE, después de que se haya agotado la última resolución respecto de la elección de Gubernatura.",
          numConsejo: "10 consejos municipales",
          res:
            " y los Consejos Municipales del Instituto Electoral del Estado de Colima",
          consejo1: "o del Instituto Electoral del Estado de Colima ",
          consejo2: "al Instituto Electoral del Estado de Colima",
          consejo3: "Consejo General del Instituto Electoral del Estado de Colima",
        });
        break;
      case 7:
        setText({
          estado:"de Chiapas",
          diputacion: "elegirán 40 diputaciones locales y 123 ayuntamientos",
          parrafo:
            "Chiapas inició con la primera sesión del Consejo General del Instituto de Elecciones y Participación, celebrada en el mes de enero de 2021 y concluirá también con la declaración de validez de la elección de las diputaciones locales y ayuntamientos o en su caso, con las resoluciones que en última instancia emita el órgano jurisdiccional.",
          numConsejo: "24 consejos distritales y 123 consejos municipales",
          res:
            " y los Consejos Distritales y Municipales del Instituto de Elecciones y Participación Ciudadana",
          consejo1: "o del Instituto de Elecciones y de Participación Ciudadana ",
          consejo2: "al Instituto de Elecciones y de Participación Ciudadana",
          consejo3: "Consejo General del Instituto de Elecciones y Participación Ciudadana",
        });
        break;
      case 8:
        setText({
          estado:"de Chihuahua",
          diputacion:
            "elegirá una gubernatura, 33 diputaciones locales, 67 ayuntamientos y 67 sindicaturas",
          parrafo:
            "Chihuahua inició con la primera sesión del Consejo Estatal del Instituto Estatal Electoral de Chihuahua, celebrada el 01 de octubre 2020 y concluye con los resultados y declaración de validez de las elecciones o en su caso, con las resoluciones que en última instancia emita el órgano jurisdiccional.",
          numConsejo:
            "67 asambleas municipales, de las cuales, 10 actuarán también como asambleas distritales",
          res: " y las asambleas municipales y distritales del IEE",
          consejo1: "o el Consejo Estatal del Instituto Estatal Electoral de Chihuahua ",
          consejo2: "al Instituto Estatal Electoral de Chihuahua",
          consejo3:"Consejo Estatal del Instituto Estatal Electoral de Chihuahua",
        });
        break;
      case 9:
        setText({
          estado:"de la Ciudad de México",
          diputacion:
            "elegirá 66 Diputaciones Locales, 1 alcaldesa o alcalde y 10 concejalías por cada una de las 16 demarcaciones territoriales",
          parrafo:
            "la Ciudad de México inició con la primera sesión del Consejo General del Instituto Electoral de la Ciudad de México, celebrada el 11 septiembre de 2020 y concluirá con la entrega de las constancias de mayoría y las declaratorias de validez de las elecciones de diputaciones del Congreso de la Ciudad de México y alcaldías, con las resoluciones que en última instancia emita el órgano jurisdiccional.",
          numConsejo: "33 Consejos Distritales",
          res:
            " y los Consejos Distritales del Instituto Electoral de la Ciudad de México",
          consejo1: "o del Instituto Electoral de la Ciudad de México ",
          consejo2: "al Instituto Electoral de la Ciudad de México",
          consejo3:"Consejo General del Instituto Electoral de la Ciudad de México"
        });
        break;
      case 10:
        setText({
          estado:"de Durango",
          diputacion:
            "elegirán 25 Diputaciones Locales",
          parrafo:
            "el estado de Durango inició con la primera sesión del Consejo General del Instituto Electoral y de Participación Ciudadana del Estado de Durango, en la primera semana de noviembre de 2020 y concluirá también con la declaración de validez de la elección de las diputaciones locales, con las resoluciones que en última instancia emita el órgano jurisdiccional.",
          numConsejo: "9 Consejos Municipales",
          res:
            " y los Consejos Municipales Cabecera de Distrito del Instituto Electoral y de Participación Ciudadana del Estado de Durango",
          consejo1: "o del Instituto Electoral y de Participación Ciudadana del Estado de Durango ",
          consejo2:"al Instituto Electoral y de Participación Ciudadana del Estado de Durango",
          consejo3:"Consejo General del IEPC del Estado de Durango",
        });
        break;
      case 11:
        setText({
          estado:"de Guanajuato",
          diputacion: "elegirá 36 Diputaciones Locales y 46 Ayuntamientos",
          parrafo:
            "el estado de Guanajuato inició con la primera sesión del Consejo General del Instituto Electoral del Estado de Guanajuato, en septiembre de 2020 y concluirá también con la declaración de validez de la elección de las diputaciones locales y ayuntamientos o en su caso, con las resoluciones que en última instancia emita el órgano jurisdiccional.",
          numConsejo: "22 Consejos Distritales y 46 Consejos Municipales",
          res:
            ", los Consejos Distritales y Muicipales del Instituto Electoral del Estado de Guanajuato",
          consejo1: "o del Instituto Electoral del Estado de Guanajuato ",
          consejo2: "al Instituto Electoral del Estado de Guanajuato",
          consejo3: "Consejo General del Instituto Electoral del Estado de Guanajuato",
        });
        break;
      case 12:
        setText({
          estado: "de Guerrero",
          diputacion:
            "elegirá una Gubernatura, 46 Diputaciones Locales, y 80 Ayuntamientos",
          parrafo:
            "Guerrero inició con la primera sesión del Consejo General del Instituto Electoral y de Participación Ciudadana del Estado de Guerrero, en la primera semana de septiembre de 2020 y concluirá también con la declaración de validez de la elección de las diputaciones locales y ayuntamientos o en su caso, con las resoluciones que en última instancia emita el órgano jurisdiccional.",
          numConsejo: "28 Consejos Distritales",
          res:
            " y los Consejos Distritales Electorales del Instituto Electoral y de Participación Ciudadana del Estado de Guerrero",
            consejo1:"o del Instituto Electoral y de Participación Ciudadana del Estado de Guerrero ",
            consejo2: "al Instituto Electoral y de Participación Ciudadana del Estado de Guerrero",
          consejo3:
            "Consejo General del Instituto Electoral y de Participación Ciudadana del Estado de Guerrero",
        });
        break;
      case 13:
        setText({
          estado: "de Hidalgo",
          diputacion: "elegirán 30 diputaciones locales",
          parrafo:
            "Hidalgo inició con la primera sesión del Consejo General del Instituto Estatal Electoral de Hidalgo, celebrada el 15 de diciembre de 2020 y concluirá con la declaración de validez de la elección.",
          numConsejo: "18 Consejos Distritales",
          res:
            " y los Consejos Distritales del Instituto Estatal Electoral de Hidalgo",
          consejo1: "o del Instituto Estatal Electoral de Hidalgo ",
          consejo2:"al Instituto Estatal Electoral de Hidalgo",
          consejo3:"Consejo General del Instituto Estatal Electoral de Hidalgo",
        });
        break;
      case 14:
        setText({
          estado: "de Jalisco",
          diputacion: "elegirán 38 diputaciones locales y 125 ayuntamientos",
          parrafo:
            "Jalisco inició el día en que se publica la convocatoria del Consejo General del IEPC Jalisco para la celebración de las elecciones ordinarias, durante la primera quincena de octubre de 2020 y concluye con la declaratoria de la conclusión del proceso electoral.",
          numConsejo:
            "20 consejos distritales electorales y 125 consejos municipales electorales",
          res: " y los Consejos Distritales y Municipales del IEPC Jalisco",
          consejo1:
            ", y con la publicación de la convocatoria para la celebración de las elecciones ordinarias en Jalisco, ",
            consejo2:"al Instituto Electoral y de Participación Ciudadana del Estado de Jalisco",
            consejo3:"Consejo General del Instituto Electoral y de Participación Ciudadana del Estado de Jalisco",
        });
        break;

      case 15:
        setText({
          estado: "del Estado de México",
          diputacion: "elegirán 75 diputaciones locales y 125 ayuntamientos",
          parrafo:
            "el Estado de México inició con la primera sesión del Consejo General del Instituto Electoral del Estado de México, en la primera semana de enero de 2021 y concluirá con el cómputo final y la declaración de validez que realice el Consejo General del IEEM, o con las resoluciones que, en su caso, pronuncie la autoridad jurisdiccional competente.",
          numConsejo:
            "45 consejos distritales electorales y 125 consejos municipales electorales",
          res: " y los Consejos Distritales y Municipales del IEEM",
          consejo1: "o del Instituto Electoral del Estado de México ",
          consejo2:"al Instituto Electoral del Estado de México",
          consejo3:"Consejo General del Instituto Electoral del Estado de México",
        });
        break;
      case 16:
        setText({
          estado: "de Michoacán",
          diputacion:
            "elegirá una gubernatura, 40 Diputaciones Locales y 112 ayuntamientos",
          parrafo:
            "Michoacán inició con la primera sesión del Consejo General del Instituto Electoral de Michoacán, celebrada el 06 septiembre de 2020 y derivado de que en el estado de Michoacán se elegirá gubernatura el proceso concluirá con el Dictamen y declaración de validez de dicha elección, o en su caso, con las resoluciones que en última instancia emita el órgano jurisdiccional.",
          numConsejo: "116 Consejos Distritales o Municipales",
          res:
            " y los Consejos Distritales y los Consejos Municipales del Instituto Electoral de Michoacán",
          consejo1: "o del Instituto Electoral de Michoacán ",
          consejo2: "al Instituto Electoral de Michoacán",
          consejo3:"Consejo General del Instituto Electoral de Michoacán",
        });
        break;
      case 17:
        setText({
          estado:"de Morelos",
          diputacion: "elegirá 20 Diputaciones Locales, y 33 Ayuntamientos",
          parrafo:
            "Morelos inició con la primera sesión del Consejo Estatal Electoral del Instituto Morelense de Procesos Electorales y Participación Ciudadana, el 7 de septiembre de 2020 y concluirá también con la declaración de validez de la elección de las diputaciones locales y ayuntamientos o en su caso, con las resoluciones que en última instancia emita el órgano jurisdiccional.",
          numConsejo: "12 Consejos Distritales y 36 Consejos Municipales",
          res:
            ", los Consejos Distritales y los Consejos Municipales Electorales del Instituto Morelense de Procesos Electorales y Participación Ciudadana",
          consejo1:
            "o el Consejo Estatal Electoral del Instituto Morelense de Procesos Electorales y Participación Ciudadana ",
            consejo2:"al Instituto Morelense de Procesos Electorales y Participación Ciudadana",
            consejo3:"Consejo Estatal Electoral del Instituto Morelense de Procesos Electorales y Participación Ciudadana",
        });
        break;
      case 18:
        setText({
          estado:"de Nayarit",
          diputacion:
            "elegirá 1 Gubernatura, 30 Diputaciones Locales, y 20 Presidencias y Sindicaturas municipales y 197 regidurías",
          parrafo:
            "Nayarit inicia con la primera sesión del Consejo Local Electoral del Instituto Estatal Electoral de Nayarit, el 7 de enero de 2021 y derivado de que se elegirá Gubernatura el proceso electoral concluirá con el dictamen y declaración de validez de dicha elección, o en su caso, con las resoluciones que en última instancia emita el órgano jurisdiccional.",
          numConsejo: "20 Consejos Municipales Electorales",
          res:
            " y los Consejos Municipales Electorales del Instituto Estatal Electoral de Nayarit",
          consejo1:
            "o el Consejo Local Electoral del Instituto Estatal Electoral de Nayarit ",
            consejo2:"al Instituto Estatal Electoral de Nayarit",
            consejo3:"Consejo Local Electoral del Instituto Estatal Electoral de Nayarit",
        });
        break;
      case 19:
        setText({
          estado: "de Nuevo León",
          diputacion:
            "elegirá 1 Gubernatura, 42 Diputaciones Locales, y 51 Ayuntamientos",
          parrafo:
            "Nuevo León inicia con la primera sesión del Consejo General de la Comisión Estatal Electoral Nuevo León, en los primeros siete días de octubre de 2020 y concluirá también con la declaración de validez de la elección de gubernatura, diputaciones locales y ayuntamientos o en su caso, con las resoluciones que en última instancia emita el órgano jurisdiccional.",
          numConsejo: "51 Comisiones Municipales Electorales",
          res:
            " y las Comisiones Municipales Electorales de la Comisión Estatal Electoral Nuevo León",
          consejo1: "o de la Comisión Estatal Electoral Nuevo León ",
          consejo2: "a la Comisión Estatal Electoral Nuevo León",
          consejo3: "Consejo General de la Comisión Estatal Electoral Nuevo León",
        });
        break;
      case 20:
        setText({
          estado: "de Oaxaca",
          diputacion: "elegirá 42 Diputaciones Locales y 153 Ayuntamientos",
          parrafo:
            "Oaxaca inicia con la primera sesión del Consejo General del Instituto Estatal Electoral y de Participación Ciudadana de Oaxaca, en la primera semana de diciembre de 2020 y concluirá también con la declaración de validez de la elección de las diputaciones locales y ayuntamientos o en su caso, con las resoluciones que en última instancia emita el órgano jurisdiccional.",
          numConsejo: "25 Consejos Distritales y 153 Consejos Municipales",
          res:
            ", los Consejos Distritales y Municipales Electorales del Instituto Estatal Electoral y de Participación Ciudadana de Oaxaca",
          consejo1:
            "o del Instituto Estatal Electoral y de Participación Ciudadana de Oaxaca ",
            consejo2:"al Instituto Estatal Electoral y de Participación Ciudadana de Oaxaca",
            consejo3:"Consejo General del Instituto Estatal Electoral y de Participación Ciudadana de Oaxaca",
        });
        break;
      case 21:
        setText({
          estado: "de Puebla",
          diputacion: "elegirá 41 Diputaciones Locales, y 217 Ayuntamientos",
          parrafo:
            "Puebla inicia con la primera sesión del Consejo General del Instituto Electoral del Estado, el 3 de noviembre de 2020 y concluirá también con la declaración de validez de la elección de las diputaciones locales y ayuntamientos o en su caso, con las resoluciones que en última instancia emita el órgano jurisdiccional.",
          numConsejo: "26 Consejos Distritales y 217 Consejos Municipales",
          res:
            ", los Consejos Distritales y los Consejos Municipales del Instituto Electoral del Estado",
          consejo1: "o del Instituto Electoral del Estado ",
          consejo2:"al Instituto Electoral del Estado",
          consejo3:"Consejo General del Instituto Electoral del Estado",
        });
        break;
      case 22:
        setText({
          estado:"de Querétaro",
          diputacion:
            "elegirá 1 Gubernatura, 25 Diputaciones Locales y 18 Ayuntamientos",
          parrafo:
            "Querétaro inicia con la primera sesión del Consejo General del Instituto Electoral del Estado de Querétaro, en octubre de 2020 y concluirá también con la declaración de validez de la elección de gubernatura, diputaciones locales y ayuntamientos o en su caso, con las resoluciones que en última instancia emita el órgano jurisdiccional.",
          numConsejo:
            "15 Consejos Distritales Electorales y 12 Consejos Municipales",
          res:
            ", los Consejos Distritales y Municipales del Instituto Electoral del Estado de Querétaro",
          consejo1:
            "o el Consejo General del Instituto Electoral del Estado de Querétaro ",
            consejo2:"al Instituto Electoral del Estado de Querétaro",
            consejo3:"Consejo General del Instituto Electoral del Estado de Querétaro",
        });
        break;
      case 23:
        setText({
          estado:"de Quintana Roo",
          diputacion: "elegirá 11 Ayuntamientos",
          parrafo:
            "Quintana Roo inició con la primera sesión del Consejo General del Instituto Electoral de Quintana Roo, en la primera semana de enero de 2021 y concluirá también con la declaración de validez de la elección de  ayuntamientos o en su caso, con las resoluciones que en última instancia emita el órgano jurisdiccional.",
          numConsejo: "11 Consejos Municipales",
          res:
            " y los Consejos Municipales del Instituto Electoral de Quintana Roo",
          consejo1: "o del Instituto Electoral de Quintana Roo ",
          consejo2:"al Instituto Electoral de Quintana Roo",
          consejo3:"Consejo General del Instituto Electoral de Quintana Roo",
        });
        break;
      case 24:
        setText({
          estado:"de San Luis Potosí",
          diputacion:
            "elegirá 1 Gubernatura, 27 Diputaciones Locales y 58 Ayuntamientos",
          parrafo:
            "el Estado de San Luis Potosí inició con la primera sesión del Consejo General del CEEPAC de San Luis Potosí, en septiembre de 2020 y derivado de que se elegirá Gubernatura el proceso electoral concluirá con el Dictamen y declaración de validez de dicha elección, o en su caso con las resoluciones que en última instancia emita el órgano jurisdiccional.",
          numConsejo:
            "15 Comisiones Distritales Electorales y 58 Cómites Municipales",
          res:
            " y las Comisiones Distritales Electorales y los Comités Municipales Electorales del CEEPAC",
          consejo1:
            "o del Consejo Estatal Electoral y de Participación Ciudadana de San Luis Potosí ",
            consejo2:"al Consejo Estatal Electoral y de Participación Ciudadana de San Luis Potosí",
            consejo3:"Consejo General del CEEPAC",
        });
        break;
      case 25:
        setText({
          estado: "de Sinaloa",
          diputacion:
            "elegirá una Gubernatura, 40 Diputaciones Locales y 18 Ayuntamientos",
          parrafo:
            "Sinaloa inició con la primera sesión del Consejo General del Instituto Electoral del Estado de Sinaloa, en diciembre de 2020 y derivado de que se elegirá Gubernatura el proceso electoral concluirá con el Dictamen y declaración de validez de dicha elección, o en su caso, con las resoluciones que en última instancia emita el órgano jurisdiccional.",
          numConsejo: "24 Consejos Distritales y 12 Consejos Municipales",
          res:
            " y los Consejos Distritales y municipales del Instituto Electoral del Estado de Sinaloa",
            consejo1:"o del Instituto Electoral del Estado de Sinaloa ",
            consejo2:"al Instituto Electoral del Estado de Sinaloa",
          consejo3: "Consejo General del Instituto Electoral del Estado de Sinaloa",
        });
        break;
      case 26:
        setText({
          estado: "de Sonora",
          diputacion:
            "elegirá 1 Gubernatura, 33 Diputaciones Locales y 72 Ayuntamientos",
          parrafo:
            "Sonora inicia con la primera sesión del Consejo General del Instituto Estatal Electoral y de Participación Ciudadana, en septiembre de 2020 y concluirá también con la declaración de validez de la elección de gubernatura, diputaciones locales y ayuntamientos o en su caso, con las resoluciones que en última instancia emita el órgano jurisdiccional.",
          numConsejo: "21 Consejos Distritales y 72 Consejos Municipales",
          res:
            ", los Consejos Distritales y Municipales del Instituto Estatal Electoral y de Participación Ciudadana",
          consejo1: "o del Instituto Estatal Electoral y de Participación Ciudadana ",
          consejo2: "al Instituto Estatal Electoral y de Participación Ciudadana",
          consejo3:"Consejo General del Instituto Estatal Electoral y de Participación Ciudadana",
        });
        break;
      case 27:
        setText({
          estado: "de Tabasco",
          diputacion:
            "elegirá 35 Diputaciones Locales, y 17 Presidencias Municipales y Regidurías",
          parrafo:
            "Tabasco inicia con la primera sesión del Consejo Estatal del Instituto Electoral y de Participación Ciudadana de Tabasco, en la primera semana de octubre de 2020 y concluirá también con la declaración de validez de la elección de las diputaciones locales y las presidencias municipales y regidurías o en su caso, con las resoluciones que en última instancia emita el órgano jurisdiccional.",
          numConsejo: "21 Consejos Electorales Distritales",
          res:
            " y los Consejos Electorales Distritales del Instituto Electoral y de Participación Ciudadana de Tabasco",
          consejo1:
            "o el Consejo Estatal del Instituto Electoral y de Participación Ciudadana de Tabasco ",
            consejo2:"al Instituto Electoral y de Participación Ciudadana de Tabasco",
            consejo3:"Consejo Estatal del Instituto Electoral y de Participación Ciudadana de Tabasco",
        });
        break;
      case 28:
        setText({
          estado: "de Tamaulipas",
          diputacion: "elegirá 36 Diputaciones Locales y 43 Ayuntamientos",
          parrafo:
            "Tamaulipas inicia con la primera sesión del Consejo General del Instituto Electoral de Tamaulipas, en septiembre de 2020 y concluirá también con la declaración de validez de la elección de las diputaciones locales y ayuntamientos o en su caso, con las resoluciones que en última instancia emita el órgano jurisdiccional.",
          numConsejo: "22 Consejos Distritales y 43 Consejos Municipales",
          res:
            ", los Consejos Distritales y Municipales del Instituto Electoral de Tamaulipas",
          consejo1: "o del Instituto Electoral de Tamaulipas ",
          consejo2:"al Instituto Electoral de Tamaulipas",
          consejo3:"Consejo General del Instituto Electoral de Tamaulipas",
        });
        break;
      case 29:
        setText({
          estado:"de Tlaxcala",
          diputacion:
            "elegirá 1 Gubernatura, 25 Diputaciones Locales, 60 Ayuntamientos y 299 Presidencias de Comunidad",
          parrafo:
            "Tlaxcala inicia con la primera sesión del Consejo General del Instituto Tlaxcalteca de Elecciones, en noviembre de 2020 y concluirá también con la declaración de validez de la elección de gubernatura, diputaciones locales, ayuntamientos y presidencias de comunidad o en su caso, con las resoluciones que en última instancia emita el órgano jurisdiccional.",
          numConsejo: "15 Consejos Distritales y 60 Consejos Municipales",
          res:
            ", los Consejos Distritales y Municipales del Instituto Tlaxcalteca de Elecciones",
          consejo1: "o del Instituto Tlaxcalteca de Elecciones ",
          consejo2:"al Instituto Tlaxcalteca de Elecciones",
          consejo3:"Consejo General del Instituto Tlaxcalteca de Elecciones",
        });
        break;
      case 30:
        setText({
          estado: "de Veracruz",
          diputacion: "elegirá 50 Diputaciones Locales y 212 Ayuntamientos",
          parrafo:
            "Veracruz inicia con la primera sesión del Consejo General del OPLE Veracruz, en la primera semana de enero de 2021 y concluirá con los resultados electorales de la elección de las diputaciones locales y ayuntamientos o en su caso, con las resoluciones que en última instancia emita el órgano jurisdiccional.",
          numConsejo:
            "30 Consejos Distritales y 212 Consejos Municipales",
          res:
            ", los Consejos Distritales y los Consejos Municipales del OPLE Veracruz",
          consejo1: "o del OPLE Veracruz ",
          consejo2:"al OPLE Veracruz",
          consejo3:"Consejo General del Organismo Público Local Electoral del Estado de Veracruz",
        });
        break;
      case 31:
        setText({
          estado: "de Yucatán",
          diputacion: "elegirá 25 Diputaciones Locales, y 106 Ayuntamientos",
          parrafo:
            "Yucatán inició con la primera sesión del Consejo General del Instituto Electoral y de Participación Ciudadana de Yucatán, el 4 de noviembre de 2020 y concluirá también con la declaración de validez de la elección de las diputaciones locales y ayuntamientos o en su caso, con las resoluciones que en última instancia emita el órgano jurisdiccional.",
          numConsejo: "15 Consejos Distritales y 106 Consejos Municipales",
          res:
            ", los Consejos Distritales y los Consejos Municipales del Instituto Electoral y de Participación Ciudadana de Yucatán",
          consejo1:
            "o del Instituto Electoral y de Participación Ciudadana de Yucatán ",
            consejo2:"Instituto Electoral y de Participación Ciudadana de Yucatán",
            consejo3:"Consejo General del Instituto Electoral y de Participación Ciudadana de Yucatán",
        });
        break;
      case 32:
        setText({
          estado: "de Zacatecas",
          diputacion:
            "elegirá 1 Gubernatura, 30 Diputaciones Locales y 58 Ayuntamientos",
          parrafo:
            "Zacatecas inicia con la primera sesión del Consejo General del Instituto Electoral del Estado de Zacatecas, el 7 de septiembre de 2020 y derivado de que se elegirá Gubernatura el proceso electoral concluirá con el Dictamen y declaración de validez de la elección, o en su caso, con las resoluciones que en última instancia emita el órgano jurisdiccional.",
          numConsejo: "18 Consejos Distritales y 58 Consejos Municipales",
          res:
            " y los Consejos Distritales y Municipales del Instituto Electoral del Estado de Zacatecas",
          consejo1: "o del Instituto Electoral del Estado de Zacatecas ",
          consejo2:"al Instituto Electoral del Estado de Zacatecas",
          consejo3:"Consejo General del Instituto Electoral del Estado de Zacatecas",
        });
        break;

      default:
        return setText({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return text;
};

export const getInicioProcLocal = (idEntidad, inicioLocal) => {
  const especiales = [14];
  if (especiales.includes(idEntidad)) {
    return "el día en que se publica la convocatoria del Consejo General del IEPC Jalisco para la celebración de las elecciones ordinarias, durante la primera quincena de octubre de 2020 y concluye con la declaratoria de la conclusión del proceso electoral.";
  } else {
    return (
      "con la primera sesión del " +
      inicioLocal +
      ", con las resoluciones que en última instancia emita el órgano jurisdiccional."
    );
  }
};

export const getPrepElec = (idEntidad, consejo) => {
  const especiales = [14];
  if (especiales.includes(idEntidad)) {
    return ", y con la publicación de la convocatoria para la celebración de las elecciones ordinarias en Jalisco, ";
  } else {
    return "o del " + consejo + " ";
  }
};
