export const required = [{ required: true, message: "Dato requerido." }];

export const inputs = [
{
    message: "Caracteres inválidos.",
    pattern: /^[a-zA-Z,?*-0123456789/#]+[^\\_!"$%&()=¡'¨°|<>áéíóúüÁÉÍÓÚÜ]+$/,
},
{ required: true, message: "Dato requerido." },
{ validateStatus: "error" },
];

export const nombres = [
    {
      message: "Caracteres inválidos.",
      pattern: /^[a-zA-Z-äëïöüÄËÏÖÜáéíóúÁÉÍÓÚÑñ]+[^0-9{}\\_!"´#$%&()=?/¡*'¨°|;,<>-]+$/,
    },
    { required: true, message: "Dato requerido." },
    { validateStatus: "error" },
    ];


export const txtTexto = /^[a-zA-Z,?*-0123456789/#\s]+$/i;
export const txtNombres = /^[a-zA-Z-áéíóúÁÉÍÓÚÑñäëïöü\s]+$/i;