import React from "react";
import { Row, Col } from "antd";
import { ReactComponent as CredSolicitud} from "../../../assets/img/homeInt/credSolicitud_icon.svg";

const RequestAcreditacion = (props) => {

  const {ciudadanos, organizaciones, acreditados} = props;

  return (
    <Row>
      <Col span={12}>
        <CredSolicitud />&nbsp;&nbsp;{acreditados} Solicitudes acreditadas
      </Col>
      <Col span={12}>
        <p>{ciudadanos} Solicitudes individuales</p>
        <p>{organizaciones} Solicitudes de miembros de organización</p>
      </Col>
    </Row>
  );
};

export default RequestAcreditacion;
