import React, { useState, useEffect, useRef } from "react";
import { Button, Icon } from "antd";
import { Link, withRouter } from "react-router-dom";
import Table from "../../../components/Admin/Action/Table";
import { getAction } from "../../../api/action";
import useAuth from "../../../hooks/useAuth";
import {
  rol_captura,
  rol_consulta,
  rol_modifica,
  rol_borrar,
} from "../../../utils/Action/roles";

function Action(props) {
  const [reload, setReload] = useState(false);
  const [action, setAction] = useState({
    list: [],
    loading: false,
  });
  const [rolModulo, setRolModulo] = useState({
    captura: false,
    modifica: false,
    consulta: false,
    borrar: false,
  });
  const {
    proceso: { proceso, estado, distrito },
  } = props;
  const [process, setProcess] = useState({});
  const isMounted = useRef(true); //Referencia que permite verificar si el componente esta visible
  const { user: { rolUsuario },} = useAuth();

  //useEffect que valida que el componente este seleccionado de lo contrario cancela cualquier subcripción
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    let idProceso, idDetalle, idEstado, idDistrito;
    if (proceso) {
      idProceso = proceso.idProcesoElectoral;
      idDetalle = proceso.idDetalleProceso;
      idEstado = estado ? estado.idEstado : 0;
      idDistrito = distrito ? distrito.idDistrito : 0;
      isMounted.current &&
        setProcess({ idProceso, idDetalle, idEstado, idDistrito });
    }
  }, [proceso, estado, distrito]);

  useEffect(() => {
    //Valida los permisos que tienen el usuario
    let captura = false;
    let modifica = false;
    let consulta = false;
    let borrar = false;
    //Devuelve un true si cuenta con los permisos
    captura = rol_captura.includes(rolUsuario);
    modifica = rol_modifica.includes(rolUsuario);
    consulta = rol_consulta.includes(rolUsuario);
    borrar = rol_borrar.includes(rolUsuario);

    setRolModulo(() => ({ captura, modifica, consulta, borrar })); //Se almacenan en el hook
    //Se realiza la petición
    if (process.idProceso) {
      setAction({ loading: true }); //Se habilita el componente de loading
      getAction(
        process.idProceso,
        process.idDetalle,
        process.idEstado,
        process.idDistrito,
        true
      )
        .then((res) => {
          const dataSource = [];
          res.data.map((prom, index) => {
            return dataSource.push({
              key: index,
              indice: index + 1,
              ...prom,
            });
          });
          setAction({ list: dataSource, loading: false }); //Se almacenan en el hook
        })
        .catch((error) => {
          console.log(error);
        });
    }
    setReload(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload, rolUsuario, process]);

  return (
    <div style={{ marginTop: "15px", marginLeft: "20px" }}>
      {rolModulo.captura && (
        <>
          <h1>Acciones de difusión</h1>
          <div>
            <Button className="convocation btn_secundario">
              <Link to={"/admin/accion/registro"}>
                <Icon type="notification" />
                <br />
                Nueva acción de difusión
              </Link>
            </Button>
          </div>
        </>
      )}
      <div style={{ marginTop: "30px" }}>
        <h3>Acciones de difusión realizadas</h3>
        <Table
          data={action.list}
          setReload={setReload}
          rolModulo={rolModulo}
          loading={action.loading}
        />
      </div>
    </div>
  );
}

export default withRouter(Action);
