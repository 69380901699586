import React from "react";
import Lesson1 from "./lessons/Lesson1";
import Lesson2 from "./lessons/Lesson2";
import Lesson3 from "./lessons/Lesson3";
import Lesson4 from "./lessons/Lesson4";
import Lesson5 from "./lessons/Lesson5";
import Lesson6 from "./lessons/Lesson6";
import Lesson7 from "./lessons/Lesson7";
import Lesson8 from "./lessons/Lesson8";
import Lesson9 from "./lessons/Lesson9";
//Main que muestra el número de la lección correpondiente
export default function MainMaterial(props) {
  const { idLesson } = props; //Se obtiene el número de la lección
  //Se envian todas las propiedades a los componentes de las lecciones
  switch (idLesson) {
    case 1:
      return <Lesson1 {...props} />;
    case 2:
      return <Lesson2 {...props} />;
    case 3:
      return <Lesson3 {...props} />;
    case 4:
      return <Lesson4 {...props} />;
    case 5:
      return <Lesson5 {...props} />;
    case 6:
      return <Lesson6 {...props} />;
    case 7:
      return <Lesson7 {...props} />;
    case 8:
      return <Lesson8 {...props} />;
    case 9:
      return <Lesson9 {...props} />;
    default:
      return null;
  }
}
