import React, { useState, useEffect } from "react";
import { Input, Table } from "antd";
import { withRouter } from "react-router-dom";

function TableAcreditables(props) {
  const { data } = props;
  const [dataSource, setDataSource] = useState([]); //hook para almacenar el array
  const [value, setValue] = useState(""); //Hook para el filtrado de nombre
  const [valClave, setValClave] = useState(""); //Hook para el filtrado de clave
  const [valFolio, setValFolio] = useState(""); //Hook para el filtrado de folio

  useEffect(() => {
    setDataSource(data);
  }, [data]);
  

//Función que filtra los registros por el nombre
const FilterByNameInput = (
    <>
      Nombre del solicitante
      <Input
        placeholder="Nombre del solicitante"
        value={value}
        onChange={(e) => {
          const currValue = e.target.value;
          setValue(currValue);
          const filteredData = data.filter((entry) =>
            entry.nombreSolicitante
              .toString()
              .toLowerCase()
              .includes(currValue.toLowerCase())
          );
          setDataSource(filteredData);
        }}
      />
    </>
);
//Función que filtra los registros por clave de elector
const FilterByClaveInput = (
    <>
      Clave de Elector
      <Input
        placeholder="Clave de Elector"
        valClave={valClave}
        onChange={(e) => {
          const currValue = e.target.value;
          setValClave(currValue);
          const filteredData = data.filter((entry) =>
            entry.claveElector
              .toString()
              .toLowerCase()
              .includes(currValue.toLowerCase())
          );
          setDataSource(filteredData);
        }}
      />
    </>
);
//Función que filtra los registros por folio
const FilterByFolioInput = (
    <>
      Folio
      <Input
        placeholder="Folio"
        valFolio={valFolio}
        onChange={(e) => {
          const currValue = e.target.value;
          setValFolio(currValue);
          const filteredData = data.filter((entry) =>
            entry.folio
              .toString()
              .toLowerCase()
              .includes(currValue.toLowerCase())
          );
          setDataSource(filteredData);
        }}
      />
    </>
);

  //Se definen las columnas de la tabla de Solicitudes acreditadas
  const columns = [
    {
      title: FilterByNameInput,
      dataIndex: "nombreSolicitante",
      key: "nombreSolicitante",
      width: 330,
    },
    {
      title: FilterByClaveInput,
      dataIndex: "claveElector",
      key: "claveElector",
      width: 230,
    },
    {
      title: FilterByFolioInput,
      dataIndex: "folio",
      key: "folio",
      width: 150,
    },
    {
      title: "Fecha en que completó el curso",
      dataIndex: "fechaAprobacionCurso",
      key: "fechaAprobacionCurso",
      width: 150,
    },
    {
      title: "Tipo de solicitud",
      dataIndex: "tipoSolicitud",
      key: "tipoSolicitud",
      width: 150,
    },
    {
      title: "Solicitud recibida por",
      dataIndex: "origenSolicitud",
      key: "origenSolicitud",
      width: 150,
    },
    {
      title: "Procedencia de la solicitud",
      dataIndex: "procedencia",
      key: "procedencia",
      width: 150,
    },
    {
      title: "Correo electrónico",
      dataIndex: "correo",
      key: "correo",
      width: 340,
    },
    {
      title: "Teléfono",
      dataIndex: "telefono",
      key: "telefono",
      width: 150,
    },
  ];

const rowSelection = {
    onSelectAll: (selected, selectedRows) => {
        const datos = [];
        if(selectedRows.length > 0) { //Seleccionaron todos
            for (let i = 0; i < dataSource.length; i++) {
                dataSource[i].seleccionado = true;
                datos.push(dataSource[i]);
            };
        } else {
            for (let i = 0; i < dataSource.length; i++) {
                dataSource[i].seleccionado = false;
                datos.push(dataSource[i]);
            };
        }
        setDataSource(datos);
    },
    onSelect: (record) => {
        const datos = [];
        for (let i = 0; i < dataSource.length; i++) {
            if (dataSource[i].idObservador === record.idObservador) {
                let value = dataSource[i].seleccionado;
                dataSource[i].seleccionado = !value;
            }
            datos.push(dataSource[i]);
        }
        setDataSource(datos);
    },
    getCheckboxProps: record => ({
        checked: record.seleccionado === true,
    }),
};

  return (
    <Table
      columns={columns}
      dataSource={dataSource}
      rowSelection={rowSelection}
      scroll={{ x: 1800 }}
      footer={() => `Total de solicitudes: ${dataSource.length}`}
    />
  );
}
export default withRouter(TableAcreditables);