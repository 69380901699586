import React, { useState } from "react";
import { Row, Modal, Icon, Col } from "antd";
import { Link, withRouter } from "react-router-dom";
import useAuth from "../../../hooks/useAuth";
import Login from "../Login/Login";
import Singup from "../Login/Signup";
/**
 *
 *Componente que muestra los links de Registro / Inicio de sesión en eñ portal público
 */
function SignIn(props) {
  const {
    history: {
      location: { pathname },
    },
  } = props;
  const [visibleSession, setVisibleSession] = useState(false);
  const [visibleRegister, setVisibleRegister] = useState(false);
  const {
    ciudadano: { ciudadano },
  } = useAuth();

  //Funciones para mostrar el modal
  const showModalSession = () => {
    setVisibleSession(true);
    setVisibleRegister(false);
  };
  const showModalRegister = () => {
    setVisibleRegister(true);
    setVisibleSession(false);
  };

  const handleOkSesion = (e) => {
    setVisibleSession(false);
  };

  const handleCancelSesion = (e) => {
    setVisibleSession(false);
  };
  const handleOk = (e) => {
    setVisibleRegister(false);
  };

  const handleCancel = (e) => {
    setVisibleRegister(false);
  };

  return (
    <>
      <Modal
        visible={visibleSession}
        onOk={handleOkSesion}
        onCancel={handleCancelSesion}
        footer={null}
        className="modalLoginRegistro"
      >
        <h1 style={{ textAlign: "center" }}>Inicia Sesión</h1>
        <Login visibleSession={visibleSession} /> {" "}
        <br/>
        <Row style={{ marginBottom: "10px" }}>
          <Link to="/portal/recovery">¿Olvidaste tu contraseña?</Link>
        </Row>
        <p>
          <strong>¿Eres nuevo?</strong><br/>
          <Link to={pathname} onClick={showModalRegister}>
            Registrate
          </Link>
          para iniciar con tu solicitud.
        </p>
      </Modal>
      <Modal
        
        visible={visibleRegister}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        className="modalLoginRegistro"
      >
        <center>
          <h1>Regístrate</h1>
        </center>
        <Singup
          setVisibleRegister={setVisibleRegister}
          visibleRegister={visibleRegister}
        />
        <hr />
        <p>
          ¿Ya tienes una cuenta? &nbsp;
          <Link to={pathname} onClick={showModalSession}>
            Inicia Sesión
          </Link>
        </p>
      </Modal>
      {!ciudadano && (
        <div className="login-registro">
        <Row>
          <Col span={12}>
            <div className="registrate">
              <Link
                to={pathname}
                type="link"
                onClick={showModalRegister}
                style={{ textDecoration: "underline" }}
              >
                Regístrate
              </Link>{" "}
              <span>para iniciar tu solicitud</span>
            </div>
          </Col>
          <Col span={12}>
            <div className="loginCiudadano">
              <Link to={pathname} onClick={showModalSession}>
                <Icon type="user" /> Inicia sesión
              </Link>
            </div>
          </Col>
        </Row>
        </div>
      )}
    </>
  );
}
export default withRouter(SignIn);
