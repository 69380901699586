import React from "react";
import { Icon, message } from "antd";
import { downloadConvocation } from "../../../../api/convocation";
import "./ContentConvocation.scss";

export default function ContentConvocation(props) {
  const { ine } = props;

  // if (loading) {
  //   return <Loading />;
  // }

  const download = (con) => {
    const json = {
      idTipo: con.idTipo,
      idConvocatoria: con.idConvocatoria,
    };
    downloadConvocation(json)
      .then((response) => {
        if (response.data) {
          const link = document.createElement("a");
          link.href = URL.createObjectURL(
            new Blob([response.data], { type: "application/pdf" })
          );
          link.setAttribute("download", `Convocatoria.pdf`);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      })
      .catch((error) => message.error("El archivo no se encuentra disponible"));
  };
  ///////////////-----------------------------------------/////////////////////////////////////////////
  return (
    <div style={{ marginRight: 50 }}>
      {ine.length > 0 ? (
        <>
          <button className="btnDescargar" onClick={(e) => download(ine[0], e)}>
            Descargar convocatoria{" "}
            <Icon type="download" style={{ fontSize: 25 }} />
          </button>

          <embed
            src={`data:application/pdf;base64,${ine[0].convocatoria}`}
            type="application/pdf"
            width="100%"
            height="1500px"
          />
        </>
      ) : (
        <p>En este momento no hay convocatorias publicadas.</p>
      )}
    </div>
  );
}
///////////////---------------INE---------------------------/////////////////////////////////////////////
// function ConvocationsINE({ convocation, download, estado }) {
//   return (
//     <>
//       {convocation.map((con, i) =>
//         estado === "-1" ? (
//           <RenderConvocationINE con={con} key={i} download={download} />
//         ) : (
//           estado === split(con.detalleProceso) && (
//             <RenderConvocationINE con={con} key={i} download={download} />
//           )
//         )
//       )}
//     </>
//   );
// }

// function RenderConvocationINE(props) {
//   const { con, download } = props;
//   return (
//     <div className="convocationCont INE" onClick={(e) => download(con, e)}>
//       <div>
//         <h5 style={{ marginTop: "3px" }}>Instituto Nacional Electoral</h5>
//         <Row>
//           <Col span={18}>
//             <p>{con.procesoElectoral} </p>
//             <span>{`Recepción de solicitudes hasta el ${moment(
//               con.fechaLimite
//             ).format("DD-MMMM-YYYY")} `}</span>
//           </Col>
//           <Col span={6}>
//             <Icon type="download" style={{ fontSize: 25 }} />
//             <div>Descargar</div>
//           </Col>
//         </Row>
//       </div>
//     </div>
//   );
// }
// ///////////////---------------OPL---------------------------/////////////////////////////////////////////
// function ConvocationsOPL({ convocation, download, estado }) {
//   return (
//     <>
//       {convocation.map((con, i) =>
//         estado === "-1" ? (
//           <RenderConvocationOPL con={con} key={i} download={download} />
//         ) : (
//           estado === split(con.detalleProceso) && (
//             <RenderConvocationOPL con={con} key={i} download={download} />
//           )
//         )
//       )}
//     </>
//   );
// }

// function RenderConvocationOPL(props) {
//   const { con, download } = props;
//   return (
//     <div className="convocationCont INE" onClick={(e) => download(con, e)}>
//       <div>
//         <strong>
//           Instituto Estatal Electoral de {split(con.detalleProceso)}
//         </strong>
//         <Row>
//           <Col span={18}>
//             <p>{con.procesoElectoral} </p>
//             <span>{`Recepción de solicitudes hasta el ${moment(
//               con.datosOples.fechaFin
//             ).format("DD-MMMM-YYYY")} `}</span>
//           </Col>

//           <Col span={6}>
//             <Icon type="download" style={{ fontSize: 25 }} />
//             <div>Descargar</div>
//           </Col>
//         </Row>
//       </div>
//     </div>
//   );
// }

// function split(detalle) {
//   let estado = detalle.split("-");
//   return estado[2].charAt(0).toUpperCase() + estado[2].slice(1).toLowerCase();
// }
