import React, { useState, useEffect } from "react";
import { withRouter, Link } from "react-router-dom";
import { Form, Icon, Row, Col } from "antd";
import useAuth from "../../../hooks/useAuth";
import { Ast } from "../../../utils/Information/functions";
import Data from "../../../components/Information/Data";
import { getInformeObservador } from "../../../api/information";
import { MSN_GUARDAR } from "../../../utils/constanst";
import { rol_captura, rol_modifica, rol_consulta, rol_cau } from "../../../utils/Information/roles";


function Information(props) {
    const { history, setLoader, proceso } = props; //Para utilizar la función push
    const { user: { user, rolUsuario } } = useAuth();
    const { data } = props.location;
    const [informe, setInforme] = useState(); 
    const { getFieldDecorator } = props.form; //Método para validar los inputs
    const [datos, setDatos] = useState({ nombre: "", clave: "", solicitud: "" });
    const [disabled, setDisabled] = useState(false); //Hook para desactivar los campos
    const [seModifica, setSeModifica] = useState(false); //Hook para desactivar la modificación
    const [button, setButton] = useState(false);

    useEffect(() => {
        setLoader({ loading: true, message: MSN_GUARDAR }); 
        if (proceso.distrito !== undefined && proceso.distrito !== null) {
            if (rol_cau.includes(rolUsuario)) {
                setButton(true);
            }
            switch ( history.location.pathname ) {
                case "/admin/informes/registro":
                  if (!rol_captura.includes(rolUsuario)) {
                    history.push("/admin/informes");
                  } else {
                    setSeModifica(true);
                    setDisabled(false);
                  }
                  break;
                case "/admin/informes/modifica":
                  if (!rol_modifica.includes(rolUsuario)) {
                    history.push("/admin/informes");
                  } else {
                    setSeModifica(true);
                    setDisabled(false);
                  }
                  break;
                case "/admin/informes/consulta":
                  if (!rol_consulta.includes(rolUsuario)) {
                    history.push("/admin/informes");
                  } else {
                    if (!rol_modifica.includes(rolUsuario)) {
                        setButton(true);
                    }
                    setDisabled(true);
                    if (rol_cau.includes(rolUsuario)) {
                        setSeModifica(false);
                    } else {
                        setSeModifica(true);
                    }
                  }
                  break;
                default:
                  break;
            }
        } else {
            history.push("/admin/informes");
        }
        getInforme();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [proceso]);

    function getInforme() {
        if (data !== undefined && data !== null && data.idObservador !== null) {
            getInformeObservador(data.idObservador).then((res) => {
                if (res.data !== null) {
                    setInforme(res.data);
                    setDatos({nombre: res.data.nombreObservador, clave: res.data.claveElector, 
                        solicitud: res.data.tipoSolicitud === 1 ? "Miembro de organización":"Individual"});
                }
                setLoader({ loading: false, message: "" });
            }).catch((error) => {
                console.log("Ocurrio un error", error);
                setLoader({ loading: false, message: "" }); 
            }); 
        }
    }

    useEffect(() => {
        if (informe !== undefined && informe !== null) {
            informe.medioCaptura = 1;
            informe.usuario = user.sub;
            informe.captura = localStorage.getItem('NOMBRE');
            let tipoJunta = "Junta Distrital";
            if (proceso.distrito.idDistrito === 0) {
                tipoJunta = "Junta Local";
            } 
            informe.nombreJunta = `${tipoJunta} ${proceso.estado.nombreEstado} | ${proceso.distrito.idDistrito}. ${proceso.distrito.nombreDistrito}`;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [informe]);

    return (
        <div style={{ marginTop: 50 }}>
            <div style={{ marginTop: "10px", marginBottom: "30px" }}>
                <Link to={"/admin/informes"} className="back">
                    <Icon type="play-circle" theme="filled" rotate="180" /> Regresar
                </Link>
            </div>
            <h1>Informe de observación electoral</h1>
            <hr />
            <p style={{ marginTop: 30 }}>
                Los datos marcados con ( <Ast /> ) son obligatorios.
            </p>
            <div className="card-menu-home" >
            <Row>
                <Col span={6} style={{ paddingRight: "20px" }}>
                    <strong>Nombre del Observador/a:</strong>
                    <p>{datos.nombre}</p>
                </Col>
                <Col span={6} style={{ paddingRight: "20px" }}>
                    <strong>Clave de Elector:</strong>
                    <p>{datos.clave}</p>
                </Col>
                <Col span={6} style={{ paddingRight: "20px" }}>
                    <strong>Tipo de solicitud:</strong>
                    <p>{datos.solicitud}</p>
                </Col>
            </Row>
            </div>
            {informe !== null && (
                <Data
                informe={informe} //se manda el hook de los valores
                getFieldDecorator={getFieldDecorator} //Se envia para validar los inputs
                form={props.form}
                setLoader={setLoader}
                setInforme={setInforme}
                history={history}
                disabled={disabled}
                setDisabled={setDisabled}
                seModifica={seModifica}
                button={button}
                />
            )}
        </div>
      );

}
const InformationForm = Form.create()(Information);
export default withRouter(InformationForm);