import React from "react";
import { Col, Row } from "antd";
import { Link } from "react-router-dom";

export default function Questions(props) {
  return (
    <div className="card-menu-home">
      <Row gutter={24}>
        <p className="titulo">Preguntas frecuentes</p>
        <Col xxl={8} xl={8} lg={8} md={8} sm={24} xs={24}>
          <div>
            <div className="numero">1</div>
            <p>
              <b>¿Qué documentos necesito para ser Observador Electoral?</b>
            </p>
            <p>
              Credencial de elector vigente y una fotografía de retrato reciente.
            </p>
            <Link to={"/portal/requisitos"} className="link">Consulta los requisitos completos.</Link>
          </div>
        </Col>
        <Col xxl={8} xl={8} lg={8} md={8} sm={24} xs={24}>
          <div>
            <div className="numero">2</div>
            <p>
              <b>¿Qué puedo hacer como Observador Electoral?</b>
            </p>
            <p>
              Puedes observar las distintas etapas del desarrollo de todo el Proceso Electoral.
            </p>
            <Link to={"/portal/observador"} className="link">Conoce los derechos y obligaciones de un observador.</Link>
          </div>
        </Col>
        <Col xxl={8} xl={8} lg={8} md={8} sm={24} xs={24}>
          <div>
            <div className="numero">3</div>
            <p>
              <b>
                ¿Es necesario tener conocimientos sobre elecciones para ser Observador?
              </b>
            </p>
            <p>
              El Instituto te brinda capacitación para que puedas desempeñar tu labor.
            </p>
            <Link to={"/portal/acreditacion"} className="link">Conoce el proceso para ser acreditado como observador.</Link>
          </div>
        </Col>
      </Row>
    </div>
  );
}
