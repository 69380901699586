import React from 'react';
import "./GeneralHome.scss";

const RequestObs = (props) => {

    const {conteo } = props;
    return (
        <div className="cardMain">
            <strong><p className="observadorC">{conteo}</p></strong>
            <p>Solicitudes recibidas</p>
        </div>
    );
}

export default RequestObs;