import React from "react";
import { useSelector } from "react-redux";
import { GuiaMaterial, MatApoyo } from "../Generals";

export default function Lesson9({ idLesson, data, setLoading }) {
  const { temario } = useSelector((state) => state.menu);
  const { material, temarioMat } = useSelector((state) => state.material);
  //Función que obtiene el índice del titulo de la lección y así poder mostrar el número de la lección
  const getIndex = () => {
    let array = material ? temarioMat : temario;
    let index = array.findIndex((obj) => obj.idLeccion === idLesson);
    if (index + 1 === 8) {
      return "VIII Y IX";
    } else {
      return "IX Y X";
    }
  };

  const getNumber = () => {
    let array = material ? temarioMat : temario;
    let index = array.findIndex((obj) => obj.idLeccion === idLesson);
    return index + 1;
  };
  return (
    <div className="texto_parrafo">
      <p>
        Los <b>delitos electorales</b> son aquellas acciones u omisiones que
        lesionan o ponen en peligro el adecuado desarrollo de la función
        electoral y atentan contra las características del voto que debe ser
        universal, libre, directo, personal, secreto e intransferible, descritas
        y sancionadas por la Ley General en Materia de Delitos Electorales.{" "}
        <br />
        <br />
        Entre los que se encuentran:
      </p>
      <ul>
        <li>
          La <b>compra del voto</b>, que se refiere al desvío de recursos
          públicos por parte de autoridades gubernamentales o partidos políticos
          para pagar la orientación del voto.
        </li>
        <li>
          La <b>coacción del voto</b> es cuando una persona, grupo o institución
          ejerce presiones de cualquier tipo sobre las o los ciudadanos para
          limitar u orientar la emisión de su voto.
        </li>
      </ul>
      <p>
        Además de las anteriores, hay otras conductas que se encuentran
        tipificadas como delitos electorales.
      </p>
      <p>
        En el siguiente material de apoyo podrás conocer algunos de los delitos
        electorales.
      </p>
      {/* <MatApoyo array={[{ titulo: "Delitos electorales" }]} /> */}
      {data.length && <MatApoyo array={[data[0]]} />}
      <p>
        Cualquier persona que incurra en alguna o varias de estas conductas
        estaría cometiendo un delito electoral, además existen tipos penales que
        se han reservado para personas que <b>tienen una calidad especial</b>,
        por lo que la sanción a quienes realicen alguna de las conductas antes
        enlistadas dependerá del delito cometido.{" "}
      </p>
      <p>
        A continuación, revisa el material de apoyo para conocer los sujetos
        activos de un delito electoral.
      </p>
      {/* <MatApoyo array={[{ titulo: "Tipos de sujetos activos del delito" }]} /> */}
      {data.length && <MatApoyo array={[data[1]]} />}
      <p>
        A aquella persona que incurra o cometa algún delito electoral se le
        impondrán sanciones como:
      </p>
      <ul>
        <li>La pérdida del derecho al voto activo y pasivo.</li>
        <li>La suspensión de sus derechos políticos.</li>
        <li>Multa y privación de la libertad.</li>
      </ul>
      <p>
        Revisa el siguiente material donde podrás conocer las características de
        la denuncia de un delito electoral.
      </p>
      {/* <MatApoyo array={[{ titulo: "Los elementos de la denuncia" }]} /> */}
      {data.length && <MatApoyo array={[data[2]]} />}
      <p className="bold">
        Para el tema de violencia política, te invitamos a ver el siguiente
        video, haz clic en el enlace.
      </p>
      {/* <MatApoyo
        array={[
          {
            titulo:
              "¿Qué es la violencia política contra las mujeres en razón de género?",
          },
        ]}
      /> */}
      {data.length && <MatApoyo array={[data[3]]} />}
      <p>
        Como puedes ver la observación electoral desempeña un papel fundamental
        en la prevención y erradicación de los delitos electorales, dado que:
      </p>
      <ul>
        <dt>
          a) Tiene un efecto de disuasión en quienes se interesan en llevar a
          cabo prácticas irregulares o delictivas.{" "}
        </dt>
        <dt>
          b) Permite obtener información sobre las características de las formas
          de manipulación del voto.
        </dt>
        <dt>
          c) Proporciona insumos para comprender el fenómeno y diseñar políticas
          públicas para prevenirlo y erradicarlo.
        </dt>
        <dt>
          d) Genera confianza y credibilidad en las instituciones y en los
          procesos electorales.
        </dt>
        <dt>e) Contribuye a fortalecer la democracia y la paz social.</dt>
      </ul>
      <p>
        Es importante que frente a cualquier conducta que pudiera constituir un
        delito electoral,{" "}
        <b>
          <u>se denuncie</u>
        </b>{" "}
        ante la Fiscalía Especializada en Delitos Electorales o ante la
        autoridad electoral local correspondiente.
      </p>
      <p>
        Las y los observadores contribuyen a la certeza y a que todo marche
        conforme a derecho, lo cual implica la responsabilidad de denunciar si
        se presencia un delito electoral, una situación de violencia política
        contra las mujeres en razón de género o cualquier situación que no se
        realice conforme a la ley.
      </p>
      <p>
        Felicidades has concluido tus lecciones, ahora que conoces la
        importancia de tu labor ayúdanos a evitar que se cometan delitos
        electorales ¡Denuncia cualquier delito que presencies!, ¡tu aportación
        cuenta, no basta con observar, hay que actuar!
      </p>
      <p className="bold">
        No olvides consultar los{" "}
        <GuiaMaterial setLoading={setLoading}>
          CAPÍTULOS {getIndex()} DE TU GUÍA TEMÁTICA PARA CAPACITAR A LAS Y LOS
          OBSERVADORES ELECTORALES
        </GuiaMaterial>{" "}
        que está disponible en los materiales de consulta de este curso, así
        como el material de apoyo para que refuerces tus conocimientos.{" "}
      </p>
      {/* <MatApoyo
        array={[
          {
            titulo: `Presentación ${getNumber()}: Delitos Electorales y violencia política contra las mujeres`,
          },
        ]}
      /> */}
      {data.length && (
        <MatApoyo
          array={[data[4]]}
          number={true}
          getNumber={`Presentación ${getNumber()}.`}
        />
      )}
      <p>
        Ahora ya puedes realizar la actividad de evaluación de esta lección.{" "}
      </p>
    </div>
  );
}
