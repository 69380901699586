import React, { useState, useEffect, useRef } from "react";
import moment from 'moment';
import { Ast, controlInforme } from "../../utils/Information/functions";
import { required, txtTexto } from "../../utils/Information/rules";
import { Row, Col, Checkbox, Tabs, Radio, Form, DatePicker, Button, message, Input, Alert } from "antd";
import { getInformeEtapa, getInformeEtapaJunta } from "../../api/information";
import Components from "../Information/Components";

const { TabPane } = Tabs;

export default function Data(props) {
    const { informe, getFieldDecorator, form, setLoader, setInforme, history, disabled, setDisabled, seModifica, button } = props;
    const isMounted = useRef(true);
    const [etapas, setEtapas] = useState();  
    const [etapasSeleccionadas, setEtapasSeleccionadas] = useState(); 
    const [etapaSeleccionada, setEtapaSeleccionada] = useState();  // etapa seleccionada que se irá a Components
    const [reportarInformacion, setReportarInformacion] = useState(); 
    const [materiales] = useState([
        { label: 'Organización Electoral', value: 1 },
        { label: 'Capacitación Electoral', value: 2 },
        { label: 'Prerrogativas', value: 3 },
        { label: 'Registro Federal Electoral', value: 4 },
    ]); 
    const dateFormat = 'DD/MM/YYYY';
    const [materialesSeleccionados, setMaterialesSeleccionados] = useState([]); 
    const [incidentesSeleccionados, setIncidentesSeleccionados] = useState([]);
    const [eliminarIncidentes, setEliminarIncidentes] = useState([]);
    const [actividadesSeleccionadas, setActividadesSeleccionadas] = useState([]); 
    const [etapasCheckbox, setEtapasCheckbox] = useState([]);
    const [characterMat, setCharacterMat] = useState(500);
    const [fechaLimite, setFechaLimite] = useState();

    useEffect(() => {
        return () => {
          isMounted.current = false;
        };
    }, []);

    useEffect(() => {
        if (isMounted.current && informe !== null && informe !== undefined) {
            setEtapas(informe.etapas);
            if (informe.fechaLimite !== null) {
                setFechaLimite(informe.fechaLimite);
            }
            const checkedEtapas = [];
            for (let i = 0; i < informe.etapas.length; i++) {
                if (informe.etapas[i].checked) {
                    checkedEtapas.push(informe.etapas[i].value);
                }
            }
            setEtapasCheckbox(checkedEtapas);
            form.setFieldsValue({
                etapasSeleccionar: checkedEtapas,
            });
            if (informe.informacionElectoral !== null && informe.informacionElectoral.length > 0) {
                setReportarInformacion(true);
                setMaterialesSeleccionados(informe.informacionElectoral);
            } else {
                setReportarInformacion(false);
                setMaterialesSeleccionados([]);
            }
            if (informe.fechaElaboracion !== null) {
                props.form.setFieldsValue({ fechaElaboracion: moment(informe.fechaElaboracion, dateFormat) });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [informe]);

    useEffect(() => {
        if (isMounted.current && materialesSeleccionados !== null && materialesSeleccionados !== undefined && informe !== undefined && informe !== null) {
            const detMat = form.getFieldValue("detalleMateriales");
            if (detMat !== undefined && detMat !== null) {
                form.setFieldsValue({
                    materialesInformacion: materialesSeleccionados,
                });
            } else {
                form.setFieldsValue({
                    materialesInformacion: materialesSeleccionados,
                    detalleMateriales: informe.detalleMateriales ? informe.detalleMateriales:"",
                });
                if (informe.detalleMateriales !== null) {
                    const charLength = 500 - informe.detalleMateriales.length;
                    setCharacterMat(charLength);
                }
            }            
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [materialesSeleccionados]);
    
    useEffect(() => {
        if (isMounted.current && etapas !== null && etapas !== undefined && etapas.length > 0) {
            const etapasGuardadas = [];
            for (let i = 0; i < etapas.length; i++) {
                etapas[i].checked = false;
                for (let j = 0; j < etapasCheckbox.length; j++) {
                    if (etapas[i].value === etapasCheckbox[j]) {
                        etapas[i].checked = true;
                        etapasGuardadas.push(etapas[i]);
                    }
                }
            }
            setEtapasSeleccionadas(etapasGuardadas);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [etapasCheckbox]);

    useEffect(() => {
        if (isMounted.current && etapasSeleccionadas !== null && etapasSeleccionadas !== undefined && etapasSeleccionadas.length > 0) {
            if (informe.medioCaptura === 0) {
                getInformeEtapa(informe.idInforme, etapasSeleccionadas[0].value, etapasSeleccionadas[0].idInformeEtapa).then((res) => {
                    if (res.data !== null) {
                        setEtapaSeleccionada(res.data);
                    } 
                }).catch((error) => {
                    console.log("Ocurrio un error", error);
                }); 
            } else {
                getInformeEtapaJunta(informe.idInforme, etapasSeleccionadas[0].value, etapasSeleccionadas[0].idInformeEtapa).then((res) => {
                    if (res.data !== null) {
                        setEtapaSeleccionada(res.data);
                    } 
                }).catch((error) => {
                    console.log("Ocurrio un error", error);
                }); 
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [etapasSeleccionadas]);

    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [etapaSeleccionada]);

    function onChangeBox(e) {
        const checkedValues = [];
        for (let i = 0; i < e.length; i++) {
            checkedValues.push(e[i]);
        }
        setEtapasCheckbox(checkedValues);
    }

    function onChangeTabs(e) {
        for (let i = 0; i < etapasSeleccionadas.length; i++) {
            if (Number(e) === etapasSeleccionadas[i].value) {
                if (informe.medioCaptura === 0) {
                    getInformeEtapa(informe.idInforme, etapasSeleccionadas[i].value, etapasSeleccionadas[i].idInformeEtapa).then((res) => {
                        if (res.data !== null) {
                            setEtapaSeleccionada(res.data);
                        } 
                    }).catch((error) => {
                        console.log("Ocurrio un error", error);
                    });
                } else {
                    getInformeEtapaJunta(informe.idInforme, etapasSeleccionadas[i].value, etapasSeleccionadas[i].idInformeEtapa).then((res) => {
                        if (res.data !== null) {
                            setEtapaSeleccionada(res.data);
                        } 
                    }).catch((error) => {
                        console.log("Ocurrio un error", error);
                    });
                } 
            }
        }
    }

    const onChangeInformacion = (e) => {
        setReportarInformacion(e.target.value);
        if (!e.target.value) {
            informe.informacionElectoral = null;
            setMaterialesSeleccionados([]);
        }
    };

    function onChangeGroup(checkedValues) {
        var material = [];
        for (let i = 0; i < checkedValues.length; i++) {
            material.push(checkedValues[i]);
        }
        setMaterialesSeleccionados(material);
    }

    const handleChangeMaeriales = () => (event) => {
        const charCount = event.target.value.length;
        const charLength = 500 - charCount;
        setCharacterMat(charLength);
    };

    function disabledDateRegister(current) {
        if (informe !== undefined && informe !== null && informe.idInforme !== null) {
            return current > moment(fechaLimite, dateFormat);
        } else {
            return current > moment(fechaLimite, dateFormat).add(1, 'd');
        }        
    }

    const finish = () => {
        form.validateFields((err, values) => { 
            if (!err) {
                controlInforme(informe, setInforme, etapaSeleccionada, incidentesSeleccionados, setLoader, values, materialesSeleccionados, eliminarIncidentes, history);
            } else {
                message.info("Los datos con ( * ) son requeridos.");
            }
        });
    };

    function MaterialInformacion() {
        return (
        <div>
            <Row style={{ marginTop: 20 }}>
                <Col span={24} style={{ paddingRight: "20px" }}>
                    <Form.Item>
                        {getFieldDecorator("materialesInformacion", { rules: required })(
                        <Checkbox.Group options={materiales} type="checkbox" defaultValue={materialesSeleccionados} onChange={onChangeGroup} disabled={disabled} />
                        )}
                    </Form.Item>
                </Col>
                <Col span={24} style={{ paddingRight: "20px" }}>
                    <Ast /> Descripción
                    <Form.Item>
                        {getFieldDecorator("detalleMateriales", { rules: required, getValueFromEvent: (event) => {
                            return txtTexto.test(event.target.value)
                            ? event.target.value
                            : (event.target.value).slice(0,-1);
                        }, })(
                            <Input.TextArea
                                maxLength={500}
                                style={{ width: "100%" }}
                                onChange={handleChangeMaeriales()}
                                disabled={disabled}
                            />
                            )}
                    </Form.Item>
                    <span style={{color: "#CDCDCD"}}>Restan {characterMat} caracteres</span>
                </Col>
            </Row>
        </div>
        );
    };

    function ButtonSave() {
        return (
        <div>
            {isMounted.current && etapaSeleccionada !== undefined && (
                <Row>
                    <Col span={24} style={{ paddingTop: "20px", paddingRight: "20px" }}>
                        <Form.Item style={{ textAlign: "Center" }}>
                        <Button
                            type="primary"
                            disabled={button}
                            onClick={() => finish()}
                        >
                            Guardar Etapa
                        </Button>
                        </Form.Item>
                    </Col>
                </Row>
            )}
        </div>
        );
    };

    function ButtonChange() {
        return (
        <div>
            {isMounted.current && etapaSeleccionada !== undefined && (
                <Row>
                    <Col span={24} style={{ paddingTop: "20px", paddingRight: "20px" }}>
                        <Form.Item style={{ textAlign: "Center" }}>
                        <Button
                            type="primary"
                            disabled={button}
                            onClick={() => {setDisabled(false);}}
                        >
                            Modificar Informe
                        </Button>
                        </Form.Item>
                    </Col>
                </Row>
            )}
        </div>
        );
    };

    return (
        <div>
            <div className="card-menu-home" style={{ marginTop: 50 }}>
            <p><Ast /> Etapas del proceso Electoral donde participaste:</p>
            <Row>
                <Col span={24}>
                <Form.Item>
                    {getFieldDecorator("etapasSeleccionar", { rules: required })(
                        <Checkbox.Group options={etapas} defaultValue={etapasCheckbox} onChange={onChangeBox} disabled={disabled} />
                    )}
                </Form.Item>
                </Col>
            </Row>
            {isMounted.current && etapaSeleccionada !== undefined && etapaSeleccionada !== null && (
                <Row style={{ marginTop: 30 }}>
                    <Col span={24} style={{ marginBottom: 10 }}>
                        <Alert message={<p style={{ marginBottom: "0px" }}>
                            Selecciona la etapa del proceso electoral que deseas capturar.</p>}
                          type="info" showIcon
                        />
                    </Col>
                    <Col span={24}>
                        <Tabs onChange={onChangeTabs} defaultActiveKey={etapaSeleccionada.value.toString()}>
                            {etapasSeleccionadas.map(i => 
                                (
                                    <TabPane tab={i.label} key={i.value.toString()}>
                                        <Components
                                            incidentesSeleccionados={incidentesSeleccionados}
                                            setIncidentesSeleccionados={setIncidentesSeleccionados}
                                            etapaSeleccionada={etapaSeleccionada}
                                            getFieldDecorator={getFieldDecorator}
                                            actividadesSeleccionadas={actividadesSeleccionadas}
                                            setActividadesSeleccionadas={setActividadesSeleccionadas}
                                            eliminarIncidentes={eliminarIncidentes}
                                            setEliminarIncidentes={setEliminarIncidentes}
                                            informe={informe}
                                            form={form}
                                            disabled={disabled}
                                        />
                                    </TabPane>
                                )
                            )}
                        </Tabs>
                    </Col>
                </Row>
            )}
            </div>
            <div className="card-menu-home" style={{ marginTop: 50 }}>
            <Row>
                <Col span={24}>
                    <h2>Solicitudes de información</h2>
                </Col>
                <Col span={24}>
                    <Ast /> ¿Solicitaste algún tipo de información Electoral?
                </Col>
                <Col span={24}>
                    <Radio.Group value={reportarInformacion} onChange={onChangeInformacion} disabled={disabled}>
                        <Radio value={true}>Sí</Radio>
                        <Radio value={false}>No</Radio>
                    </Radio.Group>
                </Col>
            </Row>
            {reportarInformacion ? MaterialInformacion() : null}
            <Row style={{ marginTop: 20 }}>
                <Col span={6}>
                    <Ast /> Fecha de elaboración del informe
                    <Form.Item>
                        {getFieldDecorator("fechaElaboracion", { rules: required })(
                            <DatePicker format={dateFormat} disabledDate={disabledDateRegister} disabled={disabled}/>
                        )}
                    </Form.Item>
                </Col>
            </Row>
            </div>
            {!disabled ? ButtonSave() : seModifica ? ButtonChange() : null}
        </div>
    );
}