import React from "react";
import { Modal, Alert, message, Icon } from "antd";
import { saveAccreditation, updateAccreditation, deleteAccreditation } from "../../api/accreditation";
import { generateDocuments } from "../../api/accreditation";
import { MSN_GUARDAR } from "../../utils/constanst";

export const Ast = () =>  {
    return <span style={{ color: "#f5222d" }}>*</span>;
}

//Función que verifica antes de realizar la acreditación
export function sendAccreditation(values, docPresidente, docSecretario, setLoader, setReload, setSaved) {
  const { confirm } = Modal;
  confirm({
    content:
    <div>
      {values.sinCorreo > 0 && <Alert message={<p style={{ marginBottom: "0px" }}>
                          {values.sinCorreo} solicitudes no cuentan con correo electrónico registrado y deben ser notificadas por otro medio. </p>}
                          type="info" showIcon />}
      
      <p><br/>Se acreditará(n) <strong>{values.totalAcreditadas}</strong> solicitud(es) como observadoras y observadores electorales.
      Se notificará a la ciudadanía por medio de la cuenta de correo electrónico que registraron. <br/><br/>
      ¿Deseas continuar?</p>
    </div>
    ,
    icon: null,
    okText: "Acreditar",
    cancelText: "Cancelar",
    width: "500px",
    cancelButtonProps: { className: "ant-btn-default" },
    onOk() {
      setLoader({ loading: true, message: MSN_GUARDAR }); //Se activa el loader
      saveDocuments(values, docPresidente, docSecretario, setLoader, setReload, setSaved);
    },
    onCancel() {}
  });
}

function saveDocuments(values, docPresidente, docSecretario, setLoader, setReload, setSaved) {
  const formData = new FormData();
  if (docPresidente !== undefined) {
    formData.append("docPresidente", docPresidente);
  } else {
    formData.append("docPresidente", null);
  }
  if (docSecretario !== undefined) {
    formData.append("docSecretario", docSecretario);
  } else {
    formData.append("docSecretario", null);
  }
  formData.append(
    "solicitud",
    new Blob([JSON.stringify(values)], { type: "application/json" })
  );
  save(formData, setLoader, setReload, setSaved)
}

//Guarda la solicitud
function save(solicitud, setLoader, setReload, setSaved) {
  saveAccreditation(solicitud).then(res => {
    if (res.data.code === 200) {
      setLoader({ loading: false, message: "" }); //Se desactiva el loader
      setReload(true);
      setSaved(true);
    } else {
      setLoader({ loading: false, message: "" }); //Se desactiva el loader
      Modal.info({
        title: "Atención",
        content: <p>{res.data.causa}</p>
      });
    }
  });
}

//Actualiza la solicitud acreditada
export function update(solicitud, setReload, setVisibleModal) {
    updateAccreditation(solicitud).then(res => {
      if (res.data.code === 200) {
        Modal.success({
          title: "Solicitud actualizada",
          content: <p>{res.data.message}</p>,
          okText: "Aceptar",
          onOk() {
            setVisibleModal(false);
            setReload(true);
          }
        });
      } else {
        Modal.error({
          title: "Error",
          content: <p>{res.data.causa}</p>
        });
      }
    });
  }

//Elimina la solicitud acreditada
export function deleteObs(idObservador, correo, folio, usuario, setReload) {
  deleteAccreditation(idObservador, correo, folio, usuario).then(res => {
    if (res.data.code === 200) {
      Modal.success({
        title: "Solicitud desacreditada",
        content: <p>{res.data.message}</p>,
        okText: "Aceptar",
        onOk() {
          setReload(true);
        }
      });
    } else {
      Modal.error({
        title: "Error",
        content: <p>{res.data.causa}</p>
      });
    }
  });
}

//Función que valida el tipo de imagen y tamaño.
export function beforeUpload(file) {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("Solo archivos en formato JPG/PNG!");
    return false;
  }
  const isLt2M = file.size / 1024 / 1024 < 5;
  if (!isLt2M || isLt2M === 0) {
    message.error("¡Peso máximo de 5MB!");
    return false;
  }
  const nameFile = validateName(file.name);
  if (!nameFile) {
    message.error(
      "El nombre de archivo " +
        file.name +
        " es inválido. El nombre del documento no debe contener caracteres especiales."
    );
    return false;
  }
  return isJpgOrPng && isLt2M && nameFile;
}

//Función que muestra la imagen en el input
export function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}

function validateName(e) {
  var patron = /^[0-9a-zA-Z_-\s]+.[jpg/JPG/jpeg/JPEG/png/PNG]+$/;
  if (e.match(patron)) {
    return true;
  } else {
    return false;
  }
}

export const calulateNewHeight = (
  originalWidth,
  originalHeight,
  targetWidth
) => {
  const ratio = originalHeight / originalWidth;
  return ratio * targetWidth;
};

//Boton que muestra la animación de cargando
export const uploadButton = loading => (
  <>
    <Icon type={loading ? "loading" : "plus"} />
    <div className="ant-upload-text">Cargar</div>
  </>
);

//Función de verificacion para generar documentación.
export function sendDocuments(values, setLoader, setReload, setSaved, tipoDocumento, setReloadTable) {
  const { confirm } = Modal;
  confirm({
    content:
    <div>      
      <p>Se generará la documentación de <strong>{values.totalAcreditadas}</strong> solicitudes como observadoras y observadores electorales.</p>
      <p>Las solicitudes acreditadas que ya fueron generados sus documentos de acreditación, al seleccionar el botón (Contancia y/o Gafete) se actualizarán.</p>
      <p>¿Deseas continuar?</p>
    </div>
    ,
    icon: null,
    okText: "Generar",
    cancelText: "Cancelar",
    width: "500px",
    cancelButtonProps: { className: "ant-btn-default" },
    onOk() {
      setLoader({ loading: true, message: MSN_GUARDAR });
      generate(values, setLoader, setReload, setSaved, setReloadTable);    
    },
    onCancel() {}
  });
}

function generate(values, setLoader, setReload, setSaved,setReloadTable) {
  generateDocuments(values).then(res => {
    if (res.data) {
      var urlCreator = window.URL || window.webkitURL || window.mozURL || window.msURL;
      const url = urlCreator.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `DocumentosAcreditacion.zip`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      setLoader({ loading: false, message: "" }); //Se desactiva el loader
      setReload(true);
      setSaved(true);
      setReloadTable(true);
    }
  })
  .catch((error) => {
    setLoader({ loading: false, message: "" }); //Se desactiva el loader
    Modal.info({
      title: "Atención",
      content: <p>Ocurrió un error al generar los documentos de acreditación.</p>
    });
  });
}

