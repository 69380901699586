import { basePath } from "./config";
import axios from "axios";
import { getAccessTokenApi } from "./auth";

const TIPO_SEDE = 1;
const TIPO_INTEGRANTE = [1];
const TIPO_STATUS = [1];

export function getDownload(idObservador) {
  const url = `${basePath}/registroJuntas/descarga`;
  const jwt = getAccessTokenApi();
  return axios.post(
    url,
    { idObservador: idObservador },
    {
      headers: {
        Authorization: jwt, 
        "Content-Type": "application/json",
      },
      responseType: "blob",
    }
  );
}

export function updateDocs(idObservador) {
  const url = `${basePath}/registroJuntas/updateDocs`;
  const jwt = getAccessTokenApi();
  return axios.post(
    url,
    { idObservador: idObservador },
    {
      headers: {
        Authorization: jwt, 
        "Content-Type": "application/json",
      }
    }
  );
}

export function getRequests(proceso, listaDetalles) {  
  const url = `${basePath}/registroJuntas/tabla`;
  const jwt = getAccessTokenApi();
  return axios.post(url, { procesoSeleccionado: proceso, procesoNoSeleccionado: listaDetalles },{
    headers: { Authorization: jwt },
  });
}

export function getOrganizaciones(proceso, listaDetalles) {
    const url = `${basePath}/registroJuntas/organizaciones`;
    const jwt = getAccessTokenApi();
    return axios.post(url, { procesoSeleccionado: proceso, procesoNoSeleccionado: listaDetalles },{
      headers: { Authorization: jwt },
    });
}

export function getJuntas(idEstado, idJunta) {
  const url = `${basePath}/sesiones/juntaEjecutiva`;
  const jwt = getAccessTokenApi();
  return axios.post(url, { idEstado: idEstado, idDistritoFederal: idJunta, tipoSede: TIPO_SEDE },{
    headers: { Authorization: jwt }
  });
}

export function getFuncionarios(idEstado, idJunta ) {
  const url = `${basePath}/sesiones/funcionarios`;
  const jwt = getAccessTokenApi();
  return axios.post(url, { tipoIntegrante: TIPO_INTEGRANTE, idEstado: idEstado, idDistritoFederal: idJunta,
                           estatus: TIPO_STATUS },{
    headers: { "Authorization": jwt }
  }); 
}

export function getPaises() {
  const url = `${basePath}/registroJuntas/paises`;
  const jwt = getAccessTokenApi();
  return axios.post( url, {}, { headers: { Authorization: jwt }});
}

export function getEntidades() {
    const url = `${basePath}/registroJuntas/entidades`;
    const jwt = getAccessTokenApi();
    return axios.post(url, {}, {headers: { Authorization: jwt }});
}

export function getEntidadesObservar() {
  const url = `${basePath}/registroJuntas/entidadesObservar`;
  const jwt = getAccessTokenApi();
  return axios.post(url, {}, {headers: { Authorization: jwt }});
}

export function getEscolaridades() {
    const url = `${basePath}/solicitud/escolaridad`;
    const jwt = getAccessTokenApi();
    return axios.post(url, {}, {
      headers: { Authorization: jwt, Origen: "portal", Parametrizacion: "0,0" },
    });
}

export function getMunicipios(id) {
    const url = `${basePath}/registroJuntas/municipios`;
    const jwt = getAccessTokenApi();
    return axios.post(url, {idEstado: id}, {headers: { Authorization: jwt }});
}

export function validaClaveMail(values, modulo) {
    const url = `${basePath}/registroJuntas/validaClaveMail`;
    const jwt = getAccessTokenApi();
    return axios.post(url, { values: values, modulo: modulo }, {headers: { Authorization: jwt }});
}

export function saveRegister(register) {
   const url = `${basePath}/registroJuntas/guardar`;
   const jwt = getAccessTokenApi();
   return axios.post(url, register, {
     headers: {
       "Content-Type": "multipart/form-data",
       "Authorization": jwt,
       "Parametrizacion": "16"
     },
   });
}
  
export function updateRegister(register) {
   const url = `${basePath}/registroJuntas/actualizar`;
   const jwt = getAccessTokenApi();
   return axios.post(url, register, {
    headers: {
      "Content-Type": "multipart/form-data",
      "Authorization": jwt
    },
  });
}

export function getByIdRegister(id) {
    const url = `${basePath}/registroJuntas/id`;
    const jwt = getAccessTokenApi();
    return axios.post(url, { idObservador: id },{
      headers: { Authorization: jwt }
    });
}

export function deleteRegister(idObservador) {
  const url = `${basePath}/registroJuntas/eliminar`;
  const jwt = getAccessTokenApi();
  return axios.post(url, { idObservador: idObservador },{
    headers: { "Authorization": jwt }
  });
}

export function validaCursoIniciado(idObservador, modalidad) {
  const url = `${basePath}/registroJuntas/validaCursoIniciado`;
  const jwt = getAccessTokenApi();
  return axios.post(url, { idObservador: idObservador, modalidad: modalidad }, {headers: { Authorization: jwt }});
}

export function getFechaLimite() {
  const url = `${basePath}/registroJuntas/fechaCierre`;
  const jwt = getAccessTokenApi();
  return axios.post(
    url,
    { },
    {
      headers: { "Authorization": jwt },
    }
  );
}