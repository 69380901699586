import React from "react";
import { Row, Col, Form, Radio, Input } from "antd";
import Viewer from "../../../Generals/Viewer";
import "./Steps.scss";
import {
  AlertInfo,
  seccionElectoral,
  AlertForeig,
} from "../../../../utils/Validations/functions";
import { radioButton, observaciones } from "../../../../utils/rules";

export default function Credential(props) {
  const { getFieldDecorator, form, changeRadio, datos } = props;
  const { user, disabled, alert } = props;
  //Destructiring de las propiedades de user
  const { claveElector, seccion } = user;
  //Se carga la imagen
  const images = [{ src: user.docs[0].imagen }];
  const SIN_SECCION =
    "Sin sección electoral, la solicitud es de un mexicano residente en el extranjero.";

  return (
    <Row gutter={24}>
      <Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24}>
        <h4>Credencial de elector (frente)</h4>
        <div>
          <div id="credFrente" className="credFrente" />
          <Viewer container={"credFrente"} image={images} />
        </div>
      </Col>
      <h4>Datos capturados</h4>
      <Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24}>
        <div className="data">
          <div className="alert">
            {user.tipoObservador === 1 && (
              <>
                <AlertForeig text={SIN_SECCION} />
                <br />
              </>
            )}
            <AlertInfo disable={alert.clave} />
          </div>
          <Col span={24}>
            <Form.Item label="Clave de elector">{claveElector}</Form.Item>
          </Col>
          <Col span={24}>
          {user.tipoObservador === 0 && (
            <Form.Item label="Sección electoral">
              {seccionElectoral(seccion)}
          </Form.Item>
          )}
          </Col>

          <Col span={24}>
            <Form.Item label="¿Los datos coinciden con la credencial de elector?">
              {getFieldDecorator("clave", {
                rules: radioButton,
                initialValue: datos.clave,
              })(
                <Radio.Group
                  onChange={changeRadio("clave")}
                  disabled={disabled.clave}
                >
                  <Radio value={true}>Sí</Radio>
                  <Radio value={false}>No</Radio>
                </Radio.Group>
              )}
            </Form.Item>
          </Col>
          <Col span={24}>
            {form.getFieldValue("clave") === false && (
              <Form.Item label="Observaciones">
                {getFieldDecorator("claveObs", {
                  rules: observaciones,
                  initialValue: datos.claveObs,
                })(
                  <Input.TextArea
                    onChange={changeRadio("claveObs")}
                    disabled={disabled.claveObs}
                    maxLength={150}
                  />
                )}
              </Form.Item>
            )}
          </Col>
        </div>
      </Col>
    </Row>
  );
}
