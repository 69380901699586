import { basePath } from "./config";
import axios from "axios";
import { getAccessTokenApi } from "./auth";

export function getListaJobs() {
    const url = `${basePath}/admin/listaJobs`;
    const jwt = getAccessTokenApi();
    return axios.post(
      url,
      { },
      { headers: { Authorization: jwt } }
    );
}

export function createJobCruce(nameJob, exprCron) {
    const url = `${basePath}/admin/creaJobCruce`;
    const jwt = getAccessTokenApi();
    return axios.post(
        url,
        { nameJob: nameJob, exprCron: exprCron },
        { headers: { Authorization: jwt } }
    );
}

export function pauseJobCruce(job) {
    const url = `${basePath}/admin/pausaJobCruce`;
    const jwt = getAccessTokenApi();
    return axios.post(
        url, job,
        { headers: { Authorization: jwt } }
    );
}

export function restartJobCruce(job) {
    const url = `${basePath}/admin/reiniciarJobCruce`;
    const jwt = getAccessTokenApi();
    return axios.post(
        url, job,
        { headers: { Authorization: jwt } }
    );
}

export function deleteJobCruce(job) {
    const url = `${basePath}/admin/eliminarJobCruce`;
    const jwt = getAccessTokenApi();
    return axios.post(
        url, job,
        { headers: { Authorization: jwt } }
    );
}

export function createJobCruceFinalInf(nameJob, exprCron) {
    const url = `${basePath}/admin/creaJobCruceFinal`;
    const jwt = getAccessTokenApi();
    return axios.post(
        url,
        { nameJob: nameJob, exprCron: exprCron },
        { headers: { Authorization: jwt } }
    );
}

export function getResumen() {
    const url = `${basePath}/admin/obtenResumen`;
    const jwt = getAccessTokenApi();
    return axios.post(
      url,
      { },
      { headers: { Authorization: jwt } }
    );
}
