import React, {useEffect} from "react";
import { Link } from "react-router-dom";
import { Icon, Row, Col } from "antd";
//Imagenes
import { ReactComponent as Obs0 } from "../../../assets/img/Portal/queEsObs_00.svg";
import { ReactComponent as Obs1 } from "../../../assets/img/Portal/queEsObs_01.svg";
import { ReactComponent as Obs2 } from "../../../assets/img/Portal/queEsObs_02.svg";
import { ReactComponent as Obs3 } from "../../../assets/img/Portal/queEsObs_03.svg";
import { ReactComponent as Obs4 } from "../../../assets/img/Portal/queEsObs_04.svg";
import { ReactComponent as Obs5 } from "../../../assets/img/Portal/queEsObs_05.svg";

const Observador = () => {
  function toTop() {
    window.scrollTo(0, 0);
}

  useEffect(() => {
    toTop();
  }, []);
  
  return (
    <div className="interioresPortal">
      <Link to={"/"} className="back">
        <Icon type="play-circle" theme="filled" rotate="180" /> Regresar
      </Link>
      <h1>Conoce más sobre las y los Observadores Electorales</h1>
      <Row style={{ marginBottom: "80px" }}>
        <Col xxl={9} xl={12} lg={14} md={12} sm={24} xs={24}>
          <h2>¿Quién es una Observadora/or Electoral?</h2>
          <p>
            Cualquier ciudadana o ciudadano mexicano en pleno goce de sus
            derechos civiles y políticos; interesados en conocer el desarrollo y
            actividades que se realizan antes y durante las elecciones.
          </p>
          <p>
            Son facultados por la ley para observar los actos de preparación y
            desarrollo del Proceso Electoral, así como los actos de la jornada
            electoral.
          </p>
        </Col>
        <Col xxl={10} xl={10} lg={10} md={12} sm={24} xs={24} className="imgCont">
          <Obs0 />
        </Col>
      </Row>
      <Row style={{ marginBottom: "80px" }}>
        <Col xxl={9} xl={12} lg={14} md={12} sm={24} xs={24}>
          <h2>¿Qué es lo que hace una Observadora/or Electoral?</h2>
          <p>
            Observa la instalación de casillas, el desarrollo de la votación, el
            escrutinio y cómputo de la votación en la casilla, la recepción de
            escritos de incidencias y protesta, la lectura en voz alta de los
            resultados en los consejos distritales, la fijación de resultados de
            la votación en la casilla y clausura de la misma.
          </p>
        </Col>
        <Col xxl={10} xl={10} lg={10} md={12} sm={24} xs={24} className="imgCont">
          <Obs1 />
        </Col>
      </Row>
      <Row style={{ marginBottom: "80px" }}>
        <Col xxl={9} xl={12} lg={14} md={12} sm={24} xs={24}>
          <h2> ¿Cómo convertirse en Observador/a Electoral?</h2>
          <ul>
            <li>
              Registra tu solicitud en este portal o acude a alguna Junta
              Ejecutiva.
            </li>
            <li>
              Cumplir con los requisitos mencionados en la convocatoria
              (consulta los detalles en la sección de requisitos).
            </li>
            <li>Tomar la capacitación de manera virtual o presencial.</li>
          </ul>
          <p>
            Los Consejos Locales y Distritales del INE revisarán las solicitudes
            y acreditarán las que cumplan con todos los requisitos.
          </p>
        </Col>
        <Col xxl={10} xl={10} lg={10} md={12} sm={24} xs={24} className="imgCont">
          <Obs2 />
        </Col>
      </Row>
      <Row style={{ marginBottom: "80px" }}>
        <Col xxl={9} xl={12} lg={14} md={12} sm={24} xs={24}>
          <h2> ¿Cuándo puedo solicitar ser Observadora/or Electoral?</h2>
          <p>
            Desde la publicación de la convocatoria y hasta el 07 de mayo de
            2021.
          </p>
        </Col>
        <Col xxl={10} xl={10} lg={10} md={12} sm={24} xs={24} className="imgCont">
          <Obs3 />
        </Col>
      </Row>
      <Row style={{ marginBottom: "80px" }}>
        <Col xxl={9} xl={12} lg={14} md={12} sm={24} xs={24}>
          <h2>¿Dónde puedo ser Observador/a Electoral?</h2>
          <p>
            Puedes llevar a cabo la observación electoral en cualquier entidad
            donde se celebre un proceso electoral, sin importar el lugar de tu
            residencia.
          </p>
        </Col>
        <Col xxl={10} xl={10} lg={10} md={12} sm={24} xs={24} className="imgCont">
          <Obs4 />
        </Col>
      </Row>
      <Row style={{ marginBottom: "80px" }}>
        <Col xxl={9} xl={12} lg={14} md={12} sm={24} xs={24}>
          <h2>¿Por qué ser Observador/a Electoral?</h2>
          <p>
            Tu participación nos ayuda a dar validez a las elecciones,
            fortaleciendo la democracia de nuestro país. Además, nos permite
            identificar áreas de oportunidad para mejorar. Por ello son muy
            importantes los informes de observación electoral que se entregan
            después de las elecciones.
          </p>
        </Col>
        <Col xxl={10} xl={10} lg={10} md={12} sm={24} xs={24} className="imgCont">
        <Obs5 />
        </Col>
      </Row>
    </div>
  );
};

export default Observador;
