import React from "react";
import { Modal, message } from "antd";
import { save, deleted, update, saveOrg, updateOrg } from "../../api/organitation";
import { MSN_GUARDAR } from "../constanst";
//Modal de confirmación para guardar la organización
export function ModalConfirm(
  org,
  file,
  history,
  setBtnGuardar,
  setLoader,
  bandera
) {
  const { confirm } = Modal;
  confirm({
    title: null,
    keyboard: false,
    content: contentSave(org, bandera),
    icon: null,
    okText: "Continuar",
    cancelText: "Corregir datos",
    cancelButtonProps: { className: "ant-btn-default" },
    onOk() {
      if (bandera && file.file !== undefined) {
      //Con documento
      sendOrganitation(org, file, history, setBtnGuardar, setLoader);
      } else {
        //sin documento
        sendOrganizacion(org, history, setBtnGuardar, setLoader);
      }
    },
    onCancel() {
      setBtnGuardar(false);
    },
  });
}
//Modal de confirmación para eliminar la organización
export function ModalDelete(org, setReload) {
  const { confirm } = Modal;
  confirm({
    title: null,
    keyboard: false,
    content: contentDelete(org),
    icon: null,
    okText: "Continuar",
    cancelText: "Cancelar",
    cancelButtonProps: { className: "ant-btn-default" },
    onOk() {
      deleted(org.idOrganizacion)
        .then((res) => {
          if (res.data.code === 200) {
            Modal.success({
              title: "Estado organización",
              content: <p>{res.data.message}</p>,
              okText: "Aceptar",
              onOk() {
                setReload(true);
              },
            });
          } else {
            Modal.warning({
              title: "Atención",
              content: <p>{res.data.causa}</p>,
            });
          }
        })
        .catch((err) => {
          console.log("Error al eliminar");
        });
    },
  });
}
//Función que obtiene los datos
function sendOrganitation(org, file, history, setBtnGuardar, setLoader) {
  const formData = new FormData();
  formData.append("documento", file.file[0]);
  formData.append(
    "organizacion",
    new Blob([JSON.stringify(org)], { type: "application/json" })
  );
  org.idOrganizacion !== undefined
    ? updateOrganitation(formData, history, setBtnGuardar, setLoader)
    : saveOrganitation(formData, history, setBtnGuardar, setLoader);
}

//Función que obtiene los datos de organización sin pdf
function sendOrganizacion(org, history, setBtnGuardar, setLoader) {
  const formData = new FormData();
  formData.append(
    "organizacion",
    new Blob([JSON.stringify(org)], { type: "application/json" })
  );
  org.idOrganizacion !== undefined
    ? actualizarOrganitation(formData, history, setBtnGuardar, setLoader)
    : guardarOrganizacion(formData, history, setBtnGuardar, setLoader);
}

//Función que guarda la organización
function saveOrganitation(org, history, setBtnGuardar, setLoader) {
  setLoader({ loading: true, message: MSN_GUARDAR }); //Se activa el loader
  save(org)
    .then((res) => {
      if (res.data.code === 200) {
        setLoader({ loading: false, message: "" }); //Se desactiva el loader
        Modal.success({
          title: "Estado organización",
          content: <p>{res.data.message}</p>,
          okText: "Aceptar",
          onOk() {
            history.push("/admin/organizacion"); //Redirecciona a la pantalla principal
          },
        });
      } else {
        setLoader({ loading: false, message: "" }); //Se desactiva el loader
        Modal.warning({
          title: "Atención",
          content: <p>{res.data.causa}</p>,
          onOk() {
            //history.push("/admin/organizacion"); //Redirecciona a la pantalla principal
            setBtnGuardar(false);
          },
        });
      }
    })
    .catch((error) => {
      console.log(error);
      setLoader({ loading: false, message: "" }); //Se desactiva el loader
      setBtnGuardar(false);
      errorRequest();
    });
}

//Función que guarda la organización sin pdf
function guardarOrganizacion(org, history, setBtnGuardar, setLoader) {
  setLoader({ loading: true, message: MSN_GUARDAR }); //Se activa el loader
  saveOrg(org)
    .then((res) => {
      if (res.data.code === 200) {
        setLoader({ loading: false, message: "" }); //Se desactiva el loader
        Modal.success({
          title: "Estado organización",
          content: <p>{res.data.message}</p>,
          okText: "Aceptar",
          onOk() {
            history.push("/admin/organizacion"); //Redirecciona a la pantalla principal
          },
        });
      } else {
        setLoader({ loading: false, message: "" }); //Se desactiva el loader
        Modal.warning({
          title: "Atención",
          content: <p>{res.data.causa}</p>,
          onOk() {
            //history.push("/admin/organizacion"); //Redirecciona a la pantalla principal
            setBtnGuardar(false);
          },
        });
      }
    })
    .catch((error) => {
      console.log(error);
      setLoader({ loading: false, message: "" }); //Se desactiva el loader
      setBtnGuardar(false);
      errorRequest();
    });
}

//Función que actualiza la organización
function updateOrganitation(org, history, setBtnGuardar, setLoader) {
  setLoader({ loading: true, message: MSN_GUARDAR }); //Se activa el loader
  update(org)
    .then((res) => {
      if (res.data.code === 200) {
        setLoader({ loading: false, message: "" }); //Se desactiva el loader
        Modal.success({
          title: "Estado organización",
          content: <p>{res.data.message}</p>,
          okText: "Aceptar",
          onOk() {
            history.push("/admin/organizacion"); //Redirecciona a la pantalla principal
          },
        });
      } else {
        setLoader({ loading: false, message: "" }); //Se desactiva el loader
        Modal.warning({
          title: "Atención",
          content: <p>{res.data.causa}</p>,
          okText: "Aceptar",
          onOk() {
            //history.push("/admin/organizacion"); //Redirecciona a la pantalla principal
            setBtnGuardar(false);
          },
        });
      }
    })
    .catch((error) => {
      console.log(error);
      setLoader({ loading: false, message: "" }); //Se desactiva el loader
      setBtnGuardar(false);
      errorRequest();
    });
}

//Función que actualiza la organización sin pdf
function actualizarOrganitation(org, history, setBtnGuardar, setLoader) {
  setLoader({ loading: true, message: MSN_GUARDAR }); //Se activa el loader
  updateOrg(org)
    .then((res) => {
      if (res.data.code === 200) {
        setLoader({ loading: false, message: "" }); //Se desactiva el loader
        Modal.success({
          title: "Estado organización",
          content: <p>{res.data.message}</p>,
          okText: "Aceptar",
          onOk() {
            history.push("/admin/organizacion"); //Redirecciona a la pantalla principal
          },
        });
      } else {
        setLoader({ loading: false, message: "" }); //Se desactiva el loader
        Modal.warning({
          title: "Atención",
          content: <p>{res.data.causa}</p>,
          okText: "Aceptar",
          onOk() {
            //history.push("/admin/organizacion"); //Redirecciona a la pantalla principal
            setBtnGuardar(false);
          },
        });
      }
    })
    .catch((error) => {
      console.log(error);
      setLoader({ loading: false, message: "" }); //Se desactiva el loader
      setBtnGuardar(false);
      errorRequest();
    });
}

//Validación al subir el archivo
export function beforeUpload(file) {
  const isPdf = file.type === "application/pdf";
  if (!isPdf) {
    message.error("Solo archivos en formato pdf");
    return false;
  }
  const isLt2M = file.size / 1024 / 1024 < 6;
  if (!isLt2M || isLt2M === 0) {
    message.error("¡Peso máximo de 6MB!");
    return false;
  }
  const nameFile = validateName(file.name);
  if (!nameFile) {
    message.error(
      "El nombre de archivo " +
        file.name +
        " es inválido. El nombre del documento no debe contener caracteres especiales."
    );
    return false;
  }
  return isPdf && isLt2M && nameFile;
}
//Valida el nombre del archivo
function validateName(e) {
  var patron = /^[0-9a-zA-Z_-\s]+.[pdf/PDF]+$/;
  if (e.match(patron)) {
    return true;
  } else {
    return false;
  }
}
//Contenido del modal guardar
const contentSave = (org, bandera) => {
  return (
    <div>
      <p>
        {bandera ? (
          <>Se dará de alta la organización:</>
        ) : (
          <>Se modificará la organización:</>
        )}
        <br />
        <strong>{org.organizacion}</strong>
      </p>
      <p>¿Deseas continuar?</p>
    </div>
  );
};
//Contenido del modal eliminar
const contentDelete = (org) => {
  return (
    <div>
      <p>
        Estás a punto de eliminar la organización:
        <br />
        <strong>{org.organizacion}</strong>
      </p>
      <p>Esta acción no se puede deshacer ¿Deseas continuar?</p>
    </div>
  );
};

export function valueUpperCase(e) {
  return e.target.value.toUpperCase();
}

export function validaRepre(apellido, apellido2) {
  if (apellido || apellido2) {
    return true;
  }
  return false;
}

export function validaRespon(apellido, apellido2) {
  if (apellido || apellido2) {
    return true;
  }
  return false;
}

export const Ast = () => {
  return <span style={{ color: "#f5222d" }}>*</span>;
};

export const errorRequest = () => {
  Modal.warning({
    title: "Atención",
    content: "Error al consumir el servicio web.",
    okText: "Aceptar",
    onOk() {
      //window.location.reload();
    },
  });
};

export function getUrl(url, tipoUrl) {
  let name = undefined;
  switch (tipoUrl) {
    case 1:
      //console.log("Desa");
      name = url.split("\\").pop();
      break;

    case 2:
      //console.log("Capa, Seguridad, prod");
      name = url.split("/").pop();
      break;

    default:
      name = "Documento.pdf";
      break;
  }
  return name;
}
