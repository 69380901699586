import React, { useEffect, useRef, useState } from "react";
import { Alert, Row } from "antd";
import { withRouter } from "react-router-dom";
import { getCursosParaAsistencia } from "../../../api/attendance";
import CoursesForAttendance from "../../../components/Admin/Attendance/CoursesForAttendance";
import useAuth from "../../../hooks/useAuth";
import { rol_captura_asistencia, rol_captura_jl_asistencia, rol_cau_asistencia } from "../../../utils/Attendance/rolesAsist";

function Attendance(props) {
    const { proceso } = props;
    const {
        user: { rolUsuario, user }, //Se obtiene el rol del usuario
    } = useAuth();
    const [ cursosParaAsistencia, setCursosParaAsistencia] = useState({
        info: [],
        loading: false,
    });
    const [rolModulo, setRolModulo] = useState({}); //Hook para almacenar los permisos
    const isMounted = useRef(true); //Referencia que permite verificar si el componente esta visible
    const [reload, setReload] = useState(false); //Para volver a cargar la tabla cuando elminan la asistencia
    let idProceso, idDetalle, idEstado, idDistrito;
    //Se verica que este seleccionado un proceso electoral
    if(proceso.proceso) {
        idProceso = proceso.proceso.idProcesoElectoral;
        idDetalle = proceso.proceso.idDetalleProceso;
        idEstado = proceso.estado ? proceso.estado.idEstado : 0;
        idDistrito = proceso.distrito ? proceso.distrito.idDistrito : 0;
    }
    //useEffect que valida que el componente este seleccionado de lo contrario cancela cualquier subcripción
    useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);
    //useEffect que valida el rol del usuario
    useEffect(() => {
        //Valida los permisos que tienen el usuario
        let captura = false;
        let cau = false;
        let rolJL = false;
        //Devuelve un true si cuenta con los permisos
        captura = rol_captura_asistencia.includes(rolUsuario);
        cau = rol_cau_asistencia.includes(rolUsuario);
        if(idDistrito !== 0) {
            rolJL = rol_captura_jl_asistencia.includes(rolUsuario);
        }
        isMounted.current && setRolModulo(() => ({ captura, cau, rolJL })); //Se almacenan en el hook
    }, [rolUsuario, idDistrito]);

    useEffect(() => {
        if(idProceso) {
            setCursosParaAsistencia({ loading:true }); //Mostrar el loading en la tabla
            getCursosParaAsistencia(idProceso, idDetalle, idEstado, idDistrito).then((res) => {
                if(isMounted.current) { //Se verifica que continue en el componente
                    const dataSource = [];
                    res.data.map((req, index) => {
                        return dataSource.push({
                            key: index,
                            indice: index + 1,
                            ...req,
                        });
                    });
                    setCursosParaAsistencia({ info:dataSource, loading:false });
                }
            }).catch((error) => {
                console.log(error);
            });
            setReload(false);
        }
    }, [idProceso, idDetalle, idEstado, idDistrito, reload]);

    return(
        <div>
            <Row>
                <h1>Registrar asistencia</h1>
            </Row>
            <Row>
                <Alert type="info" showIcon
                    message="Solo se visualizan los cursos concluidos o impartidos y no se podrá registrar asistencia para los cursos que no tengan participantes inscritos."
                />
            </Row>
            <Row>
                <CoursesForAttendance data={cursosParaAsistencia.info}
                    loading={cursosParaAsistencia.loading}
                    rolModulo={rolModulo}
                    proceso={proceso}
                    user={user}
                    setReload={setReload}
                />
            </Row>
        </div>
    );
}

export default withRouter(Attendance);
