import React, { useState, useEffect } from "react";
import { Input, Table, Form,  message, Tooltip } from "antd";
import { withRouter } from "react-router-dom";
//import useAuth from "../../../hooks/useAuth";
import moment from 'moment';
import {downloadConstancia, downloadGafete } from "../../../api/accreditation";
import "./Documents.scss";

function AcredTable(props) {
  //const { user: { user, rolUsuario } } = useAuth();
  const { data, loading } = props;
  const dateFormat = 'DD/MM/YYYY';
  const [value, setValue] = useState(""); //Hook para el filtrado de nombre
  const [valClave, setValClave] = useState(""); //Hook para el filtrado de clave
  const [valFolio, setValFolio] = useState(""); //Hook para el filtrado de folio
  const [valOrigen, setValOrigen] = useState(""); //Hook para el filtrado de origen
  const [valTipo, setValTipo] = useState(""); //Hook para el filtrado de tipo
  const [valModalidad, setValModalidad] = useState(""); //Hook para el filtrado de modalidad
  const [valSesion, setValSesion] = useState(""); //Hook para el filtrado de número de sesión
  const [valAcuerdo, setValAcuerdo] = useState(""); //Hook para el filtrado de número de acuerdo
  const [dataSource, setDataSource] = useState([]); //hook para almacenar el array
  const [solicitud] = useState();
  const [, setDatos] = useState({});

  useEffect(() => {
    setDataSource(data);
  }, [data]);

  useEffect(() => {
    if (solicitud !== undefined) {
      props.form.setFieldsValue({
        numSesion: solicitud.numeroSesion,
        numAcuerdo: solicitud.numeroAcuerdo,
        fechaSesion: moment(solicitud.fechaSesion, dateFormat),
      });
      setDatos({ nombre: solicitud.nombreSolicitante, clave: solicitud.claveElector, folio: solicitud.folio });
    }
  }, [solicitud, props.form]);

  //Función que permite descargar la constancia de acreditación
  const downloadC = (dataRow) => {
    downloadConstancia(dataRow.idObservador)
      .then((res) => {
        if (res.data) {
          var urlCreator = window.URL || window.webkitURL || window.mozURL || window.msURL;
          const url = urlCreator.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `constanciaAcreditacion.pdf`);
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
          
        }
      })
      .catch((error) => message.error("El archivo no se encuentra disponible"));
      // window.location.reload();
  };

  //Función que permite descargar la constancia de acreditación
  const downloadG = (dataRow) => {
    downloadGafete(dataRow.idObservador)
      .then((res) => {
        if (res.data) {
          var urlCreator = window.URL || window.webkitURL || window.mozURL || window.msURL;
          const url = urlCreator.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `gafeteAcreditacion.pdf`);
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        }
      })
      .catch((error) => message.error("El archivo no se encuentra disponible"));
  };

  //Función que filtra los registros por el nombre
  const FilterByNameInput = (
    <>
      Nombre del solicitante
      <Input
        placeholder="Nombre del solicitante"
        value={value}
        onChange={(e) => {
          const currValue = e.target.value;
          setValue(currValue);
          const filteredData = data.filter((entry) =>
            entry.nombreSolicitante
              .toString()
              .toLowerCase()
              .includes(currValue.toLowerCase())
          );
          setDataSource(filteredData);
        }}
      />
    </>
  );
  //Función que filtra los registros por clave de elector
  const FilterByClaveInput = (
    <>
      Clave de Elector
      <Input
        placeholder="Clave de Elector"
        valClave={valClave}
        onChange={(e) => {
          const currValue = e.target.value;
          setValClave(currValue);
          const filteredData = data.filter((entry) =>
            entry.claveElector
              .toString()
              .toLowerCase()
              .includes(currValue.toLowerCase())
          );
          setDataSource(filteredData);
        }}
      />
    </>
  );
  //Función que filtra los registros por folio
  const FilterByFolioInput = (
    <>
      Folio
      <Input
        placeholder="Folio"
        valFolio={valFolio}
        onChange={(e) => {
          const currValue = e.target.value;
          setValFolio(currValue);
          const filteredData = data.filter((entry) =>
            entry.folio
              .toString()
              .toLowerCase()
              .includes(currValue.toLowerCase())
          );
          setDataSource(filteredData);
        }}
      />
    </>
  );
  //Función que filtra los registros por origen de la solicitud
  const FilterByOrigenInput = (
    <>
      Origen de la solicitud
      <Input
        placeholder="Origen de la solicitud"
        valOrigen={valOrigen}
        onChange={(e) => {
          const currValue = e.target.value;
          setValOrigen(currValue);
          const filteredData = data.filter((entry) =>
            entry.origenSolicitud
              .toString()
              .toLowerCase()
              .includes(currValue.toLowerCase())
          );
          setDataSource(filteredData);
        }}
      />
    </>
  );
   //Función que filtra los registros por tipo
   const FilterByTipoInput = (
    <>
      Tipo de la solicitud
      <Input
        placeholder="Tipo de la solicitud"
        valTipo={valTipo}
        onChange={(e) => {
          const currValue = e.target.value;
          setValTipo(currValue);
          const filteredData = data.filter((entry) =>
            entry.tipoSolicitud
              .toString()
              .toLowerCase()
              .includes(currValue.toLowerCase())
          );
          setDataSource(filteredData);
        }}
      />
    </>
  );
  //Función que filtra los registros por modalidad del curso
  const FilterByModalidadInput = (
    <>
      Modalidad curso
      <Input
        placeholder="Modalidad curso"
        valModalidad={valModalidad}
        onChange={(e) => {
          const currValue = e.target.value;
          setValModalidad(currValue);
          const filteredData = data.filter((entry) =>
            entry.modalidad
              .toString()
              .toLowerCase()
              .includes(currValue.toLowerCase())
          );
          setDataSource(filteredData);
        }}
      />
    </>
  );
  //Función que filtra los registros por número de sesión
  const FilterBySesionInput = (
    <>
      Número de sesión
      <Input
        placeholder="Número de sesión"
        valSesion={valSesion}
        onChange={(e) => {
          const currValue = e.target.value;
          setValSesion(currValue);
          const filteredData = data.filter((entry) =>
            entry.numeroSesion
              .toString()
              .toLowerCase()
              .includes(currValue.toLowerCase())
          );
          setDataSource(filteredData);
        }}
      />
    </>
  );
  //Función que filtra los registros por número de acuerdo
  const FilterByAcuerdoInput = (
    <>
      Número de acuerdo
      <Input
        placeholder="Número de acuerdo"
        valAcuerdo={valAcuerdo}
        onChange={(e) => {
          const currValue = e.target.value;
          setValAcuerdo(currValue);
          const filteredData = data.filter((entry) =>
            entry.numeroAcuerdo
              .toString()
              .toLowerCase()
              .includes(currValue.toLowerCase())
          );
          setDataSource(filteredData);
        }}
      />
    </>
  );

  //Se definen las columnas de la tabla de documentos acreditados
  const columns = [
    {
      title: FilterByNameInput,
      dataIndex: "nombreSolicitante",
      key: "nombreSolicitante",
    },
    {
      title: FilterByClaveInput,
      dataIndex: "claveElector",
      key: "claveElector",
    },
    {
      title: FilterByFolioInput,
      dataIndex: "folio",
      key: "folio",
    },
    {
      title: FilterByOrigenInput,
      dataIndex: "origenSolicitud",
      key: "origenSolicitud",
    },
    {
      title: FilterByTipoInput,
      dataIndex: "tipoSolicitud",
      key: "tipoSolicitud",
    },
    {
      title: FilterByModalidadInput,
      dataIndex: "modalidad",
      key: "modalidad",
    },
    {
      title: "Fecha de aprobación de la acreditación",
      dataIndex: "fechaAcreditacion",
      key: "fechaAcreditacion",
    },
    {
      title: FilterBySesionInput,
      dataIndex: "numeroSesion",
      key: "numeroSesion",
    },
    {
      title: "Fecha de la sesión",
      dataIndex: "fechaSesion",
      key: "fechaSesion",
    },
    {
      title: FilterByAcuerdoInput,
      dataIndex: "numeroAcuerdo",
      key: "numeroAcuerdo",
    },
    
    {
      title: "SHA Constancia",
      dataIndex: "shaConstancia",
      key: "shaConstancia",
    },
    {
      title: "SHA Gafete",
      dataIndex: "shaGafete",
      key: "shaGafete",
    },
    {
      title: "Descargar Documentos",
      fixed: "right",
      align: "center",
      render: (record) => (
        <>
          {(
            <Tooltip title="Descargar Constancia">
              <button 
                style={{ marginLeft: "0px", width: "125px" }}
                onClick={(e) => downloadC(record)}
                disabled={record.shaConstancia === "Sin documento generado"}
              > Constancia
              </button>
            </Tooltip>
          )}{" "}
          {(
            <Tooltip title="Descargar Gafete">
              <button 
                style={{ marginLeft: "0px", width: "125px" }}
                onClick={(e) => downloadG(record)}
                disabled={record.shaGafete === "Sin documento generado"}
              > Gafete
              </button>
            </Tooltip>
          )}
        </>
      ),
    },
  ];

  const rowSelection = {
    onSelectAll: (selected, selectedRows) => {
        const datos = [];
        if(selectedRows.length > 0) { //Seleccionaron todos
            for (let i = 0; i < dataSource.length; i++) {
                dataSource[i].seleccionado = true;
                datos.push(dataSource[i]);
            };
        } else {
            for (let i = 0; i < dataSource.length; i++) {
                dataSource[i].seleccionado = false;
                datos.push(dataSource[i]);
            };
        }
        setDataSource(datos);
    },
    onSelect: (record) => {
        const datos = [];
        for (let i = 0; i < dataSource.length; i++) {
            if (dataSource[i].idObservador === record.idObservador) {
                let value = dataSource[i].seleccionado;
                dataSource[i].seleccionado = !value;
            }
            datos.push(dataSource[i]);
        }
        setDataSource(datos);
    },
    getCheckboxProps: record => ({
        checked: record.seleccionado === true,
    }),
};

  return (
    <Table
      columns={columns}
      dataSource={dataSource}
      rowSelection={rowSelection}
      loading={loading}
      locale={{ emptyText: "Sin registros" }}
      scroll={{ x: true }}
    />
  );
}
const TableRequests = Form.create()(AcredTable);
export default withRouter(TableRequests);
