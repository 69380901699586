import React, { useState, useEffect } from "react";
import { Table, Input } from "antd";
import { withRouter } from "react-router-dom";


function TableRequestsOrg(props) {
    const { data, loading } = props;
    const [dataSource, setDataSource] = useState([]);
    //Hook de busqueda
    const [valNombre, setValNombre] = useState(""); 
    
    useEffect(() => {
        setDataSource(data);
    }, [data]);

    // filtro input
    const FilterByName = (
        <>
          Nombre de la organización
          <Input
            placeholder="Nombre de la organización"
            valNombre={valNombre}
            onChange={(e) => {
              const currValue = e.target.value;
              setValNombre(currValue);
              const filteredData = data.filter((entry) =>
                entry.organizacion
                  .toString()
                  .toLowerCase()
                  .includes(currValue.toLowerCase())
              );
              setDataSource(filteredData);
            }}
          />
        </>
    );

    const columnas = [
        {
            title: FilterByName,
            dataIndex: "organizacion",
            key: "organizacion",
            fixed: 'left',
        },
        {
            title: "Solicitudes registradas",
            children: [
                {
                    title: "Origen de la solicitud",
                    children: [
                        {
                            title: "INE",
                            dataIndex: "ineRegistrados",
                            key: "ineRegistrados",
                            align:"center",
                            width: 60,
                        },
                        {
                            title: "OPL",
                            dataIndex: "oplRegistrados",
                            key: "oplRegistrados",
                            align:"center",
                            width: 60,
                        },
                        {
                            title: "Portal",
                            dataIndex: "portalRegistrados",
                            key: "portalRegistrados",
                            align:"center",
                            width: 60,
                        },
                        {
                            title: "Total",
                            dataIndex: "totalRegistrados",
                            key: "totalRegistrados",
                            align:"center",
                            width: 60,
                        },
                    ]
                },
                {
                    title: "Sexo",
                    children: [
                        {
                            title: "Mujeres",
                            dataIndex: "mujerRegistrados",
                            key: "mujerRegistrados",
                            align:"center",
                            width: 75,
                        },
                        {
                            title: "Hombres",
                            dataIndex: "hombreRegistrados",
                            key: "hombreRegistrados",
                            align:"center",
                            width: 90,
                        },
                    ]
                },
            ]
        },
        {
            title: "Solicitudes validadas",
            children: [
                {
                    title: "Origen de la solicitud",
                    children: [
                        {
                            title: "INE",
                            dataIndex: "ineValidados",
                            key: "ineValidados",
                            align:"center",
                            width: 60,
                        },
                        {
                            title: "OPL",
                            dataIndex: "oplValidados",
                            key: "oplValidados",
                            align:"center",
                            width: 60,
                        },
                        {
                            title: "Portal",
                            dataIndex: "portalValidados",
                            key: "portalValidados",
                            align:"center",
                            width: 60,
                        },
                        {
                            title: "Total",
                            dataIndex: "totalValidados",
                            key: "totalValidados",
                            align:"center",
                            width: 60,
                        },
                    ]
                },
                {
                    title: "Sexo",
                    children: [
                        {
                            title: "Mujeres",
                            dataIndex: "mujerValidados",
                            key: "mujerValidados",
                            align:"center",
                            width: 75,
                        },
                        {
                            title: "Hombres",
                            dataIndex: "hombreValidados",
                            key: "hombreValidados",
                            align:"center",
                            width: 90,
                        },
                    ]
                },
            ]
        },
        {
            title: "Solicitudes capacitadas",
            children: [
                {
                    title: "Origen de la solicitud",
                    children: [
                        {
                            title: "INE",
                            dataIndex: "ineCapacitados",
                            key: "ineCapacitados",
                            align:"center",
                            width: 60,
                        },
                        {
                            title: "OPL",
                            dataIndex: "oplCapacitados",
                            key: "oplCapacitados",
                            align:"center",
                            width: 60,
                        },
                        {
                            title: "Portal",
                            dataIndex: "portalCapacitados",
                            key: "portalCapacitados",
                            align:"center",
                            width: 60,
                        },
                        {
                            title: "Total",
                            dataIndex: "totalCapacitados",
                            key: "totalCapacitados",
                            align:"center",
                            width: 60,
                        },
                    ]
                },
                {
                    title: "Modalidad",
                    children: [
                        {
                            title: "En línea",
                            dataIndex: "lineaCapacitados",
                            key: "lineaCapacitados",
                            align:"center",
                            width: 75,
                        },
                        {
                            title: "Presencial",
                            dataIndex: "presencialCapacitados",
                            key: "presencialCapacitados",
                            align:"center",
                            width: 100,
                        },
                    ]
                },
                {
                    title: "Sexo",
                    children: [
                        {
                            title: "Mujeres",
                            dataIndex: "mujerCapacitados",
                            key: "mujerCapacitados",
                            align:"center",
                            width: 75,
                        },
                        {
                            title: "Hombres",
                            dataIndex: "hombreCapacitados",
                            key: "hombreCapacitados",
                            align:"center",
                            width: 90,
                        },
                    ]
                },
            ]
        },
        {
            title: "Solicitudes acreditadas",
            children: [
                {
                    title: "Origen de la solicitud",
                    children: [
                        {
                            title: "INE",
                            dataIndex: "ineAcreditados",
                            key: "ineAcreditados",
                            align:"center",
                            width: 60,
                        },
                        {
                            title: "OPL",
                            dataIndex: "oplAcreditados",
                            key: "oplAcreditados",
                            align:"center",
                            width: 60,
                        },
                        {
                            title: "Portal",
                            dataIndex: "portalAcreditados",
                            key: "portalAcreditados",
                            align:"center",
                            width: 60,
                        },
                        {
                            title: "Total",
                            dataIndex: "totalAcreditados",
                            key: "totalAcreditados",
                            align:"center",
                            width: 60,
                        },
                    ]
                },
                {
                    title: "Sexo",
                    children: [
                        {
                            title: "Mujeres",
                            dataIndex: "mujerAcreditados",
                            key: "mujerAcreditados",
                            align:"center",
                            width: 75,
                        },
                        {
                            title: "Hombres",
                            dataIndex: "hombreAcreditados",
                            key: "hombreAcreditados",
                            align:"center",
                            width: 90,
                        },
                    ]
                },
            ]
        },
        {
            title: "Informes capturados",
            children: [
                {
                    title: "Medio de Captura",
                    children: [
                        {
                            title: "INE",
                            dataIndex: "ineInformes",
                            key: "ineInformes",
                            align:"center",
                            width: 60,
                        },
                        {
                            title: "Portal",
                            dataIndex: "portalInformes",
                            key: "portalInformes",
                            align:"center",
                            width: 60,
                        },
                        {
                            title: "Total",
                            dataIndex: "totalInformes",
                            key: "totalInformes",
                            align:"center",
                            width: 60,
                        },
                    ]
                },
                {
                    title: "Sexo",
                    children: [
                        {
                            title: "Mujeres",
                            dataIndex: "mujerInformes",
                            key: "mujerInformes",
                            align:"center",
                            width: 75,
                        },
                        {
                            title: "Hombres",
                            dataIndex: "hombreInformes",
                            key: "hombreInformes",
                            align:"center",
                            width: 90,
                        },
                    ]
                },
            ]
        },
    ]

    return (
        <Table
            columns={columnas}
            dataSource={dataSource}
            loading={loading}
            locale={{ emptyText: "Sin registros" }}
            size="small"
            scroll={{ x: 3000 }}
        />      
    );
}

export default withRouter(TableRequestsOrg);