import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Button, Alert } from "antd";
import { withRouter, Link } from "react-router-dom";
import { ReactComponent as New } from "../../../assets/img/Courses/cursoIcon.svg";
import useAuth from "../../../hooks/useAuth";
import Table from "../../../components/Admin/Courses/Table";
import { getCourses, getCantidadParticipantes } from "../../../api/courses";
import { esJuntaLocal } from "../../../components/Generals/functions";
import { rol_captura, rol_consulta, rol_modifica, rol_borrar, rol_cau } from "../../../utils/Courses/roles";
import "./Courses.scss";

function Courses(props) {
    const isMounted = useRef(true); //Referencia que permite verificar si el componente esta visible
    const [courses, setCourses] = useState({
        list: [],
        loading: false,
    });
    const [reload, setReload] = useState(false);   
    const [rolModulo, setRolModulo] = useState({
        captura: false,
        modifica: false,
        consulta: false,
        elimina: false,
        cau: false,
    });
    const { user: { rolUsuario }, } = useAuth();
    const { proceso, setLoader } = props;
    const [total, setTotal] = useState(0);

    useEffect(() => {
      return () => {
        isMounted.current = false;
      };
    }, []);

    useEffect(() => {
      if (proceso.distrito !== undefined && proceso.distrito !== null) {
        //Valida los permisos que tienen el usuario
        setCourses({ loading: true });
        let captura = false;
        let modifica = false;
        let consulta = false;
        let elimina = false;
        let cau = false;
        captura = rol_captura.includes(rolUsuario);
        modifica = rol_modifica.includes(rolUsuario);
        consulta = rol_consulta.includes(rolUsuario);
        elimina = rol_borrar.includes(rolUsuario);
        cau = rol_cau.includes(rolUsuario);

        if (esJuntaLocal(rolUsuario, proceso.distrito.idDistrito)) {
          captura = false;
          modifica = false;
          elimina = false;
        }

        setRolModulo(() => ({ captura, modifica, consulta, elimina, cau })); //Se almacenan en el hook
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [proceso]);

    useEffect(() => {
      if (proceso.distrito !== undefined && proceso.distrito !== null) {
        getCantidadParticipantes(proceso.proceso.idProcesoElectoral, proceso.proceso.idDetalleProceso, 
          proceso.estado.idEstado, proceso.distrito.idDistrito, null, 0).then((res) => {
            setTotal(res.data);
        }).catch((error) => console.log(`Sesión expirada.`));
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [proceso, reload]);

    useEffect(() => {
      if (proceso.distrito !== undefined && proceso.distrito !== null) {
        const dataSource = [];
        getCourses(proceso).then((res) => {
          res.data.map((reg, index) => {
            return dataSource.push({
              key: index,
              indice: index + 1,
              ...reg,
            });
          });
          setCourses({ list: dataSource, loading: false }); //Se habilita el componente de loading
        });
      }
      setReload(false);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [proceso, reload]);
    
    return (
        <div>
          <Row>
            <h1>Registrar cursos</h1>
            <Col span={4}>
              <div>
                <Button className="convocation btn_secundario">
                  <Link to={"/admin/cursos/registro"}>
                    <New />
                    <br />
                    Nuevo Curso
                  </Link>
                </Button>
              </div>
            </Col>
            <Col span={20}/>
            <Col span={24} style={{ marginTop: "20px", paddingRight: "20px" }}>
                <Alert
                  message={<p style={{ marginBottom: "0px" }}>
                    Hay {total} solicitudes validadas sin curso asignado.</p>}
                  type="info" showIcon
                />
            </Col>
          </Row>
          <Row className="rowListCourses" style={{ marginTop: "20px" }}>
            <Col span={24}>
              <div>
                <h3>Lista de cursos</h3>
              </div>
            </Col>
          </Row>
          <Row className="rowTableCourses">
            <Col span={24}>
              <div>
              <Table
                data={courses.list}              
                rolModulo={rolModulo}
                loading={courses.loading}
                proceso={proceso}
                setReload={setReload}
                setLoader={setLoader}
              />
              </div>
            </Col>
          </Row>
        </div>
      );
}

export default withRouter(Courses);