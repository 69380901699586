import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useText} from "../../../../hooks/useLesson5";
import { array } from "../../../../utils/CourseOnline/lesson5";
import { capitalizeWord } from "../../../Generals/functions";
import { GuiaMaterial, MatApoyo } from "../Generals";
import {
  getOrgano,
} from "../../../../utils/CourseOnline/Util";

export default function Lesson5({  data, setLoading }) {
  const isMounted = useRef(true);
  //Se obtiene el Entidad seleccionada del store de redux
  const { idEntidad, entidad } = useSelector((state) => state.entidad);
  const {
    material,
    entidadMat: { idEntidad: id, entidad: entidadMat },
  } = useSelector((state) => state.material);

  const { parrafo, diputacion, numConsejo, res, consejo1, consejo2, consejo3, estado } = useText(
    material ? id : idEntidad
  );


  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const getList = () =>
    array.find((entity) => entity.idEntidad === getIdEntity());

  const getDistrtito = (id) => {
    console.log("Entidad");
    console.log(id);
    return (id === 9 || id === 13) ? "distritales" : "distritales y municipales";
  };

  const getTextEntity = () => {
    return material ? entidadMat : entidad;
  };

  const getIdEntity = () => {
    return material ? id : idEntidad;
  };

  const getTextBoleta = () =>{
    const entities = [1, 13, 23, 12, 5, 10, 11];
    if (entities.includes(getIdEntity())) {
      return "la boleta para la elección local es aprobada";
    } else {
      return "las boletas para las elecciones locales son aprobadas";
    }  
  }

  return (
    <div className="texto_parrafo">
      <p>
        Comencemos por definir qué es un proceso electoral. Es el conjunto de
        actos realizados por los órganos y autoridades electorales, los partidos
        políticos y la ciudadanía, cuyo objetivo es la renovación periódica de
        las y los integrantes de los poderes Ejecutivo y Legislativo tanto
        federal como de las entidades federativas, así como de quienes integran
        los Ayuntamientos en los estados y Alcaldías en la Ciudad de México.
      </p>
      <p>
        En esta ocasión elegiremos 500 diputaciones federales y para la elección
        local {estado}, se {diputacion}.
      </p>
      <p>
        Recuerda que el próximo 6 de junio de 2021, se celebrarán elecciones
        concurrentes, es decir, que la Jornada Electoral de la elección federal y
        de las elecciones locales se llevará a cabo el mismo día.
      </p>
      <p>
        El Proceso Electoral Federal 2020-2021 inició con la primera sesión del
        Consejo General del INE el 7 de septiembre de 2020 y concluirá con la
        declaración de validez de la elección de las diputaciones federales o en
        su caso las resoluciones que emita {getOrgano(getIdEntity())}
      </p>
      <p>
        ¿Sabes cuáles son las etapas del proceso electoral federal? Haz clic en
        el siguiente enlace para conocer las etapas que integran el{" "}
        <b>proceso electoral federal:</b>
      </p>
      {/* <MatApoyo array={[{ titulo: "Etapas del Proceso Electoral Federal" }]} /> */}
      {data.length && <MatApoyo array={[data[0]]} />}
      <p>
        De igual manera el <b>Proceso Electoral Local</b> 2020-2021 en{" "}
        {parrafo}
      </p>
      <p>
        En el siguiente material de apoyo te decimos cuáles son las etapas que
        integran el Proceso Electoral Local en {capitalizeWord(getTextEntity())}
        .
      </p>
      {/* <MatApoyo array={[{ titulo: "Etapas del Proceso Electoral Local" }]} /> */}
      {data.length && <MatApoyo array={[data[1]]} />}
      <p>
        Ahora ya podemos iniciar con la primera etapa del proceso electoral.{" "}
      </p>
      <h4>Primera etapa: La preparación de la elección </h4>
      <p>
        Comienza con la sesión pública que celebra el Consejo General del INE {consejo1}
        en la cual se declara el inicio formal del proceso
        electoral y concluye al iniciarse la Jornada Electoral.
      </p>
      <p> En esta etapa se llevan a cabo las siguientes actividades:</p>
      <p>
        a) <b>Integración e instalación de los órganos electorales; </b>para el
        proceso electoral federal se instalarán 32 consejos locales y 300
        consejos distritales y para el proceso electoral local se instalarán{" "}
        {numConsejo}.
      </p>
      <p>
        b){" "}
        <b>
          Integración y actualización del Padrón Electoral y la Lista Nominal de
          Electores;
        </b>{" "}
        el INE realizará dos campañas de actualización la permanente y la
        intensa para invitar a la ciudadanía a inscribirse o actualizar sus
        datos en el Padrón Electoral y la Lista Nominal con la finalidad de que
        obtengan su credencial para votar, a través de la reposición,
        inscripción o reimpresión de ésta.
      </p>
      <p>
        c) <b>Revisión de la Lista Nominal de Electores; </b>en esta etapa se
        valida la lista nominal que se utilizará el día de la Jornada Electoral,
        para lo cual las bases de datos son revisadas y verificadas por los
        partidos políticos y finalmente se entregan a los Consejos Locales del
        INE para su distribución a los Consejos Distritales y {consejo2}.
      </p>
      <p>
        d){" "}
        <b>
          Actividades de los partidos políticos y candidaturas independientes;{" "}
        </b>
        entre ellas encontramos los procesos internos de selección, las
        precampañas, el registro de candidaturas y las campañas electorales.
      </p>
      <p>
        e){" "}
        <b>Procedimiento de integración de las mesas directivas de casilla; </b>
        el INE selecciona al azar un mes del calendario y una letra del alfabeto
        para que a través de dos sorteos se seleccione a quienes serán las y los
        funcionarios de casilla, después del primer sorteo las y los
        Capacitadores-Asistentes Electorales visitan y capacitan a los
        ciudadanos y ciudadanas que resultaron sorteados y posteriormente, se
        realiza un segundo sorteo, a las y los ciudadanos que resultaron
        nuevamente sorteados; se les entrega su nombramiento y se les capacita
        para que conozcan las actividades a realizar en la mesa directiva de
        casilla.
      </p>
      <p>
        f) <b>Ubicación de las casillas; </b>existen cuatro tipos de casilla, la
        básica que se instala en cada sección electoral, la contigua que se
        instala cuando el número de electores es superior a 750, la
        extraordinaria, la cual se instala cuando las condiciones de vías de
        comunicación, geográficas o socioculturales de una sección son de
        difícil acceso y la casilla especial, que se instala para que puedan
        votar las y los electores que se encuentran en tránsito. Las casillas
        deben instalarse en lugares de fácil y libre acceso.
      </p>
      <p>
        g){" "}
        <b>
          Registro de representantes de partidos políticos y candidaturas
          independientes nacionales y locales;{" "}
        </b>
        el INE proporcionará el acceso al sistema informático para el registro
        de las y los representantes ante las mesas directivas de casilla y
        generales, cuyo objetivo es vigilar, proteger, otorgar certeza y
        legalidad a las actividades que realizan las y los funcionarios de la
        mesa directiva en la casilla, cuidando los intereses del partido o
        candidatura que representan.
      </p>
      <p>
        h) <b>Preparación de la documentación y material electoral;</b> la
        documentación electoral se refiere a todos los documentos para ser
        utilizados el día de la Jornada Electoral por ejemplo actas, boletas y
        listas nominales, mientras que el material electoral lo constituyen
        todos los elementos físicos, como son las urnas y canceles. La boleta
        para la elección federal es aprobada por el Consejo General del INE,
        mientras que{" "}
        {getTextBoleta()}{" "}
        por el {consejo3}.
      </p>
      <p className="bold">
        Hemos terminado lo relativo a la etapa de preparación del proceso
        electoral, si quieres profundizar sobre estas actividades, te invitamos
        a revisar el{" "}
        <GuiaMaterial setLoading={setLoading}>
          CAPÍTULO V DE TU GUÍA TEMÁTICA PARA CAPACITAR A LAS Y LOS OBSERVADORES
          ELECTORALES
        </GuiaMaterial>
        , que se encuentra disponible en los materiales de consulta de este
        curso.
      </p>
      <h4>Segunda etapa: La Jornada Electoral</h4>
      <p>
        <b>La Jornada Electoral</b> se celebra el día en que las y los
        ciudadanos votamos para elegir a nuestros representantes y gobernantes.
        En esta ocasión, el domingo 6 de junio se instalarán{" "}
        <b>casillas únicas</b>, para recibir, contar y registrar los votos la
        elección federal y {getIdEntity() === 13? " local":" de las locales"}.
      </p>
      <p>
        Las casillas únicas se integran con{" "}
        <b>9 funcionarios y/o funcionarias</b>, revisa el siguiente material de
        apoyo para conocer los cargos de quienes integran la casilla única.
      </p>
      {/* <MatApoyo array={[{ titulo: "Integración de la casilla única" }]} /> */}
      {data.length && <MatApoyo array={[data[2]]} />}
      Durante la Jornada Electoral las y los ciudadanos participarán como:
      <ul>
        <li>
          <b>Personas Electoras.</b>
        </li>
        <li>
          <b>Funcionarias y funcionarios de casilla.</b>
        </li>
        <li>
          <b>
            Representantes de partido político y de candidatura independiente.{" "}
          </b>
        </li>
        <li>
          <b>Observadores y observadoras electorales. </b>
        </li>
      </ul>
      Además, dentro de la casilla también podrás encontrar a:
      <ul>
        <li>
          Capacitadores y capacitadoras asistentes electorales del INE y del
          OPL.
        </li>
        <li>Supervisores o supervisoras electorales.</li>
        <li>Personal del INE y del OPL.</li>
        <li>Personal de la Oficialía Electoral.</li>
        <li>En caso necesario, fuerzas de seguridad pública.</li>
        <li>En algunos casos, Jueces y/o Notarios Públicos.</li>
        <li>
          Acompañantes de personas con discapacidad que funjan como funcionarias
          de casilla o que acudan como electoras.
        </li>
      </ul>
      <p>
        Afuera de la casilla podrán estar personas encuestadoras que preguntan a
        las y los electores que salen, por quién votaron. Estas personas siempre
        deben estar debidamente <b>identificadas</b> y tienen prohibido hacer
        actos de proselitismo o de presión sobre las y los ciudadanos.
      </p>
      <p>
        <b>
          Para conocer las actividades que se realizan durante la jornada
          electoral te invitamos a hacer clic en el siguiente video.{" "}
        </b>
      </p>
      {/* <MatApoyo
        array={[{ titulo: "Video ¿Qué pasa durante la Jornada Electoral? " }]}
      /> */}
      {data.length && <MatApoyo array={[data[3]]} />}
      <p>
        Ahora que ya sabes lo que pasa durante la Jornada Electoral, es momento
        de pasar a la siguiente etapa:
      </p>
      <p>
        <b>
          Tercera etapa: Resultados y declaración de validez de la elección{" "}
        </b>
      </p>
      <p>
        Inicia cuando se remite el paquete electoral al órgano que corresponda,
        y finaliza con los cómputos y declaraciones de validez que realicen los
        consejos del INE y las autoridades del OPL y, en su caso, con las
        resoluciones que, en última instancia, emita el Tribunal Electoral.{" "}
      </p>
      <p>
        Las actividades que las autoridades electorales realizan en esta etapa
        están orientadas a dar a conocer con certeza, transparencia y con la
        mayor rapidez posible los resultados obtenidos en las elecciones, por lo
        que se llevan a cabo las acciones siguientes:
      </p>
      <p>
        <b>A) Conteos rápidos:</b> Es el procedimiento estadístico diseñado con
        la finalidad de estimar con oportunidad las tendencias de los resultados
        finales de una elección, a partir de una muestra probabilística de los
        resultados de actas de escrutinio y cómputo de las casillas electorales,
        cuyo tamaño y composición se establecen previamente, en donde las
        conclusiones se presentan la noche de la Jornada Electoral.
      </p>
      <p>
        Los resultados del Conteo rápido bajo ningún motivo pueden considerarse
        como una constancia de triunfo.
      </p>
      <p>
        Revisa el siguiente material de apoyo para conocer el proceso que se
        lleva a cabo en el conteo rápido.
      </p>
      {/* <MatApoyo array={[{ titulo: "El conteo rápido" }]} /> */}
      {data.length && <MatApoyo array={[data[4]]} />}
      <p>
        <b> B) Programa de Resultados Electorales Preliminares (PREP).</b> Es el
        mecanismo para informar oportunamente los resultados preliminares y{" "}
        <b>no definitivos</b> de las elecciones; a través de la captura,
        digitalización y publicación de los datos asentados en las actas de
        escrutinio y cómputo de las casillas, de las cuales la primera copia se
        destina al PREP y se le conoce como Acta PREP.
      </p>
      <p>
        Para la elección federal, la publicación de los resultados electorales
        preliminares podrá iniciar a partir de las 20:00 horas tiempo del
        centro, quedando prohibido publicar por cualquier otro medio los
        resultados electorales preliminares antes de la hora señalada. El cierre
        de publicación será en un plazo máximo de 24 horas contadas a partir de
        la hora de inicio de la publicación.
      </p>
      <p>
        En el siguiente material de apoyo podrás identificar los pasos para
        llevar a cabo el PREP.
      </p>
      {/* <MatApoyo array={[{ titulo: "Fases del PREP" }]} /> */}
      {data.length && <MatApoyo array={[data[5]]} />}
      <p>
        <b>C) Información preliminar de resultados:</b> Es el procedimiento mediante el cual los consejos
        distritales del INE{res}, en sesión permanente, consultan los
        resultados contenidos en el Acta de Escrutinio y Cómputo conforme se
        vayan recibiendo los paquetes electorales.
      </p>
      <p>
        Las reglas y procedimientos de cada una de las actividades las puedes
        consultar en el Capítulo V de tu Guía temática.{" "}
      </p>
      <p>
        Posteriormente se realizan los{" "}
        <b>Cómputos Electorales de la elección</b>, que consisten en sumar los
        resultados anotados en cada Acta de Escrutinio y Cómputo de las casillas
        y, en su caso, en el recuento de votos de algunas casillas o de todas
        cuando existan los supuestos que la ley contempla para ello.
      </p>
      <p>
        En lo que se refiere a la <b>elección federal</b> los órganos facultados
        para llevar a cabo los cómputos son:
      </p>
      <ul>
        <li>
          Para la Elección de <b>Diputaciones de mayoría relativa</b>, el
          Consejo Distrital, en la sesión especial de cómputo del miércoles 9 de
          junio 2021 que inicia a las 8:00 horas.
        </li>
        <li>
          Para la Elección de <b>Diputaciones de representación proporcional</b>,
          el Consejo Local, en la sesión especial de cómputo del domingo 13 de
          junio 2021.
        </li>
      </ul>
      <p>
        En lo que se refiere a la <b>elección local</b> los órganos facultados
        para llevar a cabo los cómputos son:
      </p>
      <ul>
        {getList() !== undefined &&
          getList().datos.map((item, i) => <li key={i}>{item.titulo}</li>)}
      </ul>
      <p>
        Los pasos para realizar los cómputos <b>{getDistrtito(getIdEntity())}</b> son:
      </p>
      <ul>
        <dt>1. Actos previos.</dt>
        <dt>2. Reunión de trabajo.</dt>
        <dt>3. Sesión extraordinaria en el órgano facultado.</dt>
        <dt>4. Sesión especial de cómputo.</dt>
        <dt>5. Dictamen y declaración de validez de la elección.</dt>
      </ul>
      <p>
        Existen dos tipos de recuento, el total y el parcial. Revisa el
        siguiente material de apoyo en el cual se describe cada uno, así como
        las causales para que se presente.
      </p>
      {/* <MatApoyo
        array={[{ titulo: "Tipos de recuento de la votación y sus causales" }]}
      /> */}
      {data.length && <MatApoyo array={[data[6]]} />}
      <p>
        Tomando en cuenta los tipos de recuento de la votación, para llevar a
        cabo el cómputo se pueden presentar cuatro escenarios:
      </p>
      <ul>
        <dt>1. Cómputo con recuento total al inicio.</dt>
        <dt>2. Cómputo de 0 a 20 casillas en el Pleno del Consejo.</dt>
        <dt>
          3. Cómputo en paralelo, más de 20 paquetes (cotejo de actas en el
          Pleno del Consejo y recuento parcial en grupos de trabajo).
        </dt>
        <dt>4. Cómputo con recuento total al final.</dt>
      </ul>
      <p>
        En el siguiente material de apoyo se describe cada uno de los escenarios
        que puede presentarse, te invitamos a revisarlo.
      </p>
      {/* <MatApoyo
        array={[{ titulo: "Escenarios para llevar a cabo el cómputo " }]}
      /> */}
      {data.length && <MatApoyo array={[data[7]]} />}
      <p className="bold">
        Para profundizar sobre cada uno los pasos para realizar los cómputos de
        la elección federal y local, revisa el{" "}
        <GuiaMaterial setLoading={setLoading}>
          CAPÍTULO V DE TU GUÍA TEMÁTICA PARA CAPACITAR A LAS Y LOS OBSERVADORES
          ELECTORALES
        </GuiaMaterial>{" "}
        que está disponible en los materiales de consulta de este curso.{" "}
      </p>
      <p>
        También, no olvides dar clic al siguiente material de apoyo para
        reforzar tus conocimientos.
      </p>
      {/* <MatApoyo array={[{ titulo: "Presentación 5. El Proceso Electoral" }]} /> */}
      {data.length && <MatApoyo array={[data[8]]} />}
      <p>
        Hemos terminado la lección, te recordamos responder la actividad
        autoevaluable correspondiente.
      </p>
      <br />
    </div>
  );
}
