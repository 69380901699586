import React, { useEffect, useState } from "react";
import { Alert, Button, Icon, Row } from "antd";
import { Link, Redirect, withRouter } from "react-router-dom";
import moment from "moment";
import CursoIne from "../../../assets/img/Attendance/cursoINE_icon.svg";
import CursoOPL from "../../../assets/img/Attendance/cursoOPL_icon.svg";
import CasaIcon from "../../../assets/img/Attendance/casa_icon.svg";
import EdificioINE from "../../../assets/img/Attendance/edificioINE_icon.svg";
import { getAsistenciaModifica } from "../../../api/attendance";
import TableAttendanceUpdate from "../../../components/Admin/Attendance/TableAttendanceUpdate";
//import { Ast, confirmaActualizacionAsist } from "../../../utils/Attendance/functionsAttend";
import { confirmaActualizacionAsist } from "../../../utils/Attendance/functionsAttend";
import useAuth from "../../../hooks/useAuth";

function AttendanceUpdate(props) {
    const { history } = props;
    const {
        curso, proceso, rolModulo
    } = props.location;
    const { user: { user } } = useAuth();
    const [ asistenciaModifica, setAsistenciaModifica ] = useState({
        info: [],
        loading: false,
    });
    const [ botonDeshabilitado, setBotonDeshabilitado ] = useState(false);

    useEffect(() => {
        if(curso) {
            setAsistenciaModifica({ loading:true }); //Mostrar el loading en la tabla
            getAsistenciaModifica(proceso.proceso.idProcesoElectoral, proceso.proceso.idDetalleProceso,
                    curso.idCurso).then((res) => {
                        const dataSource = [];
                        res.data.map((req, index) => {
                            return dataSource.push({
                                key: index,
                                indice: index + 1,
                                ...req,
                            });
                        });
                setAsistenciaModifica({ info:dataSource, loading:false });
            }).catch((error) => {
                console.log(error);
            });
        }
    }, [curso, proceso]);

    //Redirecciona a la pantalla pricipal en caso de que no exista información en la variable curso
    if(!curso) {
        return <Redirect to={"/admin/asistencia"} />;
    }

    const CursoImpartidoPor = () => {
        switch(curso.institucion) {
            case 0: //Impartido por el INE
                return (
                    <div>
                        <h5>Curso&nbsp;&nbsp;&nbsp;&nbsp;<img src={CursoIne} alt="cursoIne"/>&nbsp;{curso.impartidoPor}</h5>
                    </div>
                );
            case 1: //Impartido por el OPL
                return (
                    <div>
                        <h5>Curso&nbsp;&nbsp;&nbsp;&nbsp;<img src={CursoOPL} alt="cursoOPL"/>&nbsp;{curso.impartidoPor}</h5>
                    </div>
                );
            case 2: //Impartido por la Organización
                return (
                    <div>
                        <h5>
                            Curso&nbsp;&nbsp;&nbsp;&nbsp;<Icon type="team" />&nbsp;{curso.impartidoPor}
                        </h5>
                    </div>
                );
            default:
                return (
                    <div>
                        <h5>Curso&nbsp;&nbsp;&nbsp;&nbsp;{curso.impartidoPor}</h5>
                    </div>
                );
        }
    }

    const CursoDomicilio = () => {
        if(curso.sede === 0) { //Domicilio de la junta
            return (
                <div>
                    en&nbsp;&nbsp;<img src={EdificioINE} alt="edificioINE"/> {curso.descSede}
                </div>
            );
        } else { //Otro domicilio
            return (
                <div>
                    en&nbsp;&nbsp;<img src={CasaIcon} alt="casaIcon"/> {curso.descSede}
                </div>
            );
        }
    }

    const modificaAsistencia = (event) => {
        event.preventDefault();
        for (let i = 0; i < asistenciaModifica.info.length; i++) {
            //Agregamos quien está realizando el cambio
            asistenciaModifica.info[i].usuario = user.sub;
        }
        setBotonDeshabilitado(true);
        confirmaActualizacionAsist(curso, asistenciaModifica.info, history, setBotonDeshabilitado,
            proceso.proceso.idProcesoElectoral);
    }

    return(
        <div>
            <Row>
                <Link to={"/admin/asistencia"} className="back">
                    <Icon type="play-circle" theme="filled" rotate="180" /> Volver a 
                    "Registrar de asistencia"
                </Link>
            </Row>
            <Row style={{ marginTop:"20px"}}>
                <h2>Modificar asistencia</h2>
            </Row>
            {/* <Row>
                <p>Los datos con ( <Ast /> ) son requeridos</p>
            </Row> */}
            <Row style={{ marginTop:"10px"}}>
                <CursoImpartidoPor />
            </Row>
            <Row>
                Impartido el&nbsp;&nbsp;{curso.fechaCurso}&nbsp;
                a las&nbsp;&nbsp;{moment(curso.horaInicial).format("HH:mm")}&nbsp;
                por&nbsp;&nbsp;{curso.nombreInstructor}
            </Row>
            <Row>
                <CursoDomicilio />
            </Row>
            <Row style={{ marginTop:"10px"}}>
                <Alert type="info" showIcon
                    message="No es posible modificar la asistencia a las solicitudes que se encuentran asignadas
                    a otro curso o han sido aprobadas."
                />
            </Row>
            <Row style={{ marginTop:"10px"}}>
                <h5>Asistentes al curso</h5>
            </Row>
            <Row>
                <TableAttendanceUpdate data={asistenciaModifica.info}
                    loading={asistenciaModifica.loading}
                />
            </Row>
            <Row style={{ textAlign:"center" }}>
                <Button type="default">
                    <Link to={"/admin/asistencia"}>Cancelar</Link>
                </Button>
                <Button type="primary" disabled={botonDeshabilitado || rolModulo.cau} onClick={modificaAsistencia}
                        style={{ width: 175 }}>
                    Modificar asistencia
                </Button>
            </Row>
        </div>
    );
}

export default withRouter(AttendanceUpdate);
