import React, { useState, useEffect } from "react";
import { Row, Col, Icon, Button } from "antd";
import { withRouter, Link } from "react-router-dom";
import moment from "moment";
import "./RequestsOrg.scss";
import Table from "../../../../components/Admin/Reports/TableRequestsOrg";
import { getReporteSolicitudesOrg } from "../../../../api/reports";
import { descargaExcel } from "../../../../components/Admin/Reports/RequestsOrgXls";

function RequestsOrg(props) {
  const { proceso } = props;
  const [calendario, setCalendario] = useState({ fecha: "", hora: "" }); 
  const [total, setTotal] = useState(0);
  const [requests, setRequest] = useState({ list: [], loading: false }); 

  useEffect(() => {
    if (proceso.proceso !== undefined) {
        setRequest({...requests, loading: true });
        const fechaHora = new Date();
        moment.locale("es");
        setCalendario({fecha:moment(fechaHora).format("DD/MMMM/YYYY"), hora:moment(fechaHora).format("HH:mm:ss") });
        setTotal(0);
        getReporteSolicitudesOrg(proceso.proceso.idProcesoElectoral, proceso.proceso.idDetalleProceso).then((res) => {
            const dataSource = [];
            if (res.data !== null) {
            res.data.map((reg, index) => {
                return dataSource.push({
                key: index,
                indice: index + 1,
                ...reg,
                });
            });
            setTotal(dataSource.length);
            setRequest({ list: dataSource, loading: false }); 
            } else {
                setRequest({ list: dataSource, loading: false }); 
            }
        })
        .catch((error) => {
            setRequest({ list: [], loading: false }); 
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [proceso]);

  const downloadExcel = () => {
    descargaExcel("Organizaciones", "Reporte de Solicitudes por Organización", requests.list, calendario);
  }

  return (
    <div>
      <Row className="backReportes">
        <Link to={"/admin/reportes"} className="back">
          <Icon type="play-circle" theme="filled" rotate="180" /> Volver a "Reportes" 
        </Link>
      </Row>
      <Row>
        <Col span={12}>
          <h1>Reporte de Solicitudes por Organización</h1>
        </Col>
        <Col span={12}>
          <div style={{ float:"right", fontWeight:"bold", fontSize:"12px" }}>
            Última actualización
          </div>
          <div style={{ fontSize:"12px", position:"absolute", marginTop:"20px", right:"0" }}>
            <Icon type="calendar" /> {calendario.fecha} <Icon type="clock-circle" /> {calendario.hora}
          </div>
        </Col>
      </Row>
      <Row  className="rowList">
        <Col span={12} className="colSpace">
            <div>Nacional</div> 
        </Col>
        <Col span={12} className="colSpaceTotal">
          <div><h1 style={{marginBottom: "0px"}}>{total}</h1></div>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <div><strong>Descarga el reporte:</strong> 
            <Button onClick={() => downloadExcel()} className="btn_descarga_link" type="link" >
              <Icon type="file-excel" />Excel
            </Button>
          </div>
        </Col>
        <Col span={12} className="colSpaceTotal">
          <div>Organizaciones</div>
        </Col>
      </Row>
      <Row>
        <Col span={24} className="colSpace">
          <div>
            <Table
              data={requests.list}
              loading={requests.loading}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default withRouter(RequestsOrg);