//Urls servicios externos 
export const urlJuntas = "https://pru-pef21-wssesiones-deoe.ine.mx/sesionesAdmin"; //url para consultar las Juntas Ejecutivas
//export const urlCuestionarios = "https://pruebas-sg.ine.mx/cuestionarios/api/consultaCalificacion"; //url para consultar la calificación de cuestionarios
export const urlCuestionarios = "https://cuestionarios-app.ine.mx/cuestionarios/api/consultaCalificacion"; //url para consultar la calificación de cuestionarios

//Urls para el ambiente de desarrollo
/*export const basePath = "https://localhost:8443/centralObservadores/ws";//url del servidor//
export const urlHome = "http://localhost:3000/" //url inicio;
export const URL_IMAGEN="https://localhost:8443/centralObservadores/ws/solicitud/imagen";
export const URL_ORGANIZACION = 1;*/

//Urls para el ambiente de pruebas
/*export const basePath = "https://prue22-observadoresapp.ine.mx/centralObservadores/ws" // url del servidor de pruebas
export const urlHome = "https://prue22-observadoresweb.ine.mx/" //url inicio pruebas;
export const URL_IMAGEN="https://prue22-observadoresapp.ine.mx/centralObservadores/ws/solicitud/imagen";
export const URL_ORGANIZACION = 2;*/

//Urls para el ambiente de capacitación
/*export const basePath = "https://capa-pef21-observadores-deoe.ine.mx/centralObservadores/ws" //url del servidor de capacitacion//
export const urlHome = "https://capa-pef21-weobservadores-deoe.ine.mx/" //url inicio capacitacion
export const URL_IMAGEN="https://capa-pef21-weobservadores-deoe.ine.mx/centralObservadores/ws/solicitud/imagen";
export const URL_ORGANIZACION = 2;*/

//Urls para el ambiente de seguridad.
/*export const basePath = "https://seg22-observadoresapp.ine.mx/centralObservadores/ws" //url del servidor de seguridad//
export const urlHome = "https://seg22-observadoresweb.ine.mx/" //url inicio de seguridad.
export const URL_IMAGEN="https://seg22-observadoresapp.ine.mx/centralObservadores/ws/solicitud/imagen";
export const URL_ORGANIZACION = 2;*/

//Urls para el ambiente de producción
export const basePath = "https://pef2021-observadorescentral-ws.ine.mx/centralObservadores/ws";//url del servidor//
export const urlHome = "https://pef2021-observadores-we.ine.mx/" //url inicio;
export const URL_IMAGEN="https://pef2021-observadorescentral-ws.ine.mx/centralObservadores/ws/solicitud/imagen";
export const URL_ORGANIZACION = 2;