import { types } from "../types/types";

const initialProps = {
  material: false,
  entidadMat: {},
  temarioMat: [],
};

const initialBlock = { loading: true };

/**
 * Reducer que controla el store de la entidad seleccionada para realizar el curso
 * @param {*} state
 * @param {*} action
 */
export const entityReducer = (state = {}, action) => {
  switch (action.type) {
    case types.entidad:
      return {
        idEntidad: action.payload.idEntidad,
        entidad: action.payload.entidad,
        iniciado: action.payload.iniciado,
      };
    case types.limpiarEntidadSel:
      return {};
    default:
      return state;
  }
};

export const entityReducerMat = (state = initialProps, action) => {
  switch (action.type) {
    case types.entidadMaterial:
      return {
        ...state,
        material: true,
        entidadMat: {
          idEntidad: action.payload.idEntidad,
          entidad: action.payload.entidad,
        },
      };
    case types.pantallaMaterial:
      return {
        ...state,
        material: true,
      };

    case types.temarioMaterial:
      return {
        ...state,
        temarioMat: [...action.payload],
      };
    case types.limEntidadMat:
      return initialProps;
    default:
      return state;
  }
};

export const blockReducer = (state = initialBlock, action) => {
  switch (action.type) {
    case types.bloquear:
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
};
