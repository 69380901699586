import React, {useEffect} from "react";
import { Row, Col } from "antd";
import ContentRequirements from "../../../components/User/Public/SectionRequirements/ContentRequirements";
import SignIn from "../../../components/User/Public/SignIn";
import Back from "../../../components/User/Public/Home/Back";

const Requirements = () => {
  function toTop() {
    window.scrollTo(0, 0);
}

  useEffect(() => {
    toTop();
  }, []);
  
  return (
    <>
      <SignIn />
      <Back />
      <Row>
        <Col>
          <ContentRequirements />
        </Col>
      </Row>
    </>
  );
};

export default Requirements;
