import { types } from "../types/types";
//Se almacenan las entidades a observar
const initialProps = {
  entidades: [],
};

//Se almacena el temario de acuerdo a la entidad
const initialTemario = {
  loading: true,
  temario: [],
};

//Se almacena el temario de acuerdo a la entidad
const initialLeccion = {
  ultimaLeccion: 0,
};


//Reducer para mostrar/ocultar el modal de la calificación
const initialModal = {
  modal: false,
};

/**
 * Reducer que controla el store de las entidades a observar
 * @param {*} state
 * @param {*} action
 */
export const entitiesReducer = (state = initialProps, action) => {
  switch (action.type) {
    case types.entidades:
      return {
        ...state,
        entidades: [...action.payload],
      };

    case types.limpiarEntidad:
      return initialProps;

    default:
      return state;
  }
};

/**
 * Reducer que controla el store del menú de acuerdo a la entidad seleccionada
 * @param {*} state
 * @param {*} action
 */
export const menuReducer = (state = initialTemario, action) => {
  switch (action.type) {
    case types.temario:
      return {
        ...state,
        loading: false,
        temario: [...action.payload],
      };

    case types.limpiarTemario:
      return initialTemario;

    default:
      return state;
  }
};

export const leccionReducer = (state = initialLeccion, action) => {
  switch (action.type) {
    case types.ultimaLeccion:
      return {
        ...state,
        ultimaLeccion: action.payload,
      };

    case types.limpiarultimaLeccion:
      return initialLeccion;

    default:
      return state;
  }
};


export const modalReducer = (state = initialModal, action) => {
  switch (action.type) {
    case types.modal:
      return {
        ...state,
        modal: action.payload,
      };

    case types.cerraModal:
      return initialModal;

    default:
      return state;
  }
};