import React, { useState, useEffect } from "react";
import { Icon, Modal, Table, Tooltip } from "antd";
import moment from "moment";
import { withRouter } from "react-router-dom";
import { eliminarJob, pausaJob, reiniciarJob } from "../../../utils/Console/functionsConsole";

function TableJobs(props) {
    const {
        data, //Informacion de jobs
        setReload,
        loading,
    } = props;
    const [dataSource, setDataSource] = useState([]); //hook para almacenar el array

    useEffect(() => {
        setDataSource(data); //Se inicializa el array de jobs en el hook
    }, [data]);
    
    //Se definen las columnas
    const columns = [
        {
            title: "Nombre",
            dataIndex: "jobName",
            key: "jobName",
        },
        {
            title: "Creado",
            dataIndex: "scheduleTime",
            key: "scheduleTime",
            render: (record) => moment(record).format("DD/MM/YYYY HH:mm:ss"),//Formato a la fecha 
        },
        {
            title: "Última ejecución",
            dataIndex: "lastFiredTime",
            key: "lastFiredTime",
            render: (record) => moment(record).format("DD/MM/YYYY HH:mm:ss"),//Formato a la fecha 
        },
        {
            title: "Siguiente ejecución",
            dataIndex: "nextFireTime",
            key: "nextFireTime",
            render: (record) => moment(record).format("DD/MM/YYYY HH:mm:ss"),//Formato a la fecha 
        },
        {
            title: "Estado",
            dataIndex: "jobStatus",
            key: "jobStatus",
        },
        {
            title: "Acciones",
            align: "center",
            render: (record) => (
                <>
                    <Tooltip title="Pausar job">
                        <button className="btn-circle btn_secundario" onClick={(e) => pausarCron(record)}>
                            <Icon type="pause" />
                        </button>
                    </Tooltip>
                    <Tooltip title="Reiniciar job">
                        <button className="btn-circle btn_secundario" onClick={(e) => reiniciarCron(record)}>
                            <Icon type="redo" />
                        </button>
                    </Tooltip>
                    <Tooltip title="Eliminar job">
                        <button className="btn-circle btn_secundario" onClick={(e) => eliminarCron(record)}>
                            <Icon type="delete" />
                        </button>
                    </Tooltip>
                </>
            ),
        },
    ];

    const pausarCron = (job) => {
        Modal.confirm({
            title: "Cruces de información",
            content: "¿Deseas pausar el proceso de cruce?",
            okText: "Pausar",
            cancelText: "Cancelar",
            onOk() {
                pausaJob(job, setReload);
            },
            oncancel() {}
        });
    };

    const reiniciarCron = (job) => {
        Modal.confirm({
            title: "Cruces de información",
            content: "¿Deseas reiniciar el proceso de cruce?",
            okText: "Reiniciar",
            cancelText: "Cancelar",
            onOk() {
                reiniciarJob(job, setReload);
            },
            oncancel() {}
        });
    };

    const eliminarCron = (job) => {
        Modal.confirm({
            title: "Cruces de información",
            content: "¿Deseas eliminar el proceso de cruce?",
            okText: "Eliminar",
            cancelText: "Cancelar",
            onOk() {
                eliminarJob(job, setReload);
            },
            oncancel() {}
        });
    };

    return (
        <Table
          columns={columns}
          dataSource={dataSource}
          loading={loading}
          locale={{ emptyText: "Sin registros" }}
        />
    );
}

export default withRouter(TableJobs);