import { basePath } from "./config";
import axios from "axios";
import { getTokenCiudadano, getAccessTokenApi } from "./auth";

export function getEstadoPortal(correo) {
    const url = `${basePath}/solicitud/estadoPortal`;
    const jwt = getTokenCiudadano();
    return axios.post(
      url,
      { correo: correo },
      {
        headers: { Authorization: jwt, Origen: "portal", Parametrizacion: "0,0" },
      }
    );
}

export function getEstadoInforme() {
  const url = `${basePath}/informe/continuarInforme`;
  const jwt = getTokenCiudadano();
  return axios.post(
    url,
    { },
    {
      headers: { Authorization: jwt, Origen: "portal", Parametrizacion: "0,0" },
    }
  );
}

export function getInformeCiudadano(correo) {
  const url = `${basePath}/informe/informeCiudadano`;
  const jwt = getTokenCiudadano();
  return axios.post(
    url,
    { correo: correo },
    {
      headers: { Authorization: jwt, Origen: "portal", Parametrizacion: "0,0" },
    }
  );
}

export function getInformeEtapa(informe, etapa, idInformeEtapa) {
  const url = `${basePath}/informe/etapaSeleccionada`;
  const jwt = getTokenCiudadano();
  return axios.post(
    url,
    { informe: informe, etapa: etapa, idInformeEtapa: idInformeEtapa },
    {
      headers: { Authorization: jwt, Origen: "portal", Parametrizacion: "0,0" },
    }
  );
}

export function getMunicipios(idEstado) {
  const url = `${basePath}/informe/municipios`;
  const jwt = getTokenCiudadano();
  return axios.post(url, {idEstado: idEstado}, {headers: { Authorization: jwt, Origen: "portal", Parametrizacion: "0,0" }});
}

export function getDistritoFederal(idEstado, idMunicipio) {
  const url = `${basePath}/informe/distritosFederales`;
  const jwt = getTokenCiudadano();
  return axios.post(url, {idEstado: idEstado, idMunicipio: idMunicipio }, {headers: { Authorization: jwt, Origen: "portal", Parametrizacion: "0,0" }});
}

export function getDistritoLocal(idEstado, idMunicipio, idFederal) {
  const url = `${basePath}/informe/distritosLocales`;
  const jwt = getTokenCiudadano();
  return axios.post(url, {idEstado: idEstado, idMunicipio: idMunicipio, idFederal: idFederal }, {headers: { Authorization: jwt, Origen: "portal", Parametrizacion: "0,0" }});
}

export function saveInforme(informe) {
  const url = `${basePath}/informe/guardar`;
  const jwt = getTokenCiudadano();
  return axios.post(url, informe , 
    {headers: { 
    Authorization: jwt,
    Origen: "portal",
    Parametrizacion: "0,0", }});
}

//--------------------------- CONSULTAS DEL LADO DE LA JUNTA ---------------------------//

export function getInformesReportados(idProceso, idDetalle, idEstado, idDistrito) {
  const url = `${basePath}/informe/junta/reportados`;
  const jwt = getAccessTokenApi();
  return axios.post(url, { idProceso: idProceso, idDetalle: idDetalle, idEstado: idEstado, idDistrito: idDistrito },{
    headers: { Authorization: jwt, Parametrizacion: idProceso },
  });
}

export function getInformesPorReportar(idProceso, idDetalle, idEstado, idDistrito) {
  const url = `${basePath}/informe/junta/porReportar`;
  const jwt = getAccessTokenApi();
  return axios.post(url, { idProceso: idProceso, idDetalle: idDetalle, idEstado: idEstado, idDistrito: idDistrito },{
    headers: { Authorization: jwt, Parametrizacion: idProceso },
  });
}

export function getTotalInformesPorReportar(idProceso, idDetalle, idEstado, idDistrito) {
  const url = `${basePath}/informe/junta/totalPorReportar`;
  const jwt = getAccessTokenApi();
  return axios.post(url, { idProceso: idProceso, idDetalle: idDetalle, idEstado: idEstado, idDistrito: idDistrito },{
    headers: { Authorization: jwt, Parametrizacion: idProceso },
  });
}

export function getInformeObservador(idObservador) {
  const url = `${basePath}/informe/informeObservador`;
  const jwt = getAccessTokenApi();
  return axios.post(
    url,
    { idObservador: idObservador },
    {
      headers: { Authorization: jwt, Origen: "portal", Parametrizacion: "0,0" },
    }
  );
}

export function getInformeEtapaJunta(informe, etapa, idInformeEtapa) {
  const url = `${basePath}/informe/etapaSeleccionada`;
  const jwt = getAccessTokenApi();
  return axios.post(
    url,
    { informe: informe, etapa: etapa, idInformeEtapa: idInformeEtapa },
    {
      headers: { Authorization: jwt, Parametrizacion: "0,0" },
    }
  );
}

export function getMunicipiosJunta(idEstado) {
  const url = `${basePath}/informe/municipios`;
  const jwt = getAccessTokenApi();
  return axios.post(url, {idEstado: idEstado}, {headers: { Authorization: jwt, Parametrizacion: "0,0" }});
}

export function getDistritoFederalJunta(idEstado, idMunicipio) {
  const url = `${basePath}/informe/distritosFederales`;
  const jwt = getAccessTokenApi();
  return axios.post(url, {idEstado: idEstado, idMunicipio: idMunicipio }, {headers: { Authorization: jwt, Parametrizacion: "0,0" }});
}

export function getDistritoLocalJunta(idEstado, idMunicipio, idFederal) {
  const url = `${basePath}/informe/distritosLocales`;
  const jwt = getAccessTokenApi();
  return axios.post(url, {idEstado: idEstado, idMunicipio: idMunicipio, idFederal: idFederal }, {headers: { Authorization: jwt, Parametrizacion: "0,0" }});
}

export function saveInformeJunta(informe) {
  const url = `${basePath}/informe/guardar`;
  const jwt = getAccessTokenApi();
  return axios.post(url, informe , 
    {headers: { 
    Authorization: jwt,
    Parametrizacion: "0,0", }});
}

export function getDownloadInforme(idObservador) {
  const url = `${basePath}/informe/descargar`;
  const jwt = getAccessTokenApi();
  return axios.post(
    url,
    { idObservador: idObservador },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: jwt,
        Parametrizacion: "0,0",
      },
      responseType: "blob",
    }
  );
}

export function deleteInformation(idObservador) {
  const url = `${basePath}/informe/eliminar`;
  const jwt = getAccessTokenApi();
  return axios.post(url, { idObservador: idObservador },{
    headers: { "Authorization": jwt }
  });
}