import React from "react";
import { Row } from "antd";

const ContentMaterials = () => {
  return (
    <div style={{ padding: "20px" }}>
      <Row>
        <h1>Materiales de Capacitación</h1>
        <hr></hr>
        <p>
          Ponemos a tu disposición los siguientes materiales de apoyo para tu formación como Observador/a Electoral
        </p>
      </Row>
      <Row>
      </Row>
    </div>
  );
};

export default ContentMaterials;
