import React from "react";
import { Row, Col, Form, Radio, Input } from "antd";
import Viewer from "../../../Generals/Viewer";
import "./Steps.scss";
import { AlertInfo } from "../../../../utils/Validations/functions";
import { radioButton, observaciones } from "../../../../utils/rules";

export default function Data(props) {
  const { getFieldDecorator, form, datos } = props;
  const { changeRadio, user, disabled, alert } = props;
  //Destructiring de las propiedades de user
  const { paterno, materno, nombre } = user;

  //Se carga la imagen
  const front = [{ src: user.docs[0].imagen }];

  return (
    <Row gutter={24}>
      <Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24}>
        <h4>Credencial de elector (frente)</h4>
        <div>
          <div id="credFrente" className="credFrente" />
          <Viewer container={"credFrente"} image={front} />
        </div>
      </Col>
      <h4>Datos capturados</h4>
      <Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24}>
        <div className="data">
          <div className="alert">
            <AlertInfo disable={alert.nombres} />
          </div>
          <Col xxl={24} xl={24} lg={12} md={12} sm={12} xs={24}>
            <Form.Item label="Apellido paterno">{paterno}</Form.Item>
          </Col>
          <Col xxl={24} xl={24} lg={12} md={12} sm={12} xs={24}>
            <Form.Item label="Apellido materno">{materno}</Form.Item>
          </Col>
          <Col xxl={24} xl={24} lg={12} md={12} sm={12} xs={24}>
            <Form.Item label="Nombre(s)">{nombre}</Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item label="¿Los datos coinciden con la credencial de elector?">
              {getFieldDecorator("nombres", {
                rules: radioButton,
                initialValue: datos.nombres,
              })(
                <Radio.Group
                  onChange={changeRadio("nombres")}
                  disabled={disabled.nombres}
                >
                  <Radio value={true}>Sí</Radio>
                  <Radio value={false}>No</Radio>
                </Radio.Group>
              )}
            </Form.Item>
          </Col>
          {form.getFieldValue("nombres") === false && (
            <Form.Item label="Observaciones">
              {getFieldDecorator("nombresObs", {
              rules: observaciones,
              initialValue: datos.nombresObs,
              })(
                <Input.TextArea              
                  onChange={changeRadio("nombresObs")}
                  disabled={disabled.nombresObs}
                  maxLength={150}
                />
              )}
            </Form.Item>
          )}
        </div>
      </Col>
    </Row>
  );
}
