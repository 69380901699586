import React from "react";
import { Alert } from "antd";

const Error = ({ message, type }) => {
  return (
    <div>
        <Alert
          message={message}
          type={type}
          closable
          showIcon
        />
    </div>
  );
};

export default Error;
