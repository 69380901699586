import React, { useState } from "react";
import { Form, Icon, Input } from "antd";
import Recaptcha from "react-recaptcha";
import "./Login.scss";
import axios from "axios";
import { ACCESS_TOKEN, MSN_LOGIN } from "../../utils/constanst";
import { basePath } from "../../api/config";
import Alert from "../Alert/Alert";
import { emptyCache } from "../Generals/functions";

function Login(props) {
  const { getFieldDecorator } = props.form;
  //Key para poder generar el recapcha, está key puede cambiar dependiendo el proyecto ya que se obtiene a través de una cuenta gmail
  const CAP_KEY = "6Len8ekUAAAAALaah5js5KtSdPboaGH703DuJgV2";
  const { Logo, setLoader } = props; //Se recibe el logo por props
  //Hooks
  const [capcha, setCapcha] = useState(false);
  const [inputs, setInputs] = useState({
    usuario: "",
    password: "",
    versionAplicacion: "11.0",
    idSistema: "218",
  });

  const [error, setError] = useState(false);
  const [messageAlert, setMessageAlert] = useState("");

  const verifyCallback = (response) => {
    setCapcha({ capcha }); //función que comprueba que el recapcha se selecciono correctamente
  };
  const onloadCallback = () => {
    console.log("captcha successfully loaded"); //Realiza un callback al capcha
  };
  //Funcíon que obtiene los cambios que a tenido el formulario
  const changeForm = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });
  };

  const login = (e) => {
    e.preventDefault();
     if (!capcha) {
          //Se verifica el capcha
          setError(true);
          setMessageAlert("El código de seguridad es obligatorio, favor de verificarlo.");
        } else {
    //Se realiza la petición al server
    props.form.validateFields((err) => {
      if (!err) {
        setError(false);
        setLoader({ loading: true, message: MSN_LOGIN }); //Se activa el loader
        const url = `${basePath}/validateUser`;
        axios
          .post(url, inputs)
          .then((response) => {
            const { code, message } = response.data.entity;
            if (code === 400 || code === 500) {
              setError(true);
              setMessageAlert(message);
              setLoader({ loading: false, message: "" }); //Se desactiva el loader
            } else {
              setError(false);
              const {
                tknA,
                estado,
                distrito,
                idSistema,
                idEstado,
                idDistrito,
                idMunicipio,
                ambito,
                rolUsuario,
                versionUsuario,
                infoMenu,
                nombre,
              } = response.data.entity.datosUsuario; //Se obtiene el token de json, el estado, el id distrito, el id sistema, el id estado, el id distrito, el id municipio, el ámbito, el rol usuario y la versión del usuario
              localStorage.setItem(ACCESS_TOKEN, tknA); //Se almacena el token en el localStorage
              localStorage.setItem("ESTADO", estado); //Se almacena el nombre del estado en el localStorage
              localStorage.setItem("DISTRITO", distrito); //Se almacena el nombre del distrito en el localStorage
              localStorage.setItem("ID_DISTRITO", idDistrito); //Se almacena el id del distrito en el localStorage
              localStorage.setItem("VERSION_USUARIO", versionUsuario); //Se almacena la versión del usuario en el localStorage
              localStorage.setItem("INFO_MENU", JSON.stringify(infoMenu)); //Se almacena el infoMenu del JSON en el localStorage
              localStorage.setItem("ID_SISTEMA", idSistema); //Se almacena el id del sistema en el localStorage
              localStorage.setItem("ID_ESTADO", idEstado); //Se almacena el id del estado en el localStorage
              localStorage.setItem("ID_MUNICIPIO", idMunicipio); //Se almacena el id del municipio en el localStorage
              localStorage.setItem("AMBITO", ambito); //Se almacena el ámbito en el localStorage
              localStorage.setItem("ROL_USUARIO", rolUsuario); //Se almacena el rol del usuario en el localStorage
              localStorage.setItem("NOMBRE", nombre);
              localStorage.setItem(
                "menu",
                JSON.stringify(
                  response.data.entity.datosUsuario.infoMenu.listMenu
                )
              );
              //setLoader({ loading: false, message: "" });  //Se desactiva el loader
              emptyCache();
              window.location.href = "/admin/home"; //Se redirecciona al home
            }
          })
          .catch((err) => {
            console.log(err);
            setError(true);
            setMessageAlert("Error al iniciar sesión");
            setLoader({ loading: false, message: "" }); //Se desactiva el loader
          });
      }
    });
    }
  };

  return (
    <div className="div_login">
      <div className="login-image">
        <Logo />
      </div>
      <Form className="login-form" onChange={changeForm} onSubmit={login}>
        <Form.Item>
          {getFieldDecorator("user", {
            rules: [
              { required: true, message: "Dato requerido" },
              { max: 50, message: "Número de caracteres permitidos: 50" },
              {
                pattern: /^[^<>%$&|#]*$/,
                message: "Dato inválido, ingresa nuevamente.",
              },
            ],
          })(
            <Input
              prefix={<Icon type="user" style={{ color: "#d5007f" }} />}
              placeholder="Usuario"
              name="usuario"
              style={{ width: "100%" }}
              maxLength={51}
            />
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator("pass", {
            rules: [
              { required: true, message: "Dato requerido" },
              { max: 50, message: "Número de caracteres permitidos: 50" },
              {
                pattern: /^[^<>]*$/,
                message: "Dato inválido, ingresa nuevamente.",
              },
            ],
          })(
            <Input.Password
              prefix={<Icon type="lock" style={{ color: "#d5007f" }} />}
              type="password"
              name="password"
              placeholder="Contraseña"
              style={{ width: "100%" }}
              maxLength={51}
            />
          )}
        </Form.Item>
        <div>
          {error ? <Alert message={messageAlert} type={"error"} /> : null}
        </div>
        <a
          className="url"
          href="https://cua.ine.mx/claveacceso/"
          target="blank"
        >
          Cambiar contraseña
        </a>
        <div className="login-form__capcha" id="rc-imageselect">
          <Recaptcha
            onloadCallback={onloadCallback}
            verifyCallback={verifyCallback}
            sitekey={CAP_KEY}
            hl={"es"}
            render="explicit"
            tabIndex="3"
          />
        </div>
        <Form.Item>
          <button type="submit" className="login-form__button">
            Iniciar Sesión
          </button>
        </Form.Item>
      </Form>
      <div id="loginSysData">
        <p>Versión del sistema 11.0</p>
        <p>Derechos reservados ©INE 2020</p>
      </div>
      {/* <a href="html">Protección de datos</a> */}
    </div>
  );
}
const LoginForm = Form.create()(Login);
export default LoginForm;
