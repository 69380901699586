import React, { useState, useEffect } from "react";
import { Input, Icon, Tooltip, Table } from "antd";
import { withRouter, Link } from "react-router-dom";
import moment from "moment";
import { ModalDelete } from "../../../utils/Organitation/function";
import { USUARIO_CAU } from "../../../utils/constanst";
//import "moment/locale/es";

function TableOrganitation(props) {
  const {
    data, //Se recibe el array de las organizaciones
    setReload, //Para recargar la tabla
    rolModulo: { consulta, modifica, borrar }, //Para habilitar los permisos
    loading,
    rolUsuario,
  } = props; //Variables que se reciben por los props
  const [value, setValue] = useState(""); //Hook para el filtrado de nombre
  const [dataSourceOrg, setDataSourceOrg] = useState([]); //hook para almacenar el array
  useEffect(() => {
    setDataSourceOrg(data); //Se inicializa el array de organizaciones en el hook
  }, [data]);
  //Función para eliminar una organización
  const deleted = (org, e) => {
    e.preventDefault(); //Evita recargar la página
    ModalDelete(org, setReload); //Se muestra el alert
  };
  //Función que filtra las organizaciones por el nombre
  const FilterByNameInput = (
    <>
      Nombre
      <Input
        placeholder="Nombre de la organización"
        value={value}
        onChange={(e) => {
          const currValue = e.target.value;
          setValue(currValue);
          const filteredData = data.filter((entry) =>
            entry.organizacion
              .toString()
              .toLowerCase()
              .includes(currValue.toLowerCase())
          );
          setDataSourceOrg(filteredData);
        }}
      />
    </>
  );
  //Se definen las columnas de la tabla de organizaciones
  const columns = [
    {
      title: "#",
      dataIndex: "indice",
      key: "indice",
      width: 80,
    },

    {
      title: FilterByNameInput,
      dataIndex: "organizacion",
      key: "organizacion",
      width: 350,
    },
    {
      title: "Código",
      dataIndex: "codigo",
      key: "codigo",
      align: "center",
      width: 150,
    },
    {
      title: "Fecha de alta",
      dataIndex: "fechaAlta",
      key: "fechaAlta",
      align: "center",
      width: 150,
      render: (record) => moment(record).format("DD/MM/YYYY"), //Formato a la fecha
    },
    {
      title: "Teléfono",
      dataIndex: "telefono",
      key: "telefono",
      align: "center",
      width: 150,
    },

    {
      title: "Correo",
      dataIndex: "correoElectronico",
      key: "correoElectronico",
      align: "center",     
      width: 250, 
    },

    {
      title: "Acciones",
      align: "left",   
      fixed: "right", 
      width: 160,   
      render: (record) => (
        <>
          {consulta && (
            <Tooltip title="Visualizar">
              <Link
                to={{
                  pathname: "/admin/organizacion/modificar",
                  data: record,
                  status: true,
                }}
              >
                <button className="btn-circle btn_secundario">
                  <Icon type="eye" theme="filled" />
                </button>
              </Link>
            </Tooltip>
          )}{" "}
          {modifica && (
            <Tooltip title="Modificar">
              <Link
                to={{
                  pathname: "/admin/organizacion/modificar",
                  data: record,
                  status: false,
                }}
              >
                <button className="btn-circle btn_secundario">
                  <Icon type="edit" theme="filled" />
                </button>
              </Link>
            </Tooltip>
          )}
          {borrar && rolUsuario !== USUARIO_CAU && (
            <Tooltip title="Eliminar">
              <button
                className="btn-circle btn_secundario"
                onClick={(e) => deleted(record, e)}
              >
                <Icon type="delete" />
              </button>
            </Tooltip>
          )}
        </>
      ),
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={dataSourceOrg}
      loading={loading}
      locale={{ emptyText: "Sin registros" }}
      scroll={{ x: "calc(100vh - 4em)" }}
    />
  );
}
export default withRouter(TableOrganitation);
