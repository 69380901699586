import { createStore } from "redux";
import reducer from "./reducers";
//import { getEntitiesStorage, setStorage } from "./util/localStorage";
//const entitiesStorage = getEntitiesStorage();

//Configuración redux para mostrar la configuración en el navegador
const store = createStore(
  reducer, // Se importan los reducer creados
  //entitiesStorage,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

//Función para sincronizar el state con el localStorage 
// store.subscribe(() => {
//   setStorage({
//     entidades: store.getState().entidades,
//   });
// });
export default store;
