import React, { useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import { Steps, Row, Divider, Button, Col } from "antd";
import Links from "../../../../components/User/Public/Links";
import SectionPortal from "../../../../components/User/Public/SectionPortal";
import { getEstadoGralSolicitud, getStatus, getEstadoCurso } from "../../../../api/requestPub";
import { getEstadoPortal, getEstadoInforme } from "../../../../api/information";
import useAuth from "../../../../hooks/useAuth";
import Loading from "../../../../components/Generals/Loading";
import Notice from "./Notice";
import { NoObservation, PresencialEspera, FinalizaCurso } from "./Observation";
import { ESTATUS_SIN_REVISION, ESTATUS_CON_OBSERVACIONES, ESTATUS_OBSERVACIONES_ATENDIDAS, ESTATUS_VALIDADO } from "../../../../utils/constanst";
import { PAGE_SIN_REVISION, PAGE_OBSERVACIONES_ATENDIDAS, PAGE_CON_OBSERVACIONES, PRESENCIAL_SIN_ASIGNAR, PRESENCIAL_SIN_ASISTENCIA, CURSO_FINALIZADO,
  CURSO_LINEA_PENDIENTE, PAGE_ACREDITADO, CURSO_LINEA_TERMINADO, PAGE_INFORMES } from "../../../../utils/constanst";
import Download from "./Dowload";
import { ReactComponent as LogoRevision } from "../../../../assets/img/Portal/capturaSolicitud.svg";
import { ReactComponent as LogoContamos } from "../../../../assets/img/Portal/declina_img.svg";
import { ReactComponent as LogoCursoPre } from "../../../../assets/img/Portal/cursoPresAsignado.svg";
import { ReactComponent as LogoPresencialEspera } from "../../../../assets/img/Portal/solVerificada_img.svg";
import { useDispatch } from "react-redux";
import { cleanEntityMat } from "../../../../redux/actions/entity";
import { useConstancy } from "../../../../hooks/useConstancy";
import { useSelector } from "react-redux";
import { ReactComponent as Observaciones } from "../../../../assets/img/Portal/observacionesSolicitud.svg";
import { ReactComponent as LogoCursoAprobado } from "../../../../assets/img/Portal/cursoAprobado.svg";

function Home(props) {
  const { setLoader } = props;
  const { Step } = Steps;
  const [status, setStatus] = useState({ status: 0 });
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState({ fase: 0, page: 0 });
  const [direccionCurso, setDireccionCurso] = useState({
    estatus: false, organo: "", direccion: "", fecha: "", hInicial: "", hFinal: "",
  });
  const {
    ciudadano: { ciudadano, detalle },
  } = useAuth();
  const [estadoGralSolicitud, setEstadoGralSolicitud] = useState();
  const dispatch = useDispatch();
  const [continuar, setContinuar] = useState(true);
  const [sinRequisitos, setSinRequisitos] = useState(false);
  //Verifica si existe la constancia del curso en línea
  useConstancy(ciudadano.id);

  useEffect(() => {
    if (ciudadano !== null) {
      dispatch(cleanEntityMat()); //Se limpia el state de entidades a observar
      //Se obtiene el status de la solicitud del observador
      getStatus(ciudadano.sub)
        .then((res) => {
          const { data } = res; //Destructuring de la respuesta
          setStatus({ status: data }); //Se almacenan en el hook el status del observador
          //Si no existe ningun registro
          switch (data) {
            case 0:
              setPage({ fase: 0, page: 0 }); //Se asigna la fase y la página
              setLoading(false); //Se desactiva el loading
              break;
            case ESTATUS_SIN_REVISION:
              setPage({ fase: 1, page: PAGE_SIN_REVISION });
              setLoading(false);
              break;
            case ESTATUS_CON_OBSERVACIONES:
              setPage({ fase: 1, page: PAGE_CON_OBSERVACIONES });
              setLoading(false);
              break;
            case ESTATUS_OBSERVACIONES_ATENDIDAS:
              setPage({ fase: 1, page: PAGE_OBSERVACIONES_ATENDIDAS });
              setLoading(false);
              break;
            case ESTATUS_VALIDADO:
              getEstadoGralSolicitud(ciudadano.sub) //Se realiza la peticón del estado general
              .then((res) => {
                setEstadoGralSolicitud(res.data);
              })
              .catch((error) => {
                console.log(error);
              });
              break;
            default:
              break;
          }
          getEstadoGralSolicitud(ciudadano.sub)
            .then((res) => {
              if (res.data === 2 || res.data === 3 || res.data === 5) {
                setEstadoGralSolicitud(res.data);
              } else {
                if (res.data === 6) {
                  setSinRequisitos(true);
                }
              }               
            })
            .catch((error) => {
              console.log(error);
          });
        })
        .catch((error) => {
          console.log("Ocurrio un error", error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (ciudadano !== null && estadoGralSolicitud !== undefined && estadoGralSolicitud !== null ) {
      // saber qué pantalla mostrar en el portal después de la validación de su solicitud
      getEstadoPortal(ciudadano.sub).then((res) => {
        if (res.data !== null && res.data.code === 200) {
          if (res.data.fase === 3 || res.data.fase === 4) {
            getEstadoInforme()
            .then((res) => {
              setContinuar(res.data);               
            })
            .catch((error) => {
              console.log(error);
              setContinuar(false);
            });
          }
          if (res.data.pagina === 7 || res.data.pagina === 9) {
            getEstadoCurso()
            .then((res) => {
              setContinuar(res.data);               
            })
            .catch((error) => {
              console.log(error);
              setContinuar(false);
            });
          }
          setPage({ fase: res.data.fase, page: res.data.pagina });
          setLoading(false);
          if (res.data.cursoPresencial !== null && res.data.cursoPresencial.direccionCompleta !== null) {
            setDireccionCurso({ estatus: true,
              organo: res.data.cursoPresencial.organoImparte,
              direccion: res.data.cursoPresencial.direccionCompleta,
              fecha: res.data.cursoPresencial.fecha,
              hInicial: res.data.cursoPresencial.horaInicial,
              hFinal: res.data.cursoPresencial.horaFinal, });
          }
        } 
        }).catch((error) => {
          console.log(error);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [estadoGralSolicitud]);

  //Loading mientras carga la información
  if (loading) {
    return <Loading />;
  }

  //Apartado de avisos
  const Message = () => {
    return (
      <div className="home mensaje">
        {!ciudadano.lim ? (
          <>
            <Row gutter={24}>
              <Col xxl={18} xl={18} lg={18} md={16} sm={24} xs={24}>
                <h1>Llena tu solicitud de acreditación</h1>
                <p style={{ fontSize: 18 }}>
                  Captura tus datos y adjunta los documentos que se te
                  solicitan. Necesitas imágenes de tu credencial de elector y
                  una fotografía de retrato reciente.
                </p>

                <Link to={{ pathname: "/ciudadano/solicitud", state: true }}>
                  <Button type="primary">Llenar solicitud</Button>
                </Link>
              </Col>
              <Col xxl={6} xl={6} lg={6} md={8} sm={0} xs={0}>
                <LogoRevision className="logo" />
              </Col>
            </Row>
          </>
        ) : (
          <>
            <Row gutter={24}>
              <Col xxl={18} xl={18} lg={18} md={16} sm={24} xs={24}>
                <h1>El registro de solicitudes ha terminado</h1>
                <p style={{ fontSize: 18 }}>
                  Estimado ciudadano/a la fecha límite de registro de su
                  solicitud ha terminado, agradecemos su interés por participar.
                </p>
              </Col>
              <Col xxl={6} xl={6} lg={6} md={8} sm={0} xs={0}>
                <LogoRevision className="logo" />
              </Col>
            </Row>
          </>
        )}
      </div>
    );
  };

  const WhithObservation = () => {
    return (
      <div className="home">
        <Row gutter={24}>
          <Col xxl={18} xl={18} lg={18} md={16} sm={24} xs={24}>
          {!sinRequisitos ? (
            <>
              <h1>Tus datos requieren ajustes</h1>
              <p style={{ fontSize: 18 }}>
                Realizamos observaciones en tu solicitud, actualiza tus datos para
                continuar con tu proceso.
              </p>
              <Link to={{ pathname: "/ciudadano/observaciones", state: true }}>
                <Button style={{ width: 210, marginTop: 7 }} type="primary">
                  Revisar observaciones
                </Button>
              </Link>
            </>
          ):(
            <>
              <h1>Tu solicitud no completó con los requisitos</h1>
              <p style={{ fontSize: 18 }}>
              Si tienes alguna duda comunícate a tu junta dónde realizaste el registro.
              </p>
            </>
          )}
          </Col>
          <Col xxl={6} xl={6} lg={6} md={8} sm={0} xs={0}>
            <Observaciones className="logo" />
          </Col>
        </Row>
      </div>
    );
  };

  const CoursePresencial = () => {
    return (
      <div className="home mensaje">
        <Row gutter={24}>
          <Col xxl={18} xl={18} lg={18} md={16} sm={24} xs={24}>
            <h1>Se asignó tu curso de capacitación</h1>
            <p style={{ fontSize: 18 }}>
              Tu curso fue programado el {direccionCurso.fecha} de{" "}
              {direccionCurso.hInicial} a {direccionCurso.hFinal} en el
              siguiente <br />
              domicilio {direccionCurso.direccion}.
            </p>
            <p style={{ fontSize: 18 }}>
              Te recomendamos llegar con 15 minutos de anticipación y llevar una{" "}
              <br />
              copia de tu solicitud de acreditación, así como tu credencial de
              elector.
            </p>
          </Col>
          <Col xxl={6} xl={6} lg={6} md={8} sm={0} xs={0}>
            <LogoCursoPre className="logo" />
          </Col>
        </Row>
      </div>
    );
  };

  const CourseOnline = () => {
    //State para validar si ya terminó un curso
    const { constancia } = useSelector((state) => state.constancia);
    return (
      <div className="home mensaje">
        <Row gutter={24}>
          <Col xxl={18} xl={18} lg={18} md={16} sm={24} xs={24}>
            {!constancia ? (
              <>
                <h1>Tu solicitud ha sido verificada</h1>
                {!continuar ? (
                <>
                  <p style={{ fontSize: 18 }}>
                    Puedes continuar con tu proceso de acreditación. Desde este
                    momento está disponible tu capacitación en línea.
                  </p>
                  <Link to={{ pathname: "/ciudadano/capacitacion" }}>
                    <Button
                      style={{ width: 210, marginTop: 7, marginBottom: 30 }}
                      type="primary"
                    >
                      Recibir capacitación
                    </Button>
                  </Link>
                  <p>
                    Los materiales de apoyo a tu capacitación estarán disponibles
                    incluso después de que tomes tu curso. Puedes encontrar el
                    enlace en la sección “Materiales de capacitación”.
                  </p>
                </>
                ):(
                <>
                  <p style={{ fontSize: 18 }}>
                    Lamentamos informarte que el tiempo para realizar la capacitación en línea ha terminado.
                  </p>
                </>
                )}
              </>
            ) : (
              <>
                <h1>Has aprobado tu curso de capacitación</h1>
                <p style={{ fontSize: 16 }} className="texto_parrafo">
                Al cumplir con todos los requisitos, el Consejo Local o Distrital determinará la aprobación de tu solicitud, por lo que serás notificado (a) y podrás descargar tus documentos de acreditación desde este Portal.
                </p>
                {!continuar ? (
                <>
                  <p style={{ fontSize: 16 }} className="texto_parrafo">
                  Podrás continuar con el/los curso(s) en el momento que lo
                  desees, tu avance se guarda automáticamente.
                  </p>
                  <Link to={{ pathname: "/ciudadano/capacitacion" }}>
                    <Button
                      style={{ width: 210, marginTop: 7, marginBottom: 30 }}
                      type="primary"
                    >
                      Continuar capacitación
                    </Button>
                  </Link>
                  <p style={{ fontSize: 16 }} className="texto_parrafo">
                    Los materiales de apoyo a tu capacitación estarán disponibles
                    incluso después de que tomes tu curso. Puedes encontrar el
                    enlace en la sección “Materiales de capacitación”.
                  </p>
                </>
                ):(
                <>
                </>
                )}
              </>
            )}
            {/* <p>
              Tu solicitud ha sido verificada Puedes continuar con tu proceso de
              acreditación.
            </p>
            <p>
              Si decidiste tomar tu capacitación virtual, se te brindarán mayores
              informes a partir de Enero del 2021.
            </p> */}
          </Col>
          <Col xxl={6} xl={6} lg={6} md={8} sm={0} xs={0}>
            {!constancia ? (
              // Logo por defecto
              <Observaciones className="logo" />
            ) : (
              <LogoCursoAprobado clasname="logo" />
            )}
          </Col>
        </Row>
      </div>
    );
  }

  const CourseOnlineFinish = () => {
    //State para validar si ya terminó un curso
    const { constancia } = useSelector((state) => state.constancia);
    return (
      <div className="home mensaje">
        <Row gutter={24}>
          <Col xxl={18} xl={18} lg={18} md={16} sm={24} xs={24}>
            {!constancia ? (
              <>
                <h1>Tu solicitud ha sido verificada</h1>
                {!continuar ? (
                <>
                <p style={{ fontSize: 18 }}>
                  Puedes continuar con tu proceso de acreditación. Desde este
                  momento está disponible tu capacitación en línea.
                </p>
                <Link to={{ pathname: "/ciudadano/capacitacion" }}>
                  <Button
                    style={{ width: 210, marginTop: 7, marginBottom: 30 }}
                    type="primary"
                  >
                    Recibir capacitación
                  </Button>
                </Link>
                <p>
                  Los materiales de apoyo a tu capacitación estarán disponibles
                  incluso después de que tomes tu curso. Puedes encontrar el
                  enlace en la sección “Materiales de capacitación”.
                </p>
                </>
                ):(
                  <>
                    <p style={{ fontSize: 18 }}>
                      Lamentamos informarte que el tiempo para realizar la capacitación en línea ha terminado.
                    </p>
                  </>
                )}
              </>
            ) : (
              <>
                <h1>Has aprobado tu curso de capacitación</h1>
                <p style={{ fontSize: 16 }} className="texto_parrafo">
                Al cumplir con todos los requisitos, el Consejo Local o Distrital determinará la aprobación de tu solicitud, por lo que serás notificado (a) y podrás descargar tus documentos de acreditación desde este Portal.
                </p>
                <p style={{ fontSize: 16 }} className="texto_parrafo">
                  Los materiales de apoyo a tu capacitación estarán disponibles
                  incluso después de que tomes tu curso. Puedes encontrar el
                  enlace en la sección “Materiales de capacitación”.
                </p>
              </>
            )}
  
            {/* <p>
              Tu solicitud ha sido verificada Puedes continuar con tu proceso de
              acreditación.
            </p>
            <p>
              Si decidiste tomar tu capacitación virtual, se te brindarán mayores
              informes a partir de Enero del 2021.
            </p> */}
          </Col>
          <Col xxl={6} xl={6} lg={6} md={8} sm={0} xs={0}>
            {!constancia ? (
              // Logo por defecto
              <Observaciones className="logo" />
            ) : (
              <LogoCursoAprobado clasname="logo" />
            )}
          </Col>
        </Row>
      </div>
    );
  }

  const Acreditado = () => {
    return (
      <div className="home mensaje">
        <Row gutter={24}>
          <Col xxl={18} xl={18} lg={18} md={16} sm={24} xs={24}>
            <h1>Eres un observador acreditado</h1>
            <p style={{ fontSize: 18 }}>
              Felicidades a partir de este momento puedes descargar los documentos
              que te identifican como un observador acreditado.
            </p>
            <p style={{ fontSize: 18 }}>
              Tu gafete y acreditación lo puedes encontrar debajo en esta página
              en la sección <i>“Documentos de tu proceso”</i>.
            </p>
            
            {!continuar ? (
              <>
                <p style={{ fontSize: 18 }}>
                  A partir de este momento puedes comenzar con tu informe como observador electoral.
                  Descuida, si lo realizas por este medio podrás modificarlo en cualquier momento.
                </p>
                <Link to={{ pathname: "/ciudadano/informes", state: true }}>
                  <Button
                    style={{ width: 210, marginTop: 7, marginBottom: 30 }}
                    type="primary"
                  >
                    Presentar informe
                  </Button>
                </Link>
              </>
            ):(
              <>
                <p style={{ fontSize: 18 }}>
                  Lamentamos informarte que el tiempo para capturar tu informe como observador electoral ha terminado.
                </p>
              </>
            )}
          </Col>
          <Col xxl={6} xl={6} lg={6} md={8} sm={0} xs={0}>
            <LogoPresencialEspera className="logo" />
          </Col>
        </Row>
      </div>
    );
  };
  
  const Informes = () => {
    return (
      <div className="home mensaje">
        <Row gutter={24}>
          <Col xxl={18} xl={18} lg={18} md={16} sm={24} xs={24}>
            {!continuar ? (
              <>
                <p style={{ fontSize: 18 }}>
                  Muchas gracias por tu apoyo durante este proceso electoral,
                  tu esfuerzo nos ayuda para seguir mejorando.
                </p>
                <p style={{ fontSize: 18 }}>
                  Puedes continuar con tu informe como observador electoral.
                  Descuida, si lo realizas por este medio podrás modificarlo en cualquier momento.
                </p>
                <Link to={{ pathname: "/ciudadano/informes", state: true }}>
                  <Button
                    style={{ width: 210, marginTop: 7, marginBottom: 30 }}
                    type="primary"
                  >
                    Presentar informe
                  </Button>
                </Link>
              </>
            ):(
              <>
                <p style={{ fontSize: 18 }}>
                  Lamentamos informarte que el tiempo para realizar las modificaciones a tu informe como observador electoral ha terminado.
                </p>
              </>
            )}
          </Col>
          <Col xxl={6} xl={6} lg={6} md={8} sm={0} xs={0}>
            <LogoPresencialEspera className="logo" />
          </Col>
        </Row>
      </div>
    );
  };

  const RenderSwitch = () => {
    switch (page.page) {
      case PAGE_SIN_REVISION:
        return <Notice />;
      case PAGE_CON_OBSERVACIONES:
        return <WhithObservation />;
      case PAGE_OBSERVACIONES_ATENDIDAS:
        return <NoObservation />;
      case PRESENCIAL_SIN_ASIGNAR:
        return <PresencialEspera />;
      case PRESENCIAL_SIN_ASISTENCIA:
        return <CoursePresencial />;
      case CURSO_FINALIZADO:
        return <FinalizaCurso />;
      case CURSO_LINEA_PENDIENTE:
        return <CourseOnline />;
      case CURSO_LINEA_TERMINADO:
        return <CourseOnlineFinish />;
      case PAGE_ACREDITADO:
        return <Acreditado />;
      case PAGE_INFORMES:
        return <Informes />;
      default:
        return <Message />;
    }
  };

  const Declinacion = () => {
    return (
      <div className="secDeclina home mensaje">
        <Row>
          <Col xxl={18} xl={18} lg={18} md={16} sm={24} xs={24}>
            <h1>Has declinado tu solicitud</h1>
            <p>
              Lamentamos que no puedas acompañarnos como Observadora u
              Observador en este proceso electoral.
            </p>
            <p>
              Esperamos contar contigo en el siguiente proceso electoral.
              Recuerda que tu participación es lo más importante en las
              elecciones.
            </p>
            <p>Contamos todas, contamos todos. INE</p>
          </Col>
          <Col xxl={6} xl={6} lg={6} md={8} sm={0} xs={0} className="imgCont">
            <LogoContamos />
          </Col>
        </Row>
      </div>
    );
  };

  return (
    <div style={{ marginTop: "20px" }}>
      {estadoGralSolicitud !== 3 &&
        estadoGralSolicitud !== 5 && ( //No ha declinado o cancelado
          <div>
            <Divider>
              <h2>Este es el avance en tu proceso de acreditación</h2>
            </Divider>

            <div style={{ width: "100%" }} className="avanceProceso">
              <Steps current={page.fase}>
                <Step description="Creación de solicitud y captura de datos" />
                <Step description="Revisión de solicitud / corrección de datos" />
                <Step description="Capacitación como Observador/a" />
                <Step description="Acreditación" />
                <Step description="Presentación de informes " />
              </Steps>
            </div>
            {<RenderSwitch />}
          </div>
        )}
      {
        estadoGralSolicitud === 3 && (<div> <Declinacion /> </div>) //Solicitud declinada
      }
      {
        estadoGralSolicitud === 5 && (<div> <Declinacion /> </div>) //Solicitud cancelada
      }
      {status.status > 0 && (
        <Download
          ciudadano={ciudadano}
          detalle={detalle}
          setLoader={setLoader}
          status={status}
          page={page}
          estadoGralSolicitud={estadoGralSolicitud}
        />
      )}
      <Row className="home" gutter={[24, 0]}>
        <SectionPortal idEstadoGralSol={estadoGralSolicitud} />
      </Row>
      <Row>
        <Links />
      </Row>
    </div>
  );
}

export default withRouter(Home);
