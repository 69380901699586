import { basePath } from "./config";
import axios from "axios";
import { getAccessTokenApi } from "./auth";

export function getCantidadSolicitudes( idProcesoElectoral, idDetalleProceso, idEstadoJunta, idDistrito ) {
    const url = `${basePath}/acreditacion/cantidadSolicitudes`;
    const jwt = getAccessTokenApi();
    return axios.post(url, { idProcesoElectoral: idProcesoElectoral, idDetalleProceso: idDetalleProceso, idEstadoJunta: idEstadoJunta, idDistrito: idDistrito },{
      headers: { "Authorization": jwt }
    });
}

export function getSolicitudesAcreditadas( idProcesoElectoral, idDetalleProceso, idEstadoJunta, idDistrito ) {
  const url = `${basePath}/acreditacion/solicitudesAcreditadas`;
  const jwt = getAccessTokenApi();
  return axios.post(url, { idProcesoElectoral: idProcesoElectoral, idDetalleProceso: idDetalleProceso, idEstadoJunta: idEstadoJunta, idDistrito: idDistrito },{
    headers: { "Authorization": jwt }
  });
}

export function getSolicitudesAcreditables( idProceso, idDetalle, idEstado, idDistrito, fechaInicial, fechaFinal, todasSolicitudes ) {
  const url = `${basePath}/acreditacion/solicitudesAcreditables`;
  const jwt = getAccessTokenApi();
  return axios.post(url, { idProceso: idProceso, idDetalle: idDetalle, idEstado: idEstado, idDistrito: idDistrito, fechaInicial: fechaInicial, fechaFinal: fechaFinal, todasSolicitudes: todasSolicitudes },{
    headers: { "Authorization": jwt }
  });
}

export function saveAccreditation(acreditacion) {
  const url = `${basePath}/acreditacion/guardarAcreditacion`;
  const jwt = getAccessTokenApi();
  return axios.post(url, acreditacion, {
    headers: { "Content-Type": "multipart/form-data", "Authorization": jwt, "Parametrizacion": "16" },
  });
}

export function updateAccreditation(solicitud) {
  const url = `${basePath}/acreditacion/modificaSolicitud`;
  const jwt = getAccessTokenApi();
  return axios.post(url, solicitud, {
    headers: { "Authorization": jwt, },
  });
}

export function deleteAccreditation( idObservador, correo, folio, usuario ) {
  const url = `${basePath}/acreditacion/eliminaSolicitud`;
  const jwt = getAccessTokenApi();
  return axios.post(url, { idObservador: idObservador, correo: correo, folio: folio, usuario: usuario },{
    headers: { "Authorization": jwt }
  });
}

export function getFirmas( idEstadoJunta, idDistrito ) {
  const url = `${basePath}/acreditacion/obtenerFirmas`;
  const jwt = getAccessTokenApi();
  return axios.post(url, { idEstadoJunta: idEstadoJunta, idDistrito: idDistrito },{
    headers: { "Authorization": jwt }
  });
}

export function generateDocuments( solicitud ) {
  const url = `${basePath}/acreditacion/generarDocumentos`;
  const jwt = getAccessTokenApi();
  return axios.post(url, solicitud, {
    headers: { 
      "Authorization": jwt,
      "Content-Type": "application/json",
   },
    responseType: "blob",
  });
}

export function downloadConstancia(idObservador ) {
  const url = `${basePath}/acreditacion/descargar`;
  const jwt = getAccessTokenApi();
  return axios.post(url, {idObservador: idObservador, tipo: 0},{
    headers: {
      "Authorization": jwt,
      "Content-Type": "application/json", 
    },
    responseType: "blob",
  });
}

export function downloadGafete(idObservador ) {
  const url = `${basePath}/acreditacion/descargar`;
  const jwt = getAccessTokenApi();
  return axios.post(url, {idObservador: idObservador, tipo: 1},{
    headers: {
      "Authorization": jwt,
      "Content-Type": "application/json", 
    },
    responseType: "blob",
  });
}

export function getSolicitudesAcreditadasDocs( idProcesoElectoral, idDetalleProceso, idEstadoJunta, idDistrito ) {
  const url = `${basePath}/acreditacion/solicitudesAcreditadasDocs`;
  const jwt = getAccessTokenApi();
  return axios.post(url, { idProcesoElectoral: idProcesoElectoral, idDetalleProceso: idDetalleProceso, idEstadoJunta: idEstadoJunta, idDistrito: idDistrito },{
    headers: { "Authorization": jwt }
  });
}