import { Button, Steps, Icon } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getCuestionario } from "../../../api/courses";
// import Lessons from "../../../pages/Public/Citizen/CourseOnline/Lessons";
import { ACT_FINAL, ACT_VALORACION } from "../../../utils/constanst";
import Loading from "../../Generals/Loading";
import {
  openModalAction,
  closeModalAction,
} from "../../../redux/actions/course";
import ModalOnline from "./Modal";

/**
 * Componente que muestra el temario de lecciones que corresponden a una entidad
 * @param {*} array -lecciones
 * @param {*} index - indice que inicia la barra lateral
 */
export const MenuLessons = ({ index }) => {
  const { Step } = Steps;
  //Se obtiene el temario del state - redux
  const { temario, loading } = useSelector((state) => state.menu);
  const { ultimaLeccion } = useSelector((state) => state.leccion);

  //Función que contola la barra lateral-Temario de lecciones
  const getStatus = (i) => {
    //Valida que no se selecione una lección en la pantalla de introducción
    if (index === null) return;
    //Si se encuentra en la misma lección aparece el número de la lección status-process
    if (parseInt(ultimaLeccion) === parseInt(i)) {
      return "process";
    }
    //Si la ultima lección es mayor las pone como finalizadas
    if (ultimaLeccion > i) {
      return "finish";
    }
    return "wait";
  };

  return (
    <Steps direction="vertical" size="small" current={index}>
      {loading || temario.length <= 0 ? (
        <Loading />
      ) : (
        temario.map((item, i) => (
          <Step
            status={getStatus(i)}
            title={item.leccion}
            key={i}
            icon={item.tipoLeccion > 1 && <Icon type="profile" />}
          />
        ))
      )}
    </Steps>
  );
};

/**
 *Componente que muestra los botones para cambiar de lecciones (Temporal)
 * @param {*} array- lecciones
 * @param {*}  setCurrent - hook que permite avanzar de lección
 */
export const ButtonsMenu = ({ lesson, index, setCurrent, idLesson, user }) => {
  //Destructuring del idLeccion
  const { idLeccion } = idLesson;
  const dispatch = useDispatch();
  //Se obtiene la información del store-Redux
  const { idEntidad } = useSelector((state) => state.entidad);
  const { ultimaLeccion } = useSelector((state) => state.leccion);
  const { loading } = useSelector((state) => state.bloquear);
  //Hook para mostrar/ocultar el modal de la calificación
  //const [isVisibleModal, setIsVisibleModal] = useState(false);
  //Hook para almacenar la url correspondiente a la entidad y lección
  const [url, setUrl] = useState({});

  //Funciones para abrir y cerra el modal
  const openModal = () => dispatch(openModalAction(true));
  const closeModal = () => dispatch(closeModalAction());

  useEffect(() => {
    //Función que obtiene la url del cuestionario correspondiente
    getCuestionario(idEntidad, idLeccion)
      .then((res) => {
        setUrl(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idLeccion]);

  //Función que brira la pestaña de cuestionarios
  const sendQuiz = () => {
    dispatch(closeModalAction()) //Se oculta el modal
    //Se manda el url + usuario en base64
    url.url && window.open(url.url + "_~" + setBase64(user), "_blank"); //Se abre en una nueva pestaña
  };

  //Función que retorna una cadena en base64
  const setBase64 = (data) => {
    let buff = new Buffer(data);
    return buff.toString("base64");
  };

  //Componente que muestra los botones de navegación hacia atras y adelante
  const ButtonsNavigation = ({ visible = false }) => {
    return (
      <>
        {index > 0 && (
          <Button
            type="default"
            onClick={() => setCurrent(index - 1)}
            style={{ width: "auto" }}
            disabled={idLeccion === 10 ? false : loading}
          >
            Ver lección anterior
          </Button>
        )}
        {visible && index < ultimaLeccion && (
          <Button
            type="primary"
            onClick={() => setCurrent(index + 1)}
            style={{ width: "auto" }}
            disabled={loading}
          >
            Siguiente
          </Button>
        )}
      </>
    );
  };

  //Función que muestra el texto correspondiente en los botones de contestar las lecciones
  const getText = (idLesson) => {
    switch (idLesson) {
      case ACT_FINAL:
        return "Contestar evaluación final";
      case ACT_VALORACION:
        return "Contestar valoración del curso";
      default:
        return `Contestar Autoevaluación Lección ${index + 1}`;
    }
  };

  return (
    <>
      <ModalOnline
        //isOpen={isVisibleModal}
        close={closeModal}
        idLeccion={idLeccion}
        setCurrent={setCurrent}
        index={index}
        sendQuiz={sendQuiz}
        url={url.url}
      />
      
      <p>
      <Icon type="info-circle" style={{ fontSize: '16px', color: '#db2491' }} theme="outlined"  /> En caso de encontrase en la actividad de Autoevaluación, si <strong>NO</strong> deseas <strong>CONTINUAR</strong> en ese momento con la actividad, será indispensable que cierres sesión en la página de Autoevaluación, seleccionando el botón <strong>“Cerrar Sesión”</strong> el cual se encuentra en la parte superior derecha, posteriormente deberás cerrar la pestaña, si así lo deseas, podrás regresar a la pestaña del Portal de Observadores. 
      </p>

      {index === ultimaLeccion ? (
        <>
          {/* Botones para regresar hacia atras y adelante */}
          {idLeccion !== 11 && <ButtonsNavigation />}

          <Button
            type="primary"
            style={{ width: "auto" }}
            onClick={(e) => {
              sendQuiz();
            }}
          >
            {getText(idLeccion)}
          </Button>

          {idLeccion === 11 && (
            <Link to={{ pathname: "/ciudadano/inicio" }}>
              {" "}
              <Button
                style={{ width: 210, marginTop: 7, marginBottom: 30 }}
                type="primary"
              >
                Finalizar Curso
              </Button>
            </Link>
          )}
          {index < lesson.length - 1 && (
            <Button
              type="primary"
              onClick={() => openModal()}
              style={{ width: "auto" }}
            >
              Mostrar calificación
            </Button>
          )}
        </>
      ) : (
        <>
          {/* Botones para regresar hacia atras y adelante */}
          <ButtonsNavigation visible={true} />
        </>
      )}
    </>
  );
};
