import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom"
import { Input, Table } from "antd";

function TableParticipantes(props) {
    const {
        data,
        loading
    } = props;
    const [dataSource, setDataSource] = useState([]); //hook para almacenar el array
    const [filtroNombre, setFiltroNombre] = useState("");
    const [filtroClaveElector, setFiltroClaveElector] = useState("");
    const [filtroFolio, setFiltroFolio] = useState("");

    useEffect(() => {
        setDataSource(data);
    }, [data]);

    const FilterByNombre = (
        <>
            Nombre
            <Input
                placeholder="Nombre"
                value={filtroNombre}
                onChange={(e) => {
                    const currValue = e.target.value;
                    setFiltroNombre(currValue);
                    const filteredData = data.filter((entry) =>
                        entry.nombreObservador.toLowerCase().includes(currValue.toLowerCase())
                        && entry.claveElector.toLowerCase().includes(filtroClaveElector.toLowerCase())
                        && entry.folio.toLowerCase().includes(filtroFolio.toLowerCase())
                    );
                    setDataSource(filteredData);
                }}
            />
        </>
    );

    const FilterByClaveElector = (
        <>
            Clave de elector
            <Input
                placeholder="Clave de elector"
                value={filtroClaveElector}
                onChange={(e) => {
                    const currValue = e.target.value;
                    setFiltroClaveElector(currValue);
                    const filteredData = data.filter((entry) =>
                        entry.claveElector.toLowerCase().includes(currValue.toLowerCase())
                        && entry.nombreObservador.toLowerCase().includes(filtroNombre.toLowerCase())
                        && entry.folio.toLowerCase().includes(filtroFolio.toLowerCase())
                    );
                    setDataSource(filteredData);
                }}
            />
        </>
    );

    const FilterByFolio = (
        <>
            Folio
            <Input
                placeholder="Folio"
                value={filtroFolio}
                onChange={(e) => {
                    const currValue = e.target.value;
                    setFiltroFolio(currValue);
                    const filteredData = data.filter((entry) =>
                        entry.folio.toLowerCase().includes(currValue.toLowerCase())
                        && entry.claveElector.toLowerCase().includes(filtroClaveElector.toLowerCase())
                        && entry.nombreObservador.toLowerCase().includes(filtroNombre.toLowerCase())
                    );
                    setDataSource(filteredData);
                }}
            />
        </>
    );

    //Se definen las columnas
    const columnas = [
        {
            title: FilterByNombre,
            dataIndex: "nombreObservador",
        },
        {
            title: FilterByClaveElector,
            dataIndex: "claveElector",
        },
        {
            title: FilterByFolio,
            dataIndex: "folio",
        }
    ];

    const rowSelection = {
        onSelectAll: (selected, selectedRows) => {
            const datos = [];
            if(selectedRows.length > 0) { //Seleccionaron todos
                for (let i = 0; i < dataSource.length; i++) {
                    dataSource[i].asistencia = true;
                    datos.push(dataSource[i]);
                };
            } else {
                for (let i = 0; i < dataSource.length; i++) {
                    dataSource[i].asistencia = false;
                    datos.push(dataSource[i]);
                };
            }
            setDataSource(datos);
        },
        onSelect: (record) => {
            const datos = [];
            for (let i = 0; i < dataSource.length; i++) {
                if (dataSource[i].idObservador === record.idObservador) {
                    let value = dataSource[i].asistencia;
                    dataSource[i].asistencia = !value;
                }
                datos.push(dataSource[i]);
            };
            setDataSource(datos);
        },
        getCheckboxProps: record => ({
            checked: record.asistencia === true,
        }),
    };

    return (
        <>
            <Table rowSelection={rowSelection}
                columns={columnas} dataSource={dataSource}
                loading={loading} locale={{ emptyText: "Sin registros" }}
                scroll={{ y:true }}
            />
        </>
    );
}

export default withRouter(TableParticipantes);
