import React, {useEffect} from "react";
import { Col, Row } from "antd";
import TabsNor from "../Portal/TabsNor";
import Back from "../../../components/User/Public/Home/Back";

const Normatividad = () => {
  function toTop() {
    window.scrollTo(0, 0);
}

  useEffect(() => {
    toTop();
  }, []);
  
  return (
    <div style={{ marginTop: "20px" }}>
      <Back />
      <br />
      <br />
      <br />
      <Row>
        <Col span={14}>
          <h1>Normatividad</h1>
          <p>
            El proceso de acreditación y la observación electoral en México se
            sustentan en las leyes de la Ley General de Instituciones y
            Procedimientos Electorales (LGIPE), así como en los artículos del
            Reglamento de Elecciones del INE (RE).
          </p>
        </Col>
        <Col span={10}></Col>
      </Row>
      <Row>
        <Col span={14}>
          <TabsNor />
        </Col>
        <Col span={10}></Col>
      </Row>
    </div>
  );
};

export default Normatividad;
