//Types para llevar el control de las acciones y reducer
export const types = {
  entidades: "[Curso] Entidades",
  limpiarEntidad: "[Curso] Limpiar Entidades",

  entidad: "[Curso] Entidad-Seleccionada",
  limpiarEntidadSel: "[Curso] Limpiar Entidad-Seleccionada",
  pantallaMaterial: "[Curso] Pantalla-Material",
  entidadMaterial: "[Curso] Entidad-Material",
  temarioMaterial: "[Curso] Temario-Material",
  limEntidadMat: "[Curso] Limpiar Entidad-Material",

  ultimaLeccion: "[Curso] Última lección",
  limpiarultimaLeccion: "[Curso] Limpiar Última lección",

  temario: "[Temario] Agregar-temario",
  limpiarTemario: "[Temario] limpiar-temario",

  constancia: "[Curso] Buscar constancia",
  limConstancia: "[Curso] Inicializar constancia",

  bloquear: "[Curso] bloquear botones",
  modal: "[Curso] Abrir modad",
  cerraModal: "[Curso] Cerrar modad",
};
