import { Modal, Alert } from "antd";
import React from "react";
import { save, getObservations } from "../../api/validation";
import {
  OBS_CRED_FRENTE,
  OBS_CRED_VUELTA,
  OBS_FOTOGRAFIA,
  OBS_DIF_PERSONA,
  OBS_NOMBRE,
  OBS_CLAVE_ELEC,
  OBS_DIRECCION,
} from "../constanst";

////////////////////////// ENVIO DE OBSERVACIONES ///////////////////////////////////////
export function modal(screen, setScreen, datos, history) {
  const { solicitud } = datos[0];
  Modal.confirm({
    title: null,
    keyboard: false,
    content: solicitud === "success" ? contentValidate() : contentObs(),
    icon: null,
    width: 500,
    okText:
      solicitud === "success" ? "Validar solicitud" : "Enviar observaciones",
    okButtonProps: { style: { width: 200 } },
    cancelText: "Cancelar",
    cancelButtonProps: { className: "ant-btn-default" },
    onOk() {
      saveRequest(screen, setScreen, datos, history);
    },
    onCancel() {
      setScreen({ ...screen, image: false, btn: false });
    },
  });
}

//Función que guarda las observaciones de la solicitud
function saveRequest(screen, setScreen, datos, history) {
  save(datos)
    .then((res) => {
      if (res.data.code === 200) {
        Modal.success({
          title: "Estado solicitud",
          content: <p>{res.data.message}</p>,
          okText: "Aceptar",
          onOk() {
            history.push("/admin/validaciones"); //Redirecciona a la pantalla principal
          },
        });
      } else {
        Modal.error({
          title: "Error",
          content: <p>{res.data.causa}</p>,
          onOk() {
            setScreen({ ...screen, image: false, btn: false });
          },
        });
      }
    })
    .catch((error) => {
      errorRequest();
    });
}

export const errorRequest = () => {
  Modal.error({
    title: "Error",
    content: "Error al consumir el servicio web.",
    okText: "Aceptar",
    onOk() {
      //window.location.reload();
    },
  });
};

//Función que valida que todas las preguntas esten en true
export function validate(datos) {
  const { foto, fotoIgual, nombres, clave, direccion } = datos;

  return foto && fotoIgual && nombres && clave && direccion;
}
//Retorna el texto de acuerdo de donde se obtuvo la solicitud
export function getByOrigen(idOrigen) {
  let origen;
  switch (idOrigen) {
    case 1:
      origen = "INE";
      break;
    case 2:
      origen = "OPL";
      break;
    case 3:
      origen = "Portal Público";
      break;
    case 4:
      origen = "Junta";
      break;
    default:
      break;
  }
  return origen;
}

///////////////// OBTENER OBSERVACIONES /////////////////////
export function getObservation(id, setDatos, setDisabled) {
  getObservations(id)
    .then((res) => {
      setObservation(res.data, setDatos, setDisabled);
    })
    .catch((error) => {
      console.log(error);
      //errorRequest();
    });
}
//Función que obtiene las observación para consultar
function setObservation(observaciones, setDatos, setDisabled) {
  let frente, vuelta, foto, fotoIgual, nombre, clave, direccion;
  let obsNombre, obsClave, obsDirec;
  // eslint-disable-next-line array-callback-return
  observaciones.some((v) => {
    if (v.idObservacion === OBS_CRED_FRENTE) {
      frente = v.estatus;
    }
    if (v.idObservacion === OBS_CRED_VUELTA) {
      vuelta = v.estatus;
    }
    if (v.idObservacion === OBS_FOTOGRAFIA) {
      foto = v.estatus;
    }
    if (v.idObservacion === OBS_DIF_PERSONA) {
      fotoIgual = v.estatus;
    }
    if (v.idObservacion === OBS_NOMBRE) {
      nombre = v.estatus;
      obsNombre = v.observaciones;
    }
    if (v.idObservacion === OBS_CLAVE_ELEC) {
      clave = v.estatus;
      obsClave = v.observaciones;
    }
    if (v.idObservacion === OBS_DIRECCION) {
      direccion = v.estatus;
      obsDirec = v.observaciones;
    }
  });
  block(setDisabled); //Se bloquean los campos
  setDatos({
    frente: frente !== undefined ? frente : true,
    vuelta: vuelta !== undefined ? vuelta : true,
    foto: foto !== undefined ? foto : true,
    fotoIgual: fotoIgual !== undefined ? fotoIgual : true,
    nombres: nombre !== undefined ? nombre : true,
    nombresObs: obsNombre,
    clave: clave !== undefined ? clave : true,
    claveObs: obsClave,
    direccion: direccion !== undefined ? direccion : true,
    direccionObs: obsDirec,
  });
}
//Obtiene las observaciones atendidas
export function getObsAtten(id, setDatos, setUpdate, setDisabled, setAlert) {
  getObservations(id)
    .then((res) => {
      setObsAtten(res.data, setDatos, setUpdate, setDisabled, setAlert);
    })
    .catch((error) => {
      console.log(error);
      //errorRequest();
    });
}

//Función que obtiene las observaciones y habilita las preguntas que esten con estatus false
function setObsAtten(obs, setDatos, setUpdate, setDisabled, setAlert) {
  let frente, vuelta, foto, fotoIgual, nombre, clave, direccion;
  // eslint-disable-next-line array-callback-return
  obs.some((v) => {
    if (v.idObservacion === OBS_CRED_FRENTE) {
      frente = v.estatus;
    }
    if (v.idObservacion === OBS_CRED_VUELTA) {
      vuelta = v.estatus;
    }
    if (v.idObservacion === OBS_FOTOGRAFIA) {
      foto = v.estatus;
    }
    if (v.idObservacion === OBS_DIF_PERSONA) {
      fotoIgual = v.estatus;
    }
    if (v.idObservacion === OBS_NOMBRE) {
      nombre = v.estatus;
    }
    if (v.idObservacion === OBS_CLAVE_ELEC) {
      clave = v.estatus;
    }
    if (v.idObservacion === OBS_DIRECCION) {
      direccion = v.estatus;
    }
  });
  //Variables para validar si paso la primer pantalla
  let front = frente || frente === undefined ? true : false;
  let back = vuelta || vuelta === undefined ? true : false;

  if (!front || !back) {
    //console.log("Los documentos no estan legibles - Primera pantalla");
    setUpdate(true);
    setDatos({
      frente: frente || frente === undefined ? true : undefined,
      vuelta: vuelta || vuelta === undefined ? true : undefined,
      foto: foto || foto === undefined ? true : undefined,
    });
    setDisabled({
      frente: frente === undefined || frente ? true : false,
      vuelta: vuelta === undefined || vuelta ? true : false,
      foto: foto === undefined || foto ? true : false,
    });
    setAlert({
      frente: frente === undefined || frente ? false : true,
      vuelta: vuelta === undefined || vuelta ? false : true,
      foto: foto === undefined || foto ? false : true,
    });
  } else {
    //console.log("No tiene observaciones en esta pantalla");
    setUpdate(false);
    setDatos({
      //Se asignan los valores a cada pregunta de los radios
      frente: true,
      vuelta: true,
      foto: foto === undefined || foto ? true : undefined,
      fotoIgual: fotoIgual === undefined || fotoIgual ? true : undefined,
      nombres: nombre === undefined || nombre ? true : undefined,
      clave: clave === undefined || clave ? true : undefined,
      direccion: direccion === undefined || direccion ? true : undefined,
    });
    setDisabled({
      //Se bloquean las preguntas de acuerdo al estatus obtenido
      frente: true,
      vuelta: true,
      foto: foto === undefined || foto ? true : false,
      fotoIgual: fotoIgual === undefined || fotoIgual ? true : false,
      nombres: nombre === undefined || nombre ? true : false,
      clave: clave === undefined || clave ? true : false,
      direccion: direccion === undefined || direccion ? true : false,
    });
    setAlert({
      //Se muestra la alerta en caso de que la observacion aparezca en false
      frente: false,
      vuelta: false,
      foto: foto === undefined || foto ? false : true,
      fotoIgual: fotoIgual === undefined || fotoIgual ? false : true,
      nombres: nombre === undefined || nombre ? false : true,
      clave: clave === undefined || clave ? false : true,
      direccion: direccion === undefined || direccion ? false : true,
    });
  }
  // }
}
//////////////////////// GENERACIÓN DE JSON //////////////////////////////////
export function jsonObs(datos) {
  const json = [
    {
      idEstatus: datos.idEstatus, //Estatus del observador
      solicitud: datos.solicitud, //bandera del estatus de la solicitud muestra error/success
      idObservador: datos.idObservador,
      capacitacion: datos.capacitacion, //Obtiene el modo de capacitacion que selecciono el observador
      usuario: datos.usuario, //usuario que realiza la validación
      observaciones: [
        //JsonArray de las 3 observaciones de la primera pantalla
        {
          idDocumento: OBS_CRED_FRENTE,
          estatus: datos.frente,
          observacion: "",
        },
        {
          idDocumento: OBS_CRED_VUELTA,
          estatus: datos.vuelta,
          observacion: "",
        },
        {
          idDocumento: OBS_FOTOGRAFIA,
          estatus: datos.foto,
          observacion: "",
        },
      ],
    },
  ];
  return json;
}
//Se genera el json cuando se llega al final de la pantalla de las preguntas
export function generaJson(datos) {
  const json = [
    {
      idEstatus: datos.idEstatus, //Estatus del observador
      solicitud: datos.solicitud, //bandera del estatus de la solicitud muestra error/success
      idObservador: datos.idObservador,
      capacitacion: datos.capacitacion, //Obtiene el modo de capacitacion que selecciono el observador
      usuario: datos.usuario, //usuario que realiza la validación
      observaciones: [
        //JsonArray de las 8 observaciones
        {
          idDocumento: OBS_CRED_FRENTE,
          estatus: datos.frente,
          observacion: "",
        },
        {
          idDocumento: OBS_CRED_VUELTA,
          estatus: datos.vuelta,
          observacion: "",
        },
        {
          idDocumento: OBS_FOTOGRAFIA,
          estatus: datos.fotoIgual === true ? datos.foto : false,
          observacion: "",
        },
        {
          idDocumento: OBS_DIF_PERSONA,
          estatus: datos.foto === true ? datos.fotoIgual : false,
          observacion: "",
        },
        {
          idDocumento: OBS_NOMBRE,
          estatus: datos.nombres,
          observacion: datos.nombresObs,
        },
        {
          idDocumento: OBS_CLAVE_ELEC,
          estatus: datos.clave,
          observacion: datos.claveObs,
        },
        {
          idDocumento: OBS_DIRECCION,
          estatus: datos.direccion,
          observacion: datos.direccionObs,
        },
      ],
    },
  ];
  return json;
}
//Solo se agregan las observaciones que se encuentres deshabilitadas
export function generateJsonAttend(datos, disabled, update) {
  let array = []; //Array para almacenar las observaciones
  //Se la pregunta esta habilitada la agrega al array
  if (!disabled.frente) {
    array.push({
      idDocumento: OBS_CRED_FRENTE,
      estatus: datos.frente,
      observacion: "",
    });
  }

  if (!disabled.vuelta) {
    array.push({
      idDocumento: OBS_CRED_VUELTA,
      estatus: datos.vuelta,
      observacion: "",
    });
  }

  if (!disabled.foto) {
    array.push({
      idDocumento: OBS_FOTOGRAFIA,
      estatus: datos.fotoIgual === true ? datos.foto : false,
      observacion: "",
    });
  }

  if (!disabled.fotoIgual) {
    array.push({
      idDocumento: OBS_DIF_PERSONA,
      estatus: datos.foto === true ? datos.fotoIgual : false,
      observacion: "",
    });
  }

  if (!disabled.nombres) {
    array.push({
      idDocumento: OBS_NOMBRE,
      estatus: datos.nombres,
      observacion: datos.nombresObs,
    });
  }

  if (!disabled.clave) {
    array.push({
      idDocumento: OBS_CLAVE_ELEC,
      estatus: datos.clave,
      observacion: datos.claveObs,
    });
  }
  if (!disabled.direccion) {
    array.push({
      idDocumento: OBS_DIRECCION,
      estatus: datos.direccion,
      observacion: datos.direccionObs,
    });
  }

  const json = [
    {
      idEstatus: datos.idEstatus, //Estatus del observador
      solicitud: datos.solicitud, //bandera del estatus de la solicitud muestra error/success
      idObservador: datos.idObservador,
      capacitacion: datos.capacitacion, //Obtiene el modo de capacitacion que selecciono el observador
      usuario: datos.usuario, //usuario que realiza la validación
      modificar: update, //Bandera que indica si es el caso de que solo haya registrado las observaciones de la primera pantalla
      observaciones: array,
    },
  ];
  return json;
}
//Json que quenera en el primer caso de la pantalla al solo registrar las 3 primeras preguntas
export function jsonAttend(datos, disabled, update) {
  let array = [];
  if (!disabled.frente) {
    array.push({
      idDocumento: OBS_CRED_FRENTE,
      estatus: datos.frente,
      observacion: "",
    });
  }

  if (!disabled.vuelta) {
    array.push({
      idDocumento: OBS_CRED_VUELTA,
      estatus: datos.vuelta,
      observacion: "",
    });
  }

  if (!disabled.foto) {
    array.push({
      idDocumento: OBS_FOTOGRAFIA,
      estatus: datos.foto,
      observacion: "",
    });
  }

  const json = [
    {
      idEstatus: datos.idEstatus,
      solicitud: datos.solicitud,
      idObservador: datos.idObservador,
      capacitacion: datos.capacitacion,
      usuario: datos.usuario,
      modificar: update,
      observaciones: array,
    },
  ];
  return json;
}
//Retorna un json con las observaciones validadas
//Se ejecuta cuando el estatus del observador es validado
export function jsonValidado(setDisabled) {
  const json = {
    clave: true,
    direccion: true,
    foto: true,
    fotoIgual: true,
    frente: true,
    nombres: true,
    vuelta: true,
  };
  block(setDisabled); //Se bloaquean todas las preguntas
  return json;
}
////////////// BLOQUEAR LAS PREGUNTAS ///////////////////////////
function block(setDisabled) {
  setDisabled({
    frente: true,
    vuelta: true,
    foto: true,
    fotoIgual: true,
    nombres: true,
    nombresObs: true,
    clave: true,
    claveObs: true,
    direccion: true,
    direccionObs: true,
  });
}
/////////////// CONTENIDO DE MENSAJES ///////////////////////////
const contentObs = () => {
  return (
    <div>
      <p>Se enviarán las observaciones a la solicitud.</p>
      <p>¿Deseas continuar?</p>
    </div>
  );
};

const contentValidate = () => {
  return (
    <div>
      <p>La solicitud será validada.</p>
      <p>¿Deseas continuar?</p>
    </div>
  );
};

export const AlertInfo = (props) => {
  const { disable } = props;
  return (
    <Alert
      message="Datos corregidos por el ciudadano."
      type="info"
      showIcon
      style={disable ? {} : { display: "none" }}
    />
  );
};

export const AlertForeig = ({ text }) => {
  return <Alert message={text} type="info" showIcon />;
};

export const Ast = () => {
  return <span style={{ color: "#f5222d" }}>*</span>;
};

export const seccionElectoral = (seccion) => {
  if (seccion.toString().length < 4) {
    let faltanteCP = 4 - seccion.toString().length;
    let finalSeccion = seccion.toString();
    for (let i = 0; i < faltanteCP; i++) {
      finalSeccion = "0" + finalSeccion;
    }
    return finalSeccion;
  }
  return seccion;
};

export const addCp = (cp) => {
  if (cp) {
    if (cp.toString().length < 5) {
      let faltanteCP = 5 - cp.toString().length;
      let finalCP = cp.toString();
      for (let i = 0; i < faltanteCP; i++) {
        finalCP = "0" + finalCP;
      }
      return finalCP;
    }
    return cp;
  }
  return "";
};
