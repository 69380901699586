export const apellidos = [
  {
    message: "Caracteres inválidos.",
    pattern: /^[a-zA-Z-áéíóúÁÉÍÓÚÑñ]+[^0-9{}\\_!"´#$%&()=?/¡*'¨°|;,<>-]+$/,
  },
  { validateStatus: "error" },
];

export const nombres = [
  {
    message: "Caracteres inválidos.",
    pattern: /^[a-zA-Z-äëïöüÄËÏÖÜáéíóúÁÉÍÓÚÑñ]+[^0-9{}\\_!"´#$%&()=?/¡*'¨°|;,<>-]+$/,
  },
  { required: true, message: "Dato requerido." },
  { validateStatus: "error" },
  ];

export const calleCol = [
  {
    message: "Caracteres inválidos.",
    pattern: /^[a-zA-Z-áéíóúüÁÉÍÓÚÜ0-9]+[^\\_!"$%&()=?¡*'¨°|<>]+$/,
  },
  { required: true, message: "Dato requerido." },
  { validateStatus: "error" },
];

export const numero = [
  {
    message: " ",
    pattern: /^[a-zA-Z-áéíóúüÁÉÍÓÚÜ0-9]+[^\\_!"$%&()=?¡*'¨°|<>]+$/,
  },
  { validateStatus: "error" },
  ];

export const cp = [
  {
    message: "Código postal inválido.",
    pattern: /^[0-9]+$/,
  },
  { required: true, message: "Dato requerido." },
  { validateStatus: "error" },
  { min: 5, message: "Código postal inválido." },
];

export const required = [{ required: true, message: "Dato requerido" }];

export const txtTexto = /^[a-zA-Z-áéíóúÁÉÍÓÚÑñäëïöü\s]+$/i;
export const numeroInt = /^[a-zA-Z-äëïöüÄËÏÖÜáéíóúÁÉÍÓÚÑñ0-9,;#'/\s.]*$/;
export const soloDigitos = /^\d*$/;