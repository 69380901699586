import React, { useEffect, useState } from "react";
import { Row, Icon, Col, Button } from "antd";
import { withRouter, Link } from "react-router-dom";
import { getReporteDetalleSolicitudes } from "../../../../api/reports";
import TableDetailRequests from "../../../../components/Admin/Reports/TableDetailRequests";
import { rol_jl_reporte_solicitudes } from "../../../../utils/Reports/roles";
import useAuth from "../../../../hooks/useAuth";
import { saveExcelDetalleSolicitudes} from "../../../../api/xls";
import moment from "moment";
import "../../Reports/Reports.scss";

function Requests(props) {
    const { proceso } = props;
    const { registroTabla, etiqueta } = props.location;
    const [ reporteDetalleSolicitudes, setreporteDetalleSolicitudes] = useState({
        info: [],
        loading: false,
    });
    const [ nombreEstadoEtiqueta, setNombreEstadoEtiqueta] = useState([]);
    const {
        user: { rolUsuario },
    } = useAuth();
    const [ usuarioJL, setUsuarioJL] = useState([]);

    useEffect(() => {
        setUsuarioJL(rol_jl_reporte_solicitudes.includes(rolUsuario));
        setreporteDetalleSolicitudes({ loading: true }); //Se habilita el componente de loading
        if(registroTabla !== undefined && registroTabla !== null) {
            //Viene registro de tabla del reporte
            const idProceso = proceso.proceso.idProcesoElectoral;
            const idDetalle = proceso.proceso.idDetalleProceso;
            const idEstado = registroTabla.idEstado;
            if(registroTabla.idDistrito !== undefined && registroTabla.idDistrito !== null) {
   //             if(registroTabla.idDistrito !== 0) {
                    const idDistritoFed = registroTabla.idDistrito;
                    getReporteDetalleSolicitudes(idProceso, idDetalle, idEstado, idDistritoFed).then((res) => {
                        const dataSource = res.data;
                        setreporteDetalleSolicitudes({ info: dataSource, loading: false });
                    }).catch((error) => {
                        console.log(error);
                    });
           /*     } else {
                    getReporteDetalleSolicitudes(idProceso, idDetalle, idEstado).then((res) => {
                        const dataSource = res.data;
                        setreporteDetalleSolicitudes({ info: dataSource, loading: false });
                    }).catch((error) => {
                        console.log(error);
                    });
                }*/
            } else {
                getReporteDetalleSolicitudes(idProceso, idDetalle, idEstado,null).then((res) => {
                    const dataSource = res.data;
                    setreporteDetalleSolicitudes({ info: dataSource, loading: false });
                }).catch((error) => {
                    console.log(error);
                });
            }
        } else {
            //Carga info dependiendo del menú geográfico o el valor de la etiqueta (navegacion)
            if(etiqueta !== undefined && etiqueta !== null) {
                const idProceso = proceso.proceso.idProcesoElectoral;
                const idDetalle = proceso.proceso.idDetalleProceso;
                const idEstado = etiqueta;
                getReporteDetalleSolicitudes(idProceso, idDetalle, idEstado,null).then((res) => {
                    const dataSource = res.data;
                    setreporteDetalleSolicitudes({ info: dataSource, loading: false });
                    setNombreEstadoEtiqueta(res.data[0].nombreEstado);
                }).catch((error) => {
                    console.log(error);
                });
            } else {
                if(proceso.distrito !== undefined && proceso.distrito !== null) {
                    if(proceso.distrito.idDistrito !== 0) {
                        const idProceso = proceso.proceso.idProcesoElectoral;
                        const idDetalle = proceso.proceso.idDetalleProceso;
                        const idEstado = proceso.estado.idEstado;
                        const idDistritoFed = proceso.distrito.idDistrito;
                        getReporteDetalleSolicitudes(idProceso, idDetalle, idEstado, idDistritoFed).then((res) => {
                            const dataSource = res.data;
                            setreporteDetalleSolicitudes({ info: dataSource, loading: false });
                        }).catch((error) => {
                            console.log(error);
                        });
                    } else {
                        const idProceso = proceso.proceso.idProcesoElectoral;
                        const idDetalle = proceso.proceso.idDetalleProceso;
                        const idEstado = proceso.estado.idEstado;
                        getReporteDetalleSolicitudes(idProceso, idDetalle, idEstado,null).then((res) => {
                            const dataSource = res.data;
                            setreporteDetalleSolicitudes({ info: dataSource, loading: false });
                        }).catch((error) => {
                            console.log(error);
                        });
                    }
                } else if(proceso.proceso) {
                    let idProceso = proceso.proceso.idProcesoElectoral;
                    let idDetalle = proceso.proceso.idDetalleProceso;
                    let idEstado = 0;
                    if(proceso.estado !== undefined && proceso.estado !== null) {
                        idEstado = proceso.estado.idEstado;
                    }
                    getReporteDetalleSolicitudes(idProceso, idDetalle, idEstado,null).then((res) => {
                        const dataSource = res.data;
                        setreporteDetalleSolicitudes({ info: dataSource, loading: false });
                    }).catch((error) => {
                        console.log(error);
                    });
                }
            }
        }
        //La función useEffect se ejecutará cada vez que una de estas variables cambie su valor - U
        // eslint-disable-next-line
    }, [proceso, registroTabla, etiqueta]);

    //const EtiquetaVinculo = () => {
    function EtiquetaVinculo() {
        if(registroTabla !== undefined && registroTabla !== null) {
            if(registroTabla.idDistrito !== undefined && registroTabla.idDistrito !== null) {
                const nombreEstado = registroTabla.nombreEstado;
                const idEstadoEtiqueta = registroTabla.idEstado;
                if(registroTabla.idDistrito !== 0) {
                    const nombreDistrito = registroTabla.distrito;
                    if(usuarioJL) {
                        return (
                            <div>Nacional | <Link to={{ pathname:"/admin/reportes/detalleSolicitudes",
                                etiqueta:idEstadoEtiqueta}}>{ nombreEstado }</Link> | { nombreDistrito }</div>
                        );
                    } else {
                        return (
                            <div><Link to={{ pathname:"/admin/reportes/detalleSolicitudes",
                                etiqueta:0}}>Nacional</Link> | <Link to={{ pathname:"/admin/reportes/detalleSolicitudes",
                                etiqueta:idEstadoEtiqueta}}>{ nombreEstado }</Link> | { nombreDistrito }</div>
                        );
                    }
                } else {
                    if(usuarioJL) {
                        return (
                            <div>Nacional | <Link to={{ pathname:"/admin/reportes/detalleSolicitudes",
                                etiqueta:idEstadoEtiqueta}}>{ nombreEstado }</Link> | JUNTA LOCAL </div>
                        );
                    } else {
                        return (
                            <div><Link to={{ pathname:"/admin/reportes/detalleSolicitudes",
                                etiqueta:0}}>Nacional</Link> | <Link to={{ pathname:"/admin/reportes/detalleSolicitudes",
                                etiqueta:idEstadoEtiqueta}}>{ nombreEstado }</Link> | JUNTA LOCAL </div>
                        );
                    }
                }
            } else if(registroTabla.idEstado !== undefined && registroTabla.idEstado !== null) {
                if(registroTabla.idEstado !== 0) {
                    const nombreEstado = registroTabla.nombreEstado;
                    return (
                        <div><Link to={{ pathname:"/admin/reportes/detalleSolicitudes",
                            etiqueta:0}}>Nacional</Link> | { nombreEstado }</div>
                    );
                } else {
                    return (
                        <div>Nacional</div>
                    );
                }
            } else {
                return (
                    <div>Nacional</div>
                );
            }
        } else {
            if(etiqueta !== undefined && etiqueta !== null) {
                if(etiqueta !== 0) {
                    if(usuarioJL) {
                        return (
                            <div>Nacional | { nombreEstadoEtiqueta }</div>
                        );
                    } else {
                        return (
                            <div><Link to={{ pathname:"/admin/reportes/detalleSolicitudes",
                                etiqueta:0}}>Nacional</Link> | { nombreEstadoEtiqueta }</div>
                        );
                    }
                } else {
                    return (
                        <div>Nacional</div>
                    );
                }
            } else {
                if(proceso.distrito !== undefined && proceso.distrito !== null) {
                    const nombreEstado = proceso.estado.nombreEstado;
                    if(proceso.distrito.idDistrito !== 0) {
                        const nombreDistrito = proceso.distrito.idDistrito + '. '+ proceso.distrito.nombreDistrito;
                        return (
                            <div>Nacional | { nombreEstado } | { nombreDistrito }</div>
                        );
                    } else {
                        return (
                            <div>Nacional | { nombreEstado }</div>
                        );
                    }
                } else if(proceso.estado !== undefined && proceso.estado !== null) {
                    if(proceso.estado.idEstado !== 0) {
                        const nombreEstado = proceso.estado.nombreEstado;
                        return (
                            <div>Nacional | { nombreEstado }</div>
                        );
                    } else {
                        return (
                            <div>Nacional</div>
                        );
                    }
                } else {
                    return (
                        <div>Nacional</div>
                    );
                }
            }
        }
    }

    const downloadExcel = () => {
        
        saveExcelDetalleSolicitudes("Detalle Solicitudes", "Reporte de detalle de solicitudes", reporteDetalleSolicitudes.info)
    }

    function validaBoton() {
        var band = true;
        if(reporteDetalleSolicitudes.info !== undefined && reporteDetalleSolicitudes.info !== null) {
            if(reporteDetalleSolicitudes.info.length > 0) {
                band = false;
                return band;
            } else
                return band;
        }
        return band;
    }

    const UltimaActualizacion = () => {
        const fechaHora = new Date();
        moment.locale("es");
        const fecha = moment(fechaHora).format("DD/MMMM/YYYY");
        const hora = moment(fechaHora).format("HH:mm:ss");
        return (
            <div style={{ fontSize:"12px", position:"absolute", marginTop:"20px", right:"0" }}>
                <Icon type="calendar" /> {fecha} <Icon type="clock-circle" /> {hora}
            </div>
        );
    }

    const SolicitudesRec = () => {
        var total = 0;
        if(reporteDetalleSolicitudes.info !== undefined && reporteDetalleSolicitudes.info !== null &&
                reporteDetalleSolicitudes.info.length > 0) {
            if(reporteDetalleSolicitudes.info[0].idDistrito !== undefined && reporteDetalleSolicitudes.info[0].idDistrito !== null) {
                total = reporteDetalleSolicitudes.info.length;
            } else {
               reporteDetalleSolicitudes.info.forEach((e) => {
                    total += e.total;
                });
            }
        }else{ total = "" }
        return (
            <div style={{ position:"absolute", right:"0", fontSize:"24px", fontWeight:"bold" }}>
                {total}
            </div>
        );
    }

    return(
        <div>
            <Row>
                <Link to={"/admin/reportes"} className="back">
                    <Icon type="play-circle" theme="filled" rotate="180" /> Volver a 
                    "Reportes"
                </Link>
            </Row>
            <Row style={{ marginTop:"20px"}}>
                <Col span={12}>
                    <h2>Detalle de solicitudes</h2>
                </Col>
                <Col span={12}>
                    <div style={{ float:"right", fontWeight:"bold", fontSize:"12px" }}>
                        Última actualización
                    </div>
                    <UltimaActualizacion />
                </Col>
            </Row>
            <Row style={{ marginTop:"10px"}}>
                <Col span={12}>
                    <EtiquetaVinculo />
                </Col>
                <Col span={12}>
                    <SolicitudesRec />
                </Col>
            </Row>
            <Row>
                <Col span={12}>
                    <div style={{ fontWeight:"bold", fontSize:"14px" }}>
                        Descarga el reporte:
                        <Button onClick={() => downloadExcel()} className="btn_descarga_reporte" type="link"
                                disabled={validaBoton()}>
                            <Icon type="file-excel" />Excel
                        </Button>
                    </div>
                </Col>
                <Col span={12}>
                    <div style={{ position:"absolute", right:"0" }}>
                        Solicitudes recibidas
                    </div>
                </Col>
            </Row>
            <Row style={{ marginTop:"5px" }}>
                <TableDetailRequests data={reporteDetalleSolicitudes.info}
                    loading={reporteDetalleSolicitudes.loading}
                    proceso={proceso}
                    registroTabla={registroTabla}
                    etiqueta={etiqueta}
                />
            </Row>
        </div>
    );
}

export default withRouter(Requests);