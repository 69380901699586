import React from "react";
import { Link, withRouter } from "react-router-dom";
import { Menu } from "antd";
import "./Portal.scss";

function MenuPortal(props) {
  const { location } = props;
  return (
    <div className="menuPortal">
      <Menu
        mode="inline"
        theme="light"
        defaultSelectedKeys={[location.pathname]}
      >
        <Menu.Item key="/portal/convocatoria">
          Convocatorias
          <Link to={"/portal/convocatoria"} />
        </Menu.Item>
        <Menu.Item key="/portal/requisitos">
          Requisitos
          <Link to={"/portal/requisitos"} />
        </Menu.Item>
        {/* <Menu.Item key="/portal/materiales">
          Material de Capacitación
          <Link to={"/portal/materiales"} />
        </Menu.Item> */}
      </Menu>
    </div>
  );
}

export default withRouter(MenuPortal);
