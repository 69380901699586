import { useEffect, useState } from "react";

export const useLesson2 = (idEntidad) => {
  const [lesson, setLesson] = useState({});

  useEffect(() => {
    switch (idEntidad) {
      case 1:
        setLesson({
          parrafo:
            "Para las elecciones a nivel local en Aguascalientes, se consideran, además de las secciones electorales y la entidad federativa donde se elegirán los cargos, los Municipios, los Distritos electorales locales y la Circunscripción electoral que considera toda la extensión del estado.",
        });
        break;
      case 2:
        setLesson({
          parrafo:
            "Para las elecciones a nivel local en Baja California, se consideran, además de las secciones electorales y la entidad federativa donde se elegirán los cargos, los Municipios, los Distritos electorales locales y 8 Circunscripciones electorales que considera toda la extensión territorial del estado, donde se eligen 8 Diputados por el principio de representación proporcional.",
        });
        break;
      case 3:
        setLesson({
          parrafo:
            "Para las elecciones a nivel local en Baja California Sur, se consideran, además de las secciones electorales y la entidad federativa donde se elegirán los cargos, los Municipios, los Distritos electorales locales, la Entidad Federativa y la Circunscripción electoral que considera toda la extensión territorial del estado.",
        });
        break;
      case 4:
        setLesson({
          parrafo:
            "Para las elecciones a nivel local en Campeche, se consideran, además de las secciones electorales, y la entidad federativa donde se elegirán los cargos, los Municipios, los Distritos electorales locales, Entidad Federativa comprende todo el territorio del estado, donde se elige una Gubernatura y Circunscripciones electorales que considera toda la extensión territorial del estado.",
        });
        break;
      case 5:
        setLesson({
          parrafo:
            "Para la elección a nivel local en Coahuila, se consideran, además de las secciones electorales y la entidad federativa donde se elegirán los cargos, los Municipios, los Distritos electorales locales y la circunscripción electoral que considera toda la extensión territorial del estado.",
        });
        break;
      case 6:
        setLesson({
          parrafo:
            "Para las elecciones a nivel local en Colima, se consideran, además de las secciones electorales y la entidad federativa donde se elegirán los cargos, los Municipios, los distritos electorales locales, la Entidad Federativa y la Circunscripción electoral que considera toda la extensión territorial del estado.",
        });
        break;
      case 7:
        setLesson({
          parrafo:
            "Para la elección a nivel local en Chiapas, se consideran, además de las secciones electorales y la entidad federativa donde se elegirán los cargos, los Municipios, los Distritos electorales locales y circunscripción electoral, es la delimitación geográfica conformada por todo el estado.",
        });
        break;
      case 8:
        setLesson({
          parrafo:
            "Para las elecciones a nivel local en Chihuahua, se consideran, además de las secciones electorales y la entidad federativa donde se elegirán los cargos, los Municipios, los distritos electorales locales, la Entidad Federativa, donde se elige una Gubernatura y una Circunscripción electorales que considera toda la extensión territorial del estado.",
        });
        break;

      case 9:
        setLesson({
          parrafo:
            "Para las elecciones a nivel local en la Ciudad de México, se consideran, además de las secciones electorales y la entidad federativa donde se elegirán los cargos, las demarcaciones territoriales, los Distritos electorales locales, las Circunscripciones para la elección de concejalías y la Circunscripción electoral que considera toda la extensión de la Ciudad de México, para las diputaciones locales por el principio de representación proporcional.",
        });
        break;
      case 10:
        setLesson({
          parrafo:
            "Para las elecciones a nivel local en Durango, se consideran, además de las secciones electorales y la entidad federativa donde se elegirán los cargos, los Municipios, los Distritos electorales locales y la Circunscripción  electoral que considera toda la extensión del estado.",
        });
        break;
      case 11:
        setLesson({
          parrafo:
            "Para las elecciones a nivel local en el estado de Guanajuato, se consideran, además de las secciones electorales y la entidad federativa donde se elegirán los cargos, los Municipios, los Distritos electorales locales y la Circunscripción electoral que considera toda la extensión del estado.",
        });
        break;
      case 12:
        setLesson({
          parrafo:
            "Para las elecciones a nivel local en Guerrero, se consideran, además de las secciones electorales y la entidad federativa donde se elegirán los cargos, los Municipios, los Distritos electorales locales y la Circunscripción electoral que considera toda la extensión del estado.",
        });
        break;
      case 13:
        setLesson({
          parrafo:
            "Para la elección a nivel local en Hidalgo, se consideran, además de las secciones electorales y la entidad federativa donde se elegirán los cargos, los Municipios, los Distritos electorales locales y Circunscripciones electorales que considera toda la extensión territorial del estado.",
        });
        break;
      case 14:
        setLesson({
          parrafo:
            "Para las elecciones a nivel local en Jalisco, se consideran, además de las secciones electorales y la entidad federativa donde se elegirán los cargos, los Municipios, los Distritos electorales locales, la Entidad Federativa, donde se elige una Gubernatura y la Circunscripción electoral que considera toda la extensión territorial del estado.",
        });
        break;
      case 15:
        setLesson({
          parrafo:
            "Para las elecciones a nivel local en el Estado de México, se consideran, además de las secciones electorales y la entidad federativa donde se elegirán los cargos, los Municipios, los Distritos electorales locales, la Entidad Federativa y la circunscripción electoral que considera toda la extensión territorial del estado.",
        });
        break;
      case 16:
        setLesson({
          parrafo:
            "Para las elecciones a nivel local en Michoacán, se consideran, además de las secciones electorales y la entidad federativa donde se elegirán los cargos, los Municipios, los Distritos electorales locales y la Circunscripción electoral que considera toda la extensión del estado.",
        });
        break;
      case 17:
        setLesson({
          parrafo:
            "Para las elecciones a nivel local en Morelos, se consideran, además de las secciones electorales y la entidad federativa donde se elegirán los cargos, los Municipios, los Distritos electorales locales y la Circunscripción electoral que considera toda la extensión del estado.",
        });
        break;
      case 18:
        setLesson({
          parrafo:
            "Para las elecciones a nivel local en Nayarit, se consideran, además de las secciones electorales y la entidad federativa donde se elegirán los cargos, los Municipios, los Distritos electorales locales y la Circunscripción electoral que considera toda la extensión del estado.",
        });
        break;
      case 19:
        setLesson({
          parrafo:
            "Para las elecciones a nivel local en Nuevo León, se consideran, además de las secciones electorales y la entidad federativa donde se elegirán los cargos, los Municipios, los Distritos electorales locales y la Circunscripción electoral que considera toda la extensión del estado.",
        });
        break;
      case 20:
        setLesson({
          parrafo:
            "Para las elecciones a nivel local en Oaxaca, se consideran, además de las secciones electorales y la entidad federativa donde se elegirán los cargos, los Municipios, los Distritos electorales locales y la Circunscripción electoral que considera toda la extensión del estado.",
        });
        break;
      case 21:
        setLesson({
          parrafo:
            "Para las elecciones a nivel local en Puebla, se consideran, además de las secciones electorales y la entidad federativa donde se elegirán los cargos, los Municipios, los Distritos electorales locales y la Circunscripción electoral que considera toda la extensión del estado.",
        });
        break;
      case 22:
        setLesson({
          parrafo:
            "Para las elecciones a nivel local en Querétaro, se consideran, además de las secciones electorales y la entidad federativa donde se elegirán los cargos, los Municipios, los Distritos electorales locales y la Circunscripción electoral que considera toda la extensión del estado.",
        });
        break;
      case 23:
        setLesson({
          parrafo:
            "Para las elecciones a nivel local en el Estado de Quintana Roo, se consideran, además de las secciones electorales y la entidad federativa donde se elegirán los cargos, los Municipios, los Distritos electorales locales y la Circunscripción electoral que considera toda la extensión del estado.",
        });
        break;
      case 24:
        setLesson({
          parrafo:
            "Para las elecciones a nivel local en San Luis Potosí, se consideran, además de las secciones electorales y la entidad federativa donde se elegirán los cargos, los Municipios, los Distritos electorales locales y la circunscripción electoral que considera toda la extensión del estado.",
        });
        break;
      case 25:
        setLesson({
          parrafo:
            "Para las elecciones a nivel local en Sinaloa, se consideran, además de las secciones electorales y la entidad federativa donde se elegirán los cargos, los Municipios, los Distritos electorales locales y la Circunscripción electoral que considera toda la extensión del estado.",
        });
        break;
      case 26:
        setLesson({
          parrafo:
            "Para las elecciones a nivel local en Sonora, se consideran, además de las secciones electorales y la entidad federativa donde se elegirán los cargos, los Municipios, los Distritos electorales locales y la Circunscripción electoral que considera toda la extensión del estado.",
        });
        break;
      case 27:
        setLesson({
          parrafo:
            "Para las elecciones a nivel local en Tabasco, se consideran, además de las secciones electorales y la entidad federativa donde se elegirán los cargos, los Municipios, los Distritos electorales locales y las Circunscripciones electorales que son las divisiones territoriales formadas por distritos electorales.",
        });
        break;
      case 28:
        setLesson({
          parrafo:
            "Para las elecciones a nivel local en Tamaulipas se consideran, además de las secciones electorales y la entidad federativa donde se elegirán los cargos, los Municipios, los Distritos electorales locales y la Circunscripción electoral que considera toda la extensión del estado.",
        });
        break;
      case 29:
        setLesson({
          parrafo:
            "Para las elecciones a nivel local en Tlaxcala, se consideran, además de las secciones electorales y la entidad federativa donde se elegirán los cargos, los Municipios, los Distritos electorales locales y la Circunscripción electoral que considera toda la extensión del estado.",
        });
        break;
      case 30:
        setLesson({
          parrafo:
            "Para las elecciones a nivel local en Veracruz, se consideran, además de las secciones electorales y la entidad federativa donde se elegirán los cargos, los Municipios, los Distritos electorales locales y la Circunscripción electoral que considera toda la extensión del estado.",
        });
        break;
      case 31:
        setLesson({
          parrafo:
            "Para las elecciones a nivel local en Yucatán, se consideran, además de las secciones electorales y la entidad federativa donde se elegirán los cargos, los Municipios, los Distritos electorales locales y la Circunscripción electoral que considera toda la extensión del estado.",
        });
        break;
      case 32:
        setLesson({
          parrafo:
            "Para las elecciones a nivel local en Zacatecas, se consideran, además de las secciones electorales y la entidad federativa donde se elegirán los cargos, los Municipios, los Distritos electorales locales y la Circunscripción electoral que considera toda la extensión del estado.",
        });
        break;
      default:
        return setLesson({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return lesson;
};
