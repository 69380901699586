import { combineReducers } from "redux";
import {
  entitiesReducer,
  leccionReducer,
  menuReducer,
  modalReducer,
} from "./courseReducer";
import { blockReducer, entityReducer, entityReducerMat } from "./entityReducer";
import { constancyReducer } from "./constancyReducer";
//Se exportan los reducer creados para que se muestre en la herramienta de redux
export default combineReducers({
  entidades: entitiesReducer,
  entidad: entityReducer,
  menu: menuReducer,
  leccion: leccionReducer,
  material: entityReducerMat,
  constancia: constancyReducer,
  bloquear: blockReducer,
  modal: modalReducer,
});
