import React, { useEffect } from "react";
import { radioButton } from "../../../utils/rules";
import { Radio, Row, Form, Checkbox } from "antd";
import { useState } from "react";
import { getEntities } from "../../../api/requestPub";
import "./Steps.scss";

export const Entities = (props) => {
  const { getFieldDecorator, datos, handleChange, form, setDatos } = props;
  const [entities, setEntities] = useState([]);

  useEffect(() => {
    if (datos.entObservar === 1) {
      getEntities()
        .then((res) => {
          setEntities(res.data);
        })
        .catch((e) => {
          console.log("Error al obtener las entidades");
        });
    }
  }, [datos.entObservar]);

  //Función que obtiene las entidades seleccionadas
  function onChange(checkedValues) {
    setDatos((d) => ({ ...d, entidades: checkedValues }));
  }

  return (
    <div>
      <h3 style={{ marginTop: 30 }}>Entidades a observar</h3>
      <Row gutter={24}>
        <Form.Item label="¿En qué entidad o entidades deseas observar el Proceso Electoral?">
          {getFieldDecorator("entObservar", {
            initialValue: datos.entObservar,
            rules: radioButton,
          })(
            <Radio.Group onChange={handleChange("entObservar")}>
              <Radio value={0}>
                Sólo en la entidad de mi credencial
                <div className="textRadio">
                  (Tu credencial corresponde a {datos.entidad}).
                </div>
              </Radio>
              <Radio value={1} style={{ marginLeft: 50 }}>
                En una o más entidades distintas
                <div className="textRadio">
                  <p></p>
                </div>
              </Radio>
            </Radio.Group>
          )}
        </Form.Item>
      </Row>
      {form.getFieldValue("entObservar") === 1 && (
        <Form.Item>
          {getFieldDecorator("entidades", {
            rules: radioButton,
          })(<Checkbox.Group options={entities} onChange={onChange} />)}
        </Form.Item>
      )}
    </div>
  );
};
