import React from "react";
import { GuiaMaterial, MatApoyo } from "../Generals";

export default function Lesson6({ data, setLoading }) {
  return (
    <div className="texto_parrafo">
      <p>
        Ahora que ya conoces las actividades que se realizan en el proceso
        electoral, es muy importante que sepas, que el INE promueve los
        derechos humanos en materia político-electoral y garantiza su ejercicio
        en igualdad de condiciones, sin ningún tipo de discriminación, ya sea
        por identidad de género, orientación sexual, expresión de género,
        apariencia, edad, cualquier tipo de discapacidad, origen étnico,
        situación migratoria o por cualquier otra condición.{" "}
      </p>
      <p>
        Por lo cual el INE ha aprobado y adoptado dos protocolos de inclusión
        aplicables en todos los procesos electorales:
      </p>
      <ul>
        <dt>
          1. El{" "}
          <i>
            Protocolo para la Inclusión de las Personas con Discapacidad como
            funcionarios y funcionarias de Mesas Directivas de Casilla, al que
            llamaremos
          </i>{" "}
          Protocolo de discapacidad.
        </dt>
        <dt>
          2. El{" "}
          <i>
            Protocolo para adoptar las medidas tendientes a garantizar a las
            personas trans el ejercicio del voto en igualdad de condiciones y
            sin discriminación en todos los tipos de elección y mecanismos de
            participación ciudadana, al que llamaremos{" "}
          </i>{" "}
          Protocolo trans.
        </dt>
        <dt>
          3. Las medidas para garantizar el voto y la participación de las
          personas en igualdad de condiciones y sin discriminación.
        </dt>
      </ul>
      <p>
        Te invitamos a conocer los Protocolos de inclusión que están disponibles
        en el material de consulta de este curso.
      </p>
      <p>
        <b>
          ¡Vamos a comenzar la sexta lección! Haz clic en el siguiente video.{" "}
        </b>
      </p>
      {/* <MatApoyo
        array={[
          {
            titulo:
              "Video ¿Cuáles son las medidas de inclusión aplicables en el Proceso Electoral?",
          },
        ]}
      /> */}
      {data.length && <MatApoyo array={[data[0]]} />}
      <p>
        <b>
          A continuación, revisa el siguiente material de apoyo para reforzar lo
          que aprendiste.
        </b>
      </p>
      {/* <MatApoyo
        array={[
          { titulo: "Requisitos para votar" },
          {
            titulo:
              "Presentación 6. Medidas de inclusión durante el proceso electoral",
          },
        ]}
      /> */}
      {data.length && <MatApoyo array={[data[1], data[2]]} />}
      <p className="bold">
        Para profundizar tus conocimientos sobre la observación electoral puedes
        revisar el{" "}
        <GuiaMaterial setLoading={setLoading}>
          CAPÍTULO VI DE TU GUÍA TEMÁTICA PARA CAPACITAR A LAS Y LOS
          OBSERVADORES ELECTORALES
        </GuiaMaterial>{" "}
        que está disponible en los materiales de consulta de este curso.
      </p>
      <p className="bold">
        No olvides realizar la actividad autoevaluable correspondiente a esta
        lección.
      </p>
    </div>
  );
}
