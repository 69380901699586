import React, { useState, useEffect } from "react";
import { Form, Icon, Input, Row, Button } from "antd";
import Recaptcha from "react-recaptcha";
import axios from "axios";
import {
  ACCESS_TOKEN_CI,
  DETALLE_PROCESO_CI,
  MSN_LOGIN,
} from "../../../utils/constanst";
import { basePath } from "../../../api/config";
//import './Login.scss';
import Alert from "../../Alert/Alert";
import { VERSION_SISTEMA, ID_SISTEMA } from "../../../utils/constanst";
import jwtDecode from "jwt-decode";
import BlockLoader from "../../Generals/Loader";
import { emptyCache } from "../../Generals/functions";

function LoginUser(props) {
  const { getFieldDecorator } = props.form;
  //Key para poder generar el recapcha, está key puede cambiar dependiendo el proyecto ya que se obtiene a través de una cuenta gmail
  const CAP_KEY = "6Len8ekUAAAAALaah5js5KtSdPboaGH703DuJgV2";
  //Hooks
  const [capcha, setCapcha] = useState(false);
  const [inputs, setInputs] = useState({
    correo: "",
    password: "",
    versionAplicacion: VERSION_SISTEMA,
    idSistema: ID_SISTEMA,
  });
  const [error, setError] = useState(false);
  const [messageAlert, setMessageAlert] = useState("");
  const { visibleSession } = props;
  const [loader, setLoader] = useState({ loading: false, message: "" }); //State para mostrar el loader

  useEffect(() => {
    if (!visibleSession) {
      props.form.resetFields();
      if (error) {
        setError(false);
      }
    }
  }, [visibleSession, props.form, error]);

  const verifyCallback = (response) => {
    setCapcha({ capcha }); //función que comprueba que el recapcha se selecciono correctamente
  };
  const onloadCallback = () => {
    console.log("captcha successfully loaded"); //Realiza un callback al capcha
  };
  //Funcíon que obtiene los cambios que a tenido el formulario
  const changeForm = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });
  };

  const login = (e) => {
    e.preventDefault();
    if (!capcha) {
       //Se verifica el capcha
       setError(true);
       setMessageAlert("El código de seguridad es obligatorio, favor de verificarlo.");
      } else {
    //Se realiza la petición al server
    props.form.validateFields((err) => {
      if (!err) {
        setError(false);
        setLoader({ loading: true, message: MSN_LOGIN }); //Se activa el loader
        const url = `${basePath}/validaCiudadano`;
        axios
          .post(url, inputs)
          .then((response) => {
            const { code, message } = response.data.entity;
            if (code === 400 || code === 500) {
              setError(true);
              setMessageAlert(message);
              setLoader({ loading: false, message: "" }); //Se desactiva el loader
            } else {
              //setError(false);
              const { tknA, listaDetalles } = response.data.entity.datosUsuario; //Se obtiene el token de json
              const token = jwtDecode(tknA);
              localStorage.setItem(ACCESS_TOKEN_CI, tknA); //Se almacena en localStorage
              localStorage.setItem(
                DETALLE_PROCESO_CI,
                JSON.stringify(listaDetalles)
              ); //Se almacena la listaDetalles del JSON en el localStorage
              localStorage.setItem("correo", token.sub); //Se almacena en localStorage
              emptyCache();
              window.location.href = "/ciudadano/inicio"; //Se redirecciona al home
            }
          })
          .catch((err) => {
            console.log(err);
            setLoader({ loading: false, message: "" }); //Se desactiva el loader
          });
      }
    });
    }
  };

  return (
    //Para mostrar el loader en el login
    <BlockLoader loading={loader.loading} message={loader.message}>
      <Form onChange={changeForm} onSubmit={login}>
        <Form.Item label="Correo electrónico">
          {getFieldDecorator("correo", {
            rules: [
              { required: true, message: "Dato requerido" },
              { max: 50, message: "Número de caracteres permitidos: 50" },
              {
                pattern: /^[^<>%$&|#]*$/,
                message: "Dato inválido, ingresa nuevamente.",
              },
            ],
          })(
            <Input
              prefix={<Icon type="user" style={{ color: "#d5007f" }} />}
              placeholder="nombre@dominio.com"
              name="correo"
              style={{ width: "100%" }}
              maxLength={51}
            />
          )}
        </Form.Item>
        <Form.Item label="Contraseña">
          {getFieldDecorator("pass", {
            rules: [
              { required: true, message: "Dato requerido" },
              { max: 50, message: "Número de caracteres permitidos: 50" },
              {
                pattern: /^[^<>%$&|#]*$/,
                message: "Dato inválido, ingresa nuevamente.",
              },
            ],
          })(
            <Input.Password
              prefix={<Icon type="lock" style={{ color: "#d5007f" }} />}
              type="password"
              name="password"
              placeholder="Contraseña"
              style={{ width: "100%" }}
              maxLength={51}
            />
          )}
        </Form.Item>
        <Row>
          {error ? <Alert message={messageAlert} type={"error"} /> : null}
        </Row>
        <Row>
          <div style={{ transform: "scale(0.80)" }} className="contCaptcha">
            <Recaptcha
              onloadCallback={onloadCallback}
              verifyCallback={verifyCallback}
              sitekey={CAP_KEY}
              hl={"es"}
              render="explicit"
            />
          </div>
        </Row>
        <Row>
          <Button type="primary" htmlType="submit" style={{ width: "100%" }}>
            Inicia sesión
          </Button>
        </Row>
      </Form>
    </BlockLoader>
  );
}
const LoginForm = Form.create()(LoginUser);
export default LoginForm;
