import React, { useState, useEffect, useRef } from "react";
import { Form, Input, Row, Col, Select, Checkbox } from "antd";
import {
  calle,
  cp,
  required,
  soloDigitos,
  numeroInt,
} from "../../../utils/rules";
import {
  getState,
  getMunicipio,
  getByIdDistrito,
  getByJunta,
} from "../../../api/requestPub";

export default function Adress(props) {
  //Se obtienen las funciones por props
  const {
    changeCheckbox,
    getFieldDecorator,
    handleChange,
    datos,
    disabled,
    setDatos,
    form,
    setJuntas,
  } = props;

  const { Option } = Select;
  const [select, setSelect] = useState([]); //Hook para almacenar los estados
  const [municipio, setMunicipio] = useState([]); //Hook para almacenar los municipios
  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    //Obtiene los estados
    getState()
      .then((res) => {
        if (isMounted.current) {
          setSelect(res.data); //Se cargan ambos componentes de entidad
          setJuntas((val) => ({ ...val, estados: res.data }));
        }
      })
      .catch((error) => {
        console.log("Ocurrio un error", error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Obtiene el id y el nombre del estado
  const getStates = (value, event) => {
    //Se almacena la información de ambos estados en el hook principal
    setDatos({
      ...datos,
      entidad: event.props.children,
      conEntidad: event.props.children, //Quitar cuando se implemmente el servicio de sesiones
      idEntidad: value,
      entidadJunta: value,
    });
    //Se raliza un reset a los combos de municipio y localidad y se le asigna el mimo valor al estado_junta
    form.setFieldsValue({
      municipio: undefined,
      localidad: undefined,
      juntaEjecutiva: undefined,
      entidadJunta: parseInt(value), //Se parsea debido a que viene como string
    });
    //Se cargan los municipios de acuerdo al id del estado
    getMunicipio(value)
      .then((res) => {
        setMunicipio(res.data); //Se carga en el hook
      })
      .catch((err) => {
        console.log("Error");
      });
    // .finally(() => {
    getByJunta(value) //Se obtienen las juntas de la entidad seleccionada
      .then((res) => {
        setJuntas((val) => ({ ...val, juntas: res.data.domicilios })); //Se cargan en el hook
        return res.data.domicilios;
      })
      .catch((e) => {
        console.log(e);
      })
      .then((response) => {
        //Se valida que la sección electoral y la entidad esten seleccionadas
        if (value && datos.seccion) {
          //Se obtiene el distrito por defaul de acuerdo a la sección y la entidad
          getByIdDistrito(value, datos.seccion)
            .then((res) => {
              //Se recibe la respuesta y se verifica que sea exitosa
              if (res.data.code === "200") {
                //Se busc el idDistro en el arreglo de juntas proporcionas por el servicio
                const junta = getByIdJuntaDeafault(
                  parseInt(res.data.idDistrito), //Se manda el idDistrito obtenido de la consulta
                  response //Se manda el array de las juntas
                );
                //Se concatenan los valores para mostrar por defecto en el select de juntas
                const juntaDefault = junta
                  ? `${junta.idDistritoFederal}|${junta.nombreSede}|${junta.direccion}`
                  : undefined;
                form.setFieldsValue({ juntaEjecutiva: juntaDefault }); //Se le asigna el valor al select de juntas
                setDatos((d) => ({ ...d, juntaEjecutiva: juntaDefault })); //Se almacena en el hook de datos
              }
            })
            .catch((e) => {
              console.log(e);
            });
        }
      })
      .catch((err) => {
        console.log("Error");
      });
    // });
  };
  //Función que retorna un json que encuentra en un array de acuerdo al id
  const getByIdJuntaDeafault = (id, array) => {
    if (array) {
      return array.find((junta) => junta.idDistritoFederal === id);
    }
    return undefined;
  };

  //Obtiene el id y el nombre del municipio
  const getMunicipioSelect = (value, event) => {
    //Se almacena la información del municipio en el hook principal
    setDatos({
      ...datos,
      municipio: event.props.children,
      idMunicipio: value,
    });
    //Se raliza un reset al combo de  localidad
    form.setFieldsValue({
      localidad: undefined,
    });
  };

  return (
    <Form>
      <h3>Domicilio</h3>
      <Row gutter={24}>
        <Col span={12} xxl={8} xl={8} lg={8} md={8} sm={24} xs={24}>
          <Form.Item label="Calle">
            {getFieldDecorator("calle", {
              initialValue: datos.calle,
              rules: calle,
            })(
              <Input
                //placeholder="Calle"
                onChange={handleChange("calle")}
                maxLength={50}
              />
            )}
          </Form.Item>
        </Col>
        <Col span={4} xxl={4} xl={4} lg={4} md={4} sm={24} xs={24}>
          <Form.Item>
            <br />
            {getFieldDecorator("numero", {
              valuePropName: "checked",
              initialValue: datos.numero,
            })(
              <Checkbox onChange={changeCheckbox("numero")}>
                Sin número
              </Checkbox>
            )}
          </Form.Item>
        </Col>
        <Col span={4} xxl={4} xl={4} lg={4} md={4} sm={24} xs={24}>
          <Form.Item label="No. exterior">
            {getFieldDecorator("numeroExt", {
              initialValue: datos.numeroExt,
              getValueFromEvent: (event, previo) => {
                return numeroInt.test(event.target.value)
                  ? event.target.value
                  : previo;
              },
            })(
              <Input
                //placeholder="No. exterior"
                disabled={disabled.num}
                onChange={handleChange("numeroExt")}
                maxLength={20}
              />
            )}
          </Form.Item>
        </Col>
        <Col span={4} xxl={4} xl={4} lg={4} md={4} sm={24} xs={24}>
          <Form.Item label="No interior">
            {getFieldDecorator("numeroInt", {
              initialValue: datos.numeroInt,
              getValueFromEvent: (event, previo) => {
                return numeroInt.test(event.target.value)
                  ? event.target.value
                  : previo;
              },
            })(
              <Input
                //placeholder="No interior"
                disabled={disabled.num}
                onChange={handleChange("numeroInt")}
                maxLength={20}
                minLength={1}
              />
            )}
          </Form.Item>
        </Col>
      </Row>

      <Form.Item label="Código postal" style={{ width: 100 }}>
        {getFieldDecorator("codigoPostal", {
          initialValue: datos.codigoPostal,
          rules: cp,
          getValueFromEvent: (event, previo) => {
            return soloDigitos.test(event.target.value)
              ? event.target.value
              : previo;
          },
        })(
          <Input
            //placeholder="09690"
            onChange={handleChange("codigoPostal")}
            maxLength={5}
          />
        )}
      </Form.Item>

      <Row gutter={24}>
        <Col span={8} xxl={8} xl={8} lg={8} md={8} sm={24} xs={24}>
          <Form.Item label="Entidad">
            {getFieldDecorator("entidad", {
              initialValue: datos.entidad,
              rules: required,
            })(
              <Select
                showSearch
                optionFilterProp="children"
                placeholder="Selecciona"
                //onChange={changeSelect("municipio")}
                onSelect={(value, e) => getStates(value, e)}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {select.map((item) => (
                  <Option key={item.id.idEstado}>{item.nombreEstado}</Option>
                ))}
              </Select>
            )}
          </Form.Item>
        </Col>
        <Col span={8} xxl={8} xl={8} lg={8} md={8} sm={24} xs={24}>
          <Form.Item label="Municipio">
            {getFieldDecorator("municipio", {
              initialValue: datos.municipio,
              rules: required,
            })(
              <Select
                showSearch
                optionFilterProp="children"
                placeholder="Selecciona"
                //onChange={changeSelect("municipio")}
                onSelect={(value, e) => getMunicipioSelect(value, e)}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {municipio.map((item) => (
                  <Option key={item.idMunicipio}>{item.municipio}</Option>
                ))}
              </Select>
            )}
          </Form.Item>
        </Col>
        <Col span={8} xxl={8} xl={8} lg={8} md={8} sm={24} xs={24}>
          <Form.Item label="Colonia">
            {getFieldDecorator("localidad", {
              initialValue: datos.localidad,
              rules: calle,
            })(
              <Input
                //placeholder="Calle"
                onChange={handleChange("localidad")}
                maxLength={40}
              />
            )}
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}
