import { basePath } from "./config";
import axios from "axios";
import { getAccessTokenApi } from "./auth";

export function getTypeAction() {
  const url = `${basePath}/accion/tipos`;
  const jwt = getAccessTokenApi();
  return axios.post(
    url,
    {},
    {
      headers: { Authorization: jwt },
    }
  );
}

export function getGroupAction() {
  const url = `${basePath}/accion/grupos`;
  const jwt = getAccessTokenApi();
  return axios.post(
    url,
    {},
    {
      headers: { Authorization: jwt },
    }
  );
}

export function getAction(idProceso, idDetalle, idEstado, idDistrito, status) {
  const url = `${basePath}/accion/tabla`;
  const jwt = getAccessTokenApi();
  return axios.post(
    url,
    {
      idProceso: idProceso,
      idDetalle: idDetalle,
      idEstado: idEstado,
      idDistrito: idDistrito,
      estatus: status,
    },
    {
      headers: { Authorization: jwt, Parametrizacion: idProceso },
    }
  );
}

export function save(accion) {
  const url = `${basePath}/accion`;
  const jwt = getAccessTokenApi();
  return axios.post(url, accion, {
    headers: { Authorization: jwt, Parametrizacion: accion.idProcesoElectoral },
  });
}

export function update(accion) {
  const url = `${basePath}/accion/actualizar`;
  const jwt = getAccessTokenApi();
  return axios.post(url, accion, {
    headers: { Authorization: jwt, Parametrizacion: accion.idProcesoElectoral },
  });
}

export function deleted(accion, user) {
  const url = `${basePath}/accion/estatus`;
  const jwt = getAccessTokenApi();
  return axios.post(
    url,
    { idRegistro: accion.idRegistro, usuario: user.sub },
    {
      headers: {
        Authorization: jwt,
        Parametrizacion: accion.idProcesoElectoral,
      },
    }
  );
}
