import React from "react";
import { Row, Col, Form, Radio } from "antd";
import Viewer from "../../../Generals/Viewer";
import "./Steps.scss";
import { AlertInfo } from "../../../../utils/Validations/functions";
import { radioButton } from "../../../../utils/rules";

export default function Photo(props) {
  const { getFieldDecorator, datos, changeRadio } = props;
  const { user, disabled, alert } = props;
  //Se carga la imagen
  const front = [{ src: user.docs[0].imagen }];

  return (
    <Row gutter={24}>
      <Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24}>
        <h4>Credencial de elector (frente)</h4>
        <div>
          <div id="foto" className="foto" />
          <Viewer container={"foto"} image={front} />
        </div>
      </Col>
      
      <Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24}>
        <h4>Datos capturados</h4>
          <div className="data">
            <div className="alert">
            <AlertInfo disable={alert.fotoIgual} />
          </div>
          <Col span={24}>
            <img
              src={user.docs[2].imagen}
              alt="fotografía"
              width="200"
              height="220"
            />
          </Col>
          <Col span={24}>
            <Form.Item label="¿La misma persona aparece en las fotografías?">
              {getFieldDecorator("fotoIgual", {
                rules: radioButton,
                initialValue: datos.fotoIgual,
              })(
                <Radio.Group
                  onChange={changeRadio("fotoIgual")}
                  disabled={disabled.fotoIgual}
                >
                  <Radio value={true}>Sí</Radio>
                  <Radio value={false}>No</Radio>
                </Radio.Group>
              )}
            </Form.Item>
          </Col>
        </div>
      </Col>
    </Row>
  );
}
