import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useInstituto } from "../../../../hooks/useLesson4";
import {
  getLocalText,
  getPartidos,
  getTextAlcaldia,
  getCandComunes,
} from "../../../../utils/CourseOnline/Util";
import { capitalizeWord } from "../../../Generals/functions";
import { GuiaMaterial, MatApoyo } from "../Generals";

export default function Lesson4({ data, setLoading }) {
  const isMounted = useRef(true);
  //Se obtiene el Entidad seleccionada del store de redux
  const { idEntidad, entidad } = useSelector((state) => state.entidad);
  const {
    material,
    entidadMat: { idEntidad: id, entidad: entidadMat },
  } = useSelector((state) => state.material);
  //Hook que obtiene la información de la lección de acuerdo a la entidad
  const { instituto, gober, consejo } = useInstituto(material ? id : idEntidad);

  //Para desmontar el componente
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  //Se obtiene el texto de la entidad de acuerdo a la pantalla en que se encuentre
  //Pantallas: Material de capacitación / Recibir capacitación
  const getTextEntity = () => {
    return material ? entidadMat : entidad;
  };

  //Se obtiene el id de la entidad de acuerdo a la pantalla en la que se encuentre
  //Pantallas: Material de capacitación / Recibir capacitación
  const getIdEntity = () => {
    return material ? id : idEntidad;
  };

  return (
    <div className="texto_parrafo">
      <p>
        Además de las autoridades electorales que revisamos en la lección
        anterior, otros actores que participan en el Proceso Electoral son los
        partidos políticos y las candidaturas independientes.
      </p>
      <p>
        Empecemos por los <b>partidos políticos</b> y sus características:{" "}
      </p>
      <p>
        Los partidos políticos, son entidades de interés público, con
        personalidad jurídica, con registro ante el INE o ante los OPL, con
        derecho a participar en las elecciones para cargos de elección popular a
        nivel federal, estatal, municipal y de la Ciudad de México. Gozan de
        derechos, prerrogativas y obligaciones que establece la ley.
      </p>
      <p>
        Los partidos políticos tienen fines específicos establecidos en la ley,
        revisa el material de apoyo para que los conozcas.{" "}
      </p>
      {/* <MatApoyo array={[{ titulo: "Fines de los partidos políticos " }]} /> */}
      {data.length && <MatApoyo array={[data[0]]} />}
      <p>
        Hay partidos <b>nacionales</b> y <b>locales</b>. Los partidos políticos
        nacionales son aquellos que se registraron ante el INE y se encuentran
        facultados para participar en los procesos federales, es decir, en la
        elección del titular de la Presidencia de la República, Diputaciones y
        Senadurías del Congreso de la Unión, así como en los procesos locales de
        Gobernador, Diputaciones Locales y Ayuntamientos. Mientras que los
        partidos políticos locales con registro ante {instituto} tienen la
        facultad de participar únicamente en los procesos estatales{getTextAlcaldia(getIdEntity())}
        , es decir, en la elección de{getIdEntity() !== 10?":":""} {gober}
      </p>
      <p>
        A nivel nacional hay registrados 10 partidos políticos, de los cuales 3
        son de nuevo registro. Mientras que a nivel local en{" "}
        {capitalizeWord(getTextEntity())} {getPartidos(getIdEntity())}
      </p>
      <p>
        En el siguiente material de apoyo podrás conocer cuáles son los partidos
        políticos con registro nacional{getLocalText(getIdEntity())}.
      </p>
      {/* Materiales - se valida que tenga el vaterial local y nacional*/}
      {data.length && data[1].material === data[2].material ? (
        <MatApoyo array={[data[1]]} />
      ) : (
        <MatApoyo array={[data[1], data[2]]} />
      )}

      <p>
        Los partidos políticos tienen derechos y obligaciones, revisa el
        siguiente material dando clic en el enlace para conocerlos.
      </p>
      {/* Materiales */}
      {data.length && <MatApoyo array={[data[3]]} />}
      <p>
        Además, los partidos políticos tienen obligaciones en materia de
        transparencia, como publicar en su página electrónica su información
        específica, y en materia de violencia política contra las mujeres en
        razón de género, el garantizar en igualdad de condiciones la
        participación de hombres y mujeres. La información detallada de sus
        obligaciones la puedes consultar en el Capítulo IV de tu Guía Temática.
      </p>
      <p>
        Los partidos políticos nacionales y locales pueden participar en el
        proceso electoral concurrente postulando candidaturas de forma
        individual o a través de coaliciones.{" "}
        {getCandComunes(getIdEntity(), capitalizeWord(getTextEntity()))}
      </p>
      <p>
        Da clic en el siguiente enlace para conocer las características de las
        coaliciones.
      </p>
      {/* <MatApoyo array={[{ titulo: "Características de las coaliciones " }]} /> */}
      {data.length && <MatApoyo array={[data[4]]} />}
      <p>
        Debes saber que la solicitud de registro del convenio de coalición
        deberá presentarse a la o el Presidente del Consejo General del INE{" "}
        {consejo}, según la elección que lo motive, a más tardar treinta días
        antes de que inicie el periodo de precampaña de la elección de que se
        trate.
      </p>
      <p>
        Una vez concluida la etapa de resultados y declaración de validez{" "}
        <b>
          concluye automáticamente la coalición. Los partidos de nuevo registro
          NO pueden formar coaliciones.
        </b>
      </p>
      <p className="bold">
        Consulta las formas de participación que tienen los partidos políticos
        en {capitalizeWord(getTextEntity())}, en el{" "}
        <GuiaMaterial setLoading={setLoading}>
          CAPÍTULO IV DE TU GUÍA TEMÁTICA CAPACITAR A LAS Y LOS OBSERVADORES
          ELECTORALES
        </GuiaMaterial>{" "}
        que está disponible en los materiales de consulta de este curso.
      </p>
      <p>
        ¿Sabes qué es una precampaña, el registro de candidaturas y las campañas
        electorales?
      </p>
      <p>
        Una <b>precampaña</b> es el conjunto de actos que realizan los partidos
        políticos, sus militantes y las y los precandidatos a cargos de elección
        popular, durante los procesos internos de selección de candidaturas.
      </p>
      <p>
        Posterior a la precampaña se <b>registran candidaturas</b> de partidos
        políticos a los cargos de elección popular, el partido político o
        coalición postulante deberá presentar y obtener el registro de la
        plataforma electoral que sus candidatos y candidatas sostendrán a lo
        largo de las campañas políticas, para lo cual deberán acreditar que
        realizaron los procesos internos establecidos.
      </p>
      <p>
        Una vez ya registradas las candidaturas se llevan a cabo las{" "}
        <b>campañas electorales</b>, que son un conjunto de actividades que
        realizan los partidos políticos nacionales, las coaliciones y las y los
        candidatos registrados para obtener el voto de la ciudadanía. Se
        consideran actos de campaña todas las reuniones públicas, asambleas,
        marchas y en general aquellos en que las y los candidatos o voceros de
        los partidos políticos se dirigen al electorado para promover sus
        candidaturas.
      </p>
      <p>
        El día de la Jornada Electoral y durante los tres días anteriores NO se
        permite realizar actos públicos de campaña, de propaganda o de
        proselitismo.{" "}
      </p>
      <p>
        Da clic en el siguiente enlace para conocer los periodos de precampaña,
        registro de candidaturas y campañas electorales de la elección federal y
        locales.{" "}
      </p>
      {/* Materiales */}
      {data.length && <MatApoyo array={[data[5], data[6]]} />}
      <p>
        Como vimos anteriormente, uno de los derechos de los partidos políticos
        es recibir financiamiento, el cual se divide en <b>público y privado</b>
        . Puedes revisar las características de cada tipo en el material de
        apoyo.
      </p>
      {/* <MatApoyo array={[{ titulo: "Financiamiento a partidos políticos " }]} /> */}
      {data.length && <MatApoyo array={[data[7]]} />}
      <p>
        Ahora conoceremos la figura de <b>candidaturas independientes</b>{" "}
        {getIdEntity() === 9 && "o sin partido "} y sus características:
      </p>
      <p>
        La{" "}
        <b>
          Candidatura Independiente{getIdEntity() === 9 && " o sin partido "}
        </b>
        , se refiere a la o el ciudadano mexicano que obtiene por parte de la
        autoridad electoral su registro para una candidatura a un cargo de
        elección popular, sin necesidad de que un partido político lo postule.{" "}
      </p>
      <p>
        Los procesos de selección de Candidaturas Independientes para cargos en
        elecciones federales y locales comprenden las siguientes etapas:{" "}
      </p>
      <ol>
        <li>
          La <b>emisión de la Convocatoria</b>, por parte del INE y de los OPL.
        </li>
        <li>
          Los <b>Actos previos al registro</b>, es decir, la manifestación de la
          intención de participar como candidato o candidata independiente.
        </li>
        <li>
          La <b>Obtención de apoyo ciudadano</b>, la cual se hará partir del día
          siguiente de la fecha en que obtengan la calidad de aspirantes a una
          candidatura independiente. Podrán realizar actos para recabar el
          porcentaje de apoyo ciudadano requerido, por ejemplo, a través de
          reuniones públicas, asambleas o marchas.
        </li>
        <li>
          En el <b>Registro de Candidaturas</b>, las y los ciudadanos que
          aspiren a participar como candidatas o candidatos independientes
          deberán presentar su solicitud de registro y documentación requerida.
        </li>
      </ol>
      <p>
        Revisa el siguiente material de apoyo para conocer los derechos y
        obligaciones de las candidaturas independientes
        {getIdEntity() === 9 && " o sin partido "}, así como los periodos y
        características del registro.
      </p>
      {/* Materiales */}
      {data.length && <MatApoyo array={[data[8], data[9], data[10]]} />}
      <p>
        A las candidaturas independientes, por ley, también se les otorga
        financiamiento, el cual tiene las siguientes modalidades:
      </p>
      <p>
        <b>A. Financiamiento Público:</b> Se constituye por fondos públicos que
        aporta el Estado y equivale al monto que le correspondería a un partido
        de nuevo registro, el cual se distribuye de forma igualitaria entre
        todas las candidaturas independientes registradas.
      </p>
      <p>
        <b>B. Financiamiento Privado:</b> Se constituye por las aportaciones que
        realice la o el candidato y sus simpatizantes, conforme a los
        porcentajes establecidos en la ley.
      </p>
      <p>
        Es importante que sepas que tanto los partidos políticos como las
        candidaturas independientes tienen derecho de{" "}
        <b>acceso a la radio y la televisión.</b>
      </p>
      <p>
        Por lo que ningún partido político, ni las y los precandidatos o
        candidatos podrán:
      </p>
      <ul>
        <li>
          Contratar o adquirir por sí mismos o por terceras personas tiempos en
          cualquier modalidad de radio y televisión.
        </li>
        <li>
          Contratar tiempos para su promoción personal con fines electorales.
        </li>
        <li>
          Contratar propaganda en radio y televisión para influir en el
          electorado, a favor o en contra de partidos políticos o de
          candidaturas de elección popular.
        </li>
        <li>
          Transmitir en territorio nacional este tipo de propaganda contratada
          en el extranjero.
        </li>
      </ul>
      <p>
        El INE es la única autoridad encargada de la administración del tiempo
        que corresponde al Estado en radio y televisión para garantizar el
        ejercicio de las prerrogativas y derechos que la Constitución y la ley
        otorgan a los partidos políticos y candidaturas independientes en radio
        y televisión. También se encarga de la administración del tiempo que
        corresponde al Estado destinado a los fines propios del Instituto y a
        los de otras autoridades electorales.{" "}
      </p>
      <p>
        Para terminar, resulta fundamental que sepas que los partidos políticos,
        sus coaliciones y las y los candidatos a cargos de elección popular
        federal y local, incluyendo a las y los candidatos independientes,
        tienen la obligación de informar sobre el origen, monto, destino y
        aplicación de los recursos que reciben, para lo cual la{" "}
        <b>
          Fiscalización a partidos políticos y a candidaturas independientes
        </b>{" "}
        está a cargo del Consejo General del INE por conducto de su Comisión de
        Fiscalización, que a su vez es apoyada por la Unidad Técnica de
        Fiscalización, instancia que ejercerá las facultades de supervisión,
        seguimiento y control técnico.
      </p>
      <p>
        Ahora que ya conoces la importancia, los derechos, obligaciones y
        prerrogativas de los partidos políticos y candidaturas independientes,
        te recordamos realizar la siguiente actividad, no dejes de consultar el{" "}
        <GuiaMaterial setLoading={setLoading}>
          Capítulo IV de tu Guía temática
        </GuiaMaterial>{" "}
        para conocer a detalle los temas y el material de apoyo.
      </p>
      <b>
        Para reforzar lo que aprendiste revisa el siguiente material de apoyo.
      </b>
      {/* Materiales */}
      {data.length && <MatApoyo array={[data[11]]} />}
      <p>
        <b>
          Ahora que ya conoces los derechos y obligaciones de los actores
          políticos, realiza la actividad de autoevaluación para reforzar lo que
          aprendiste. Después podrás continuar con la siguiente lección.
        </b>
      </p>
    </div>
  );
}
