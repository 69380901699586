import { Modal } from "antd";
//import React from "react";
import { deleteJobCruce, pauseJobCruce, restartJobCruce } from "../../api/cruces";

export function pausaJob(job, setReload) {
    pauseJobCruce(job).then((res) => {
        if(res.data.code === 200) {
            Modal.success({
                title: "Pausa del cruce",
                content: res.data.message,
                okText: "Aceptar",
                onOk() {
                    setReload(true);
                }
            });
        } else {
            Modal.error({
                title: "Atención",
                content: res.data.message,
                onOk() {
                    setReload(true);
                }
            });
        }
    });
}

export function reiniciarJob(job, setReload) {
    restartJobCruce(job).then((res) => {
        if(res.data.code === 200) {
            Modal.success({
                title: "Reinicio del proceso de cruce",
                content: res.data.message,
                okText: "Aceptar",
                onOk() {
                    setReload(true);
                }
            });
        } else {
            Modal.error({
                title: "Atención",
                content: res.data.message,
                onOk() {
                    setReload(true);
                }
            });
        }
    });
}

export function eliminarJob(job, setReload) {
    deleteJobCruce(job).then((res) => {
        if(res.data.code === 200) {
            Modal.success({
                title: "Eliminar el proceso de cruce",
                content: res.data.message,
                okText: "Aceptar",
                onOk() {
                    setReload(true);
                }
            });
        } else {
            Modal.error({
                title: "Atención",
                content: res.data.message,
                onOk() {
                    setReload(true);
                }
            });
        }
    });
}
