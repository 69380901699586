import React, { useEffect } from "react";
import { Layout } from "antd";
//Componentes
import Header from "../../../components/User/Header/Header";
import Footer from "../../../components/User/Footer/Footer";
import { ReactComponent as Logo_Header } from "../../../assets/img/logoINE_bco.svg";
import SignIn from "../../../components/User/Public/SignIn";
import useAuth from "../../../hooks/useAuth";
import HomeComponent from "../../../components/User/Public/Home/Home";
import { Redirect } from "react-router-dom";
import { getTokenCiudadano } from "../../../api/auth";
import { emptyCache } from "../../../components/Generals/functions";

const Home = (props) => {
  const { Content } = Layout;
  const {
    ciudadano: { ciudadano },
  } = useAuth();

  useEffect(() => {
    emptyCache();
  }, []);
  // valida si tiene una sesión activa lo manda al home del ciudadano
  if (getTokenCiudadano()) {
    return <Redirect to="/ciudadano/inicio" />;
  }
  return (
    <Layout style={{ minHeight: "100vh", background: "#FFFFFF" }}>
      <Header Logo={Logo_Header} ciudadano={ciudadano} />
      <Content style={{ padding: "0 50px", marginTop: "10px" }}>
        {/* Modales para Iniciar Sesión y Registrarse */}
        <SignIn />
        <HomeComponent />
      </Content>
      <Footer version="versión 11.0" anio="2020" />
    </Layout>
  );
};
export default Home;
