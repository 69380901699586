import { Modal } from "antd";
import React from "react";
import { eliminaAsistencia, guardaAsistencia, modificaAsistencia } from "../../api/attendance";

export const Ast = () => {
  return <span style={{ color: "#f5222d" }}>*</span>;
};

export function confirmaAsistencia(curso, asistencias, history, setBotonDeshabilitado, idProceso) {
  let titleText = <div>Se guardará la asistencia del curso <strong>{curso.impartidoPor}</strong> impartido
    por <strong>{curso.nombreInstructor}</strong> el <strong>{curso.fechaCurso}</strong>.</div>;
  Modal.confirm({
    title: titleText,
    icon: null,
    content: "¿Deseas continuar?",
    okText: "Guardar",
    cancelText: "Verificar datos",
    onOk() {
      guardar(asistencias, history, idProceso);
    },
    onCancel() {
      setBotonDeshabilitado(false);
    }
  });
}

function guardar(asistencias, history, idProceso) {
  guardaAsistencia(asistencias, idProceso).then((res) => {
    if(res.data.code === 200) {
      Modal.success({
        title: "Asistencia registrada",
        content: res.data.message,
        okText: "Aceptar",
        onOk() {
          history.push("/admin/asistencia");
        },
      });
    } else {
      Modal.error({
        title: "Atención",
        content: res.data.message,
        onOk() {
          history.push("/admin/asistencia");
        }
      });
    }
  });
}

export function eliminarAsistencia(curso, idProceso, setReload) {
  eliminaAsistencia(curso, idProceso).then((res) => {
    if(res.data.code === 200) {
      Modal.success({
        title: "Asistencia eliminada",
        content: res.data.message,
        okText: "Aceptar",
        onOk() {
          setReload(true); //Para volver a carga la tabla de la pantalla inicial
        },
      });
    } else {
      Modal.error({
        title: "Atención",
        content: res.data.message,
        onOk() {
          setReload(true); //Para volver a carga la tabla de la pantalla inicial
        }
      });
    }
  });
}

export function confirmaActualizacionAsist(curso, asistencias, history, setBotonDeshabilitado, idProceso) {
  let titleText = <div>Se modificará la asistencia del curso <strong>{curso.impartidoPor}</strong> impartido
    por <strong>{curso.nombreInstructor}</strong> el <strong>{curso.fechaCurso}</strong>.</div>;
  Modal.confirm({
    title: titleText,
    icon: null,
    content: "¿Deseas continuar?",
    okText: "Modificar",
    cancelText: "Verificar datos",
    onOk() {
      modificar(asistencias, history, idProceso);
    },
    onCancel() {
      setBotonDeshabilitado(false);
    }
  });
}

function modificar(asistencias, history, idProceso) {
  modificaAsistencia(asistencias, idProceso).then((res) => {
    if(res.data.code === 200) {
      Modal.success({
        title: "Asistencia modificada",
        content: res.data.message,
        okText: "Aceptar",
        onOk() {
          history.push("/admin/asistencia");
        },
      });
    } else {
      Modal.error({
        title: "Atención",
        content: res.data.message,
        onOk() {
          history.push("/admin/asistencia");
        }
      });
    }
  });
}
