import React from "react";
import { Modal } from "antd";
import { saveInforme, saveInformeJunta, deleteInformation } from "../../api/information";
import { MSN_GUARDAR } from "../../utils/constanst";

export function controlInforme(informe, setInforme, etapaSeleccionada, incidentesSeleccionados, setLoader, values, materialesSeleccionados, eliminarIncidentes, history) {
    // seeteamos la información del informe capturados
    setLoader({ loading: true, message: MSN_GUARDAR }); //Se activa el loader
    informe.informacionElectoral = materialesSeleccionados;
    informe.detalleMateriales = values.detalleMateriales;
    informe.fechaElaboracion = values.fechaElaboracion.format('DD/MM/YYYY');
    etapaSeleccionada.idEstado = values.entidad ? Number(values.entidad) : null;
    etapaSeleccionada.idMunicipio = values.municipio ? Number(values.municipio) : null;
    etapaSeleccionada.idDistritoFederal = values.distritoFederal ? Number(values.distritoFederal) : null;
    etapaSeleccionada.idDistritoLocal = values.distritoLocal ? Number(values.distritoLocal) : null;
    etapaSeleccionada.propuesta = values.propuesta ? values.propuesta.trim() : null;
    etapaSeleccionada.comentarios = values.comentarios ? values.comentarios.trim() : null;
    etapaSeleccionada.incidentesOcurridos = incidentesSeleccionados;
    etapaSeleccionada.eliminarIncidentes = eliminarIncidentes;

    for (let i = 0; i < informe.etapas.length; i++) {
        if (informe.etapas[i].value === etapaSeleccionada.value) {
            informe.etapas[i] = etapaSeleccionada;
            break;
        }
    }
    if (informe.medioCaptura === 0) {
      savePortal(informe, setInforme, setLoader, history, etapaSeleccionada);
    } else {
      saveJunta(informe, setLoader, history, etapaSeleccionada);
    }
}


function savePortal(informe, setInforme, setLoader, history, etapaSeleccionada) {
    //console.log(JSON.stringify(informe));
    //console.log(informe);
    saveInforme(informe).then(res => {
      if (res.data.code === 200) {
        setLoader({ loading: false, message: "" }); //Se desactiva el loader
        Modal.success({
            title: <p>El informe se ha actualizado con los datos capturados en la etapa <strong>{etapaSeleccionada.label}</strong>.</p>,
            content: <p>{res.data.message}</p>,
            okText: "Aceptar",
            onOk() {
                setInforme(res.data.informe);
                history.push("/ciudadano/informes"); 
            }
        });        
      } else {
        setLoader({ loading: false, message: "" }); //Se desactiva el loader
        Modal.info({
          title: "Atención",
          content: <p>{res.data.causa}</p>
        });
      }
    });
}

function saveJunta(informe, setLoader, history, etapaSeleccionada) {
  saveInformeJunta(informe).then(res => {
    if (res.data.code === 200) {
      setLoader({ loading: false, message: "" }); //Se desactiva el loader
      Modal.success({
          title: <p>El informe se ha actualizado con los datos capturados en la etapa <strong>{etapaSeleccionada.label}</strong>.</p>,
          content: <p>{res.data.message}</p>,
          okText: "Aceptar",
          onOk() {
              history.push("/admin/informes"); 
          }
      });        
    } else {
      setLoader({ loading: false, message: "" }); //Se desactiva el loader
      Modal.info({
        title: "Atención",
        content: <p>{res.data.causa}</p>
      });
    }
  });
}

//Elimina la solicitud acreditada
export function deleteInforme(idObservador, setReload) {
  deleteInformation(idObservador).then(res => {
    if (res.data.code === 200) {
      Modal.success({
        title: "Informe eliminado",
        content: <p>{res.data.message}</p>,
        okText: "Aceptar",
        onOk() {
          setReload(true);
        }
      });
    } else {
      Modal.error({
        title: "Error",
        content: <p>{res.data.causa}</p>
      });
    }
  });
}

export const Ast = () =>  {
    return <span style={{ color: "#f5222d" }}>*</span>;
}