import React, { useState, useEffect } from "react";
import { withRouter, Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "antd";
import { getLastLesson } from "../../../../api/courses";
import { Back } from "../../../../components/User/CourseOnline/Generals";
import Material from "../../../../components/User/CourseOnline/Material";
import {
  ButtonsMenu,
  MenuLessons,
} from "../../../../components/User/CourseOnline/Menu";
import useAuth from "../../../../hooks/useAuth";
import Loading from "../../../../components/Generals/Loading";
import { Title } from "../../../../components/User/CourseOnline/Title";
import { cleanlastLesson, lastLesson } from "../../../../redux/actions/course";
import { ACT_FINAL, CAL_MIN } from "../../../../utils/constanst";
import { setBlock } from "../../../../redux/actions/entity";

function Lessons(props) {
  const { idEntidad } = useSelector((state) => state.entidad);
  //Se valida que este seleccionada una entidad
  const validate = () => {
    return idEntidad ? true : false;
  };
  //Renderiza el contenido de la lección
  if (validate()) {
    return <RenderLessons {...props} />;
  } else {
    //Regrese a la pantalla principal de cursos en línea
    return <Redirect to="/ciudadano/capacitacion" />;
  }
}

const RenderLessons = (props) => {
  const { setLoader: setLoading } = props;
  //Se obtiene la información almacenada en el store
  const { idEntidad, entidad } = useSelector((state) => state.entidad);
  const { temario } = useSelector((state) => state.menu);
  //Hook para llevar el control de la lección que se encuentra
  const [current, setCurrent] = useState(0); //Se inicia el contador de las lecciones
  //Hook que muestra el componente de Loading
  const [loader, setLoader] = useState(true);
  const dispatch = useDispatch(); //Se declara el dispatch para ejecutar las acciones de redux
  //Se obtienen las propiedades del ciudadano
  const {
    ciudadano: { ciudadano },
  } = useAuth();
  const row = 18;
  const xxL = 6;

  useEffect(() => {
    window.scrollTo(0, 0); // para posicionar el scroll al inicio
    if (temario.length > 0) {
      //Se obtiene la última lección del ciudadano
      getLastLesson(ciudadano.id, idEntidad)
        .then((res) => {
          const { ultimaLeccion, calificacion } = res.data;
          //Se valida que exista la lección
          if (ultimaLeccion) {
            //Se valida que el temario ya este en el store para obtener la lección en la cual se quedo el ciudadano
            if (temario.length > 0) {
              //Se obtiene la posición de la lección en el temario
              let lesson = getIndex(temario, ultimaLeccion);
              //Valida si se encuentra en la evaluación y que su calificación sea aprobatoria
              if (ultimaLeccion === ACT_FINAL && calificacion < CAL_MIN) {
                //Si no es aprobatoria se queda en la pantalla de evaluación final
                setCurrent(lesson);
                dispatch(lastLesson(lesson));
              } else {
                //Avanza a la siguiente lección
                setCurrent(lesson + 1);
                dispatch(lastLesson(lesson + 1));
              }
            }
          } else {
            dispatch(cleanlastLesson()); //Se inicializa la acción por si se quedo en el store de Redux
          }
          setLoader(false); //Se desactiva el loader
        })
        .catch((e) => {
          console.log(e);
          setLoader(false);
        });
    }
    dispatch(setBlock(true)); //Al cargar el componente se bloquean los botones de navegación
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idEntidad, temario]);

  /**
   * Función que obtiene le indice de la lección en la cual se quedó el ciudadano
   * @param {*} array - Envía el temario
   * @param {*} idLeccion
   */
  const getIndex = (array, idLeccion) => {
    if (array.length > 0) {
      let index = array.findIndex((obj) => obj.idLeccion === idLeccion);
      return index;
    }
    return 0;
  };

  return (
    <>
      <Back path="/ciudadano/capacitacion" />
      {loader || temario.length <= 0 ? (
        <Loading />
      ) : (
        <Title
          entitie={entidad}
          lesson={temario[current].leccion}
          id={temario[current].idLeccion}
        />
      )}
      <Row gutter={24} className="material texto_parrafo">
        <Col span={row} xxl={row} xl={row} lg={row} md={row} sm={24} xs={24}>
          <div className="steps-content">
            {!loader && (
              <Material lesson={temario[current]} setLoading={setLoading} />
            )}
          </div>

          <div className="steps-action">
            {!loader && (
              <ButtonsMenu
                lesson={temario}
                index={current}
                setCurrent={setCurrent}
                idLesson={temario[current]}
                user={ciudadano.sub}
              />
            )}
          </div>
        </Col>

        <Col span={xxL} xxl={xxL} xl={xxL} lg={xxL} md={xxL} sm={24} xs={24}>
          {loader ? (
            <Loading />
          ) : (
            temario[current].idLeccion !== 11 && <MenuLessons index={current} />
          )}
        </Col>
      </Row>
    </>
  );
};

export default withRouter(Lessons);
