import React, { useEffect, useState } from 'react';
import { Row, Icon, Col } from 'antd';
import { withRouter, Link } from "react-router-dom";
import { getReporteOrg } from "../../../../api/reports";
import TableOrg from '../../../../components/Admin/Reports/TableOrg';
import moment from "moment";

function Organizations(props) {

    //const { proceso } = props;
    const { proceso: { proceso } } = props;
    const [reporteOrg, setReporteOrg] = useState({
        info: [],
        loading: false
    });

    useEffect(() => {
        setReporteOrg({ loading: true });
        let idProceso;
        let idDetalle;
        if (proceso !== undefined) {
            idProceso = proceso.idProcesoElectoral;
            idDetalle = proceso.idDetalleProceso;
        } else {
            idProceso = 0;
            idDetalle = 0;
        }

        getReporteOrg(idProceso, idDetalle).then((res) => {
            const dataSource = res.data;
            setReporteOrg({ info: dataSource, loading: false });
        }).catch((error) => {
            console.log(error);
        });
    }, [proceso]);

    const UltimaActualizacion = () => {
        const fechaHora = new Date();
        moment.locale("es");
        const fecha = moment(fechaHora).format("DD/MMMM/YYYY");
        const hora = moment(fechaHora).format("HH:mm:ss");
        return (
            <div style={{ fontSize: "12px", position: "absolute", marginTop: "20px", right: "0" }}>
                <Icon type="calendar" /> {fecha} <Icon type="clock-circle" /> {hora}
            </div>
        );
    }

    const OrganizacionesRec = () => {
        var total = 0;
        if (reporteOrg.info !== undefined && reporteOrg.info !== null && reporteOrg.info.length > 0) {
            total = reporteOrg.info.length;
        }
        return (
            <div style={{ position: "absolute", right: "0", fontSize: "24px", fontWeight: "bold" }}>
                {total}
            </div>
        );
    }

    return (
        <div>
            <Row>
                <Link to={"/admin/reportes"} className="back">
                    <Icon type="play-circle" theme="filled" rotate="180" /> Volver a
                    "Reportes"
                </Link>
            </Row>
            <Row style={{ marginTop: "20px" }}>
                <Col span={12}>
                    <h2>Organizaciones</h2>
                </Col>
                <Col span={12}>
                    <div style={{ float: "right", fontWeight: "bold", fontSize: "12px" }}>
                        Última actualización
                    </div>
                    <UltimaActualizacion />
                </Col>
            </Row>
            <Row>
                <Col span={12}>

                </Col>
                <Col span={12}>
                    <OrganizacionesRec />
                </Col>
            </Row>
            <Row style={{ marginTop: "25px" }}>
                <TableOrg data={reporteOrg.info}
                    loading={reporteOrg.loading}
                />
            </Row>
        </div>
    );
}

export default withRouter(Organizations);