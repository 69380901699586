import { basePath } from "./config";
import axios from "axios";
import { getAccessTokenApi } from "./auth";

export function getCursosParaAsistencia(idProceso, idDetalle, idEstado, idDistrito) {
    const url = `${basePath}/asistencia/cursosAntesFechaActual`;
    const jwt = getAccessTokenApi();
    return axios.post(
        url,
        { idProceso: idProceso, idDetalle: idDetalle, idEstado: idEstado, idDistrito: idDistrito, },
        { headers: { Authorization: jwt, Parametrizacion: idProceso } }
    );
}

export function getParticipantesCurso(idProceso, idDetalle, idCurso) {
    const url = `${basePath}/asistencia/participantesCurso`;
    const jwt = getAccessTokenApi();
    return axios.post(
        url,
        { idProceso: idProceso, idDetalle: idDetalle, idCurso: idCurso, },
        { headers: { Authorization: jwt, Parametrizacion: idProceso } }
    );
}

export function guardaAsistencia(asistencias, idProceso) {
    const url = `${basePath}/asistencia/guardaAsistencia`;
    const jwt = getAccessTokenApi();
    return axios.post(
        url, asistencias,
        { headers: { Authorization: jwt, Parametrizacion: idProceso } }
    );
}

export function eliminaAsistencia(curso, idProceso) {
    const url = `${basePath}/asistencia/eliminaAsistencia`;
    const jwt = getAccessTokenApi();
    return axios.post(
        url, curso,
        { headers: { Authorization: jwt, Parametrizacion: idProceso } }
    );
}

export function getAsistenciaCurso(idProceso, idDetalle, idCurso) {
    const url = `${basePath}/asistencia/asistenciaCurso`;
    const jwt = getAccessTokenApi();
    return axios.post(
        url,
        { idProceso: idProceso, idDetalle: idDetalle, idCurso: idCurso, },
        { headers: { Authorization: jwt, Parametrizacion: idProceso } }
    );
}

export function getAsistenciaModifica(idProceso, idDetalle, idCurso) {
    const url = `${basePath}/asistencia/asistenciaModifica`;
    const jwt = getAccessTokenApi();
    return axios.post(
        url,
        { idProceso: idProceso, idDetalle: idDetalle, idCurso: idCurso, },
        { headers: { Authorization: jwt, Parametrizacion: idProceso } }
    );
}

export function modificaAsistencia(asistencias, idProceso) {
    const url = `${basePath}/asistencia/modificaAsistencia`;
    const jwt = getAccessTokenApi();
    return axios.post(
        url, asistencias,
        { headers: { Authorization: jwt, Parametrizacion: idProceso } }
    );
}
