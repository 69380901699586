import React, { useState, useEffect } from "react";
import { Row, Col, Radio } from "antd";
import { withRouter } from "react-router-dom";
import useAuth from "../../../hooks/useAuth";
import { rol_captura, rol_consulta, rol_modifica, rol_descarga, rol_borrar } from "../../../utils/Information/roles";
import { getTotalInformesPorReportar, getInformesReportados, getInformesPorReportar } from "../../../api/information";
import { esJuntaLocal } from "../../../components/Generals/functions";
import TableInformation from "../../../components/Admin/Information/TableInformation";
import TableRequest from "../../../components/Admin/Information/TableRequest";
import "./Information.scss";

function Information(props) {
  const [rolModulo, setRolModulo] = useState({ captura: false, modifica: false, consulta: false, descarga: false, borrar: false, });
  const { user: { rolUsuario } } = useAuth();
  const { proceso, setLoader } = props;
  const [reload, setReload] = useState(false);
  const [tipoListado, setTipoListado] = useState(0);
  const [total, setTotal] = useState(0);
  const [information, setInformation] = useState({ list: [], loading: false });      // tipo_listado (0)
  const [requests, setRequests] = useState({ list: [], loading: false });            // tipo_listado (1)

  useEffect(() => {
    if (proceso.distrito !== undefined && proceso.distrito !== null) {
      //Valida los permisos que tienen el usuario
      let captura = false;
      let modifica = false;
      let consulta = false;
      let descarga = false;
      let borrar = false;
      captura = rol_captura.includes(rolUsuario);
      modifica = rol_modifica.includes(rolUsuario);
      consulta = rol_consulta.includes(rolUsuario);
      descarga = rol_descarga.includes(rolUsuario);
      borrar = rol_borrar.includes(rolUsuario);

      if (esJuntaLocal(rolUsuario, proceso.distrito.idDistrito)) {
        captura = false;
        modifica = false;
        borrar = false;
      }
      setRolModulo(() => ({ captura, modifica, consulta, descarga, borrar }));
    }
  }, [rolUsuario, proceso]);

  useEffect(() => {
    if (proceso.distrito !== undefined && proceso.distrito !== null) {
      setInformation({ loading: true });
      getTotalInformesPorReportar(proceso.proceso.idProcesoElectoral, proceso.proceso.idDetalleProceso, 
        proceso.estado.idEstado, proceso.distrito.idDistrito).then((res) => {
          setTotal(res.data);
      }).catch((error) => {
        console.log(error); 
        setTotal(0);
      }); 
      getInformesReportados(proceso.proceso.idProcesoElectoral, proceso.proceso.idDetalleProceso, 
        proceso.estado.idEstado, proceso.distrito.idDistrito).then((res) => {
          const dataSource = [];
          if (res.data !== null && res.data.length > 0) {
            res.data.map((reg, index) => {
              return dataSource.push({ key: index, indice: index + 1, ...reg, });
            });
          }
          setInformation({ list: dataSource, loading: false });          
      }).catch((error) => {
        console.log(error);
        setInformation({ list: [], loading: false });     
      });      
    }
    setTipoListado(0);
    setReload(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [proceso, reload]);

  useEffect(() => {
    if (proceso.distrito !== undefined && proceso.distrito !== null && tipoListado === 1) {
       // ir por la lista de las solicitudes pendientes por informar     
       setRequests({ loading: true });
       getInformesPorReportar(proceso.proceso.idProcesoElectoral, proceso.proceso.idDetalleProceso, 
        proceso.estado.idEstado, proceso.distrito.idDistrito).then((res) => {
          const dataSource = [];
          if (res.data !== null && res.data.length > 0) {
            res.data.map((reg, index) => {
              return dataSource.push({ key: index, indice: index + 1, ...reg, });
            });
          }
          setRequests({ list: dataSource, loading: false });          
      }).catch((error) => {
        console.log(error);
        setRequests({ list: [], loading: false });     
      });   
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [proceso, tipoListado]);  

  const onChangeRadio = (e) => {
    setTipoListado(e.target.value);
  };

  function InformesReportados() {
    return (
    <div>
        <Row className="rowListRequests">
            <Col span={24}>
                <h3>Lista de Informes reportados</h3>
            </Col>
        </Row>
        <Row className="rowTableRequests">
            <Col span={24}>
                <div>
                    <TableInformation
                    data={information.list}              
                    rolModulo={rolModulo}
                    loading={information.loading}
                    setReload={setReload}
                    setLoader={setLoader}
                    />
                </div>
            </Col>
        </Row>
    </div>
    );
  };

  function InformesPorReportar() {
    return (
    <div>
        <Row className="rowListRequests">
            <Col span={24}>
                <h3>Lista de observadores pendientes por informar</h3>
            </Col>
        </Row>
        <Row className="rowListRequests">
            <Col span={24}>
                <div>
                    <TableRequest
                    data={requests.list}              
                    rolModulo={rolModulo}
                    loading={requests.loading}
                    />
                </div>
            </Col>
        </Row>
    </div>
    );
  };
  
  return (
    <div>
      <Row>
        <h1>Registro de Informes</h1>
        <Col span={24}>
          <div className="cardSummary">
            <p className="pShortStyle">
              <span className="quantity">{total}</span> solicitudes pendientes por realizar el informe (observadores/as acreditados).
            </p>
          </div>
        </Col>
      </Row>
      <Row className="rowListRequests">
          <Col span={3}>
            Mostrar solicitudes
          </Col>
      </Row>
      <Row>
          <Col span={12} className="colSpace">
            <Radio.Group value={tipoListado} onChange={onChangeRadio}>
                <Radio value={0}>Informes</Radio>
                <Radio value={1}>Por informar</Radio>
            </Radio.Group>
          </Col>
      </Row>
      {tipoListado === 0 ? InformesReportados() : InformesPorReportar()}
    </div>
  );
}

export default withRouter(Information);