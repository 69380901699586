import React from "react";
import { Layout, Button, Icon } from "antd";
import { Route, Switch } from "react-router-dom";
import Header from "../components/User/Header/Header";
import Footer from "../components/User/Footer/Footer";
import { ReactComponent as LogoHeader } from "../assets/img/logoINE_bco.svg";
import useAuth from "../hooks/useAuth";
import { logoutCiudadano } from "../api/auth";

const LayoutPortal = (props) => {
  const { routes } = props;
  const { Content } = Layout;
  const {
    ciudadano: { ciudadano },
  } = useAuth();

  const logoutUser = () => {
    const status = logoutCiudadano();
    if (status) {
      window.location.href = "/"; //Se redirecciona al home
    }
  };    
  return (
    <Layout style={{ minHeight: "100vh", background: "#FFFFFF" }}>
      <Header Logo={LogoHeader} ciudadano={ciudadano} />
      <div className="barraUsuario">
        {ciudadano && (
          <div>
            Hola{" "}
            <strong>
              {ciudadano.nombreObservador
                ? ciudadano.nombreObservador
                : ciudadano.sub}{" "}
            </strong>
            <Button onClick={logoutUser} className="btnCerrarsesion">
              <Icon type="logout" />
              Cerrar sesión
            </Button>
          </div>
        )}
      </div>
      <Content style={{ padding: "0 50px", marginTop: "10px" }}>
        {/**Contenido, se vuelven a iterar las rutas para poder navegar entre ellas */}
        <LoadRoutes routes={routes} />
      </Content>
      <Footer />
    </Layout>
  );
};

//Función que itera las rutas individualmente
function LoadRoutes({ routes }) {
  return (
    <Switch>
      {routes.map((route, index) => (
        <Route
          key={index}
          path={route.path}
          exact={route.exact}
          component={route.component}
        />
      ))}
    </Switch>
  );
}

export default LayoutPortal;
