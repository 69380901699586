import ExcelJS from 'exceljs/dist/es5/exceljs.browser.js'
import { saveAs } from 'file-saver'

//Estilos generales
const fuenteTitulo = {
  name: "Arial",
  size: 10,
  bold: true
};

const fuenteDatos = {
  name:"Arial",
  size: 10
}

const estiloGris = {
  type: "pattern",
  pattern: "solid",
  fgColor: { argb: "CCC2C2C2" },
  bgColor: { argb: "CCC2C2C2" }
};

export async function descargaExcel(sheetName, nombreReporte, datos, proceso, sistema) {
  //Creación del workbook
  const wb = new ExcelJS.Workbook()
  wb.creator = 'Sistema de Observadores Electorales 11.0';
  wb.lastModifiedBy = 'Sistema de Observadores Electorales';
  wb.created = new Date();
  //Creación de nueva hoja
  const sheet = wb.addWorksheet(sheetName);
  //Datos generales del reporte
  sheet.mergeCells("A1:Q1");
  sheet.getCell("A1").value = "Proceso Electoral Federal 2020 - 2021";
  sheet.getCell("A1").font = fuenteTitulo;
  sheet.getCell("A1").alignment = { horizontal: "center"};
  sheet.mergeCells("A2:Q2");
  sheet.getCell("A2").value = nombreReporte;
  sheet.getCell("A2").font = fuenteTitulo;
  sheet.getCell("A2").alignment = { horizontal: "center"};
  var fila;
  if(proceso.distrito.idDistrito !== null && proceso.distrito.idDistrito !== undefined) {
    sheet.unMergeCells("A1");
    sheet.mergeCells("A1:J1");
    sheet.getCell("A1").value = "Proceso Electoral Federal 2020 - 2021";
    sheet.unMergeCells("A2");
    sheet.mergeCells("A2:J2");
    sheet.getCell("A2").value = nombreReporte;
    const rowEstadoDistrito = sheet.getRow(3);
    if(proceso.distrito.idDistrito !== 0) {
      rowEstadoDistrito.values = [
        "Estado:", proceso.estado.nombreEstado, "Distrito Federal:", proceso.distrito.idDistrito +". "+ proceso.distrito.nombreDistrito
      ]
    } else {
      rowEstadoDistrito.values = [
        "Estado:", proceso.estado.nombreEstado, "Junta Local"
      ]
    }
    sheet.getCell("A3").font = fuenteTitulo;
    sheet.getCell("A3").fill = estiloGris;
    sheet.getCell("B3").font = fuenteDatos;
    sheet.getCell("C3").font = fuenteTitulo;
    sheet.getCell("C3").fill = estiloGris;
    sheet.getCell("D3").font = fuenteDatos;
    const rowFechaGeneracion = sheet.getRow(4);
    rowFechaGeneracion.values = [
      "Fecha y horario de generación:", `${sistema.fecha} ${sistema.hora}`
    ]
    sheet.getCell("A4").font = fuenteTitulo;
    sheet.getCell("A4").fill = estiloGris;
    sheet.getCell("B4").font = fuenteDatos;
    //Titulo de los datos del reporte
    const rowTituloDatos = sheet.getRow(5);
    rowTituloDatos.height = 42;
    rowTituloDatos.values = [
      "Nombre del solicitante", "Clave de Elector", "Folio", "Fecha en que completó el curso", 
      "Tipo de solicitud", "Solicitud recibida por", "Procedencia de la solicitud", 
      "Correo electrónico", "Teléfono"
    ];
    rowTituloDatos.font = fuenteTitulo;
    rowTituloDatos.eachCell(function(cell) {
      cell.fill = estiloGris;
      cell.alignment = { vertical: "middle", horizontal: "center", wrapText: true };
    });
    //Datos del reporte
    fila = 6;
    datos.forEach((e) => {
      const rowDatos = sheet.getRow(fila);
      rowDatos.font = fuenteDatos;
      rowDatos.values = [
        e.nombreSolicitante, e.claveElector, e.folio, e.fechaAprobacionCurso, e.tipoSolicitud,
        e.origenSolicitud, e.procedencia, e.correo, e.telefono
      ];
      fila++;
    });
    sheet.getColumn(1).width = 30;
    sheet.getColumn(2).width = 30;
    sheet.getColumn(3).width = 15;
    sheet.getColumn(4).width = 15;
    sheet.getColumn(5).width = 10;
    sheet.getColumn(6).width = 15;
    sheet.getColumn(7).width = 15;
    sheet.getColumn(8).width = 20;
    sheet.getColumn(9).width = 20;
  }

  //Creación del excel
  const buf = await wb.xlsx.writeBuffer();
  saveAs(new Blob([buf]), nombreReporte + ".xlsx");
}