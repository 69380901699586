//--------------------------------LECCIÓN 2 ----------------------------------
export const getAyuntamiento = (idEntidad, status = false) => {
  const ayuntamiento = [1, 2, 3, 5, 6, 7, 8, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32];
  const juntas = [4];

  if (juntas.includes(idEntidad)) {
    return "los ayuntamientos y su colegiado auxiliar la Junta Municipal.";
  }

  //   const cdmx = [9];
  if (idEntidad === 9) {
    if (status) {
      return "los titulares de las Alcaldías y las y los integrantes de los consejos.";
    }
    if (ayuntamiento.includes(idEntidad)) {
      return "los ayuntamientos";
    } else {
      return "las alcaldías";
    }
  } else {
    return ayuntamiento.includes(idEntidad)
      ? "los ayuntamientos"
      : "las alcaldías";
  }
};
export const getAyuntamiento1 = (idEntidad, status = false) => {
  const ayuntamiento = [1, 2, 3, 5, 6, 7, 8, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32];
  const juntas = [4];

  if (juntas.includes(idEntidad)) {
    return "los integrantes de los ayuntamientos y las juntas Municipales";
  }

  //   const cdmx = [9];
  if (idEntidad === 9) {
    if (status) {
      return "los titulares de las Alcaldías y las y los integrantes de los concejos";
    }
    if (ayuntamiento.includes(idEntidad)) {
      return "los integrantes de los ayuntamientos";
    } else {
      return "los integrantes de las alcaldías";
    }
  } else {
    return ayuntamiento.includes(idEntidad)
      ? "los integrantes de los ayuntamientos"
      : "los integrantes de las alcaldías";
  }
};

export const getMunicipioText = (idEntidad) => {
  const municipio = [1, 2, 3, 4, 5, 6, 7, 8, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31,32];
  const cdmx = [9];
  //CDMX
  if (cdmx.includes(idEntidad)) {
    return "las demarcaciones territoriales";
  }
  //Generales
  return municipio.includes(idEntidad) ? "los Municipios" : "las Alcaldías";
};

export const getCircunscripcion = (idEntidad) => {
  const general = [1, 5, 6, 8, 10, 11, 12, 13, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 28, 29, 30, 31, 32];
  const otros = [2];
  const estados = [3, 15];
  const juntas = [4];
  const delimitacion = [7];
  const otros2 = [27];
  const gubernatura = [14];

  if (delimitacion.includes(idEntidad)) {
    return "y circunscripción electoral, es la delimitación geográfica conformada por todo el estado.";
  }

  if (juntas.includes(idEntidad)) {
    return "y la Entidad Federativa comprende todo el territorio del estado, donde se elige una Gubernatura y Circunscripciones electorales que considera toda la extensión territorial del estado.";
  }

  if(otros2.includes(idEntidad)){
    return "y las Circunscripciones electorales que son las divisiones territoriales formadas por distritos electorales.";
  }

  //Baja california
  if (otros.includes(idEntidad)) {
    return " y 8 Circunscripciones electorales que considera toda la extensión territorial del estado, donde se eligen 8 Diputados por el principio de representación proporcional.";
  }

  if (estados.includes(idEntidad)) {
    if (idEntidad === 15) {
      return ", la Entidad Federativa y la Circunscripción electoral que considera toda la extensión territorial del estado.";
    } else
      return ", la Entidad Federativa y la Circunscripción electoral que considera toda la extensión territorial del estado.";
  }

  if(gubernatura.includes(idEntidad)){
    return ", la Entidad Federativa, donde se elige una Gubernatura y la Circunscripción electoral que considera toda la extensión territorial del estado";
  }
  return general.includes(idEntidad)
    ? " y la Circunscripción electoral que considera toda la extensión del estado."
    : " las Circunscripciones para la elección de concejalías y la Circunscripción electoral que considera toda la extensión de la Ciudad de México, para las diputaciones locales por el principio de representación proporcional.";
};
//--------------------------------FIN LECCIÓN 2 ----------------------------------
//--------------------------------LECCIÓN 4 --------------------------------------

export const getTextAlcaldia = (idEntidad) => {
  const alcaldia = [2, 7, 9, 15];
  const municipal = [1,3,4,5,6,8,10,11,12,13,14,16,17,19,20,21,22,23,24,25,26,2728,29,30,31,32];
  const especial = [18]
  //CDMX. BJN
  if (alcaldia.includes(idEntidad)) {
    return ", distritales y de las alcaldías";
  }
  if(municipal.includes(idEntidad)){
    return ", distritales y municipales";
  }
  if(especial.includes(idEntidad)){
    return " y municipales"
  }
};

export const getPartidos = (idEntidad) => {
  const cero = [4, 12, 16];
  const uno = [6, 8, 9, 10, 11, 15, 19, 22, 25, 26, 31];
  const dos = [1, 2, 20, 24];
  const tres = [14, 18, 21, 23];
  const cuatro = [3, 5, 7, 13, 30];
  const doce = [17];
  const cinco = [29, 32];
  const especial = [27];			 
  const especial2 = [28];			 

  if (cero.includes(idEntidad)) {
    return "no hay registrados partidos políticos.";
  }

  if (uno.includes(idEntidad)) {
    return "hay registrado 1 partido político.";
  }
  if (dos.includes(idEntidad)) {
    return "hay registrados 2 partidos políticos.";
  }
  if (tres.includes(idEntidad)) {
    return "hay registrados 3 partidos políticos.";
  }
  if (cuatro.includes(idEntidad)) {
    return "hay registrados 4 partidos políticos.";
  }
  if (cinco.includes(idEntidad)) {
    return "hay registrados 5 partidos políticos.";
  }								  
  if (doce.includes(idEntidad)) {
    return "hay registrados 12 partidos políticos.";
  }
  if (especial.includes(idEntidad)) {
    return "no hay registrado ningún partido político.";
  }
  if (especial2.includes(idEntidad)) {
    return "no hay registrado partido político alguno.";
  }

};

export const getLocalText = (idEntidad) => {
  const local = [1, 2, 3, 5, 6, 7, 8, 9, 10, 11, 13, 14, 15, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32];
  if(local.includes(idEntidad))
    return " y local";
}

export const getCandComunes = (idEntidad, textEntidad) => {
  const comunes = [1, 3, 6, 7, 8, 9, 10, 12, 13, 15, 16, 17, 18, 20, 21, 22, 23, 25, 26, 27, 28, 31];
  const alianza = [24];
  const especial = [29];

  if(comunes.includes(idEntidad))
    return "En "+ textEntidad +" se contempla que los partidos políticos también pueden participar mediante candidaturas comunes.";
  
  if(alianza.includes(idEntidad))
    return "En "+ textEntidad +" se contempla que los partidos políticos también pueden participar mediante alianzas partidarias.";

    
  if(especial.includes(idEntidad))
  return "En "+ textEntidad +" se contempla que los partidos políticos locales también pueden participar mediante candidaturas comunes.";
}
//--------------------------------FIN LECCIÓN 4 --------------------------------------
//--------------------------------LECCIÓN 5 --------------------------------------
export const getOrgano = (idEntidad) => {
  const otro = [4];
  if(otro.includes(idEntidad)){
    return "el órgano jurisdiccional."
  }else
  return "el Tribunal Electoral del Poder Judicial de la Federación.";
}
