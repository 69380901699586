import { Icon } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { getIntroduction } from "../../../../api/courses";
import { GuiaMaterial, MatApoyoLeccion1 } from "../Generals";

export default function Lesson1({ data, setLoading }) {
  const [intro, setIntro] = useState({});
  const { testimonioObservador } = intro;
  const isMounted = useRef(true);
  // let copyArray = data;
  // let array = copyArray.length && [data[0], data[1]];

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0); // para posicionar el scroll al inicio
    getIntroduction()
      .then((res) => {
        isMounted.current && setIntro(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  function download(file) {
    let a = document.createElement("a"); //Create <a>
    a.href = `data:application/pdf;base64,${file}`; //Image Base64 Goes here
    a.download = `Testimonio – Informe de una observadora.pdf`; //File name Here
    a.click(); //Downloaded file
  }

  return (
    <>
      <p className="bold">
        ¡Vamos a comenzar con tu capacitación! ¿Estás listo/a? Haz clic en el
        siguiente video.
      </p>
      {data.length && <MatApoyoLeccion1 array={[data[0], data[1]]} />}
      <br />
      <br />
      <div className="texto_parrafo">
        <p className="bold">
          Para profundizar tus conocimientos sobre la observación electoral
          puedes revisar el{" "}
          <GuiaMaterial setLoading={setLoading}>
            CAPÍTULO I DE TU GUÍA TEMÁTICA PARA CAPACITAR A LAS Y LOS
            OBSERVADORES ELECTORALES
          </GuiaMaterial>{" "}
          que está disponible en los materiales de consulta de este curso.
        </p>
        <p className="bold">
          Como observador u observadora electoral puedes presentar los informes
          de todo lo que observaste durante el proceso electoral, te invitamos a
          conocer el testimonio de una ciudadana, que como tú desempeñó esta
          importante función.
        </p>

        <div className="matTitle">Material de apoyo</div>
        <dl className="matApoyo">Testimonio – Informe de una observadora</dl>
        <embed
          src={`data:application/pdf;base64,${testimonioObservador}`}
          type="application/pdf"
          width="100%"
          height="900px"
        />
        <button
          className="btnDescargar"
          style={{ marginLeft: "0px" }}
          onClick={(e) => download(testimonioObservador)}
        >
          Descargar Testimonio – Informe de una observadora <Icon type="download" />
        </button>
        <p className="bold">
          Ahora que tienes un panorama general de la observación electoral y los
          principios que la rigen, realiza la actividad de autoevaluación para
          reforzar lo que aprendiste. Después podrás continuar con la siguiente
          lección.
        </p>
        <br />
      </div>
    </>
  );
}
