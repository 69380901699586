import React, {useState} from "react";
import { withRouter } from "react-router-dom";
import { Icon, Form, Upload, Button } from "antd";
import {sendGluster} from "../../../utils/Others/function";

function Gluster(props) {

   const [document, setDocument] = useState([
      { file: [], nameFile: "", url: "" },
    ]);

    const file = {
      name: "file",
      multiple: false,
      showUploadList: {
        showDownloadIcon: false,
      },
      onRemove: (file) => {
        setDocument((state) => {
          return {
            file: [],
          };
        });
      },
      
    beforeUpload: (file) => {
      if (file) {
        setDocument((state) => ({
          file: [file],
        }));
      }
      return false;
    },
      document,
    };
      //Función que guarda la organización
  const addFile = (event) => {
   event.preventDefault();
     //Se valida el proceso
     console.log("subiendo archivo");
     props.form.validateFields((err, values) => {
        sendGluster( document.file[0]);
        setDocument([]);
     });
 };

  return (
    <div>
      <Form>
        <Form.Item style={{ marginTop: 20 }}>
          <Upload {...file} accept=".jrxml" fileList={document.file}>
            <Button type="default" disabled={document.status}>
              <Icon type="upload" />
              Cargar
            </Button>
          </Upload>
        </Form.Item>
        <Form.Item>
        <Button type="primary"
                    onClick={(e) => {addFile(e);}}>
                    Guardar
                  </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

export default withRouter(Form.create()(Gluster));
