import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import {
  getAyuntamiento,
  getAyuntamiento1,
} from "../../../../utils/CourseOnline/Util";
import { GuiaMaterial, MatApoyo } from "../Generals";
import { useLesson2 } from "../../../../hooks/useLesson2";

export default function Lesson2({ data, setLoading }) {
  const isMounted = useRef(true);
  //Se obtiene el Entidad seleccionada del store de redux
  const { idEntidad } = useSelector((state) => state.entidad);
  //State-Redux

  const {
    material,
    entidadMat: { idEntidad: id },
  } = useSelector((state) => state.material);
  const { parrafo } = useLesson2(material ? id : idEntidad);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0); // para posicionar el scroll al inicio
  }, []);

  const getIdEntity = () => {
    return material ? id : idEntidad;
  };

  return (
    <>
      <div className="texto_parrafo">
        <p>
          El Sistema Electoral Mexicano es el conjunto de instituciones, normas
          y procedimientos involucrados en el proceso electoral, encaminados a
          la integración y renovación periódica de las y los integrantes de los
          poderes Ejecutivo y Legislativo de la Unión y de las entidades
          federativas, así como de las y los integrantes de los ayuntamientos en
          los estados y de las alcaldías en la Ciudad de México. En este
          sentido, el sistema electoral mexicano comprende dos ámbitos:
        </p>
        <ul>
          <li>El Sistema Electoral Federal.</li>
          <li>El Sistema Electoral Local.</li>
        </ul>
        <p>
          Para conocer cómo se integra cada uno revisa el siguiente material de
          apoyo.
        </p>
        {/* Cambiar */}
        {data.length && (
          <MatApoyo
            // array={[
            //   { titulo: "Integración del Sistema Electoral Federal" },
            //   {
            //     titulo: `Integración del Sistema Electoral Local de ${capitalizeWord(
            //       getTextEntity()
            //     )}`,
            //   },
            // ]}
            array={[data[0], data[1]]}
          />
        )}
        <p>
          Como sabes, México es una república representativa, democrática, laica
          y federal, integrada por 32 entidades federativas, libres y soberanas
          respecto a su régimen interno de gobierno, pero unidas en una
          Federación, y su organización política tanto a nivel de la federación
          como a nivel local en cada una de las 32 entidades federativas se
          ajusta al esquema clásico de división y separación de funciones entre
          los poderes Ejecutivo, Legislativo y Judicial.
        </p>
        <p>
          Mediante el siguiente material de apoyo, podrás identificar la
          organización política a nivel federal y local.
        </p>
        {/* <MatApoyo
          array={[
            { titulo: "Organización política federal" },
            {
              titulo: `Organización política local de ${capitalizeWord(
                getTextEntity()
              )}`,
            },
          ]}
        /> */}
        {data.length && <MatApoyo array={[data[2], data[3]]} />}
        <p>
          Los municipios, en los 31 estados de la República Mexicana y las
          demarcaciones territoriales en la Ciudad de México son la base de la
          división territorial y de la organización político-administrativa de
          cada entidad. El órgano de representación popular encargado de las
          funciones de gobierno y administración del municipio es el
          Ayuntamiento, en tanto que el órgano en la demarcación territorial es
          la Alcaldía.
        </p>
        <p>
          Revisa el siguiente material de apoyo para conocer cómo se integran{" "}
          {getAyuntamiento(getIdEntity(), false)}{" "}
          {getIdEntity() !== 4 ? "de tu entidad." : ""}
        </p>
        {/* <MatApoyo
          array={[
            idEntidad !== 9 && {
              titulo: `Los Ayuntamientos en ${capitalizeWord(getTextEntity())}`,
            },
            idEntidad === 9 && {
              titulo: "Las Alcaldías en la Ciudad de México",
            },
          ]}
        /> */}
        {data.length && <MatApoyo array={[data[4]]} />}
        <p>
          Recuerda que únicamente los poderes legislativo y ejecutivo se
          renuevan mediante elección popular, al igual que{" "}
          {getAyuntamiento1(getIdEntity(), true)}.
        </p>
        <p>
          Ahora que ya sabes cómo se conforma el sistema electoral del país y
          cómo se organiza políticamente, analicemos la importancia de ejercer
          tu derecho al voto, toda vez que este constituye no solo una
          obligación, sino uno de los derechos político-electorales que han sido
          reconocidos como Derechos Humanos.
        </p>
        <p>Por eso, debes tener presente qué se requiere para poder votar.</p>
        <p>
          En el siguiente material de apoyo te decimos cuáles son los requisitos
          para poder votar y cuáles son las características del voto.
        </p>
        {/* <MatApoyo
          array={[
            { titulo: `Requisitos para votar y características del voto` },
          ]}
        /> */}
        {data.length && <MatApoyo array={[data[5]]} />}
        <p>
          El próximo 6 de junio de 2021, se celebrarán elecciones concurrentes,
          es decir, que las elecciones locales se realizarán en la misma fecha
          de la elección federal. ¿Ya sabes qué cargos se elegirán en la Jornada
          Electoral?
        </p>
        <p>
          Da clic en el siguiente material de apoyo, conoce los cargos que se
          elegirán.
        </p>
        {/* <MatApoyo
          array={[
            { titulo: "Cargos federales a elegir" },
            {
              titulo: `Cargos locales a elegir en ${capitalizeWord(
                getTextEntity()
              )}`,
            },
          ]}
        /> */}
        {data.length && <MatApoyo array={[data[6], data[7]]} />}
        <p>
          Los cargos que se elegirán se designarán mediante dos principios:
          <b> Mayoría Relativa y Representación Proporcional:</b>
        </p>
        <ul>
          <li>
            <b>Mayoría Relativa:</b> Cuando gana el partido o candidatura que
            obtiene el mayor número de votos en determinada área geográfica.
          </li>
          <li>
            <b>Representación Proporcional:</b> Es la asignación de cargos con
            base en el porcentaje de votación general obtenida por cada partido
            político en una región geográfica.
          </li>
        </ul>
        <p>
          Para comprender cómo se implementan estos principios es importante que
          conozcas el <b>marco geográfico electoral</b>, el cual se refiere al
          espacio geográfico que determina la distribución de las y los
          ciudadanos que pueden votar en el territorio nacional y que sirve de
          base para la planeación y organización de los procesos electorales.
          Los distintos niveles de desagregación regional en que se clasifica el
          país son:
        </p>
        <ul>
          <li>
            <b>Secciones electorales: </b> Es la delimitación territorial más
            pequeña de la geografía electoral, cada una tiene como mínimo 100
            electores y como máximo 3,000 y en cada sección electoral se
            instala, por lo menos, una casilla.
          </li>
          <li>
            <b>Distritos electorales federales:</b> El territorio nacional se
            divide en 300 distritos electorales, por cada distrito electoral
            uninominal se elige por el principio de mayoría relativa una
            diputación federal.
          </li>
          <li>
            <b>Entidades federativas:</b> Cada uno de los 32 estados miembros de
            la República federal, corresponden a la división política del
            territorio nacional.
          </li>
          <li>
            <b>Circunscripción plurinominal:</b> División territorial formada
            por 5 circunscripciones para la asignación de diputaciones por el
            principio de Representación Proporcional.
          </li>
        </ul>
        <p>{parrafo}</p>
        <p className="bold">
          Los detalles del marco geográfico electoral federal y local de tu
          entidad los puedes consultar en el{" "}
          <GuiaMaterial setLoading={setLoading}>
            CAPÍTULO II DE TU GUÍA TEMÁTICA PARA CAPACITAR A LAS Y LOS
            OBSERVADORES ELECTORALES
          </GuiaMaterial>{" "}
          y sus respectivos anexos que se encuentran a tu disposición en el
          material de consulta.
        </p>
        <p>
          Antes de realizar la actividad respectiva puedes consultar el
          siguiente material de apoyo para reforzar lo que aprendiste.
        </p>
        {/* <MatApoyo
          array={[{ titulo: "Presentación 2. Sistema Electoral Mexicano" }]}
        /> */}
        {data.length && <MatApoyo array={[data[8]]} />}
        <p>
          ¡Felicidades, has concluido con esta lección! A continuación, realiza
          la actividad de autoevaluación de la Lección 2.
        </p>
      </div>
    </>
  );
}
