import React from "react";
import { Col, Icon, message, Row } from "antd";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { RenderDocument } from "./Material";
import { getDownloadGuia } from "../../../api/courses";
import { MSG_GUIA, MSN_GUARDAR } from "../../../utils/constanst";
import { capitalizeWord } from "../../Generals/functions";
import { saveAs } from "file-saver";

/**
 * Componente que muestra en lista las entidades.
 * @param {*} array de entidades
 */
export const RenderEntities = ({ entidades }) => {
  return (
    <ul>
      {entidades && entidades.map((item, i) => <li key={i}>{item.entidad}</li>)}
    </ul>
  );
};

/**
 * Componente que permite regresar a una url en especifico
 * @param {*} path
 */
export const Back = ({ path }) => {
  return (
    <Link to={path} className="back">
      <Icon type="play-circle" theme="filled" rotate="180" /> Regresar
    </Link>
  );
};

/**
 * Función que renderiza los diferentes tipos de materiales de cada lección
 * array de los materiales por lección y entidad
 */
export const MatApoyo = ({ array, number = false, getNumber }) => {
  return (
    <>
      {array.length > 0 &&
        array.map((item, i) => (
          <Row gutter={24} key={i} justify="center" className="material">
            <Col span={24} xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
              {item && (
                <RenderDocument
                  doc={item}
                  number={number}
                  getNumber={getNumber}
                />
              )}
            </Col>
          </Row>
        ))}
    </>
  );
};

//Componente para la lección 1
export const MatApoyoLeccion1 = ({ array, number = false, getNumber }) => {
  return (
    <>
      {array.length > 0 &&
        array.map((item, i) => (
          <Row gutter={24} key={i} justify="center" className="material">
            <Col span={24} xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
              {i === 1 ? (
                <>
                  <p>
                    <b>
                      A continuación, revisa el siguiente material de apoyo para
                      reforzar lo que aprendiste.
                    </b>
                  </p>
                </>
              ) : (
                ""
              )}
              {item && (
                <RenderDocument
                  doc={item}
                  number={number}
                  getNumber={getNumber}
                />
              )}
            </Col>
          </Row>
        ))}
    </>
  );
};

//Componente que valida el botón de regreso de acuerdo a la pantalla en la que se encuentre
export const GuiaMaterial = ({ children, setLoading }) => {
  //Se obtiene el valor para validar en que pantalla se encuentra
  const { material } = useSelector((state) => state.material);
  //Se obtiene la entidad seleccionada del state-Redux
  const { idEntidad, entidad } = useSelector((state) => state.entidad);

  //Función que descarga la guia de capacitación
  const download = () => {
    //Se pone el loading de procesando.. en la pantalla
    setLoading({ loading: true, message: MSN_GUARDAR });
    //Se obtiene la guía de acuerdo a la entidad
    getDownloadGuia(idEntidad)
      .then((res) => {
        if (res.data) {
          setLoading({ loading: false, message: "" }); //Se desactiva el loader
          //Se guarda el archivo en pdf
          saveAs(
            new Blob([res.data]),
            `${MSG_GUIA} ${capitalizeWord(entidad)}.pdf`
          );
        }
      })
      .catch((e) => {
        setLoading({ loading: false, message: "" }); //Se desactiva el loader
        message.error("El archivo no se encuentra disponible");
      });
  };
  return (
    <>
      {/* Si se encuentra en la pantalla de recibir capacitación se descargará la guía correspondiente. */}
      {!material ? (
        <button onClick={(e) => download()} className="btnMaterial bold">
          {children}
        </button>
      ) : (
        <>
          {/*De lo contrario solo te pone al principio de la página */}
          <button
            onClick={(e) => window.scrollTo(0, 0)}
            className="btnMaterial bold"
          >
            {children}
          </button>
        </>
      )}
    </>
  );
};
