import {basePath} from './config';
import axios from 'axios';
import {getAccessTokenApi} from './auth';

export function getEstadisticasPortal(){
    const url = `${basePath}/portal/estadisticas`;
    return axios.post(url, {}, {});
}

export function getEstadisticasLogin(){
    const url = `${basePath}/login/estadisticas`;
    return axios.post(url, {}, {});
}


export function getEstadisticasHome(proceso){
    const jwt = getAccessTokenApi();
    const url = `${basePath}/home/estadisticas`;
    return axios.post(url, {
        idProceso: proceso.idProceso,
        idDetalle: proceso.idDetalle,
        idEstado: proceso.idEstado,
        idDistrito: proceso.idDistrito,
    }, {headers: { Authorization: jwt }});
}