import React from "react";
import { Modal } from "antd";
import { save, deleted, update } from "../../api/action";

export const Ast = () => {
  return <span style={{ color: "#f5222d" }}>*</span>;
};

//Modal de confirmación para guardar la promoción
export function ModalConfirm(accion, history, setBtnGuardar) {
  const { confirm } = Modal;
  confirm({
    title: null,
    content: contentSave(accion),
    icon: null,
    okText: "Continuar",
    cancelText: "Corregir datos",
    cancelButtonProps: { className: "ant-btn-default" },
    onOk() {
      sendAction(accion, history, setBtnGuardar);
    },
    onCancel() {
      setBtnGuardar(false);
    },
  });
}

//Modal de confirmación para eliminar la organización
export function ModalDelete(accion, setReload, user) {
  const { confirm } = Modal;
  confirm({
    title: null,
    content: contentDelete(accion),
    icon: null,
    okText: "Continuar",
    cancelText: "Cancelar",
    cancelButtonProps: { className: "ant-btn-default" },
    onOk() {
      deleted(accion, user).then((res) => {
        if (res.data.code === 200) {
          Modal.success({
            title: "Estado Acción",
            content: <p>{res.data.message}</p>,
            okText: "Aceptar",
            onOk() {
              setReload(true);
            },
          });
        } else {
          Modal.error({
            title: "Error",
            content: <p>{res.data.causa}</p>,
          });
        }
      });
    },
  });
}

//Función que obtiene los datos
function sendAction(accion, history, setBtnGuardar) {
  accion.fechaRealizacion = accion.fechaRealizacion.format("YYYY-MM-DD");
  accion.idRegistro !== undefined
    ? updateAction(accion, history, setBtnGuardar)
    : saveAction(accion, history, setBtnGuardar);
}

//Función que guarda la promoción
function saveAction(accion, history, setBtnGuardar) {
  save(accion).then((res) => {
    if (res.data.code === 200) {
      Modal.success({
        title: "Estado Acción",
        content: <p>{res.data.message}</p>,
        okText: "Aceptar",
        onOk() {
          history.push("/admin/accion"); //Redirecciona a la pantalla principal
        },
      });
    } else {
      Modal.error({
        title: "Error",
        content: <p>{res.data.causa}</p>,
        onOk() {
          //history.push("/admin/promocion");
          setBtnGuardar(false); //Redirecciona a la pantalla principal
        },
      });
    }
  });
}
//Función que actualiza la promoción
function updateAction(accion, history, setBtnGuardar) {
  update(accion).then((res) => {
    if (res.data.code === 200) {
      Modal.success({
        title: "Estado Acción",
        content: <p>{res.data.message}</p>,
        okText: "Aceptar",
        onOk() {
          history.push("/admin/accion"); //Redirecciona a la pantalla principal
        },
      });
    } else {
      Modal.error({
        title: "Error",
        content: <p>{res.data.causa}</p>,
        okText: "Aceptar",
        onOk() {
          //history.push("/admin/promocion");//Redirecciona a la pantalla principal
          setBtnGuardar(false);
        },
      });
    }
  });
}

//Contenido del modal guardar
const contentSave = (org) => {
  return (
    <div>
      <p>Los datos de la acción de difusión serán guardados.</p>
      <p>¿Deseas continuar?</p>
    </div>
  );
};

//Contenido del modal eliminar
const contentDelete = () => {
  return (
    <div>
      <p>Estás a punto de eliminar la acción de difusión.</p>
      <p>Esta acción no se puede deshacer ¿Deseas continuar?</p>
    </div>
  );
};
