export const array = [
  {
    idEntidad: 1,
    datos: [
      {
        titulo:
          "Para la elección de Diputaciones locales de mayoría relativa y representación proporcional serán los Consejos Distritales Electorales. ",
      },
      {
        titulo:
          "Para la elección de Ayuntamientos serán los Consejos Municipales Electorales.",
      },
      {
        titulo:
          "Ambos se realizarán en la sesión especial de cómputo del miércoles 9 de junio 2021, que iniciará a las 8:00 horas.",
      },
    ],
  },
  {
    idEntidad: 2,
    datos: [
      {
        titulo:
          "Para la elección de Gubernatura, diputaciones locales y ayuntamientos serán los Consejos Distritales en la sesión especial de cómputo del 09 de junio de 2021.",
      },
      {
        titulo:
          "Para la elección de Diputaciones locales de representación proporcional y regidurías bajo el mismo principio será el Consejo General del IEEBC. Se realizarán en la sesión especial de cómputo de hasta 15 días posteriores a la Jornada Electoral.",
      },
    ],
  },
  {
    idEntidad: 3,
    datos: [
      {
        titulo:
          "Para el cómputo distrital de la elección de Gubernatura serán los Consejos distritales del Instituto Estatal Electoral de Baja California Sur. Se realizarán en la sesión especial de cómputo del 09 de junio de 2021.",
      },
      {
        titulo:
          "Para el cómputo general de la elección de Gubernatura será el Consejo General del IEEBCS. Se realizarán en la sesión especial de cómputo del 13 de junio de 2021.",
      },
      {
        titulo:
          "Para la elección de Diputaciones locales de mayoría relativa serán los Consejos Distritales del IEEBCS. Se realizarán en la sesión especial de cómputo del 09 de junio de 2021.",
      },
      {
        titulo:
          "Para la elección de Diputaciones locales de representación proporcional será el Consejo General del IEEBCS. Se realizarán en la sesión especial de cómputo del domingo 13 de junio de 2021.",
      },
      {
        titulo:
          "Para la elección de miembros de Ayuntamientos y regidurías de representación proporcional serán los Consejos Municipales del IEEBCS. Se realizarán en la sesión especial de cómputo del 09 de junio de 2021.",
      },
    ],
  },
  {
    idEntidad: 4,
    datos: [
      {
        titulo:
          "Para la elección de Gubernatura serán los Consejos Distritales del Instituto Electoral del Estado de Campeche en la sesión especial de cómputo del 09 de junio de 2021.",
      },
      {
        titulo:
          "Para la elección de Diputaciones locales serán los Consejos Distritales del IEEC el 09 de junio de 2021.",
      },
      {
        titulo:
          "Para la elección de Ayuntamientos serán los Consejos Municipales y Consejos Distritales (cuando en el municipio no exista Consejo Electoral Municipal) del IEEC. Se realizarán en la sesión especial de cómputo del 09 de junio de 2021.",
      },
      {
        titulo:
          "Para la elección de las Juntas Municipales serán los Consejos Municipales y Consejos Distritales (cuando en el municipio no exista Consejo Electoral Municipal) del IEEC. Se realizarán en la sesión especial de cómputo del 09 de junio de 2021.",
      },
    ],
  },
  {
    idEntidad: 5,
    datos: [
      {
        titulo:
          "Para la elección de Ayuntamientos serán los Comités Municipales Electorales del IEC. Se realizarán en la sesión especial de cómputo del 09 de junio de 2021.",
      },
    ],
  },
  {
    idEntidad: 6,
    datos: [
      {
        titulo:
          "Para la elección de Gubernatura será el Consejo General del Instituto Electoral del Estado de Colima en la sesión especial de cómputo del 09 de junio de 2021.",
      },
      {
        titulo:
          "Para la elección de Diputaciones locales de mayoría relativa será el Consejo General del IEE. el 13 de junio de 2021.",
      },
      {
        titulo:
          "Para la elección de Diputaciones locales de representación proporcional será el Consejo General del IEE, el 26 de junio de 2021.",
      },
      {
        titulo:
          "Para la elección de miembros de Ayuntamientos serán los Consejos Municipales del IEE el 17 de junio de 2021.",
      },
      {
        titulo:
          "Para la elección de regidurías de representación proporcional será el Consejo General del IEE el 30 de junio de 2021.",
      },
    ],
  },
  {
    idEntidad: 7,
    datos: [
      {
        titulo:
          "Para la elección de Diputaciones de mayoría relativa, el Consejo Distrital, en la sesión especial de cómputo del 08 de junio 2021.",
      },
      {
        titulo:
          "Para la elección de Diputaciones de representación proporcional, el Consejo General del IEPC, en la sesión especial de cómputo del 13 de junio 2021.",
      },
      {
        titulo:
          "Para la elección de Ayuntamientos serán los Consejos Municipales, el 08 de junio de 2021.",
      },
    ],
  },
  {
    idEntidad: 8,
    datos: [
      {
        titulo:
          "Para la elección de Gubernatura el cómputo municipal lo realiza la Asamblea Municipal, mientras que el Consejo Estatal realiza el cómputo total el viernes 11 de junio de 2021.",
      },
      {
        titulo:
          "Para la elección de Diputaciones locales de mayoría relativa el computo municipal lo realiza la Asamblea Municipal el 09 de junio de 2021, mientras que la Asamblea Distrital realiza el cómputo total el viernes 11de junio de 2021. ",
      },
      {
        titulo:
          "Para la elección de Diputaciones locales de representación proporcional será el Consejo Estatal del IEE, en la sesión especial de cómputo del 11 de junio de 2021.",
      },
      {
        titulo:
          "Para la elección de Presidencias Municipales y Sindicaturas serán las Asambleas Municipales del IEE en la sesión especial de cómputo del 09 de junio de 2021.",
      },
    ],
  },
  {
    idEntidad: 9,
    datos: [
      {
        titulo:
          "Para la elección de Diputaciones locales de mayoría relativa serán los Consejos Distritales del Instituto Electoral de la Ciudad de México. Se realizarán en la sesión especial de cómputo del domingo 6 de junio de 2021.",
      },
      {
        titulo:
          "Para la elección de Diputaciones locales de representación proporcional será el Consejo General del Instituto Electoral de la Ciudad de México. Se realizarán en la sesión especial de cómputo del domingo 12 de junio de 2021.",
      },
      {
        titulo:
          "Para la elección de Alcaldías y Concejalías por ambos principios serán los Consejos Distritales cabecera de Demarcación territorial. Se realizarán en la sesión especial de cómputo del domingo 10 de junio de 2021.",
      },
    ],
  },
  {
    idEntidad: 10,
    datos: [
      {
        titulo:
          "Para la elección de Diputaciones locales de mayoría relativa serán los Consejos Municipales Cabecera de Distrito el domingo 13 de junio de 2021. ",
      },
      {
        titulo:
          "Para la elección de Diputaciones de representación proporcional será el Consejo General del IEPC el miércoles 16 de junio de 2021.",
      },
    ],
  },
  {
    idEntidad: 11,
    datos: [
      {
        titulo:
          "Para la elección de Diputaciones locales de mayoría relativa será el Consejo Distrital en sesión especial de cómputo el miércoles 9 de junio de 2021.",
      },
      {
        titulo:
          "Para la elección de Diputaciones locales de representación proporcional será el Consejo General el domingo 13 de junio de 2021.",
      },
      {
        titulo:
          "Para la elección de Ayuntamientos serán los Consejos Municipales miércoles 9 de junio 2021.",
      },
    ],
  },
  {
    idEntidad: 12,
    datos: [
      {
        titulo:
          "Para las elecciones de Gubernatura y Diputaciones locales de mayoría relativa y representación proporcional y la elección de miembros del ayuntamiento serán los Consejos Distritales Electorales del Instituto Electoral y de Participación Ciudadana del Estado de Guerrero, durante la sesión especial de cómputo del miércoles 9 de junio 2021.",
      },
    ],
  },
  {
    idEntidad: 13,
    datos: [
      {
        titulo:
          "Para la elección de Diputaciones Locales por el principio de mayoría relativa serán los Consejos Distritales del IEEH. Se realizarán en la sesión especial de cómputo del 09 de junio de 2021.",
      },
      {
        titulo:
          "Para la elección de Diputaciones Locales por el principio de representación proporcional será el Consejo General del IEEH. La fecha está sujeta a resolución de los medios de Impugnación.",
      },
    ],
  },
  {
    idEntidad: 14,
    datos: [
      {
        titulo:
          "Para la elección de Diputaciones locales de mayoría relativa serán los Consejos Distritales del IEPC Jalisco en la sesión especial de cómputo del 09 de junio de 2021.",
      },
      {
        titulo:
          "Para la elección de Diputaciones locales de representación proporcional será el Consejo General del IEPC Jalisco el domingo 13 de junio de 2021.",
      },
      {
        titulo:
          "Para la elección de Munícipes serán los Consejos Municipales del IEPC Jalisco en la sesión especial de cómputo del 09 de junio de 2021.",
      },
    ],
  },
  {
    idEntidad: 15,
    datos: [
      {
        titulo:
          "Para la elección de Diputaciones locales de mayoría relativa serán los Consejos Distritales del IEEM en la sesión especial de cómputo del 09 de junio de 2021.",
      },
      {
        titulo:
          "Para la elección de Diputaciones locales de representación proporcional será el Consejo General del IEEM el domingo 13 de junio de 2021.",
      },
      {
        titulo:
          "Para la elección de integrantes de ayuntamientos serán los Consejos Municipales del IEEM en la sesión especial de cómputo del 09 de junio de 2021.",
      },
    ],
  },
  {
    idEntidad: 16,
    datos: [
      {
        titulo:
          "Para la elección de Gubernatura y de Diputaciones locales de representación proporcional será el Consejo General del Instituto Electoral de Michoacán. Se realizarán en la sesión especial de cómputo del 13 de junio de 2021.",
      },
      {
        titulo:
          "Para la elección de Diputaciones locales de mayoría relativa serán los Consejos Distritales del IEM. Se realizarán en la sesión especial de cómputo del 09 de junio de 2021.",
      },
      {
        titulo:
          "Para la elección de Ayuntamientos serán los Consejos Municipales del IEM. Se realizarán en la sesión especial de cómputo del 09 de junio de 2021.",
      },
    ],
  },
  {
    idEntidad: 17,
    datos: [
      {
        titulo:
          "Para la elección de diputaciones locales de mayoría relativa serán los Consejos Distritales Locales, en sesión especial de cómputo del miércoles 9 de junio de 2021.",
      },
      {
        titulo:
          "Para la elección de diputaciones locales de representación proporcional será el Consejo Estatal Electoral, en sesión especial de cómputo del domingo 13 de junio de 2021.",
      },
      {
        titulo:
          "Para la elección de ayuntamientos serán los Consejos Municipales Electorales el miércoles 9 de junio de 2021.",
      },
      {
        titulo:
          "Para la elección de regidurías será el Consejo Estatal Electoral en la sesión especial de cómputo del domingo 13 de junio de 2021.",
      },
    ],
  },
  {
    idEntidad: 18,
    datos: [
      {
        titulo:
          "Para las elecciones de Gubernatura, Diputaciones Locales, Presidencia y Sindicaturas y Regidurías serán los Consejos Municipales Electorales, en sesión especial de cómputo del miércoles 9 de junio de 2021 a partir de las 8:00 horas.",
      },
    ],
  },
  {
    idEntidad: 19,
    datos: [
      {
        titulo:
          "Para el cómputo parcial de la elección de Gubernatura, serán las Mesas Auxiliares de Cómputo, en sesión especial de cómputo del miércoles 9 de junio de 2021, mientras que para el cómputo total de la referida elección será el Consejo General de la de la Comisión Estatal Electoral Nuevo León, en sesión especial de cómputo el viernes 11 de junio de 2021.",
      },
      {
        titulo:
          "Para el cómputo parcial de la elección de Diputaciones locales de mayoría relativa serán las Mesas Auxiliares de Cómputo, en sesión especial de cómputo del miércoles 9 de junio de 2021, en tanto que para el cómputo total de las diputaciones locales de mayoría relativa es el Consejo General de la Comisión Estatal Electoral Nuevo León, en sesión especial de cómputo del viernes 11 de junio de 2021.",
      },
      {
        titulo:
          "Para la elección de ayuntamientos serán las Comisiones Municipales Electorales, en sesión especial de cómputo del miércoles 9 de junio de 2021.",
      },
    ],
  },
  {
    idEntidad: 20,
    datos: [
      {
        titulo:
          "Para la elección de Diputaciones locales de mayoría relativa y de representación proporcional serán los Consejos Distritales Electorales, en sesión especial de cómputo del miércoles 9 de junio de 2021.",
      },
      {
        titulo:
          "Para la elección de ayuntamientos serán los Consejos Municipales Electorales, en sesión especial de cómputo del jueves 10 de junio de 2021.",
      },
    ],
  },
  {
    idEntidad: 21,
    datos: [
      {
        titulo:
          "Para la elección de Diputaciones locales de mayoría relativa serán los Consejos Distritales en sesión especial de cómputo del miércoles 9 de junio de 2021.",
      },
      {
        titulo:
          "Para la elección de ayuntamientos serán los Consejos Municipales, en sesión especial de cómputo del domingo 9 de junio de 2021.",
      },
      {
        titulo:
          "Para la elección de Diputaciones locales de representación proporcional será el Consejo General del Instituto Electoral del Estado, el domingo 13 de junio de 2021.",
      },
    ],
  },
  {
    idEntidad: 22,
    datos: [
      {
        titulo:
          "Para el cómputo parcial de la elección de Gubernatura, serán los Consejos Distritales y Municipales, en sesión especial de cómputo del miércoles 9 de junio de, mientras que, para el cómputo estatal de la misma elección, será el Consejo General del Instituto Electoral del Estado de Querétaro una vez concluidos los cómputos en los consejos distritales y municipales y recibidas las actas respectivas en la Secretaría Ejecutiva.",
      },
      {
        titulo:
          "Para el cómputo parcial de la elección de Diputaciones Locales de mayoría relativa, serán los Consejos Municipales, en tanto que, para el cómputo total de la misma elección y por el mismo principio, serán los Consejos Distritales, ambos en sesión especial de cómputo del miércoles 9 de junio de 2021.",
      },
      {
        titulo:
          "Para la elección de Diputaciones locales de representación proporcional, será el Consejo General del Instituto Electoral del Estado de Querétaro una vez concluidos los cómputos en los consejos distritales y municipales y recibidas las actas correspondientes en la Secretaría Ejecutiva.",
      },
      {
        titulo:
          "Para la elección de ayuntamientos, serán los Consejos Distritales y Municipales, según corresponda, en sesión especial de cómputo del miércoles 9 de junio de 2021.",
      },
    ],
  },
  {
    idEntidad: 23,
    datos: [
      {
        titulo:
          "Para la elección de los miembros de Ayuntamientos de mayoría relativa serán los Consejos Municipales en la sesión especial de cómputo del domingo 13 de junio de 2021.",
      },
      {
        titulo:
          "Para la elección de los miembros de Ayuntamientos de representación proporcional será el Consejo General en la sesión especial de cómputo del miércoles 16 de junio de 2021.",
      },
    ],
  },
  {
    idEntidad: 24,
    datos: [
      {
        titulo:
          "Para la elección de Gubernatura y Diputaciones Locales serán las comisiones distritales electorales en la sesión especial de cómputo del miércoles 9 de junio de 2021.",
      },
      {
        titulo:
          "Para la elección de ayuntamientos será el Comité Municipal Electoral el miércoles de junio de 2021.",
      },
      {
        titulo:
          "Diputaciones locales de representación proporcional será el Consejo General del CEEPAC el domingo 13 de junio 2021.",
      },
    ],
  },
  {
    idEntidad: 25,
    datos: [
      {
        titulo:
          "Para la elección de Gubernatura y diputaciones locales de representación proporcional será el Consejo General en sesión especial de cómputo el domingo 13 de junio.",
      },
      {
        titulo:
          "Para la elección de Diputaciones locales de mayoría relativa serán los Consejos Distritales Electorales, el miércoles 9 de junio.",
      },
      {
        titulo:
          "Para la elección de Ayuntamientos serán los Consejos Municipales Electorales y Consejos Distritales con funciones de Consejos Municipales, el día 9 de junio.",
      },
    ],
  },
  {
    idEntidad: 26,
    datos: [
      {
        titulo:
          "Para la elección de Gubernatura, será el Consejo General del Instituto Estatal Electoral y de Participación Ciudadana, en sesión especial de cómputo efectuada a más tardar el domingo 13 de junio de 2021.",
      },
      {
        titulo:
          "Para la elección de Diputaciones locales de mayoría relativa serán los Consejos Distritales en sesión especial de cómputo realizada entre lunes 7 y el viernes 11 de junio de 2021, mientras que para las diputaciones locales de representación proporcional será el Consejo General del Instituto Estatal Electoral y de Participación Ciudadana, en sesión especial de cómputo del miércoles 30 de junio de 2021.",
      },
      {
        titulo:
          "Para la elección de ayuntamientos por el principio de mayoría relativa serán los Consejos Municipales, en sesión especial de cómputo efectuada entre el lunes 7 y el miércoles 9 de junio de 2021, en tanto que para las regidurías de representación proporcional será el Consejo General del Instituto Estatal Electoral y de Participación Ciudadana, en sesión especial de cómputo del miércoles 30 de junio de 2021.",
      },
    ],
  },
  {
    idEntidad: 27,
    datos: [
      {
        titulo:
          "Para las elecciones de Diputaciones locales por los principios de mayoría relativa y de representación proporcional, de Presidencias Municipales y Regidurías por los principios de mayoría relativa y de representación proporcional serán los Consejos Electorales Distritales en sesión especial de cómputo del miércoles 9 de junio de 2021.",
      },
    ],
  },
  {
    idEntidad: 28,
    datos: [
      {
        titulo:
          "Para la elección de Diputaciones locales de mayoría relativa serán los Consejos Distritales en sesión especial de cómputo del miércoles 9 de junio de 2021.",
      },
      {
        titulo:
          "Para la elección de Diputaciones locales de representación proporcional será el Consejo General del Instituto Electoral de Tamaulipas, el sábado 12 de junio de 2021.",
      },
      {
        titulo:
          "Para la elección de ayuntamientos serán los Consejos Municipales, el miércoles 9 de junio de 2021.",
      },
    ],
  },
  {
    idEntidad: 29,
    datos: [
      {
        titulo:
          "Para la elección de Gubernatura, será el Consejo General del Instituto Tlaxcalteca de Elecciones, en sesión especial de cómputo del domingo 13 de junio de 2021.",
      },
      {
        titulo:
          "Para la elección de Diputaciones locales de mayoría relativa serán los Consejos Distritales el miércoles 9 de junio de 2021, mientras que para las diputaciones locales de representación proporcional será el Consejo General del Instituto Tlaxcalteca de Elecciones, en sesión especial de cómputo del domingo 13 de junio de 2021, al término del cómputo de Gubernatura.",
      },
      {
        titulo:
          "Para la elección de Ayuntamientos, será el Consejo Municipal el miércoles 9 de junio de 2021, en tanto que para las regidurías de representación proporcional será el Consejo General en sesión especial de cómputo del domingo 13 de junio de 2021.",
      },
      {
        titulo:
          "Para la elección de Presidencias de Comunidad, serán los Consejos Municipales en sesión especial de cómputo del miércoles 9 de junio de 2021, al término del cómputo de integrantes del ayuntamiento.",
      },
    ],
  },
  {
    idEntidad: 30,
    datos: [
      {
        titulo:
          "Para la elección de Diputaciones locales de mayoría relativa serán los Consejos Distritales y los Consejos Municipales en sesión especial de cómputo del miércoles 9 de junio y del jueves 10 de junio de 2021.",
      },
      {
        titulo:
          "Para la elección de diputaciones locales de representación proporcional serán únicamente los Consejos Distritales, en sesión especial de cómputo del miércoles 9 de junio y del jueves 10 de junio de 2021.",
      },
      {
        titulo:
          "Para la elección de ayuntamientos serán los Consejos Distritales, en sesión especial de cómputo del miércoles 9 de junio de 2021.",
      },
    ],
  },
  {
    idEntidad: 31,
    datos: [
      {
        titulo:
          "Para la elección de diputaciones locales de mayoría relativa serán los Consejos Distritales Locales, en sesión especial de cómputo del miércoles 9 de junio de 2021. ",
      },
      {
        titulo:
          "Para las diputaciones locales de representación proporcional será el Consejo General del Instituto Electoral y de Participación Ciudadana de Yucatán, el domingo 13 de junio de 2021.",
      },
      {
        titulo:
          "Para la elección de regidurías de mayoría relativa serán los Consejos Municipales, el miércoles 9 de junio de 2021. ",
      },
      {
        titulo:
          "Para la elección de regidurías por representación proporcional será el Consejo General del Instituto Electoral y de Participación Ciudadana de Yucatán dentro de los 5 días siguientes al de los cómputos estatales.  ",
      },
    ],
  },
  {
    idEntidad: 32,
    datos: [
      {
        titulo:
          "Para el cómputo distrital de la elección de Gubernatura, serán los Consejos Distritales, en sesión especial de cómputo del miércoles 9 de junio de 2021, mientras que, para el cómputo estatal de la misma elección, será el Consejo General del Instituto Electoral del Estado de Zacatecas el domingo 13 de junio de 2021.",
      },
      {
        titulo:
          "Para el cómputo de la elección de Diputaciones Locales de mayoría relativa, serán los Consejos Distritales, el miércoles 9 de junio de 2021, en tanto que, para el cómputo estatal y la asignación de las diputaciones locales por el principio de representación proporcional, será el Consejo General del Instituto Electoral del Estado de Zacatecas, en sesión especial del domingo 13 de junio de 2021.",
      },
      {
        titulo:
          "Para la elección de ayuntamientos serán los Consejos Municipales en sesión especial de cómputo del miércoles 9 de junio de 2021, mientras que el cómputo estatal y la asignación de regidurías por el principio de representación proporcional, será el Consejo General del Instituto Electoral del Estado de Zacatecas el domingo 13 de junio de 2021.",
      },
    ],
  },
];
