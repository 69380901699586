import React, { useState, useEffect } from "react";
import { Table } from "antd";

import { withRouter, Link } from "react-router-dom";


function TableRequests(props) {
    const {
        data, //Informacion del reporte de solicitudes
        loading,
        proceso,
        registroTabla,
        etiqueta
    } = props;
    const [dataSource, setDataSource] = useState([]); //hook para almacenar el array

    useEffect(() => {
        setDataSource(data); //Se inicializa el array de jobs en el hook
    }, [data]);
          
    var columnasGenerales = [
        {
            title: "Total",
            dataIndex: "total",
            key: "total",

        },
        {
            title: "Hombres",
            dataIndex: "hombres",
            key: "hombres",

        },
        {
            title: "Mujeres",
            dataIndex: "mujeres",
            key: "mujeres",

        },
        {
            title: "Procedencia de la solicitud",
            children: [
                {
                    title: "Nacionales",
                    dataIndex: "nacionales",
                    key: "nacionales",
        
                },
                {
                    title: "Extranjeros",
                    dataIndex: "extranjeros",
                    key: "extranjeros",

                },
            ]
        },
        {
            title: "Origen de la solictud",
            children: [
                {
                    title: "Portal público",
                    dataIndex: "recPP",
                    key: "recPP",
        
                },
                {
                    title: "JL",
                    dataIndex: "recJL",
                    key: "recJL",
        
                },
                {
                    title: "JD",
                    dataIndex: "recJD",
                    key: "recJD",
        
                },
                {
                    title: "OPL",
                    dataIndex: "recOple",
                    key: "recOple",
        
                },
            ]
        },{
            title: "Tipo de solicitud",
            children: [
                {
                    title: "Individual",
                    dataIndex: "solIndiv",
                    key: "solIndiv",
        
                },
                {
                    title: "Organización",
                    dataIndex: "solOrg",
                    key: "solOrg",
        
                },
            ]
        },
        {
            title: "Modalidad del curso",
            children: [
                {
                    title: "En línea",
                    dataIndex: "enLinea",
                    key: "enLinea",
        
                },{
                    title: "Presencial",
                    dataIndex: "presencial",
                    key: "presencial",
        
                },{
                    title: "Curso a distancia",
                    dataIndex: "distancia",
                    key: "distancia",        
                }
            ]
        },
        {
            title: "Estado del curso",
            children: [
                {
                    title: "Con curso",
                    dataIndex: "conCurso",
                    key: "conCurso",
        
                },{
                    title: "Sin curso",
                    dataIndex: "sinCurso",
                    key: "sinCurso",
        
                }

            ]
        },{
            title: "Estado de la revisión",
            children: [
                {
                    title: "Sin revisión",
                    dataIndex: "sinRevision",
                    key: "sinRevision",
        
                },{
                    title: "Con observaciones",
                    dataIndex: "conObservaciones",
                    key: "conObservaciones",
        
                },{
                    title: "Validada",
                    dataIndex: "estVal",
                    key: "estVal",
        
                }

            ]
        },
        {
            title: "Estatus general de la solicitud",
            children: [
                {
                    title: "Pendientes",
                    dataIndex: "acrePen",
                    key: "acrePen",
        
                },
                {
                    title: "Aprobadas",
                    dataIndex: "acreApr",
                    key: "acreApr",
        
                },
                {
                    title: "Denegadas",
                    dataIndex: "acreDen",
                    key: "acreDen",
        
                },
                {
                    title: "Canceladas",
                    dataIndex: "acreCan",
                    key: "acreCan",
        
                },
                {
                    title: "Declinadas",
                    dataIndex: "acreDec",
                    key: "acreDec",
        
                },
                {
                    title: "Registradas en otro sistema",
                    dataIndex: "acreObs",
                    key: "acreObs",
        
                },
                {
                    title: "No completaron los requisitos",
                    dataIndex: "acreRec",
                    key: "acreRec",
        
                },
            ]
        },
               
        
        
    ];

    //Se definen las columnas de nivel JL
    var columnasJL = [
        {
            title: "Junta Ejecutiva",
            dataIndex: "nombreDistrito",
            key: "nombreDistrito",
            fixed: "left",
            render: (text, record) => <Link to={{
                pathname:"/admin/reportes/solicitudes",
                registroTabla: record}}>{text}</Link>
        },
    ]

    //Se definen las columnas de nivel JD
    var columnasJD = [
        {
            title: "Junta Ejecutiva",
            dataIndex: "nombreDistrito",
            key: "nombreDistrito",
            fixed: "left",

        },
    ];
  

    //Se definen las columnas de nivel OC
    var columnasOC = [
        {
            title: "Entidad",
            dataIndex: "nombreEstado",
            key: "nombreEstado",
            fixed: "left",
            render: (text, record) => <Link to={{
                pathname:"/admin/reportes/solicitudes",
                registroTabla: record}}>{text}</Link>
        },
    ];

    columnasOC = columnasOC.concat(columnasGenerales);
    columnasJL = columnasJL.concat(columnasGenerales);
    columnasJD = columnasJD.concat(columnasGenerales);
    
    var columnas = [];

    if(registroTabla !== undefined && registroTabla !== null) {
        if(registroTabla.idDistritoFederal !== undefined && registroTabla.idDistritoFederal !== null) {
                columnas = columnasJD;
        }else if(registroTabla.idEstado !== undefined && registroTabla !== null) {
            if(registroTabla.idEstado !== 0) {
                columnas = columnasJL;
            }else{
                columnas = columnasOC;
            }
        }else{
            columnas = columnasOC;
        }
    }else{
        if(etiqueta !== undefined && etiqueta !== null) {
            if(etiqueta !== 0) {
                columnas = columnasJL;
            }else{
                columnas = columnasOC;
            }
        }else{
            if(proceso.distrito !== undefined && proceso.distrito !== null){
                if(proceso.distrito.idDistrito !== 0) {
                    columnas = columnasJD;
                }else{
                    columnas = columnasJL;
                }
            }else if(proceso.estado !== undefined && proceso.estado !== null){
                if(proceso.estado.idEstado !== 0){
                    columnas = columnasJL;
                }else{
                    columnas = columnasOC;
                }
            }else{
                columnas = columnasOC;
            }
        }
    }


    return (
        <Table

            columns={columnas}
            dataSource={dataSource}
            loading={loading}
            locale={{ emptyText: "Sin registros" }}
            size="small"
            scroll={{ x:true }}

        />


    );
}

export default withRouter(TableRequests);