import React, { useState, useEffect } from "react";
import { Input, Icon, Tooltip, Table, Button, Form, Select } from "antd";
import { withRouter, Link } from "react-router-dom";
import { getByOrigen } from "../../../utils/Validations/functions";
import moment from "moment";
import {
  ESTATUS_SIN_REVISION,
  ESTATUS_OBSERVACIONES_ATENDIDAS,
  ESTATUS_VALIDADO,
  ESTATUS_CON_OBSERVACIONES,
} from "../../../utils/constanst";

function TableValidation(props) {
  const { data, loading, rolModulo, rol, status, disabled } = props;
  const [value, setValue] = useState({ name: "", clave: "", status: "" }); //Hook para el filtrado de inputs
  const [dataSource, setDataSource] = useState([]); //hook para almacenar el array
  const [source, setSource] = useState([]); //hook copia para almacenar el array
  const [valTipoSol, setValTipoSol] = useState(""); //Hook para el filtrado de tipo de solicitud
  const { Option } = Select;

  // console.log(disabled);
  useEffect(() => {
    setDataSource(data); //Se inicializa el array de organizaciones en el hook
    setSource(data); //Copia del array que se utiliza para hacer el filtro
    setValue({ status: "Todos" }); //Valor por defecto del select
  }, [data]);

  //Función que filtra las organizaciones por el nombre
  const FilterByName = (
    <>
      Nombre del solicitante
      <Input
        value={value.name}
        onChange={(e) => {
          const currValue = e.target.value;
          setValue({ ...value, name: currValue });
          const filteredData = source.filter((entry) =>
            entry.nombreCompleto
              .toString()
              .toLowerCase()
              .includes(currValue.toLowerCase())
          );
          setDataSource(filteredData);
        }}
      />
    </>
  );

  //Función que filtra las organizaciones por el nombre
  const FilterByClave = (
    <>
      Clave de elector
      <Input
        value={value.clave}
        onChange={(e) => {
          const currValue = e.target.value;
          setValue({ ...value, clave: currValue });
          const filteredData = source.filter((entry) =>
            entry.claveElector
              .toString()
              .toLowerCase()
              .includes(currValue.toLowerCase())
          );
          setDataSource(filteredData);
        }}
      />
    </>
  );

  //Función que filtra los registros por tipo de solicitud
  const FilterByTipoSol = (
    <>
      Recibida por
      <br />
      <Select
        style={{ width: 130 }}
        optionFilterProp="children"
        placeholder="Todos"
        value={valTipoSol}
        onChange={(value) => {
          const currValue = value;
          setValTipoSol(currValue);
          setValue({ status: "Todos" });
          const filteredData = data.filter((entry) =>
            entry.origenSolicitud
              .toString()
              .toLowerCase()
              .includes(currValue.toLowerCase())
          );
          setDataSource(filteredData);
        }}
      >
        <Option value="">Todos</Option>
        <Option value="2">OPL</Option>
        <Option value="3">Portal Público</Option>
        <Option value="4">Junta</Option>
      </Select>
    </>
  );

  //Se definen las columnas de la tabla de validaciones
  const columns = [
    {
      title: "#",
      dataIndex: "indice",
      width: 80,
    },
    {
      title: FilterByName,
      dataIndex: "nombreCompleto",
      width: 250,
    },
    {
      title: FilterByClave,
      dataIndex: "claveElector",
      width: 250,
      align: "center",
    },
    {
      title: "Fecha de recepción de la solicitud",
      dataIndex: "fecha",
      width: 150,
      align: "center",
      render: (record) => record && moment(record).format("DD/MM/YYYY"), //Formato a la fecha
    },
    {
      title: "Estado de la revisión",
      dataIndex: "estatus",
      width: 150,
      align: "center",
    },
    {
      title: "Tipo de solicitud",
      dataIndex: "tipoSolicitud",
      width: 180,
      align: "center",
      render: (row) => (row === 0 ? "Individual" : "Organización"),
    },
    {
      title: FilterByTipoSol,
      dataIndex: "origenSolicitud",
      width: 180,
      align: "center",
      render: (row) => getByOrigen(row),
    },
    {
      title: "Validación contra otros sistemas",
      dataIndex: "cruce",
      width: 220,
      align: "center",
      render: (row) => (
        <>
          {row.cruce.map((mensaje, i) => (
            <p key={i}>
              {mensaje.observacion} {"  "}
            </p>
          ))}
        </>
      ),
    },

    {
      title: "Acciones",
      key: "action",
      fixed: "right",
      width: 100,
      align: "center",
      render: (row) => (
        <>
          {((row.idEstatus === ESTATUS_SIN_REVISION ||
            row.idEstatus === ESTATUS_OBSERVACIONES_ATENDIDAS) && !row.declinado) &&
            rolModulo.captura && (
              <Tooltip title="Revisar">
                <Link
                  to={{
                    pathname: "/admin/validacion/solicitud",
                    user: row,
                    idEstatus: row.idEstatus,
                    modifica: rolModulo.modifica,
                    rol: rol,
                    jl: disabled,
                  }}
                  // disabled={disabled}
                >
                  <Button
                    type="default"
                    style={{ width: 90 }}
                    // disabled={disabled}
                  >
                    Revisar
                  </Button>
                </Link>
              </Tooltip>
            )}
          {(row.idEstatus === ESTATUS_CON_OBSERVACIONES ||
            row.idEstatus === ESTATUS_VALIDADO || row.declinado) && (
            <Tooltip title="Visualizar">
              <Link
                to={{
                  pathname: "/admin/validacion/solicitud",
                  user: row,
                  idEstatus: row.idEstatus,
                }}
                disabled={row.declinado}
              >
                <Button
                  className="btn-circle btn_secundario"
                  style={{ textAlign: "center" }}
                  disabled={row.declinado}
                >
                  <Icon type="eye" theme="filled" />
                </Button>
              </Link>
            </Tooltip>
          )}
        </>
      ),
    },
  ];
  ///////////////////Select para filtrar de acuerdo al estado de la solicitud////////////////////////////////////////////
  function SelectFilter() {
    const handleChange = (value) => {
      if (value === "Todos") {
        setDataSource(data);
        setSource(data);
        setValue({ status: value });
      } else {
        const currValue = value;
        setValue({ status: currValue });
        setValTipoSol("");
        const filteredData = data.filter((entry) =>
          entry.estatus
            .toString()
            .toLowerCase()
            .includes(currValue.toLowerCase())
        );
        setDataSource(filteredData);
        setSource(filteredData);
      }
    };
    return (
      <Form.Item label="Estado de la revisión">
        <Select
          defaultValue="Todos"
          value={value.status}
          style={{ width: 200 }}
          onChange={handleChange}
        >
          <Select.Option value="Todos">Todos</Select.Option>
          {status.map((item, i) => (
            <Select.Option key={i} value={item.estatus}>
              {item.estatus}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    );
  }

  return (
    <>
      <SelectFilter />
      <Table
        columns={columns}
        locale={{ emptyText: "Sin registros" }}
        dataSource={dataSource}
        scroll={{ x: true }}
        loading={loading}
      />
    </>
  );
}

export default withRouter(TableValidation);
