import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom"
import { Table } from "antd";

function TableAttendance(props) {
    const {
        data,
        loading
    } = props;
    const [dataSource, setDataSource] = useState([]); //hook para almacenar el array

    useEffect(() => {
        setDataSource(data);
    }, [data]);

    //Se definen las columnas
    const columnas = [
        {
            title: "Nombre",
            dataIndex: "nombreObservador",
        },
        {
            title: "Clave de elector",
            dataIndex: "claveElector",
        },
        {
            title: "Folio",
            dataIndex: "folio",
        }
    ];

    const rowSelection = {
        columnTitle: <div>Asistencia</div>,
        columnWidth: 120,
        onChange: (selectedRowKeys, selectedRows) => {
            dataSource.forEach((e) => { //Limpiamos la asistencia si es que previamente la habían seleccionado
                e.asistencia = false;
            });
            for (let i = 0; i < selectedRows.length; i++) {
                selectedRows[i].asistencia = true;
            }
        },
        getCheckboxProps: record => ({
            disabled: record.asistencia !== 0, //Para deshabilitar todos los checks
            checked: record.estatus === 1,
        }),
    };

    return (
        <>
            <Table rowSelection={rowSelection}
                columns={columnas} dataSource={dataSource}
                loading={loading} locale={{ emptyText: "Sin registros" }}
                scroll={{ y:true }}
            />
        </>
    );
}

export default withRouter(TableAttendance);
