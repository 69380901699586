import { basePath, urlCuestionarios } from "./config";
import axios from "axios";
import { getAccessTokenApi, getTokenCiudadano } from "./auth";

const TIPO_SEDE = 1;
const TIPO_INTEGRANTE = [1];
const TIPO_STATUS = [1];

export function getCourses(proceso) {
  const url = `${basePath}/cursos/cursos`;
  const jwt = getAccessTokenApi();
  return axios.post(
    url,
    { proceso: proceso },
    {
      headers: { Authorization: jwt },
    }
  );
}

export function getEntidad() {
  const url = `${basePath}/registroJuntas/entidades`;
  const jwt = getAccessTokenApi();
  return axios.post(url, {}, { headers: { Authorization: jwt } });
}

export function getMunicipio(idEstado) {
  const url = `${basePath}/registroJuntas/municipios`;
  const jwt = getAccessTokenApi();
  return axios.post(
    url,
    { idEstado: idEstado },
    { headers: { Authorization: jwt } }
  );
}

export function getJuntas(idEstado, idJunta) {
  //const url = `${urlJuntas}/ws/domiciliosJunta/consulta`;
  //const url = `${basePath}/cursos/juntaEjecutiva`;
  const url = `${basePath}/sesiones/juntaEjecutiva`;
  const jwt = getAccessTokenApi();
  return axios.post(
    url,
    { idEstado: idEstado, idDistritoFederal: idJunta, tipoSede: TIPO_SEDE },
    {
      headers: { Authorization: jwt },
    }
  );
}

export function getFuncionarios(idEstado, idJunta) {
  //const url = `${urlJuntas}/ws/integrantes/consulta`;
  //const url = `${basePath}/cursos/funcionarios`;
  const url = `${basePath}/sesiones/funcionarios`;
  const jwt = getAccessTokenApi();
  return axios.post(
    url,
    {
      tipoIntegrante: TIPO_INTEGRANTE,
      idEstado: idEstado,
      idDistritoFederal: idJunta,
      estatus: TIPO_STATUS
    },
    {
      headers: { Authorization: jwt },
    }
  );
}

export function getCargos(tipoCargo) {
  const url = `${basePath}/cursos/cargos`;
  const jwt = getAccessTokenApi();
  return axios.post(
    url,
    { tipoCargo: tipoCargo },
    {
      headers: { Authorization: jwt },
    }
  );
}

export function getOrganizaciones(idProcesoElectoral, idDetalleProceso) {
  const url = `${basePath}/cursos/organizaciones`;
  const jwt = getAccessTokenApi();
  return axios.post(
    url,
    {
      idProcesoElectoral: idProcesoElectoral,
      idDetalleProceso: idDetalleProceso,
    },
    {
      headers: { Authorization: jwt },
    }
  );
}

export function getParticipantes(
  idProcesoElectoral,
  idDetalleProceso,
  idEstadoJunta,
  idDistrito,
  idOrganizacion,
  idCurso
) {
  const url = `${basePath}/cursos/participantes`;
  const jwt = getAccessTokenApi();
  return axios.post(
    url,
    {
      idProcesoElectoral: idProcesoElectoral,
      idDetalleProceso: idDetalleProceso,
      idEstadoJunta: idEstadoJunta,
      idDistrito: idDistrito,
      idOrganizacion: idOrganizacion,
      idCurso: idCurso,
    },
    {
      headers: { Authorization: jwt },
    }
  );
}

export function getParticipantesInscritos(idCurso) {
  const url = `${basePath}/cursos/inscritos`;
  const jwt = getAccessTokenApi();
  return axios.post(
    url,
    { idCurso: idCurso },
    {
      headers: { Authorization: jwt },
    }
  );
}

export function getCantidadParticipantes(
  idProcesoElectoral,
  idDetalleProceso,
  idEstadoJunta,
  idDistrito,
  idOrganizacion,
  idCurso
) {
  const url = `${basePath}/cursos/cantidadParticipantes`;
  const jwt = getAccessTokenApi();
  return axios.post(
    url,
    {
      idProcesoElectoral: idProcesoElectoral,
      idDetalleProceso: idDetalleProceso,
      idEstadoJunta: idEstadoJunta,
      idDistrito: idDistrito,
      idOrganizacion: idOrganizacion,
      idCurso: idCurso,
    },
    {
      headers: { Authorization: jwt },
    }
  );
}

export function saveCourse(solicitud) {
  const url = `${basePath}/cursos/guardar`;
  const jwt = getAccessTokenApi();
  return axios.post(url, solicitud, {
    headers: {
      Authorization: jwt,
      Parametrizacion: solicitud.idProcesoElectoral,
    },
  });
}

export function deleteCourse(idCurso) {
  const url = `${basePath}/cursos/eliminarCurso`;
  const jwt = getAccessTokenApi();
  return axios.post(
    url,
    { idCurso: idCurso },
    {
      headers: { Authorization: jwt },
    }
  );
}

export function saveParticipants(curso, usuario, candidatos) {
  const url = `${basePath}/cursos/guardarParticipantes`;
  const jwt = getAccessTokenApi();
  return axios.post(
    url,
    { curso: curso, usuario: usuario, candidatos: candidatos },
    {
      headers: { Authorization: jwt },
    }
  );
}

export function getByIdCourse(id) {
  const url = `${basePath}/cursos/id`;
  const jwt = getAccessTokenApi();
  return axios.post(
    url,
    { idCurso: id },
    {
      headers: { Authorization: jwt },
    }
  );
}

export function updateCourse(solicitud) {
  const url = `${basePath}/cursos/modifica`;
  const jwt = getAccessTokenApi();
  return axios.post(url, solicitud, {
    headers: {
      Authorization: jwt,
      Parametrizacion: solicitud.idProcesoElectoral,
    },
  });
}

export function getFechaLimite() {
  const url = `${basePath}/cursos/fechaCierre`;
  const jwt = getAccessTokenApi();
  return axios.post(
    url,
    { },
    {
      headers: { "Authorization": jwt },
    }
  );
}

/////////////////////////////////////   CURSOS EN LÍNEA     //////////////////////////////////////////////////////
export function getProcess() {
  const url = `${basePath}/parametro/configuracion`;
  const jwt = getTokenCiudadano();
  return axios.post(
    url,
    { parametro: "PROCESO" },
    {
      headers: { Authorization: jwt, Origen: "portal", Parametrizacion: "0,0" },
    }
  );
}

export function getEntitiesOnline(idObservador) {
  const url = `${basePath}/cursos/linea/entidades`;
  const jwt = getTokenCiudadano();
  return axios.post(
    url,
    { idObservador: idObservador },
    {
      headers: { Authorization: jwt, Origen: "portal", Parametrizacion: "0,0" },
    }
  );
}

export function getLessons(idEntidad, idObservador) {
  const url = `${basePath}/cursos/linea/temario`;
  const jwt = getTokenCiudadano();
  return axios.post(
    url,
    { idEntidad: idEntidad, idObservador: idObservador },
    {
      headers: { Authorization: jwt, Origen: "portal", Parametrizacion: "0,0" },
    }
  );
}

export function getOnlyLessons(idEntidad) {
  const url = `${basePath}/cursos/linea/consulta`;
  const jwt = getTokenCiudadano();
  return axios.post(
    url,
    { idEntidad: idEntidad },
    {
      headers: { Authorization: jwt, Origen: "portal", Parametrizacion: "0,0" },
    }
  );
}

export function getMaterial(idEntidad, idLeccion) {
  const url = `${basePath}/cursos/linea/material`;
  const jwt = getTokenCiudadano();
  return axios.post(
    url,
    { idEntidad: idEntidad, idLeccion: idLeccion },
    {
      headers: { Authorization: jwt, Origen: "portal", Parametrizacion: "0,0" },
    }
  );
}

export function getLastLesson(idObservador, idEntidad) {
  const url = `${basePath}/cursos/linea/ultimaLeccion`;
  const jwt = getTokenCiudadano();
  return axios.post(
    url,
    { idObservador: idObservador, idEntidad: idEntidad },
    {
      headers: { Authorization: jwt, Origen: "portal", Parametrizacion: "0,0" },
    }
  );
}

export function getIntroduction() {
  const url = `${basePath}/cursos/linea/incioCurso`;
  const jwt = getTokenCiudadano();
  return axios.post(
    url,
    {},
    {
      headers: { Authorization: jwt, Origen: "portal", Parametrizacion: "0,0" },
    }
  );
}
export function saveQualification(id, idEntidad, idLeccion, cal, sub) {
  const url = `${basePath}/cursos/linea/guardaAvance`;
  const jwt = getTokenCiudadano();
  return axios.post(
    url,
    {
      idObservador: id,
      idEntidad: idEntidad,
      idLeccion: idLeccion,
      calificacion: cal,
      usuario: sub,
    },
    {
      headers: { Authorization: jwt, Origen: "portal", Parametrizacion: "0,0" },
    }
  );
}

export function getCuestionario(idEntidad, idLeccion) {
  const url = `${basePath}/cursos/linea/cuestionario`;
  const jwt = getTokenCiudadano();
  return axios.post(
    url,
    {
      idEntidad: idEntidad,
      idLeccion: idLeccion,
    },
    {
      headers: { Authorization: jwt, Origen: "portal", Parametrizacion: "0,0" },
    }
  );
}

export function reqCuestionario(user, hash) {
  return axios.post(urlCuestionarios, {
    userName: user,
    hashCuestionario: hash,
  });
}

export function existConstancy(id) {
  const url = `${basePath}/cursos/linea/constancia`;
  const jwt = getTokenCiudadano();
  return axios.post(
    url,
    {
      idObservador: id,
    },
    {
      headers: { Authorization: jwt, Origen: "portal", Parametrizacion: "0,0" },
    }
  );
}

export function getMaterialConsulta(idEntidad, idLeccion) {
  const url = `${basePath}/cursos/linea/materialConsulta`;
  const jwt = getTokenCiudadano();
  return axios.post(
    url,
    { idEntidad: idEntidad, idLeccion: idLeccion },
    {
      headers: { Authorization: jwt, Origen: "portal", Parametrizacion: "0,0" },
    }
  );
}

export function getDownload(urlArchivo) {
  const url = `${basePath}/cursos/linea/descarga`;
  const jwt = getTokenCiudadano();
  return axios.post(
    url,
    { url: urlArchivo },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: jwt,
        Origen: "portal",
        Parametrizacion: "0,0",
      },
      responseType: "blob",
    }
  );
}

export function getDownloadGuia(idEntidad) {
  const url = `${basePath}/cursos/linea/guia`;
  const jwt = getTokenCiudadano();
  return axios.post(
    url,
    { idEntidad },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: jwt,
        Origen: "portal",
        Parametrizacion: "0,0",
      },
      responseType: "blob",
    }
  );
}
/////////////////////////////////////  FIN CURSOS EN LÍNEA     //////////////////////////////////////////////////////
