import React from "react";
import { Row, Col } from "antd";
import { Link } from "react-router-dom";

export default function Links() {
  return (
    <div style={{ paddingBottom: 20, marginTop: "20px" }}>
      <Row gutter={24}>
        <Col span={8} className="link">
          <Link to={"/portal/normatividad"}>Normatividad</Link>
        </Col>
        <Col span={8} className="link">
          <a
            href="https://www.ine.mx/wp-content/uploads/2020/10/deoe-observadores-web-avisoprivacidadintegral.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            Aviso de privacidad
          </a>
        </Col>
        <Col span={8} className="link">
          <Link to={"/portal/glosario"}>Glosario</Link>
        </Col>
      </Row>
    </div>
  );
}
