import React from 'react';
import { Row, Col, Divider } from "antd";

const RequestCourses = (props) => {
    const {inscritos, registrados, cursosImpartidos, personasCapacitadas} = props;
    return ( 
        <Row style={{alignContent:"center", textAlign:"center"}}>
             <Divider orientation="left"><h4>Sobre la capacitación presencial</h4></Divider>
            <Col span={6}>
                <p>{inscritos}</p>
                <p>Personas inscritas en curso presencial</p>
            </Col>
            <Col span={6}>
                <p>{personasCapacitadas}</p>
                <p>Personas capacitadas en curso presencial</p>
            </Col>
            <Col span={6}>
                <p>{registrados}</p>
                <p>Cursos presenciales registrados</p>
            </Col>
            <Col span={6}>
                <p>{cursosImpartidos}</p>
                <p>Cursos presenciales impartidos</p>
            </Col>
        </Row>
     );
}
 
export default RequestCourses;