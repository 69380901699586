import { types } from "../types/types";
/**
 * Acción que guarda si el ciudadano ya termino un curso 
 */
export const updateConstancy = (state) => ({
  type: types.constancia,
  payload: state,
});

export const cleanConstancy = (state) => ({
  type: types.limConstancia,
  payload: state,
});