import React, {useEffect} from 'react';
import { Row, Col, Icon } from "antd";
import { saveAs } from "file-saver";
import { getDownloadSolicitud } from "../../../api/others";
import { message } from "antd";


const Doc = (props) => {

  const {
    history
  } = props;

    function descargarDocumento() {
        //Obtencion de la cadena de la url
        const params = new URLSearchParams(props.location.search);
        const cadenaParam = params.get('d'); // bar
        if (cadenaParam != null || cadenaParam !== '') {
            getDownloadSolicitud(cadenaParam).then((res) => {
                if (res.data) {
                  //Se guarda el archivo en pdf
                  saveAs(
                    new Blob([res.data]),
                    `SolicitudPublica.pdf`
                  );
                }
                history.push("/");
              })
              .catch((e) => {
                message.error("El archivo no se encuentra disponible");
              });
        } else return null;
    }

    useEffect(() => {
        descargarDocumento(); //Se ejecuta el metodo al renderizar el componente
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            <Row>
                <Col span={8}></Col>
                <Col span={8} style={{ textAlign:"center"}}> <Icon type="cloud-download"  style={{ fontSize: '45px', color: '#9159CB' }} theme="outlined"  /></Col>
                <Col span={8}></Col>
            </Row>
        </div>
    );
};

export default Doc;