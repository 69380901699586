import React, { useEffect, useState, useRef } from "react";
import Table from "../../../components/Admin/Validation/Table";
import { withRouter, Redirect } from "react-router-dom";
import { getRequest, getStatus } from "../../../api/validation";
import { rol_captura, rol_modifica } from "../../../utils/Validations/roles";
import useAuth from "../../../hooks/useAuth";

function Validation(props) {
  const {
    proceso: { proceso, estado, distrito }, //Destructuring del popover del proceso electoral
    permission,
  } = props;
  const {
    user: { rolUsuario, versionUsuario }, //Se obtiene el rol del usuario
  } = useAuth();
  const [data, setData] = useState({ dataSource: [], loading: false }); //Hook para almacenar los resultado
  const [rolModulo, setRolModulo] = useState({}); //Hook para almacenar los permisos
  const [status, setStatus] = useState([]);
  const isMounted = useRef(true); //Referencia que permite verificar si el componente esta visible
  let idProceso, idDetalle, idEntidad, idDistrito;
  //Se verica que este seleccionado un proceso electoral
  if (proceso) {
    idProceso = proceso.idProcesoElectoral;
    idDetalle = proceso.idDetalleProceso;
    idEntidad = estado ? estado.idEstado : 0;
    idDistrito = distrito ? distrito.idDistrito : 0;
  }

  //useEffect que valida que el componente este seleccionado de lo contrario cancela cualquier subcripción
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);
  //useEffect que valida el rol del usuario
  useEffect(() => {
    //Valida los permisos que tienen el usuario
    let captura = false;
    let modifica = false;
    //Devuelve un true si cuenta con los permisos
    captura = rol_captura.includes(rolUsuario);
    modifica = rol_modifica.includes(rolUsuario);
    isMounted.current && setRolModulo(() => ({ captura, modifica })); //Se almacenan en el hook
  }, [rolUsuario]);

  useEffect(() => {
    if (idProceso) {
      setData({ loading: true }); //Mostrar el loading en la tabla
      getRequest(idProceso, idDetalle, idEntidad, idDistrito)
        .then((res) => {
          //Se verifica que continue en el componente
          if (isMounted.current) {
            const dataSource = [];
            res.data.map((req, index) => {
              return dataSource.push({
                key: index,
                indice: index + 1,
                ...req,
              });
            });
            setData({ dataSource: dataSource, loading: false }); //Se almacenan en el hook
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [idProceso, idDetalle, idEntidad, idDistrito]);

  useEffect(() => {
    getStatus()
      .then((res) => {
        isMounted.current && setStatus(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  //Se valida que el usuario tenga permiso de consultar
  if (!permission.includes(rolUsuario)) {
    return <Redirect to={"/admin/home"} />;
  }

  return (
    <div style={{ marginTop: "15px", marginLeft: "20px" }}>
      <h1>Validación de registros</h1>
      <div style={{ marginTop: "30px" }}>
        <Table
          data={data.dataSource}
          loading={data.loading}
          rolModulo={rolModulo}
          rol={rolUsuario}
          status={status}
          disabled={versionUsuario === "JL" && idDistrito !== 0}
        />
      </div>
    </div>
  );
}
export default withRouter(Validation);
