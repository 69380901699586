import { types } from "../types/types";

const initialProps = {
  constancia: false,
};

/**
 * Reducer que controla el store para validar si el ciudadano ya termino un curso
 */
export const constancyReducer = (state = initialProps, action) => {
  switch (action.type) {
    case types.constancia:
      return {
        ...state,
        constancia: action.payload
      };
    case types.limConstancia:
      return initialProps;
    default:
      return state;
  }
};
