import { basePath } from "./config";
import axios from "axios";
import { getTokenCiudadano } from "./auth";
import { TIPO_SEDE } from "../utils/constanst";

const TIPO_INTEGRANTE = [1];
const TIPO_STATUS = [1];

export function saveRequest(request) {
  const url = `${basePath}/solicitud`;
  const jwt = getTokenCiudadano();
  return axios.post(url, request, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: jwt,
      Origen: "portal",
      Parametrizacion: "0,0",
    },
  });
}

export const getOrganitation = (codigo) => {
  const url = `${basePath}/solicitud/organizacion`;
  const jwt = getTokenCiudadano();
  return axios.post(
    url,
    { codigo: codigo },
    {
      headers: {
        Authorization: jwt,
        Origen: "portal",
        Parametrizacion: "0,0",
      },
    }
  );
};

export function getStatus(correo) {
  const url = `${basePath}/solicitud/estatus`;
  const jwt = getTokenCiudadano();
  return axios.post(
    url,
    { correo: correo },
    {
      headers: { Authorization: jwt, Origen: "portal", Parametrizacion: "0,0" },
    }
  );
}

export function getCursoPresencial(correo) {
  const url = `${basePath}/cursos/cursoPresencialCiudadano`;
  const jwt = getTokenCiudadano();
  return axios.post(
    url,
    { correo: correo },
    {
      headers: { Authorization: jwt, Origen: "portal", Parametrizacion: "0,0" },
    }
  );
}

export function getState() {
  const url = `${basePath}/geografico`;
  const jwt = getTokenCiudadano();
  return axios.post(
    url,
    {},
    {
      headers: { Authorization: jwt, Origen: "portal", Parametrizacion: "0,0" },
    }
  );
}

export function getMunicipio(idEstado) {
  const url = `${basePath}/municipio`;
  const jwt = getTokenCiudadano();
  return axios.post(
    url,
    { idEstado: idEstado },
    {
      headers: { Authorization: jwt, Origen: "portal", Parametrizacion: "0,0" },
    }
  );
}

export function getDownload(usuario, tipo, proceso, detalle, idObs) {
  const url = `${basePath}/solicitud/descarga`;
  const jwt = getTokenCiudadano();
  return axios.post(
    url,
    { usuario: usuario, tipo: tipo, proceso: proceso, detalle, idObservador: idObs },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: jwt,
        Origen: "portal",
        Parametrizacion: "0,0",
      },
      responseType: "blob",
    }
  );
}

export function getStudies() {
  const url = `${basePath}/solicitud/escolaridad`;
  const jwt = getTokenCiudadano();
  return axios.post(
    url,
    {},
    {
      headers: { Authorization: jwt, Origen: "portal", Parametrizacion: "0,0" },
    }
  );
}

export function getByJunta(idEstado) {
  //const url = `${urlJuntas}/ws/domiciliosJunta/consulta`;
  //const url = `${basePath}/solicitud/junta`;
  //const url = `${basePath}/sesiones/domicilio`;
  const url = `${basePath}/sesiones/juntaEjecutiva`;
  const jwt = getTokenCiudadano();
  return axios.post(
    url,
    { idEstado: idEstado, tipoSede: TIPO_SEDE },
    {
      headers: { Authorization: jwt, Origen: "portal", Parametrizacion: "0,0" },
    }
  );
}

export function getByIdDistrito(idEstado, seccion) {
  const url = `${basePath}/solicitud/distrito`;
  const jwt = getTokenCiudadano();
  return axios.post(
    url,
    { idEntidad: idEstado, seccion: seccion },
    {
      headers: { Authorization: jwt, Origen: "portal", Parametrizacion: "0,0" },
    }
  );
}

export const getEntities = () => {
  const url = `${basePath}/solicitud/observar`;
  const jwt = getTokenCiudadano();
  return axios.post(
    url,
    {},
    {
      headers: {
        Authorization: jwt,
        Origen: "portal",
        Parametrizacion: "0,0",
      },
    }
  );
};

// export function getFuncionarios(idEstado, idJunta) {
//   //const url = `${urlJuntas}/ws/integrantes/consulta`;
//   //const url = `${basePath}/cursos/funcionarios`;
//   const url = `${basePath}/sesiones/funcionarios`;
//   const jwt = getTokenCiudadano();
//   return axios.post(
//     url,
//     {
//       tipoIntegrante: TIPO_INTEGRANTE,
//       idEstado: idEstado,
//       idDistritoFederal: idJunta,
//       estatus: TIPO_STATUS
//     },
//     {
//       headers: { Authorization: jwt, Origen: "portal", Parametrizacion: "0,0" },
//     }
//   );
// }
////////////////////////// SERVICIOS PARA LA SOLICITUD EXTRANJERA //////////////////////////
export const getCountries = () => {
  const url = `${basePath}/solicitud/paises`;
  const jwt = getTokenCiudadano();
  return axios.post(
    url,
    {},
    {
      headers: {
        Authorization: jwt,
        Origen: "portal",
        Parametrizacion: "0,0",
      },
    }
  );
};
////////////////////////// SERVICIOS OBSERVACIONES EN EL REGISTRO CIUDADANO //////////////////////////
export function getByMailRegister(mail) {
  const url = `${basePath}/observaciones`;
  const jwt = getTokenCiudadano();
  return axios.post(
    url,
    { correo: mail },
    {
      headers: {
        Authorization: jwt,
        Origen: "portal",
        Parametrizacion: "0,0",
      },
    }
  );
}

export function getJuntas(idEstado, idJunta) {
  const url = `${basePath}/sesiones/juntaEjecutiva`;
  const jwt = getTokenCiudadano();
  return axios.post(
    url,
    { idEstado: idEstado, idDistritoFederal: idJunta, tipoSede: TIPO_SEDE },
    {
      headers: { Authorization: jwt, Origen: "portal", Parametrizacion: "0,0" },
    }
  );
}

export function getFuncionarios(idEstado, idJunta) {
  const url = `${basePath}/sesiones/funcionarios`;
  const jwt = getTokenCiudadano();
  return axios.post(
    url,
    {
      tipoIntegrante: TIPO_INTEGRANTE,
      idEstado: idEstado,
      idDistritoFederal: idJunta,
      estatus: TIPO_STATUS
    },
    {
      headers: { Authorization: jwt, Origen: "portal", Parametrizacion: "0,0" },
    }
  );
}

export function validaClaveMail(values, modulo) {
  const url = `${basePath}/registroJuntas/validaClaveMail`;
  const jwt = getTokenCiudadano();
  return axios.post(
    url,
    { values: values, modulo: modulo },
    {
      headers: { Authorization: jwt, Origen: "portal", Parametrizacion: "0,0" },
    }
  );
}

export function getPaises() {
  const url = `${basePath}/observaciones/paises`;
  const jwt = getTokenCiudadano();
  return axios.post(
    url,
    {},
    {
      headers: {
        Authorization: jwt,
        Origen: "portal",
        Parametrizacion: "0,0",
      },
    }
  );
}

export function updateChanges(register) {
  const url = `${basePath}/observaciones/actualizar`;
  const jwt = getTokenCiudadano();
  return axios.post(url, register, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: jwt,
      Origen: "portal",
      Parametrizacion: "0,0",
    },
  });
}

export function getEstadoGralSolicitud(correo) {
  const url = `${basePath}/solicitudes/getEstadoGralSolicitudPortal`;
  const jwt = getTokenCiudadano();
  return axios.post(
    url,
    { correo: correo },
    {
      headers: { Authorization: jwt, Origen: "portal", Parametrizacion: "0,0" },
    }
  );
}

export function getEstadoCursoLinea(ciudadano){
  const url = `${basePath}/solicitud/estatusCursoLinea`;
  const jwt = getTokenCiudadano();
  return axios.post(
    url,
    { id: ciudadano.id },
    {
      headers: { Authorization: jwt, Origen: "portal", Parametrizacion: "0,0" },
    }
  );
}

export function getTieneAsistencia(correo) {
  const url = `${basePath}/solicitudes/getTieneAsistencia`;
  const jwt = getTokenCiudadano();
  return axios.post(
    url,
    { correo: correo },
    {
      headers: { Authorization: jwt, Origen: "portal", Parametrizacion: "0,0" },
    }
  );
}


export function getEstadoCurso() {
  const url = `${basePath}/cursos/linea/continuarCurso`;
  const jwt = getTokenCiudadano();
  return axios.post(
    url,
    { },
    {
      headers: { Authorization: jwt, Origen: "portal", Parametrizacion: "0,0" },
    }
  );
}