import React from "react";
import { Layout, Button, Icon } from "antd"; //Se importan los componentes de ant desig
import "./Header.scss";
import LogoUser from "../../assets/img/user.svg";
//cerrar sesión
import { logout } from "../../api/auth";
import { Link } from "react-router-dom";

export default function Header(props) {
  const { Logo, user, history, setUser, setMenuSelect } = props; //Se recibe el logo por props
  const { Header } = Layout; //Se importa el header del componente del Layout

  const logoutUser = () => {    
    logout(setUser, history, user);
  };

  return (
    <Header>
      <div className="menu-top">
        {/*Se crea la estructura del header  */}
        <div className="menu-top__logo" >
          <Link to={"/admin/home"} onClick={()=>(user && setMenuSelect([]))}>
            <Logo />
          </Link>
        </div>
        <div className="menu-top__ayuda">
          {user && (
            <>
              <a href="https://intranet.ine.mx/tutoriales/sistemas/Electorales/2021/federal/Observadores-Electorales/"
                  target="_blank" rel="noopener noreferrer">
                <Icon type="question-circle" theme="filled" className="centro_ayuda" />
              </a>
              <img src={LogoUser} className="user" alt="user" />
              {user.sub} |
              <Button type="link" onClick={logoutUser}>
                <Icon type="close-circle" theme="filled" />
                Cerrar sesión
              </Button>
            </>
          )}
        </div>
      </div>
    </Header>
  );
}
