import React from "react";
import { Modal } from "antd";
import { saveCourse, updateCourse } from "../../api/courses";
import { MSN_GUARDAR } from "../../utils/constanst";

export function sendCourse( values, history, setButton, total, setLoader ) {
    const { confirm } = Modal;
    const valores = values;
    var orgImparte = "INE";
    if (valores.organoImparte === 1) { orgImparte = "OPL"; } 
    if (valores.organoImparte === 2) { orgImparte = "Organización " }
    var nombreCompleto = valores.nombreImparte + (valores.aPaternoImparte!==undefined? valores.aPaternoImparte!==""?(" " + valores.aPaternoImparte):"":"") +  (valores.aMaternoImparte!==undefined? valores.aMaternoImparte!==""?(" " + valores.aMaternoImparte):"":"");
    var fecha = valores.fecha.format().substring(0, 10).replace("-","/").replace("-","/");

    var titleTexto = <div>Se creará el <strong>curso {orgImparte}</strong> impartido por <strong>{nombreCompleto} </strong> 
                        el <strong>{fecha}</strong> con <strong>{total} personas inscritas.</strong> 
                        <br/><br/> ¿Deseas continuar? </div>;
    var okTexto = <div>Crear curso</div>;

    
    if (history.location.pathname === "/admin/cursos/modifica") {
        titleTexto = <div>Se modificará el <strong>curso {orgImparte}</strong> impartido por <strong>{nombreCompleto} </strong> 
                        el <strong>{fecha}</strong> con <strong>{total} personas inscritas.</strong> 
                        <br/><br/> ¿Deseas continuar? </div>;
        okTexto = <div>Crear curso</div>;
    } 
      confirm({
         title: titleTexto,
         icon: null,
         okText: okTexto,
         cancelText: "Cancelar",
         cancelButtonProps: { className: "ant-btn-default" },
         onOk() {
          setLoader({ loading: true, message: MSN_GUARDAR }); //Se activa el loader
          var datos = values.participantes;
          var dataFilter = [];
            if (datos !== undefined) {
              for (let i = 0; i < datos.length; i++) {
                if (datos[i].participanteSeleccionado || datos[i].idObsCurso !== null) {
                  dataFilter.push(datos[i]);
                }
              }
            }
            values.participantes = dataFilter;
          ////////////////////////////////////////////////
            if (history.location.pathname === "/admin/cursos/registro") {
                save(values, history, setButton, setLoader);
            } else {
                update(values, history, setButton, setLoader);
            }
        },
        onCancel() {
          setButton({editar: false, guardar: false });
        }
      });
}

function save(values, history, setButton, setLoader) {
    saveCourse(values).then(res => {
    if (res.data.code === 200) {
      setLoader({ loading: false, message: "" }); //Se desactiva el loader
        Modal.success({
            title: "Curso registrado",
            content: <p>{res.data.message}</p>,
            okText: "Aceptar",
            onOk() {
              history.push("/admin/cursos");
            }
          });
        } else {
          Modal.info({
            title: "Atención",
            content: <p>{res.data.causa}</p>
          });
          setButton({editar: false, guardar: false });
        }
    });
}

function update(values, history, setButton, setLoader) {
  updateCourse(values).then(res => {
    if (res.data.code === 200) {
      setLoader({ loading: false, message: "" }); //Se desactiva el loader
        Modal.success({
            title: "Curso registrado",
            content: <p>{res.data.message}</p>,
            okText: "Aceptar",
            onOk() {
              history.push("/admin/cursos");
            }
          });
        } else {
          Modal.info({
            title: "ATENCIÓN",
            content: <p>{res.data.causa}</p>
          });
          setButton({editar: false, guardar: false });
        }
  });
}

export function validaApellidos(apellido, apellido2) {
  if (apellido || apellido2) {
    return true;
  }
  return false;
}

export const Ast = () =>  {
    return <span style={{ color: "#f5222d" }}>*</span>;
}