import React, {useEffect} from "react";
import { Link } from "react-router-dom";
import { Icon, Row, Col } from "antd";
//Imagenes
import { ReactComponent as Acre0 } from "../../../assets/img/Portal/procAcred_00.svg";
import { ReactComponent as Acre1 } from "../../../assets/img/Portal/procAcred_01.svg";
import { ReactComponent as Acre2 } from "../../../assets/img/Portal/procAcred_02.svg";
import { ReactComponent as Acre3 } from "../../../assets/img/Portal/procAcred_03.svg";
import { ReactComponent as Acre4 } from "../../../assets/img/Portal/procAcred_04.svg";
import { ReactComponent as Acre5 } from "../../../assets/img/Portal/procAcred_05.svg";
import { ReactComponent as Acre6 } from "../../../assets/img/Portal/procAcred_06.svg";
import { ReactComponent as Acre7 } from "../../../assets/img/Portal/procAcred_07.svg";
import { ReactComponent as Acre8 } from "../../../assets/img/Portal/procAcred_08.svg";
import { ReactComponent as Acre9 } from "../../../assets/img/Portal/procAcred_09.svg";


const Acreditacion = () => {
  function toTop() {
    window.scrollTo(0, 0);
}

  useEffect(() => {
    toTop();
  }, []);
  
  return (
    <div className="interioresPortal">
      <Link to={"/"} className="back">
        <Icon type="play-circle" theme="filled" rotate="180" /> Regresar
      </Link>
      <h1>Proceso de acreditación</h1>
      <Row>
        <Col span={24}>
          <p>
            Todo el proceso de acreditación podrás llevarlo mediante el Portal de
            Observadoras y Observadores Electorales, para ello deberás realizar lo
            siguiente:
          </p>
        </Col>
      </Row>
      <Row style={{ marginTop:"80px" }}>
        <Col xxl={9} xl={12} lg={14} md={12} sm={24} xs={24}>
          <h2>Consulta la convocatoria</h2>
          <p>
            El Consejo General del INE aprueba la convocatoria y se publica en
            el Portal de Observadoras y Observadores Electorales.
          </p>
        </Col>
        <Col xxl={10} xl={10} lg={10} md={12} sm={24} xs={24} className="imgCont">
          <Acre0 />
        </Col>
      </Row>
      <Row style={{ marginTop:"80px" }}>
        <Col xxl={9} xl={12} lg={14} md={12} sm={24} xs={24}>
          <h2>Crea tu cuenta</h2>
          <p>
            Regístrate con tu correo electrónico y crea una contraseña. Deberás
            validar tu cuenta mediante un enlace que se enviará a tu correo
            electrónico.
          </p>
        </Col>
        <Col xxl={10} xl={10} lg={10} md={12} sm={24} xs={24} className="imgCont">
          <Acre1 />
        </Col>
      </Row>
      <Row style={{ marginTop:"80px" }}>
        <Col xxl={9} xl={12} lg={14} md={12} sm={24} xs={24}>
          <h2>Inicia sesión</h2>
          <p>
            Usa tu correo electrónico y la contraseña que registraste. Cada vez
            que inicies sesión encontrarás información de tu avance en tu
            proceso de acreditación.
          </p>
          <p>
            Un indicador te mantendrá informado de la
            etapa del proceso en la que te encuentres.
          </p>
        </Col>
        <Col xxl={10} xl={10} lg={10} md={12} sm={24} xs={24} className="imgCont">
          <Acre2 />
        </Col>
      </Row>
      <Row style={{ marginTop:"80px" }}>
        <Col xxl={9} xl={12} lg={14} md={12} sm={24} xs={24}>
          <h2>Llena tu solicitud</h2>
          <p>
            Realiza tu solicitud capturando tus datos y adjuntando una
            fotografía de retrato (tipo infantil) y tu credencial de elector por
            ambos lados.
          </p>
        </Col>
        <Col xxl={10} xl={10} lg={10} md={12} sm={24} xs={24} className="imgCont">
          <Acre3 />
        </Col>
      </Row>
      <Row style={{ marginTop:"80px" }}>
        <Col xxl={9} xl={12} lg={14} md={12} sm={24} xs={24}>
          <h2>Espera la verificación de tu solicitud</h2>
          <p>
            Tus datos serán revisados para comprobar que no existan errores. El
            resultado de la revisión se te notificará mediante el portal y por
            correo electrónico. De no existir observaciones tu solicitud será
            validada.
          </p>
        </Col>
        <Col xxl={10} xl={10} lg={10} md={12} sm={24} xs={24} className="imgCont">
          <Acre4 />
        </Col>
      </Row>
      <Row style={{ marginTop:"80px" }}>
        <Col xxl={9} xl={12} lg={14} md={12} sm={24} xs={24}>
          <h2>Recibe tu curso de capacitación</h2>
          <p>
            Toma tu curso, ya sea de manera presencial o en línea, mediante el
            cual se te capacitará para desempeñar tu labor de observación
            electoral.
          </p>
        </Col>
        <Col xxl={10} xl={10} lg={10} md={12} sm={24} xs={24} className="imgCont">
          <Acre5 />
        </Col>
      </Row>
      <Row style={{ marginTop:"80px" }}>
        <Col xxl={9} xl={12} lg={14} md={12} sm={24} xs={24}>
          <h2>Espera el resultado de tu solicitud</h2>
          <p>
            Los Consejos Locales y Distritales del INE revisarán las solicitudes
            y acreditarán las que cumplan con todos los requisitos.<br />
            De existir algún detalle con tu solicitud se te informará mediante el portal y
            por correo electrónico.
          </p>
        </Col>
        <Col xxl={10} xl={10} lg={10} md={12} sm={24} xs={24} className="imgCont">
          <Acre6 />
        </Col>
      </Row>
      <Row style={{ marginTop:"80px" }}>
        <Col xxl={9} xl={12} lg={14} md={12} sm={24} xs={24}>
          <h2>Descarga tus documentos de acreditación</h2>
          <p>
            Una vez que tu solicitud ha sido acreditada por el Consejo
            correspondiente podrás encontrar tu “gafete” y “acreditación como
            observadora u observador electoral”.
          </p>
        </Col>
        <Col xxl={10} xl={10} lg={10} md={12} sm={24} xs={24} className="imgCont">
          <Acre7 />
        </Col>
      </Row>
      <Row style={{ marginTop:"80px" }}>
        <Col xxl={9} xl={12} lg={14} md={12} sm={24} xs={24}>
          <h2>Realiza la observación electoral</h2>
          <p>
            La acreditación te permite observar las siguientes actividades:
            <ul className="listaPortal">
              <li>Instalación de la casilla.</li>
              <li>Desarrollo de la votación.</li>
              <li>Escrutinio y cómputo de la votación en la casilla.</li>
              <li>
                Fijación de resultados de la votación en el exterior de la
                casilla.
              </li>
              <li>Clausura de la casilla.</li>
              <li>
                Lectura en voz alta de los resultados en el consejo distrital.
              </li>
              <li>Recepción de escritos de incidencias y protesta.</li>
            </ul>
          </p>
        </Col>
        <Col xxl={10} xl={10} lg={10} md={12} sm={24} xs={24} className="imgCont">
          <Acre8 />
        </Col>
      </Row>
      <Row style={{ marginTop:"80px" }}>
        <Col xxl={9} xl={12} lg={14} md={12} sm={24} xs={24}>
          <h2>Entrega tu informe</h2>
          <p>
            Después de la jornada electoral se debe entregar un informe con las
            actividades que observaste, permitiendo identificar áreas de
            oportunidad para mejorar.
          </p>
        </Col>
        <Col xxl={10} xl={10} lg={10} md={12} sm={24} xs={24} className="imgCont">
          <Acre9 />
        </Col>
      </Row>
    </div>
  );
};

export default Acreditacion;
