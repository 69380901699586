import React, { useEffect, useState } from "react";
import { Form, Button, Row, Select, message, Col, Input, Checkbox, Radio, Upload, Modal, Carousel, Icon, Alert, DatePicker } from "antd";
import { Link, withRouter } from "react-router-dom";
import useAuth from "../../../hooks/useAuth";
import moment from 'moment';
import { Ast, beforeUpload, getBase64, uploadButton, unlockSections, validaApellidos, sendObservations, calulateNewHeight } from "../../../utils/Public/functions";
import { apellidos, nombres, claveElector, seccion, required, ocupacion, tel, telExt, calle, numeroInt, cp, txtTexto, soloDigitos, valueUpperCase } from "../../../utils/Public/rules";
import { ESTATUS_CON_OBSERVACIONES, ID_DOC_CRED_FRENTE, ID_DOC_CRED_VUELTA, ID_DOC_FOTOGRAFIA, OBSERVADOR_EXTRANJERO, FUNCIONARIO_CONSEJERO } from "../../../utils/constanst";
import { getByMailRegister, getStudies, getState, getMunicipio, getPaises, validaClaveMail, getFuncionarios, getJuntas } from "../../../api/requestPub";
import { URL_IMAGEN } from "../../../api/config";
import { fotos } from "../../../utils/Request/examples";
import Compressor from "compressorjs";
import { getEdad, getFechaNacimiento, getSexo } from "../../../components/Generals/functions";
import "./Recovery.scss";

function Observations(props) {
    const { Option } = Select;
    const { TextArea } = Input;
    const { history, setLoader } = props;
    const { ciudadano: { ciudadano, detalle }, } = useAuth();
    const { getFieldDecorator } = props.form; //Método para validar los inputs
    const dateFormat = 'DD/MM/YYYY';
    const [original, setOriginal] = useState([]);
    const [studies, setStudies] = useState([]);
    const [selectEdo, setSelectEdo] = useState([]);
    const [selectMun, setSelectMun] = useState([]);
    const [selectEdoJunta, setSelectEdoJunta] = useState([]);
    const [entidades, setEntidades] = useState([]);  
    const [entidadesSeleccionadas, setEntidadesSeleccionadas] = useState([]);
    const [variasEntidades, setVariasEntidades] = useState(false); 
    //const [showImpartido, setShowImpartido] = useState(false);
    const [datos, setDatos] = useState({}); //datos del domicilio: entidad, municipio, localidad
    const [button, setButton] = useState(false);
    const [disabled, setDisabled] = useState({ checkNum: false, input: false, }); //Para habilidar los inputs
    const [imageFront, setImageFrontUrl] = useState({ loading: false }); //Hook para visualizar la imagen frente
    const [imageRound, setImageRoundUrl] = useState({ loading: false }); //Hook para visualizar la imagen vuelta
    const [imagePhoto, setImagePhotoUrl] = useState({ loading: false }); //Hook para visualizar la imagen foto
    const [register, setRegister] = useState({}); //Para contener las imágenes adjuntas
    const [photo, setPhoto] = useState(false); //Hook para mostrar el modal de ejemplos
    // SECCIONES BLOQUEADAS
    const [secSinObs] = useState(true);
    const [secNombres, setSecNombres] = useState({ inhabilita: true, message: "", });
    const [secClave, setSecClave] = useState({ inhabilita: true, message: "", });
    const [secDireccion, setSecDireccion] = useState({ inhabilita: true, message: "", });
    const [secCredFrente, setSecCredFrente] = useState({ inhabilita: true, message: "", });
    const [secCredVuelta, setSecCredVuelta] = useState({ inhabilita: true, message: "", });
    const [secFotografia, setSecFotografia] = useState({ inhabilita: true, message: "", });
    const [esExtranjero, setEsExtranjero] = useState(false);
    const [consejeroJunta, setConsejeroJunta] = useState();
    const [municipioJunta, setMunicipioJunta] = useState();
    const [organizacion, setOrganizacion] = useState({});
    const [idJunta, setIdJunta] = useState();
    const [tipoSolicitud, setTipoSolicitud] = useState();
    const [extranjero, setExtranjero] = useState({});

    useEffect(() => {
        if (ciudadano !== null) {
            message.info("Únicamente los datos con observaciones están habilitados");
            getRegister();
        } else {
            history.push("/ciudadano/inicio");
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function getRegister() { 
        getState().then((res) => { setSelectEdo(res.data); setSelectEdoJunta(res.data); });     // EDO DIR, EDO JUNTA
        getStudies().then((res) => { setStudies(res.data); });                                  // ESCOLARIDAD
        getByMailRegister(ciudadano.sub).then((res) => {
            setOriginal(res.data);
            if (res.data !== null && res.data.estatus === ESTATUS_CON_OBSERVACIONES) {
                var momentFecha = moment(res.data.fechaNacimiento);
                if (res.data.tipoEntidad !== 0) {
                    setVariasEntidades(true);
                }
                props.form.setFieldsValue({
                    sexo: res.data.sexo,
                    fechaNacimiento: momentFecha,
                    edad: res.data.edad.toString(),
                    aPaterno: res.data.apellidoPaterno,
                    aMaterno: res.data.apellidoMaterno,
                    nombre: res.data.nombre,
                    claveElector: res.data.claveElector,
                    seccion: res.data.seccion ? res.data.seccion.toString() : "",
                    escolaridad: res.data.cEscolaridades.idEscolaridad.toString(),
                    ocupacion: res.data.ocupacion,
                    phone: res.data.telefono  ? res.data.telefono.toString() : "",
                    cel: res.data.celular ? res.data.celular.toString() : "",
                    localidad: res.data.idLocalidad ? res.data.idLocalidad : "",
                    calle: res.data.calle ? res.data.calle : "",
                    noExterior: res.data.numeroExterno,
                    noInterior: res.data.numeroInterno,
                    codigoPostal: res.data.codigoPostal ? res.data.codigoPostal.toString() : "",
                    modalidad: res.data.modalidad,
                    tipoEntidad: res.data.tipoEntidad,
                });  
                setTipoSolicitud(res.data.tipoSolicitud);              
                if (res.data.codigoPostal && res.data.codigoPostal.toString().length < 5) {
                    var faltanteCP = 5 - res.data.codigoPostal.toString().length;
                    var finalCP = res.data.codigoPostal.toString();
                    for (let i = 0; i < faltanteCP; i++) {
                      finalCP = "0" + finalCP;
                    }
                    props.form.setFieldsValue({
                      codigoPostal: finalCP,
                    });
                }                
                props.form.setFieldsValue({
                    idEstadoJunta: res.data.idEstadoJunta.toString(),
                    junta: res.data.direccionJunta.slice(0,res.data.direccionJunta.indexOf('-')),
                    juntaDir: res.data.direccionJunta.slice(res.data.direccionJunta.indexOf('-')+1,res.data.direccionJunta.length),
                });

                if (res.data.tipoObservador === OBSERVADOR_EXTRANJERO) {
                    const idPais = res.data.extranjero.idPais;
                    setEsExtranjero(true);                    
                    getPaises().then((res) => {
                        if (res.data !== undefined && res.data !== null && res.data.length > 0) {
                            for (let i = 0; i < res.data.length; i++) {
                                if (res.data[i].idPais === idPais) {
                                props.form.setFieldsValue({
                                    paisTxt: res.data[i].pais,
                                });
                                break;
                                }
                            }
                        }
                    });
                    props.form.setFieldsValue({
                        residencia: res.data.tipoObservador,
                        lugarEmision: res.data.extranjero.emicionIne,
                        entidadNacimiento: res.data.extranjero.idEstado.toString(),
                        edoProvReg: res.data.extranjero.estado,
                        ciudadExt: res.data.extranjero.ciudad,
                    });
                    setExtranjero({ idEntidad: res.data.extranjero.idEstado, lugarEmision:res.data.extranjero.emicionIne });
                } else {
                    if (res.data.seccion.toString().length < 4) {
                        var faltante = 4 - res.data.seccion.toString().length;
                        var final = res.data.seccion.toString();
                        for (let i = 0; i < faltante; i++) {
                          final = "0" + final;
                        }
                        props.form.setFieldsValue({
                          seccion: final,
                        });
                    }
                    // ENTIDAD
                    props.form.setFieldsValue({
                        entidad: res.data.idEstado.toString(),
                    });
                    // MUNICIPIO
                    getMunicipio(res.data.idEstado).then((res) => { setSelectMun(res.data); });
                    props.form.setFieldsValue({
                        municipio: res.data.idMunicipio.toString(),
                    });
                }
                // imágenes adjuntas del ciudadano
                for (let i = 0; i < res.data.documentosObs.length; i++) {
                    switch (
                      res.data.documentosObs[i].cTiposDocumento.idTipoDocumento
                    ) {
                      case ID_DOC_CRED_FRENTE:
                        setImageFrontUrl({
                          imageUrl:
                            "data:image/png;base64," +
                            res.data.documentosObs[i].urlImagen,
                        });
          
                        break;
                      case ID_DOC_CRED_VUELTA:
                        setImageRoundUrl({
                          imageUrl:
                            "data:image/png;base64," +
                            res.data.documentosObs[i].urlImagen,
                        });
                        break;
                      case ID_DOC_FOTOGRAFIA:
                        setImagePhotoUrl({
                          imageUrl:
                            "data:image/png;base64," +
                            res.data.documentosObs[i].urlImagen,
                        });
                        break;
                      default:
                        break;
                    }
                }
                // Entidades a observar
                setEntidades(res.data.entidadesObservar);
                var entidadSeleccionada = [];
                for (let i = 0; i < res.data.entidadesObservar.length; i++) {
                if (res.data.entidadesObservar[i].checked) {
                    entidadSeleccionada.push(res.data.entidadesObservar[i].value);
                }
                }
                setEntidadesSeleccionadas(entidadSeleccionada);
                props.form.setFieldsValue({
                entidadesSeleccionadas: entidadSeleccionada,
                });
                res.data.extranjero = null;
                getFuncionarios(res.data.idEstadoJunta, res.data.idDistritoFederal).then((res) => {
                    if (res.data.integrantes !== undefined) {
                      for (let i = 0; i < res.data.integrantes.length; i++) {
                        if (res.data.integrantes[i].idPuesto === FUNCIONARIO_CONSEJERO) {
                        let nombre = res.data.integrantes[i].nombreIntegrante.trim();
                        let aPaterno = res.data.integrantes[i].primerApellidoIntegrante ? res.data.integrantes[i].primerApellidoIntegrante.trim() : "";
                        let aMaterno = res.data.integrantes[i].segundoApellidoIntegrante ? res.data.integrantes[i].segundoApellidoIntegrante.trim() : "";
                        setConsejeroJunta(`${nombre} ${aPaterno} ${aMaterno}`);
                        }
                      }
                    }
                });
                getJuntas(res.data.idEstadoJunta, res.data.idDistritoFederal).then((res) => {
                    if (res.data.domicilios !== undefined && res.data.domicilios.length > 0) {
                      setMunicipioJunta(res.data.domicilios[0].nombreMunicipio);
                    }
                });
                if (res.data.obsOrganizacion !== undefined && res.data.obsOrganizacion !== null && res.data.obsOrganizacion.length > 0) {
                    let nombre = res.data.obsOrganizacion[0].organizaciones.nombreRepre;
                    let aPaterno = res.data.obsOrganizacion[0].organizaciones.apellidoPaternoRepre ? res.data.obsOrganizacion[0].organizaciones.apellidoPaternoRepre:"";
                    let aMaterno = res.data.obsOrganizacion[0].organizaciones.apellidoMaternoRepre ? res.data.obsOrganizacion[0].organizaciones.apellidoMaternoRepre:"";
                    setOrganizacion({ ...organizacion, idOrganizacion: res.data.obsOrganizacion[0].organizaciones.idOrganizacion, 
                        organizacion: res.data.obsOrganizacion[0].organizaciones.organizacion,
                        representanteOrg: `${nombre} ${aPaterno} ${aMaterno}`,
                        correoOrg: res.data.obsOrganizacion[0].organizaciones.correoElectronico });
                }
                setIdJunta(res.data.idDistritoFederal);
                // Desbloqueo de secciones
                unlockSections(res.data.observaciones, setSecNombres, setSecClave, setSecDireccion, setSecCredFrente, setSecCredVuelta, setSecFotografia);
            } else {
                history.push("/ciudadano/inicio");
            }
        }).catch((error) => {
            console.log("Ocurrio un error", error);
        }); 
    }

    const addChanges = (event) => {
        event.preventDefault();
        props.form.validateFields((err, values) => {
            if (!err) {
                if (values.seccion === "0000") {
                    message.info( "Para poder continuar se requiere ingresar la sección electoral, favor de validar." );
                } else {
                    if (values.codigoPostal !== undefined && values.codigoPostal !== null && values.codigoPostal !== "" && Number(values.codigoPostal) === 0) {
                        message.info( "Para poder continuar se requiere ingresar un código postal válido." );
                    } else {
                        if (!validaApellidos(values.aPaterno, values.aMaterno)) {
                            message.info("Al menos uno de los dos apellido es obligatorio.");
                        } else {
                            if ( imageFront.imageUrl === undefined || imageRound.imageUrl === undefined || imagePhoto.imageUrl === undefined ) {
                                message.info("Favor de adjuntar los documentos requeridos.");
                            } else {
                                validaClaveMail(values, 3).then((res) => {
                                    if (res.data.claveElector) {
                                        message.error("Solicitud inválida. La clave de elector se encuentra registrada por otro candidato a Observador Electoral, favor de validar.");
                                    } else {
                                        values.usuario = ciudadano.sub;
                                        values.mail = ciudadano.sub;
                                        for (let i = 0; i < detalle.length; i++) {
                                            if (detalle[i].idDetalleProceso === original.idDetalleProceso) {
                                                values.nombreProceso = detalle[i].nombreProceso;
                                                break;
                                            }
                                        }
                                        if (datos.idEntidad !==undefined && datos.idEntidad !== null & datos.entidad !== undefined && datos.entidad !== null) {
                                            values.idEntidad = datos.idEntidad;
                                            values.entidad = datos.entidad;
                                        } else {
                                            if (extranjero.idEntidad !==undefined && extranjero.idEntidad !== null) {
                                                for (let i = 0; i < selectEdo.length; i++) {
                                                    if (selectEdo[i].id.idEstado === extranjero.idEntidad) {
                                                      values.idEntidad = selectEdo[i].id.idEstado;
                                                      values.entidad = selectEdo[i].nombreEstado;
                                                      break;
                                                    }
                                                }
                                                values.emisionIne = extranjero.lugarEmision;
                                            } else {
                                                for (let i = 0; i < selectEdo.length; i++) {
                                                    if (selectEdo[i].id.idEstado === Number(values.entidad)) {
                                                      values.idEntidad = values.entidad;
                                                      values.entidad = selectEdo[i].nombreEstado;
                                                      break;
                                                    }
                                                }
                                            }
                                        }
                                        if (datos.idMunicipio !==undefined && datos.idMunicipio !== null & datos.municipio !== undefined && datos.municipio !== null) {
                                            values.idMunicipio = datos.idMunicipio;
                                            values.municipio = datos.municipio;
                                        } else {
                                            for (let i = 0; i < selectMun.length; i++) {
                                              if (selectMun[i].idMunicipio === Number(values.municipio)) {
                                                values.idMunicipio = values.municipio;
                                                values.municipio = selectMun[i].municipio;
                                                break;
                                              }
                                            } 
                                        }
                                        // INCORPORAMOS LOS DATOS QUE REQUIERE EL PDF DE ACREDITACIÓN
                                        values.presidenteJunta = consejeroJunta;
                                        if (organizacion !== undefined && organizacion !== null) {
                                            values.organizacion = organizacion.organizacion;
                                            values.representanteOrg = organizacion.representanteOrg;
                                            values.correoOrg = organizacion.correoOrg;
                                        } 
                                        values.tipoSolicitud = tipoSolicitud;
                                        values.procesoElectoral = values.nombreProceso.slice(values.nombreProceso.length - 9, values.nombreProceso.length);
                                        values.municipioJunta = municipioJunta;
                                        values.entidadJunta = "";
                                        for (let i = 0; i < studies.length; i++) {
                                            if (studies[i].idEscolaridad === Number(values.escolaridad)) {
                                                values.estudios = studies[i].escolaridad;
                                                break;
                                            }
                                        }
                                        for (let i = 0; i < selectEdoJunta.length; i++) {
                                            if (selectEdoJunta[i].id.idEstado === Number(values.idEstadoJunta)) {
                                                values.entidadJunta = selectEdoJunta[i].nombreEstado;
                                                break;
                                            }
                                        }
                                        values.idJunta = idJunta.toString();
                                        if (values.aPaterno !== undefined && values.aPaterno !== null) {
                                            values.aPaterno = values.aPaterno.trim();
                                        }
                                        if (values.aMaterno !== undefined && values.aMaterno !== null) {
                                            values.aMaterno = values.aMaterno.trim();
                                        }
                                        values.nombre = values.nombre.trim();
                                        original.extranjero = null;
                                        setButton(true);
                                        sendObservations( values, register.foto, register.frente, register.vuelta, history, setButton, original, setLoader ); //Se envian los valores
                                    }
                                });
                            }
                        }
                    }
                }
            } else {
                message.info("Los datos con ( * ) son requeridos.");
            }
        });
    };

    ////////////////////////////////////// FUNCIONES EN LOS ELEMENTOS DEL FORMULARIO //////////////////////////////////////

    const onChangeSinNum = (e) => {
        if (e.target.checked) {
          setDisabled({ checkNum: true });
          props.form.setFieldsValue({ noExterior: "" });
          props.form.setFieldsValue({ noInterior: "" });
        } else {
          setDisabled({ checkNum: false });
        }
    };

    const onChangeClave = (e) => {
        if (e.target.value.length === 18) {
          let sexoCiudadano = getSexo(e.target.value);
          let fechaCiudadano = getFechaNacimiento(e.target.value);
          let edadCiudadano = getEdad(fechaCiudadano);
          if (fechaCiudadano.isValid() && !isNaN(edadCiudadano)) {
            props.form.setFieldsValue({
              sexo: sexoCiudadano,
              fechaNacimiento: fechaCiudadano,
              edad: edadCiudadano.toString(),
            });
          } else {
            message.info("Confirme que la fecha de nacimiento cuente con el formato AA/MM/DD dentro de la clave de elector.");
          }
        }
      };

    const onChangeEntidad = (e) => {
        getMunicipio(e).then((res) => {
          setSelectMun(res.data);
        });
    };

    const getCadenaEntidad = (value, event) => {
        setDatos({ ...datos, entidad: event.props.children, idEntidad: value });
    };
    const getCadenaMunicipio = (value, event) => {
        setDatos({ ...datos, municipio: event.props.children, idMunicipio: value });
    };

     //Función para comprimir la imagen
  const callbackFunction = (fileObj, name) => {
    let anchuraNueva = 1170;
    let result = new Promise((resolve, reject) => {
      let img = new Image();
      img.src = window.URL.createObjectURL(fileObj);
      img.onload = () => {
        //calcular nueva altura sin perder la relación de aspecto
        const newHeight = calulateNewHeight(
          img.width,
          img.height,
          anchuraNueva
        );
        new Compressor(fileObj, {
          width: anchuraNueva,
          height: newHeight,
          quality: 0.68,
          success(result) {
            resolve(result);
          },
          error(err) {
            console.log(err);
            reject(err);
          },
        });
      };
    });
    result
      .then((value) => {
        //let nombreArchivo = value.name.substring(0,value.name.lastIndexOf("."));
        let extNombre = value.name.substring(value.name.lastIndexOf(".")+1,value.name.length);
        //Se obtien el archivo
        switch (name) {
          case 1:
            setRegister({ ...register, frente: new File([value], `frente.${extNombre}`) }); 
            setButton(false);
            break;
          case 2:
            setRegister({ ...register, vuelta: new File([value], `vuelta.${extNombre}`) });
            setButton(false);
            break;
          case 3:
            setRegister({ ...register, foto: new File([value], `foto.${extNombre}`) });
            setButton(false);
            break;
          default:
            break;
        }
      })
      .catch((e) => {
        console.log(e);
        message.error("Ocurrió un error al adjuntar el archivo, favor de volver a intentar.");
      });
  };

    //Se carga el input de la imagen [FRENTE]
    const handleChangeFront = (info) => {
        if (info.file.status === "uploading") {
        setButton(true);
        setImageFrontUrl({ loading: true });
        return;
        }
        if (info.file.status === "error") {
            setButton(false);
            setImageFrontUrl({ loading: false });
            message.error("Ocurrió un error al adjuntar el archivo, favor de volver a intentar.");
            return;
        }
        if (info.file.status === "done") {
            getBase64(info.file.originFileObj, (imageUrl) =>
                setImageFrontUrl({
                imageUrl,
                loading: false,
                })
            );
            callbackFunction(info.file.originFileObj, 1); 
        }
    };
    //Se carga el input de la imagen [VUELTA]
    const handleChangeRound = (info) => {
        if (info.file.status === "uploading") {
        setButton(true);
        setImageRoundUrl({ loading: true });
        return;
        }
        if (info.file.status === "error") {
            setButton(false);
            setImageRoundUrl({ loading: false });
            message.error("Ocurrió un error al adjuntar el archivo, favor de volver a intentar.");
            return;
        }
        if (info.file.status === "done") {
            getBase64(info.file.originFileObj, (imageUrl) =>
                setImageRoundUrl({
                imageUrl,
                loading: false,
                })
            );
            callbackFunction(info.file.originFileObj, 2); 
        }
    };
    //Se carga el input de la imagen [FOTO]
    const handleChangePhoto = (info) => {
        if (info.file.status === "uploading") {
        setButton(true);
        setImagePhotoUrl({ loading: true });
        return;
        }
        if (info.file.status === "error") {
            setButton(false);
            setImagePhotoUrl({ loading: false });
            message.error("Ocurrió un error al adjuntar el archivo, favor de volver a intentar.");
            return;
        }
        if (info.file.status === "done") {
            getBase64(info.file.originFileObj, (imageUrl) =>
                setImagePhotoUrl({
                imageUrl,
                loading: false,
                })
            );
            callbackFunction(info.file.originFileObj, 3); 
        }
    };

    //Función que muestra el modal de ejemplo
    const modalPhoto = () => {
        return (
        <Modal
            title={null}
            visible={photo}
            onCancel={() => setPhoto(false)}
            footer={null}
            width={210}
        >
            <Carousel autoplay>
            {fotos.map((elem) => (
                <div key={elem.id}>
                <img
                    src={elem.url}
                    alt={elem.alt}
                    style={{ height: "190px", marginTop: 20 }}
                />
                </div>
            ))}
            </Carousel>
        </Modal>
        );
    };

    function SeccionNacional() {
        return (
            <div id="seccionNacional">
                <Col span={8} style={{ paddingRight: "20px" }}>
                    <Ast /> Sección electoral
                    <Form.Item>
                    {getFieldDecorator("seccion", { rules: seccion, 
                        getValueFromEvent: (event) => {
                            return soloDigitos.test(event.target.value)
                            ? event.target.value
                            : (event.target.value).slice(0,-1);
                        }, })(
                        <Input style={{ width: 75 }} maxLength={4} disabled={secClave.inhabilita} />
                    )}
                    </Form.Item>
                </Col>
            </div>
        );
    };

    function TelefonoNacional() {
        return (
            <div><Row>
                <Col xxl={8} xl={8} lg={8} md={8} sm={12} xs={24} style={{ paddingRight: "20px" }}>
                    Teléfono fijo
                    <Form.Item>
                    {getFieldDecorator("phone", { rules: tel })(
                        <Input maxLength={10} disabled={secSinObs} />
                    )}
                    </Form.Item>
                </Col>
                <Col xxl={8} xl={8} lg={8} md={8} sm={12} xs={24} style={{ paddingRight: "20px" }}>
                    Celular
                    <Form.Item>
                    {getFieldDecorator("cel", { rules: tel })(
                        <Input maxLength={10} disabled={secSinObs} />
                    )}
                    </Form.Item>
                </Col>
            </Row></div>
        );
    };

    function TelefonoExtranjero() {
        return (
            <div><Row>
                <Col xxl={8} xl={8} lg={8} md={8} sm={12} xs={24} style={{ paddingRight: "20px" }}>
                    Teléfono fijo
                    <Form.Item>
                    {getFieldDecorator("phone", { rules: telExt })(
                        <Input maxLength={15} disabled={secSinObs} />
                    )}
                    </Form.Item>
                </Col>
                <Col xxl={8} xl={8} lg={8} md={8} sm={12} xs={24} style={{ paddingRight: "20px" }}>
                    Celular
                    <Form.Item>
                    {getFieldDecorator("cel", { rules: telExt })(
                        <Input maxLength={15} disabled={secSinObs} />
                    )}
                    </Form.Item>
                </Col>
            </Row></div>
        );
    };

    function DireccionNacional() {
        return (
            <div>
                <Row>
                    <Col xxl={8} xl={8} lg={8} md={8} sm={24} xs={24} style={{ paddingRight: "20px" }}>
                        <Ast /> Código Postal
                        <Form.Item>
                        {getFieldDecorator("codigoPostal", { rules: cp, 
                            getValueFromEvent: (event) => {
                                return soloDigitos.test(event.target.value)
                                ? event.target.value
                                : (event.target.value).slice(0,-1);
                            }, })(
                            <Input maxLength={5} style={{ width: 75 }} disabled={secDireccion.inhabilita} />
                        )}
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col xxl={8} xl={8} lg={8} md={8} sm={24} xs={24} style={{ paddingRight: "20px" }}>
                        <Ast /> Entidad
                        <Form.Item>
                        {getFieldDecorator("entidad", { rules: required })(
                            <Select
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option.props.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            placeholder="Selecciona"
                            onSelect={(value, e) => getCadenaEntidad(value, e)}
                            onChange={onChangeEntidad}
                            disabled={secDireccion.inhabilita}
                            >
                            {selectEdo.map((item) => (
                                <Option key={item.id.idEstado}>{item.nombreEstado}</Option>
                            ))}
                            </Select>
                        )}
                        </Form.Item>
                    </Col>
                    <Col xxl={8} xl={8} lg={8} md={8} sm={24} xs={24} style={{ paddingRight: "20px" }}>
                        <Ast /> Municipio
                        <Form.Item>
                        {getFieldDecorator("municipio", { rules: required })(
                            <Select
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option.props.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            placeholder="Selecciona"
                            onSelect={(value, e) => getCadenaMunicipio(value, e)}
                            disabled={secDireccion.inhabilita}
                            >
                            {selectMun.map((item) => (
                                <Option key={item.idMunicipio}>{item.municipio}</Option>
                            ))}
                            </Select>
                        )}
                        </Form.Item>
                    </Col>
                    <Col xxl={8} xl={8} lg={8} md={8} sm={24} xs={24} style={{ paddingRight: "20px" }}>
                        <Ast /> Colonia
                        <Form.Item>
                        {getFieldDecorator("localidad", { rules: calle })(
                            <Input disabled={secDireccion.inhabilita} maxLength={40} />
                        )}
                        </Form.Item>
                    </Col>
                </Row>
            </div>
        );
    };

    function DireccionExtranjero() {
        return (
            <div id="dirExtranjero">
                <Row>
                    <Col xxl={8} xl={8} lg={8} md={8} sm={24} xs={24} style={{ paddingRight: "20px" }}>
                        Código Postal
                        <Form.Item>
                        {getFieldDecorator("codigoPostal", {  
                            getValueFromEvent: (event) => {
                                return soloDigitos.test(event.target.value)
                                ? event.target.value
                                : (event.target.value).slice(0,-1);
                            }, })(
                            <Input maxLength={5} style={{ width: 75 }} disabled={secDireccion.inhabilita} />
                        )}
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col xxl={8} xl={8} lg={8} md={8} sm={24} xs={24} style={{ paddingRight: "20px" }}>
                    Estado/Provincia/Región
                    <Form.Item>
                        {getFieldDecorator("edoProvReg")( <Input disabled={true} maxLength={40} /> )}
                    </Form.Item>
                    </Col>
                    <Col xxl={8} xl={8} lg={8} md={8} sm={24} xs={24} style={{ paddingRight: "20px" }}>
                    Ciudad
                    <Form.Item>
                        {getFieldDecorator("ciudadExt")( <Input disabled={true} maxLength={40} /> )}
                    </Form.Item>
                    </Col>
                    <Col xxl={8} xl={8} lg={8} md={8} sm={24} xs={24} style={{ paddingRight: "20px" }}>
                    País
                    <Form.Item>
                        {getFieldDecorator("paisTxt")( <TextArea style={{ width: 315, borderColor: "#FFF" }} readOnly autoSize /> )}
                    </Form.Item>
                    </Col>
                </Row>
            </div>
        );
    };

    function CalleExtranjero() {
        return (
          <div>
            <Col xxl={8} xl={8} lg={8} md={8} sm={24} xs={24} style={{ paddingRight: "20px" }}>
                Calle
                <Form.Item>
                    {getFieldDecorator("calle")(
                        <Input maxLength={50} disabled={secDireccion.inhabilita} />
                    )}
                </Form.Item>
            </Col>
          </div>
        );
      };
    
      function CalleNacional() {
        return (
          <div>
            <Col xxl={8} xl={8} lg={8} md={8} sm={24} xs={24} style={{ paddingRight: "20px" }}>
                {!esExtranjero && <Ast />} Calle
                <Form.Item>
                    {getFieldDecorator("calle", { rules: calle })(
                        <Input maxLength={50} disabled={secDireccion.inhabilita} />
                    )}
                </Form.Item>
            </Col>
          </div>
        );
      };

    const EntidadesObservar = () => (
        <div id="entidadesObservarReq">
          <Form.Item>
            {getFieldDecorator("entidadesSeleccionadas", { rules: required })(
              <Checkbox.Group options={entidades} disabled={true} type="checkbox" defaultValue={entidadesSeleccionadas} />
            )}
            </Form.Item>
        </div>
    );

    /////////////////////////////////////////// MENSAJES DE LAS OBSERVACIONES POR SECCION ///////////////////////////////////////////
    const MessageSecNombres = () => (
        <div className="sectionBox">
            <p><strong>Observaciones en la sección</strong></p>
            <p>{secNombres.message}</p>
        </div>
    );
    const MessageSecClave = () => (
        <div className="sectionBox">
            <p><strong>Observaciones en la sección</strong></p>
            <p>{secClave.message}</p>
        </div>
    );
    const MessageSecDireccion = () => (
        <div className="sectionBox">
            <p><strong>Observaciones en la sección</strong></p>
            <p>{secDireccion.message}</p>
        </div>
    );
    const MessageSecCredElector = () => {
        var messageFinal = "";
        if (secCredFrente.message !== "" && secCredVuelta.message !== "") {
            messageFinal = secCredFrente.message + " y " + secCredVuelta.message;
        } else {
            if (secCredFrente.message !== "") {
                messageFinal = secCredFrente.message;
            } else {
                messageFinal = secCredVuelta.message;
            }
        }
        return (
            <div className="sectionBox">
                <p><strong>Observaciones en la sección</strong></p>
                <p>Los datos en la imagen: "<strong>{messageFinal}</strong>" son ilegibles, adjunta una imagen de mayor calidad.</p>
            </div>
        )
    };
    const MessageSecFotografia = () => (
        <div className="sectionBox">
            <p><strong>Observaciones en la sección</strong></p>
            <p>{secFotografia.message}
                <br/> Revisa los ejemplos y adjunta una nueva imagen. 
                <button className="btnExample" onClick={() => setPhoto(true)}>
                    Ver ejemplos
                </button>
            </p>            
        </div>
    );
    ///////////////////////////////////////////////// FORMULARIO /////////////////////////////////////////////////
    return (
        <div style={{ marginLeft: "20px", marginTop: "30px" }}>
            <div style={{ marginTop: "10px", marginBottom: "30px" }}>
                <Link to={"/ciudadano/inicio"} className="back">
                    <Icon type="play-circle" theme="filled" rotate="180" /> Volver a "Pantalla de bienvenida"
                </Link>
            </div>
            <h1>Observaciones a la solicitud</h1>
            <Row>
                <Col span={24} style={{ marginBottom: "20px", paddingRight: "20px" }}>
                <Alert
                    message={<p style={{ marginBottom: "0px" }}>
                        Únicamente los datos con observaciones están habilitados</p>}
                    type="info"
                    showIcon
                />
                </Col>
            </Row>
            <p>Los datos marcados con ( <Ast /> ) son obligatorios</p>

            <Form onSubmit={addChanges}>
                <Row style={{ marginTop: "30px" }}>
                    <h3>Datos personales</h3>
                    {!secNombres.inhabilita ? <MessageSecNombres /> : null} 
                    ( <Ast /> ) Al menos un apellido es obligatorio
                    <div style={{ marginTop: "10px" }}>
                        <Col xxl={8} xl={8} lg={8} md={8} sm={24} xs={24} style={{ paddingRight: "20px" }}>
                            Apellido paterno
                            <Form.Item>
                            {getFieldDecorator("aPaterno", { rules: apellidos,
                                getValueFromEvent: (event) => {
                                    return txtTexto.test(event.target.value)
                                    ? event.target.value
                                    : (event.target.value).slice(0,-1);
                                }, })(
                                <Input maxLength={40} disabled={secNombres.inhabilita} />
                            )}
                            </Form.Item>
                        </Col>
                        <Col xxl={8} xl={8} lg={8} md={8} sm={24} xs={24} style={{ paddingRight: "20px" }}>
                            Apellido materno
                            <Form.Item>
                            {getFieldDecorator("aMaterno", { rules: apellidos,
                                getValueFromEvent: (event) => {
                                    return txtTexto.test(event.target.value)
                                    ? event.target.value
                                    : (event.target.value).slice(0,-1);
                                }, })(
                                <Input maxLength={40} disabled={secNombres.inhabilita} />
                            )}
                            </Form.Item>
                        </Col>
                        <Col xxl={8} xl={8} lg={8} md={8} sm={24} xs={24} style={{ paddingRight: "20px" }}>
                            <Ast /> Nombre(s)
                            <Form.Item>
                            {getFieldDecorator("nombre", { rules: nombres, 
                                getValueFromEvent: (event) => {
                                    return txtTexto.test(event.target.value)
                                    ? event.target.value
                                    : (event.target.value).slice(0,-1);
                                }, })(
                                <Input maxLength={50} disabled={secNombres.inhabilita} />
                            )}
                            </Form.Item>
                        </Col>
                    </div>
                </Row>
                <Row>
                    {!secClave.inhabilita ? <MessageSecClave /> : null}
                    <Col xxl={8} xl={8} lg={8} md={8} sm={24} xs={24} style={{ paddingRight: "20px" }}>
                        <Ast /> Clave de elector
                        <Form.Item>
                        {getFieldDecorator("claveElector", { rules: claveElector, getValueFromEvent: valueUpperCase })(
                            <Input maxLength={18} disabled={secClave.inhabilita} onChange={onChangeClave} />
                        )}
                        </Form.Item>
                    </Col> 
                    {!esExtranjero ? SeccionNacional() : null}
                </Row>
                <Row>
                    <Col xxl={8} xl={8} lg={8} md={8} sm={24} xs={24} style={{ paddingRight: "20px" }}>
                        {!disabled.input && <Ast />}Sexo
                        <Form.Item>
                        {getFieldDecorator("sexo", { rules: required })(
                            <Radio.Group disabled={true}>
                            <Radio value={"M"}>Mujer</Radio>
                            <Radio value={"H"}>Hombre</Radio>
                            </Radio.Group>
                        )}
                        </Form.Item>
                    </Col>
                    <Col xxl={8} xl={8} lg={8} md={8} sm={24} xs={24} style={{ paddingRight: "20px" }}>
                        {!disabled.input && <Ast />}Fecha de nacimiento
                        <Form.Item>
                        {getFieldDecorator("fechaNacimiento", { rules: required })(
                            <DatePicker disabled={true} format={dateFormat} />
                        )}
                        </Form.Item>
                    </Col>
                    <Col xxl={8} xl={8} lg={8} md={8} sm={12} xs={24} style={{ paddingRight: "20px" }}>
                        {!disabled.input && <Ast />}Edad
                        <Form.Item>
                        {getFieldDecorator("edad", { rules: required })(
                            <Input disabled={true} maxLength={3} />
                        )}
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col xxl={8} xl={8} lg={8} md={8} sm={24} xs={24} style={{ paddingRight: "20px" }}>
                        <Ast /> Escolaridad
                        <Form.Item>
                        {getFieldDecorator("escolaridad", { rules: required })(
                            <Select placeholder="Selecciona" disabled={secSinObs}>
                                {studies.map((item) => (
                                <Option key={item.idEscolaridad}>{item.escolaridad}</Option>
                                ))}
                            </Select>
                        )}
                        </Form.Item>
                    </Col>
                    <Col xxl={8} xl={8} lg={8} md={8} sm={24} xs={24} style={{ paddingRight: "20px" }}>
                        Ocupación
                        <Form.Item>
                        {getFieldDecorator("ocupacion", { rules: ocupacion })(
                            <Input maxLength={40} disabled={secSinObs} />
                        )}
                        </Form.Item>
                    </Col>
                </Row>
                {!esExtranjero ? TelefonoNacional() : TelefonoExtranjero()}
                <Row style={{ marginTop: "30px" }}>
                    <h3>Domicilio</h3>
                    {!secDireccion.inhabilita ? <MessageSecDireccion /> : null} 
                    <div style={{ marginTop: "10px" }}>
                        {esExtranjero ? CalleExtranjero() : CalleNacional()}
                        <Col xxl={4} xl={4} lg={4} md={8} sm={8} xs={24} style={{ paddingRight: "20px", paddingLeft: "20px", paddingTop: "20px" }} >
                            <Form.Item>
                            {getFieldDecorator("sinNumero", { valuePropName: "checked", initialValue: false, })(
                                <Checkbox onChange={onChangeSinNum} disabled={secDireccion.inhabilita}>
                                Sin número
                                </Checkbox>
                            )}
                            </Form.Item>
                        </Col>
                        <Col xxl={6} xl={6} lg={6} md={8} sm={8} xs={24} style={{ paddingRight: "20px" }}>
                            No. exterior
                            <Form.Item>
                            {getFieldDecorator("noExterior", { 
                            getValueFromEvent: (event) => {
                                return numeroInt.test(event.target.value)
                                ? event.target.value
                                : (event.target.value).slice(0,-1);
                            }, })(
                                <Input maxLength={20} disabled={disabled.checkNum || secDireccion.inhabilita} />
                            )}
                            </Form.Item>
                        </Col>
                        <Col xxl={6} xl={6} lg={6} md={8} sm={8} xs={24} style={{ paddingRight: "20px" }}>
                            No. interior
                            <Form.Item>
                            {getFieldDecorator("noInterior", { 
                            getValueFromEvent: (event) => {
                                return numeroInt.test(event.target.value)
                                ? event.target.value
                                : (event.target.value).slice(0,-1);
                            }, })(
                                <Input maxLength={20} disabled={disabled.checkNum || secDireccion.inhabilita} />
                            )}
                            </Form.Item>
                        </Col>
                    </div>
                </Row>
                {!esExtranjero ? DireccionNacional() : DireccionExtranjero()}
                <Row style={{ marginTop: "30px" }}>
                    <h3>Datos adicionales</h3>
                    <div style={{ marginTop: "10px" }} >
                        <Col span={24} style={{ paddingRight: "20px" }}>
                            <Ast /> ¿Cómo deseas tomar el curso de capacitación?
                            <Form.Item style={{ marginBottom: "0px" }}>
                            {getFieldDecorator("modalidad", { rules: required })(
                                <Radio.Group disabled={secSinObs} >
                                    <Radio value={1} >
                                        En línea <br />
                                        <div className="textRadio">
                                            (disponible desde PC o smartphone con <br />
                                            conexión a internet).
                                        </div>
                                    </Radio>
                                    <Radio value={2} >
                                        Presencial <br />
                                        <div className="textRadio">
                                            (deberás acudir al lugar en la fecha y hora
                                            <br /> que se te indique).
                                        </div>
                                    </Radio>
                                </Radio.Group>
                            )}
                            </Form.Item>
                            {/* showImpartido ? <ResultsModalidad /> : null */}
                        </Col>
                    </div>
                </Row>
                <Row>
                    <Col xxl={8} xl={8} lg={8} md={8} sm={24} xs={24} style={{ paddingRight: "20px" }}>
                        <Ast /> Estado de seguimiento
                        <Form.Item style={{ marginBottom: "0px" }}>
                        {getFieldDecorator("idEstadoJunta", { rules: required })(
                            <Select
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option.props.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            placeholder="Selecciona"
                            disabled={true}
                            >
                            {selectEdoJunta.map((item) => (
                                <Option key={item.id.idEstado}>{item.nombreEstado}</Option>
                            ))}
                            </Select>
                        )}
                        </Form.Item>
                    </Col>
                    <Col xxl={8} xl={8} lg={8} md={8} sm={24} xs={24} style={{ paddingRight: "20px" }}>
                        <Ast /> Junta Ejecutiva que dará seguimiento a tu solicitud
                        <Form.Item style={{ marginBottom: "0px" }}>
                        {getFieldDecorator("junta", { rules: required })(
                            <Input disabled={secSinObs} />
                        )}
                        </Form.Item>
                    </Col>
                    <Col xxl={8} xl={8} lg={8} md={8} sm={24} xs={24} style={{ paddingRight: "20px" }}>
                        <Ast /> Domicilio de la Junta Ejecutiva
                        <Form.Item style={{ marginBottom: "0px" }}>
                        {getFieldDecorator("juntaDir", { rules: required })(
                            <TextArea disabled={true} readOnly autoSize />
                        )}
                        </Form.Item>
                    </Col>
                </Row>
                <Row style={{ marginTop: "30px" }}>
                    <h3>Entidades a observar</h3>
                    <Col span={24} style={{ marginTop: "10px" }}>
                        {!disabled.input && <Ast />}¿En qué entidad o entidades desea el ciudadano observar el Proceso Electoral?
                        <Form.Item style={{ marginBottom: "10px" }}>
                        {getFieldDecorator("tipoEntidad", { rules: required })(
                            <Radio.Group
                            disabled={true}
                            >
                            <Radio value={0}>Solo la entidad de la credencial</Radio>
                            <Radio value={1}>En una o más entidades distintas</Radio>
                            </Radio.Group>
                        )}
                        </Form.Item>
                        <Col span={24}>
                        {variasEntidades ? <EntidadesObservar /> : null}
                        </Col>
                    </Col>
                </Row>
                <Row style={{ marginTop: "30px" }}>
                    <h3>Documentación personal</h3>
                    {modalPhoto()}
                    <div style={{ marginTop: "10px" }} >
                        <h5>Credencial de elector</h5>
                        {(!secCredFrente.inhabilita || !secCredVuelta.inhabilita)  ? <MessageSecCredElector /> : null} 
                        <p>Frente y vuelta, archivos en formato jpeg o png que no excedan los 5MB por archivo.</p>
                        <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24} style={{ paddingTop: "20px", paddingLeft: "20px", paddingRight: "20px" }} >
                            <p><Ast /> <strong>Frente (anverso)</strong></p>
                            <Form.Item style={{ marginBottom: "0px" }}>
                                <Upload
                                name="frente"
                                listType="picture-card"
                                className="avatar-uploader"
                                action={URL_IMAGEN}
                                beforeUpload={beforeUpload}
                                onChange={handleChangeFront}
                                showUploadList={false}
                                accept=".jpg,.JPG,.jpeg,.JPEG,.png,.PNG"
                                disabled={secCredFrente.inhabilita}
                                >
                                {imageFront.imageUrl ? (
                                    <img
                                    src={imageFront.imageUrl}
                                    alt="frente"
                                    style={{ width: "100%" }}
                                    />
                                ) : (
                                    uploadButton(imageFront.loading)
                                )}
                                </Upload>
                            </Form.Item>
                            {!secCredFrente.inhabilita &&  <div style={{ fontSize: 12, marginBottom: 15 }}>
                                <Icon type="info-circle" theme="filled" style={{ color: "#d5007f" }} />{" "}
                                Pulsa sobre una imagen para reemplazarla
                            </div>}
                        </Col>
                        <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24} style={{ paddingTop: "20px", paddingLeft: "20px", paddingRight: "20px" }} >
                            <p><Ast /> <strong>Vuelta (reverso)</strong></p>
                            <Form.Item style={{ marginBottom: "0px" }}>
                                <Upload
                                name="vuelta"
                                listType="picture-card"
                                className="avatar-uploader"
                                action={URL_IMAGEN}
                                beforeUpload={beforeUpload}
                                onChange={handleChangeRound}
                                showUploadList={false}
                                accept=".jpg,.JPG,.jpeg,.JPEG,.png,.PNG"
                                disabled={secCredVuelta.inhabilita}
                                >
                                {imageRound.imageUrl ? (
                                    <img
                                    src={imageRound.imageUrl}
                                    alt="vuelta"
                                    style={{ width: "100%" }}
                                    />
                                ) : (
                                    uploadButton(imageRound.loading)
                                )}
                                </Upload>
                            </Form.Item>
                            {!secCredVuelta.inhabilita &&  <div style={{ fontSize: 12, marginBottom: 15 }}>
                                <Icon type="info-circle" theme="filled" style={{ color: "#d5007f" }} />{" "}
                                Pulsa sobre una imagen para reemplazarla
                            </div>}
                        </Col>
                    </div>
                </Row>
                <Row>
                    <Col span={24} style={{ paddingLeft: "20px", paddingRight: "20px", paddingBottom: "20px" }} >
                        <h5><Ast /> Fotografía</h5>
                        {!secFotografia.inhabilita ? <MessageSecFotografia /> : null}
                        <Form.Item style={{ marginBottom: "0px" }}>
                            <Upload
                            name="photo"
                            listType="picture-card"
                            className="avatar-uploader"
                            action={URL_IMAGEN}
                            beforeUpload={beforeUpload}
                            onChange={handleChangePhoto}
                            showUploadList={false}
                            accept=".jpg,.JPG,.jpeg,.JPEG,.png,.PNG"
                            disabled={secFotografia.inhabilita}
                            >
                            {imagePhoto.imageUrl ? (
                                <img
                                src={imagePhoto.imageUrl}
                                alt="foto"
                                style={{ width: "100%" }}
                                />
                            ) : (
                                uploadButton(imagePhoto.loading)
                            )}
                            </Upload>
                        </Form.Item>
                        {!secFotografia.inhabilita &&  <div style={{ fontSize: 12, marginBottom: 15 }}>
                            <Icon type="info-circle" theme="filled" style={{ color: "#d5007f" }} />{" "}
                            Pulsa sobre una imagen para reemplazarla
                        </div>}
                    </Col> 
                </Row>
                <Row>
                    <Col span={24} style={{ paddingTop: "20px", paddingRight: "20px", paddingBottom: "10px" }}>
                        <Form.Item style={{ textAlign: "center" }}>
                        <Button
                            style={{width: 250}}
                            type="primary"
                            htmlType="submit"
                            disabled={button}
                            onClick={() => { }}
                        >
                            Guardar y enviar a revisión
                        </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </div>
    );
}
const ObservationsForm = Form.create()(Observations);
export default withRouter(ObservationsForm);