import { types } from "../types/types";

/**
 * Acción que guarda en el store la entidad seleccionada para iniciar el curso
 * @param {*} idEntidad
 * @param {*} entidad
 */
export const addEntity = (idEntidad, entidad, iniciado) => ({
  type: types.entidad,
  payload: {
    idEntidad,
    entidad,
    iniciado,
  },
});

/**
 * Acción que limpia la entidad seleccionada
 */
export const cleanEntity = (state) => ({
  type: types.limpiarEntidadSel,
  payload: state,
});

export const addEntityMat = (idEntidad, entidad) => ({
  type: types.entidadMaterial,
  payload: {
    idEntidad,
    entidad,
  },
});

/**
 * Acción que indica que se encuentra en la pantalla de materiales de capacitación
 */
export const activeScreenMat = () => ({
  type: types.pantallaMaterial,
});

export const cleanEntityMat = () => ({
  type: types.limEntidadMat,
});


export const setMenuMaterial = (state) => ({
  type: types.temarioMaterial,
  payload: state,
});

export const setBlock = (state) => ({
  type: types.bloquear,
  payload: state,
});