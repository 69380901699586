import { useEffect, useState } from "react";

export const useInstituto = (idEntidad) => {
  const [instituto, setInstituto] = useState({ instituto: "", gober: "" });
  useEffect(() => {
    switch (idEntidad) {
      case 1:
        setInstituto({
          instituto: "el Instituto Estatal Electoral de Aguascalientes",
          gober: "gubernatura, diputaciones locales y ayuntamientos.",
          consejo: "o del Instituto Estatal Electoral de Aguascalientes",
        });
        break;

      case 2:
        setInstituto({
          instituto: "el Instituto Estatal Electoral de Baja California",
          gober: "gubernatura, diputaciones locales y ayuntamientos.",
          consejo: "o del Instituto Estatal Electoral de Baja California",
        });
        break;

      case 3:
        setInstituto({
          instituto: "el Instituto Estatal Electoral de Baja California Sur",
          gober: "gubernatura, diputaciones locales y ayuntamientos.",
          consejo: "o del Instituto Estatal Electoral de Baja California Sur",
        });
        break;
      case 4:
        setInstituto({
          instituto: "el Instituto Electoral del Estado de Campeche",
          gober:
            "gubernatura, diputaciones locales, ayuntamientos y juntas municipales.",
          consejo: "o del Instituto Electoral del Estado de Campeche",
        });
        break;
      case 5:
        setInstituto({
          instituto: "el Instituto Electoral de Coahuila",
          gober: "ayuntamientos.",
          consejo: "o del Instituto Electoral de Coahuila",
        });
        break;
      case 6:
        setInstituto({
          instituto: "el Instituto Electoral del Estado de Colima",
          gober: "gubernatura, diputaciones locales y ayuntamientos.",
          consejo: "o del Instituto Electoral del Estado de Colima",
        });
        break;
      case 7:
        setInstituto({
          instituto: "el Instituto de Elecciones y Participación Ciudadana",
          gober: "diputaciones locales y ayuntamientos.",
          consejo: "o del Instituto de Elecciones y Participación Ciudadana",
        });
        break;

      case 8:
        setInstituto({
          instituto: "el Instituto Estatal Electoral de Chihuahua",
          gober:
            "gubernatura, diputaciones locales, ayuntamientos y sindicaturas.",
          consejo:
            "o el Consejo Estatal del Instituto Estatal Electoral de Chihuahua",
        });
        break;
      case 9:
        setInstituto({
          instituto: "el Instituto Electoral de la Ciudad de México",
          gober:
            "jefatura de gobierno, diputaciones locales y titulares de las alcaldías y personas integrantes de los concejos.",
          consejo: "o del Instituto Electoral de la Ciudad de México",
        });
        break;
      case 10:
        setInstituto({
          instituto: "el Instituto Electoral y de Participación Ciudadana del Estado de Durango",
          gober:
            "gubernatura, diputaciones locales y ayuntamientos.",
          consejo: "o del Instituto Electoral y de Participación Ciudadana del Estado de Durango",
        });
        break;
      case 11:
        setInstituto({
          instituto: "el Instituto Electoral del Estado de Guanajuato",
          gober: "gubernatura, diputaciones locales y ayuntamientos.",
          consejo: "o del Instituto Electoral del Estado de Guanajuato",
        });
        break;
      case 12:
        setInstituto({
          instituto:
            "el Instituto Electoral y de Participación Ciudadana del Estado de Guerrero",
          gober: "gubernatura, diputaciones locales y ayuntamientos.",
          consejo:
            "o del Instituto Electoral y de Participación Ciudadana del Estado de Guerrero",
        });
        break;

      case 13:
        setInstituto({
          instituto: "el Instituto Estatal Electoral de Hidalgo",
          gober: "gubernatura, diputaciones locales y ayuntamientos.",
          consejo: "o del Instituto Estatal Electoral de Hidalgo",
        });
        break;
      case 14:
        setInstituto({
          instituto:
            "el Instituto Electoral de Participación Ciudadana del Estado de Jalisco",
          gober: "gubernatura, diputaciones locales y ayuntamientos.",
          consejo:
            "o del Instituto Electoral de Participación Ciudadana del Estado de Jalisco",
        });
        break;

      case 15:
        setInstituto({
          instituto: "el Instituto Electoral del Estado de México",
          gober: "diputaciones locales y ayuntamientos.",
          consejo: "o del Instituto Electoral del Estado de México",
        });
        break;
      case 16:
        setInstituto({
          instituto: "el Instituto Electoral de Michoacán",
          gober: "gubernatura, diputaciones locales y ayuntamientos.",
          consejo: "o del Instituto Electoral de Michoacán",
        });
        break;
      case 17:
        setInstituto({
          instituto:
            "el Instituto Morelense de Procesos Electorales y Participación Ciudadana",
          gober: "gubernatura, diputaciones locales y ayuntamientos.",
          consejo:
            "o del Consejo Estatal Electoral del Instituto Morelense de Procesos Electorales y Participación Ciudadana",
        });
        break;
      case 18:
        setInstituto({
          instituto: "el Instituto Estatal Electoral de Nayarit",
          gober: "gubernatura, diputaciones locales y ayuntamientos.",
          consejo:
            "o del Consejo Local Electoral del Instituto Estatal Electoral de Nayarit",
        });
        break;
      case 19:
        setInstituto({
          instituto: "la Comisión Estatal Electoral Nuevo León",
          gober: "gubernatura, diputaciones locales y ayuntamientos.",
          consejo: "o de la Comisión Estatal Electoral Nuevo León",
        });
        break;
      case 20:
        setInstituto({
          instituto:
            "el Instituto Estatal Electoral y de Participación Ciudadana de Oaxaca",
          gober: "gubernatura, diputaciones locales y ayuntamientos.",
          consejo:
            "o del Instituto Estatal Electoral y de Participación Ciudadana de Oaxaca",
        });
        break;
      case 21:
        setInstituto({
          instituto: "el Instituto Electoral del Estado",
          gober: "gubernatura, diputaciones locales y ayuntamientos.",
          consejo: "o del Instituto Electoral del Estado",
        });
        break;
      case 22:
        setInstituto({
          instituto: "el Instituto Electoral del Estado de Querétaro",
          gober: "gubernatura, diputaciones locales y ayuntamientos.",
          consejo:
            "o del Consejo General del Instituto Electoral del Estado de Querétaro",
        });
        break;
      case 23:
        setInstituto({
          instituto: "el Instituto Electoral de Quintana Roo",
          gober: "ayuntamientos.",
          consejo: "o del Instituto Electoral del Quintana Roo",
        });
        break;
      case 24:
        setInstituto({
          instituto:
            "el Consejo Estatal Electoral y de Participación Ciudadana de San Luis Potosí",
          gober: "gubernatura, diputaciones locales y ayuntamientos.",
          consejo: "o del Consejo Estatal Electoral y de Participación Ciudadana",
        });
        break;
      case 25:
        setInstituto({
          instituto: "el Instituto Electoral del Estado de Sinaloa",
          gober: "gubernatura, diputaciones locales y ayuntamientos.",
          consejo: "o del Instituto Electoral del Estado de Sinaloa",
        });
        break;
      case 26:
        setInstituto({
          instituto: "el Instituto Estatal Electoral y de Participación Ciudadana",
          gober: "gubernatura, diputaciones locales y ayuntamientos.",
          consejo: "o del Instituto Estatal Electoral y de Participación Ciudadana",
        });
        break;
      case 27:
        setInstituto({
          instituto:
            "el Instituto Electoral y de Participación Ciudadana de Tabasco",
          gober: "gubernatura, diputaciones locales y ayuntamientos.",
          consejo:
            "o del Consejo Estatal del Instituto Electoral y de Participación Ciudadana de Tabasco",
        });
        break;
      case 28:
        setInstituto({
          instituto: "el Instituto Electoral de Tamaulipas",
          gober: "gubernatura, diputaciones locales y ayuntamientos.",
          consejo: "o del Instituto Electoral de Tamaulipas",
        });
        break;
      case 29:
        setInstituto({
          instituto: "el Instituto Tlaxcalteca de Elecciones",
          gober:
            "gubernatura, diputaciones locales, ayuntamientos y en de ser el caso presidencias de comunidad.",
          consejo: "o del Instituto Tlaxcalteca de Elecciones",
        });
        break;
      case 30:
        setInstituto({
          instituto: "el OPLE Veracruz",
          gober: "gubernatura, diputaciones locales y ayuntamientos.",
          consejo: "o del OPLE Veracruz",
        });
        break;
      case 31:
        setInstituto({
          instituto:
            "el Instituto Electoral y de Participación Ciudadana de Yucatán ",
          gober: "gubernatura, diputaciones locales y ayuntamientos.",
          consejo:
            "o del Instituto Electoral y de Participación Ciudadana de Yucatán",
        });
        break;
      case 32:
        setInstituto({
          instituto: "el Instituto Electoral del Estado de Zacatecas",
          gober: "gubernatura, diputaciones locales y ayuntamientos.",
          consejo: "o del Instituto Electoral del Estado de Zacatecas",
        });
        break;

      default:
        return setInstituto({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return instituto;
};
