import React, { useEffect } from "react";
import { useState } from "react";
import { Button, Progress, Icon, Row, Col, Form } from "antd";
import { Link, withRouter, Redirect } from "react-router-dom";
import useAuth from "../../../hooks/useAuth";
//Componentes para cada pantalla
import Documents from "../../../components/Admin/Validation/Steps/Documents";
import Photo from "../../../components/Admin/Validation/Steps/Photo";
import Data from "../../../components/Admin/Validation/Steps/Data";
import Credential from "../../../components/Admin/Validation/Steps/Credential";
import Address from "../../../components/Admin/Validation/Steps/Address";
//Funciones
import { jsonAttend, modal, Ast } from "../../../utils/Validations/functions";
import {
  validate,
  getObservation,
  getObsAtten,
  generateJsonAttend,
} from "../../../utils/Validations/functions";
import {
  jsonObs,
  generaJson,
  jsonValidado,
} from "../../../utils/Validations/functions";
import {
  ESTATUS_CON_OBSERVACIONES,
  ESTATUS_VALIDADO,
  ESTATUS_OBSERVACIONES_ATENDIDAS,
  USUARIO_CAU,
  MSN_GUARDAR,
} from "../../../utils/constanst";
import { getObservationsById } from "../../../api/validation";

function Validation(props) {
  const { user, idEstatus, modifica, rol, jl } = props.location; //Se obtien los valores por la url
  const {
    user: {
      user: { sub }, //Se obtiene el usuario
    },
  } = useAuth();
  const { history, setLoader } = props;
  const { getFieldDecorator } = props.form; //Método para validar los inputs
  const [current, setCurrent] = useState({ steps: 0, percent: 0 }); //Se inicializa el porcentaje y el contador de las pantallas
  //Hook para habilitar el enviar observaciones en caso de que los documentos no sean legibles
  const [screen, setScreen] = useState({
    frente: true,
    vuelta: true,
    image: false,
    btn: false,
  });
  const [datos, setDatos] = useState({}); //Se crea el json con las preguntas
  const [disabled, setDisabled] = useState({}); //Hook para bloaquar las preguntas de cada pantalla
  const [alert, setAlert] = useState({}); //Hook para mostrar el mensaje de que hubo cambios por el ciudadano
  const [update, setUpdate] = useState(false); //Hook para indicar si sus documentos fueron legibles
  const [userData, setUserData] = useState({});
  const percent = 25;

  useEffect(() => {
    if (user) {
      setLoader({ loading: true, message: MSN_GUARDAR }); //Se activa el loader
      getObservationsById(user.idObservador, user.tipoObservador)
        .then((res) => {
          setUserData(res.data);
          setLoader({ loading: false }); //Se desactiva el loader
        })
        .catch((error) => {
          console.log(error);
          setLoader({ loading: false }); //Se desactiva el loader
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    switch (idEstatus) {
      case ESTATUS_CON_OBSERVACIONES:
        //Obtiene las observaciones y bloquea las preguntas
        getObservation(user.idObservador, setDatos, setDisabled);
        break;

      case ESTATUS_OBSERVACIONES_ATENDIDAS:
        const { idObservador } = user;
        //Obtiene las observaciones y bloquea las preguntas que estas en true
        getObsAtten(idObservador, setDatos, setUpdate, setDisabled, setAlert);
        break;

      case ESTATUS_VALIDADO:
        //Genera un json con todas las preguntas en true
        setDatos(jsonValidado(setDisabled));
        break;

      default:
        //console.log("Revisión");
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idEstatus]);

  //Redirecciona a la pantalla pricipal en caso de que no exista información en la variable user
  if (!user) {
    return <Redirect to={"/admin/validaciones"} />;
  }
  //Función que cambia a la siguiente pantalla
  const next = () => {
    props.form.validateFields((err) => {
      //Valida la información en los radios
      if (!err) {
        setCurrent({
          steps: current.steps + 1,
          percent: current.percent + percent,
        }); //Cambia de pantalla y suma el porcentaje de la barra
      }
    });
  };
  //Función que regresa a la pantalla anterior
  const prev = () => {
    setCurrent({
      steps: current.steps - 1,
      percent: current.percent - percent,
    }); //Cambia de pantalla y resta el porcentaje de la barra
  };
  //Función que va almacenando las respuestas de cada pregunta
  const changeRadio = (input) => (event) => {
    setDatos({ ...datos, [input]: event.target.value });
  };
  //Función que que envía la información de la solicitud
  const finish = () => {
    props.form.validateFields((err) => {
      if (!err) {
        setScreen({ ...screen, image: true, btn: true });
        datos.idObservador = user.idObservador; //Se obtiene el id
        datos.usuario = sub; //Obtiene el usuario
        datos.capacitacion = userData.capacitacion; //Obtiene el modo de capacitación que registro el observador
        datos.idEstatus = user.idEstatus; //El estatus de observador
        //Valida si todas las preguntas esta en true
        if (validate(datos)) {
          datos.solicitud = "success"; //Estado que indica que la solicitud no tiene observaciones
          const json = generaJson(datos); //Se genera el json para enviar al back
          modal(screen, setScreen, json, history);
        } else {
          datos.solicitud = "error"; //Estado que indica que se encontraron observaciones
          if (user.idEstatus === ESTATUS_OBSERVACIONES_ATENDIDAS) {
            const json = generateJsonAttend(datos, disabled, update); //Genera el json indicando que se actualizaran las observaciones
            modal(screen, setScreen, json, history); //Se muestra el alert
          } else {
            //Entra en caso de que la solicitud este en estatus "Sin revisión"
            const json = generaJson(datos);
            modal(screen, setScreen, json, history);
          }
        }
      }
    });
  };
  //Función que envia la información en caso de que los documentos no sean legibles
  const sendObs = () => {
    props.form.validateFields((err) => {
      if (!err) {
        datos.idObservador = user.idObservador; //Se obtiene el id
        datos.solicitud = "error"; //Estado que indica que se encontraron observaciones
        datos.usuario = sub; //Obtiene el usuario
        datos.capacitacion = userData.capacitacion; //Obtiene el modo de capacitación que registro el observador
        datos.idEstatus = user.idEstatus; //El estatus de observador
        setScreen({ ...screen, image: true, btn: true }); //Se cambia el boton de siguiente por el de enviar observaciones
        if (user.idEstatus === ESTATUS_OBSERVACIONES_ATENDIDAS) {
          const json = jsonAttend(datos, disabled, update);
          modal(screen, setScreen, json, history);
        } else {
          const json = jsonObs(datos);
          modal(screen, setScreen, json, history);
        }
      }
    });
  };

  //Componentes de cada pantalla
  const steps = [
    {
      content: (
        <Documents
          setScreen={setScreen}
          getFieldDecorator={getFieldDecorator}
          changeRadio={changeRadio}
          setDatos={setDatos}
          datos={datos}
          user={Object.keys(userData).length === 0 ? null : userData}
          screen={screen}
          disabled={disabled}
          alert={alert}
        />
      ),
    },
    {
      content: (
        <Photo
          getFieldDecorator={getFieldDecorator}
          datos={datos}
          changeRadio={changeRadio}
          user={userData}
          disabled={disabled}
          alert={alert}
        />
      ),
    },
    {
      content: (
        <Data
          getFieldDecorator={getFieldDecorator}
          form={props.form}
          datos={datos}
          changeRadio={changeRadio}
          user={userData}
          disabled={disabled}
          alert={alert}
        />
      ),
    },
    {
      content: (
        <Credential
          getFieldDecorator={getFieldDecorator}
          form={props.form}
          datos={datos}
          changeRadio={changeRadio}
          user={userData}
          disabled={disabled}
          alert={alert}
        />
      ),
    },
    {
      content: (
        <Address
          getFieldDecorator={getFieldDecorator}
          form={props.form}
          changeRadio={changeRadio}
          datos={datos}
          user={userData}
          disabled={disabled}
          alert={alert}
          imagen={screen.image}
        />
      ),
    },
  ];

  return (
    <div>
      <Link to={"/admin/validaciones"} className="back">
        <Icon type="play-circle" theme="filled" rotate="180" /> Volver a
        “Validación de registros”
      </Link>

      <div style={{ marginTop: 30 }}>
        <h2>Revisar solicitud</h2>
        <p>
          Los datos con ( <Ast /> ) son requeridos
        </p>
      </div>

      <Row gutter={24} style={{ marginTop: 50 }}>
        <Col xxl={4} xl={4} lg={6} md={8} sm={8} xs={8}>Avance en la revisión</Col>
        <Col xxl={20} xl={20} lg={18} md={16} sm={16} xs={16}>
          <Progress
            percent={current.percent}
            status="active"
            strokeColor="#e149a4"
          />
        </Col>
      </Row>

      <div style={{ marginTop: 50 }}>{steps[current.steps].content}</div>

      <div style={{ textAlign: "center", marginBottom: 20, marginTop: 60 }}>
        {current.steps > 0 && (
          <Button onClick={() => prev()} type="default" disabled={screen.btn}>
            Anterior
          </Button>
        )}

        {idEstatus !== ESTATUS_CON_OBSERVACIONES
          ? current.steps < steps.length - 1 &&
            screen.frente &&
            screen.vuelta && (
              <Button
                type="primary"
                onClick={() => next()}
                disabled={
                  Object.keys(userData).length === 0 ? true : screen.btn
                }
              >
                Siguiente
              </Button>
            )
          : current.steps < steps.length - 1 &&
            datos.frente &&
            datos.vuelta && (
              <Button
                type="primary"
                onClick={() => next()}
                disabled={
                  Object.keys(userData).length === 0 ? true : screen.btn
                }
              >
                Siguiente
              </Button>
            )}

        {current.steps === steps.length - 1 &&
          idEstatus !== ESTATUS_CON_OBSERVACIONES &&
          idEstatus !== ESTATUS_VALIDADO &&
          modifica &&
          rol !== USUARIO_CAU && (
            <Button
              type="primary"
              style={{ width: "auto" }}
              onClick={() => finish()}
              disabled={screen.btn || jl}
            >
              {validate(datos) ? "Validar Solicitud" : "Enviar observaciones"}
            </Button>
          )}
        {(!screen.frente || !screen.vuelta) &&
          idEstatus !== ESTATUS_CON_OBSERVACIONES &&
          idEstatus !== ESTATUS_VALIDADO &&
          modifica &&
          rol !== USUARIO_CAU && (
            <Button
              type="primary"
              style={{ width: "auto" }}
              onClick={() => sendObs()}
              disabled={screen.btn || jl}
            >
              Enviar observaciones
            </Button>
          )}
      </div>
    </div>
  );
}

export default withRouter(Form.create()(Validation));
