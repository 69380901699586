export const apellidos = [
  {
    message: "Caracteres inválidos.",
    pattern: /^[a-zA-Z-áéíóúÁÉÍÓÚÑñ]+[^0-9{}\\_!"´#$%&()=?/¡*'¨°|;,<>-]+$/,
  },
  { validateStatus: "error" },
];

export const nombres = [
{
  message: "Caracteres inválidos.",
  pattern: /^[a-zA-Z-äëïöüÄËÏÖÜáéíóúÁÉÍÓÚÑñ]+[^0-9{}\\_!"´#$%&()=?/¡*'¨°|;,<>-]+$/,
},
{ required: true, message: "Dato requerido." },
{ validateStatus: "error" },
];

export const soloTexto = [
{
  message: "Caracteres inválidos.",
  pattern: /^[a-zA-Z-áéíóúÁÉÍÓÚÑñ]+[^0-9{}\\_!"´#$%&()=?/¡*'¨°|;<>-]+$/,
},
{ required: true, message: "Dato requerido." },
{ validateStatus: "error" },
{ min: 3, message: " " },
];

export const ocupacion = [
  {
    message: "Caracteres inválidos.",
    pattern: /^[a-zA-Z-áéíóúÁÉÍÓÚÑñ]+[^0-9{}\\_!"´#$%&()=?/¡*'¨°|;<>-]+$/,
  },
  { validateStatus: "error" },
  ];

export const claveElector = [
{
  message: "Clave de elector inválida.",
  pattern: /^([A-Z]{5,6}\d{8}[H|M]\d{3})$|^([0-9]{13})$/,
},
{ required: true, message: "Dato requerido." },
{ min: 18, message: " " },
{ validateStatus: "error" },
];

export const seccion = [
{ message: "Sección inválida.", pattern: /^[0-9]+$/ },
{ min: 4, message: "Sección inválida." },
{ required: true, message: "Dato requerido." },
{ validateStatus: "error" },
];

export const calle = [
{
  message: "Caracteres inválidos.",
  pattern: /^[a-zA-Z-áéíóúüÁÉÍÓÚÜ0-9]+[^\\_!"$%&()=?¡*'¨°|<>]+$/,
},
{ required: true, message: "Dato requerido." },
{ validateStatus: "error" },
];

export const numero = [
{
  message: " ",
  pattern: /^[a-zA-Z-áéíóúüÁÉÍÓÚÜ0-9]+[^\\_!"$%&()=?¡*'¨°|<>]+$/,
},
{ validateStatus: "error" },
];

export const cp = [
{
  message: "Código postal inválido.",
  pattern: /^[0-9]+$/,
},
{ required: true, message: "Dato requerido." },
{ validateStatus: "error" },
{ min: 5, message: "Código postal inválido." },
];

export const correo = [
{ validateStatus: "error" },
{
  type: "email",
  message: "Correo electrónico inválido.",
},
];

export const tel = [
{ message: "Teléfono inválido.", pattern: /^[0-9]+$/ },
{ min: 10, message: "Teléfono inválido." },
{ validateStatus: "error" },
];

export const telExt = [
  { message: "Teléfono inválido.", pattern: /^[0-9]+$/ },
  { min: 10, message: "Teléfono inválido." },
  { validateStatus: "error" },
];

export const required = [{ required: true, message: "Dato requerido." }];

export function valueUpperCase(e) {
return e.target.value.toUpperCase();
}

export const txtTexto = /^[a-zA-Z-áéíóúÁÉÍÓÚÑñäëïöü\s]+$/i;
export const soloDigitos = /^\d*$/;
export const numeroInt = /^[a-zA-Z-äëïöüÄËÏÖÜáéíóúÁÉÍÓÚÑñ0-9,;#'/\s.]*$/;