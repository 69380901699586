import React, { useState, useEffect } from "react";
import { Redirect, withRouter, Link } from "react-router-dom";
import { Form, Icon, Row, Col } from "antd";
import useAuth from "../../../../hooks/useAuth";
import { Ast } from "../../../../utils/Information/functions";
import Data from "../../../../components/Information/Data";
import { getInformeCiudadano } from "../../../../api/information";
import { MSN_GUARDAR } from "../../../../utils/constanst";


function Information(props) {
    const { history, setLoader, location: { state }, } = props; //Para utilizar la función push
    const { ciudadano: { ciudadano },  } = useAuth();  
    const [informe, setInforme] = useState(); 
    const { getFieldDecorator } = props.form; //Método para validar los inputs
    const [datos, setDatos] = useState({ nombre: "", clave: "", solicitud: "" });
    const [disabled, setDisabled] = useState(false); //Hook para desactivar los campos
    const [seModifica, setSeModifica] = useState(false); //Hook para desactivar la modificación
    const [button, setButton] = useState(false);

    useEffect(() => {
        setLoader({ loading: true, message: MSN_GUARDAR }); 
        getInformeCiudadano(ciudadano.sub).then((res) => {
            if (res.data !== null) {
                setSeModifica(true);
                setDisabled(false);
                setButton(false);
                setInforme(res.data);
                setDatos({nombre: res.data.nombreObservador, clave: res.data.claveElector, 
                    solicitud: res.data.tipoSolicitud === 1 ? "Miembro de organización":"Individual"});
            }
            setLoader({ loading: false, message: "" });
        }).catch((error) => {
            console.log("Ocurrio un error", error);
            setLoader({ loading: false, message: "" }); 
        }); 
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (informe !== undefined && informe !== null) {
            informe.medioCaptura = 0;
            informe.usuario = ciudadano.sub;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [informe]);

    //Se valida que acceda desde la pantalla principal
    if (state === undefined) {
        return (
        <>
            <Redirect to="/ciudadano/inicio" />
        </>
        );
    }

    return (
        <div style={{ marginTop: 50 }}>
            <div style={{ marginTop: "10px", marginBottom: "30px" }}>
                <Link to={"/ciudadano/inicio"} className="back">
                    <Icon type="play-circle" theme="filled" rotate="180" /> Regresar
                </Link>
            </div>
            <h1>Informe de observación electoral</h1>
            <hr />
            <p style={{ marginTop: 30 }}>
                Los datos marcados con ( <Ast /> ) son obligatorios.
            </p>
            <div className="card-menu-home" >
            <Row>
                <Col span={6} style={{ paddingRight: "20px" }}>
                    <strong>Nombre del Observador/a:</strong>
                    <p>{datos.nombre}</p>
                </Col>
                <Col span={6} style={{ paddingRight: "20px" }}>
                    <strong>Clave de Elector:</strong>
                    <p>{datos.clave}</p>
                </Col>
                <Col span={6} style={{ paddingRight: "20px" }}>
                    <strong>Tipo de solicitud:</strong>
                    <p>{datos.solicitud}</p>
                </Col>
            </Row>
            </div>
            {informe !== null && (
                <Data
                informe={informe} //se manda el hook de los valores
                getFieldDecorator={getFieldDecorator} //Se envia para validar los inputs
                form={props.form}
                setLoader={setLoader}
                setInforme={setInforme}
                history={history}
                disabled={disabled}
                setDisabled={setDisabled}
                seModifica={seModifica}
                button={button}
                />
            )}
        </div>
      );

}
const InformationForm = Form.create()(Information);
export default withRouter(InformationForm);