import React, { useState, useEffect } from "react";
import { Table, Input, Select } from "antd";
//import moment from "moment";
import { withRouter, Link } from "react-router-dom";

function TableDetailRequests(props) {
    const {
        data, //Informacion del reporte de solicitudes
        loading,
        proceso,
        registroTabla,
        etiqueta
    } = props;
    const [dataSource, setDataSource] = useState([]); //hook para almacenar el array
    const [filtroNombre, setFiltroNombre] = useState("");
    const [filtroClaveElector, setFiltroClaveElector] = useState("");
    const [filtroFolio, setFiltroFolio] = useState("");
    const [valTipoSol, setValTipoSol] = useState("");
    const [estatusFilter, setEstatusFilter] = useState("");
    const searchInputNombre = React.useRef(null);
    const searchInputClave = React.useRef(null);
    const searchInputFolio = React.useRef(null);

    const { Option } = Select;
    
    useEffect(() => {
        setDataSource(data); //Se inicializa el array de jobs en el hook
    }, [data]);
    
    useEffect(() => {
        if(searchInputNombre.current !== null) {
            searchInputNombre.current.focus();
        }
    }, [filtroNombre]);
    
    useEffect(() => {
        if(searchInputClave.current !== null) {
            searchInputClave.current.focus();
        }
    }, [filtroClaveElector]);

    useEffect(() => {
        if(searchInputFolio.current !== null) {
            searchInputFolio.current.focus();
        }
    }, [filtroFolio]);
    
    const FilterByName = (
        <>
            Nombre del solicitante
            <Input placeholder="Nombre del solicitante" value={filtroNombre} ref={searchInputNombre}
                onChange={(e) => {
                    const currValue = e.target.value;
                    setFiltroNombre(currValue);
                    const filteredData = data.filter((entry) =>
                        entry.nombreSol
                            .toString()
                            .toLowerCase()
                            .includes(currValue.toLowerCase()) &&
                            entry.claveElec
                            .toString()
                            .toLowerCase()
                            .includes(filtroClaveElector.toLowerCase()) &&
                            entry.folio
                            .toString()
                            .toLowerCase()
                            .includes(filtroFolio.toLowerCase()) &&
                        entry.tipoSol
                            .toString()
                            .toLowerCase()
                            .includes(valTipoSol.toLowerCase()) &&
                        entry.estatusGeneral
                                .toString()
                                .toLowerCase()
                                .includes(estatusFilter.toLowerCase())

                    );
                    setDataSource(filteredData);
                }} 
            />
        </>
    );

    const FilterByClaveElector = (
        <>
            Clave de elector
            <Input placeholder="Clave de elector" value={filtroClaveElector} ref={searchInputClave}
                onChange={(e) => {
                    const currValue = e.target.value;
                    setFiltroClaveElector(currValue);
                    const filteredData = data.filter((entry) =>
                        entry.claveElec
                            .toString()
                            .toLowerCase()
                            .includes(currValue.toLowerCase()) &&
                        entry.nombreSol
                            .toString()
                            .toLowerCase()
                            .includes(filtroNombre.toLowerCase()) &&
                        entry.folio
                            .toString()
                            .toLowerCase()
                            .includes(filtroFolio.toLowerCase()) &&
                        entry.tipoSol
                            .toString()
                            .toLowerCase()
                            .includes(valTipoSol.toLowerCase()) &&
                        entry.estatusGeneral
                                .toString()
                                .toLowerCase()
                                .includes(estatusFilter.toLowerCase())
                    );
                    setDataSource(filteredData);
                }} 
            />
        </>
    );

    const FilterByFolio = (
        <>
            Folio
            <Input placeholder="Folio" value={filtroFolio} ref={searchInputFolio}
                onChange={(e) => {
                    const currValue = e.target.value;
                    setFiltroFolio(currValue);
                    const filteredData = data.filter((entry) =>
                        entry.folio
                            .toString()
                            .toLowerCase()
                            .includes(currValue.toLowerCase()) &&
                        entry.claveElec
                            .toString()
                            .toLowerCase()
                            .includes(filtroClaveElector.toLowerCase()) &&
                        entry.nombreSol
                            .toString()
                            .toLowerCase()
                            .includes(filtroNombre.toLowerCase()) &&
                        entry.tipoSol
                            .toString()
                            .toLowerCase()
                            .includes(valTipoSol.toLowerCase()) &&
                        entry.estatusGeneral
                                .toString()
                                .toLowerCase()
                                .includes(estatusFilter.toLowerCase())
                    );
                    setDataSource(filteredData);
                }} 
            />
        </>
    );
    
   const FilterTipoSol = (
        <>
            Tipo de Solicitud
            <br/>
            <Select style={{ width: 130 }} placeholder="Todos" value={valTipoSol} optionFilterProp="children"
                onChange={(value) => {
                    const currValue = value;
                    setValTipoSol(currValue);
                    const filteredData = data.filter((entry) =>
                        entry.tipoSol
                            .toString()
                            .toLowerCase()
                            .includes(currValue.toLowerCase()) &&
                        entry.claveElec
                            .toString()
                            .toLowerCase()
                            .includes(filtroClaveElector.toLowerCase()) &&
                        entry.nombreSol
                            .toString()
                            .toLowerCase()
                            .includes(filtroNombre.toLowerCase()) &&
                        entry.folio
                            .toString()
                            .toLowerCase()
                            .includes(filtroFolio.toLowerCase()) &&
                        entry.estatusSol
                            .toString()
                            .toLowerCase()
                            .includes(estatusFilter.toLowerCase())
                    
                    );
                    setDataSource(filteredData);
              }}
            
            >
                <Option value="">Todos</Option>
                <Option value="Individual">Individual</Option>
                <Option value="Organización">Organización</Option>
            </Select>
        </>
   );

   const FilterEstatus = (
    <>
        Estatus general de la solicitud
        <br/>
        <Select style={{ width: 130 }} placeholder="Todos" value={estatusFilter} optionFilterProp="children"
            onChange={(value) => {
                const currValue = value;
                setEstatusFilter(currValue);
                const filteredData = data.filter((entry) =>
                entry.estatusGeneral
                    .toString()
                    .toLowerCase()
                    .includes(currValue.toLowerCase()) &&
                entry.claveElec
                    .toString()
                    .toLowerCase()
                    .includes(filtroClaveElector.toLowerCase()) &&
                entry.nombreSol
                    .toString()
                    .toLowerCase()
                    .includes(filtroNombre.toLowerCase()) &&
                entry.folio
                        .toString()
                        .toLowerCase()
                        .includes(filtroFolio.toLowerCase()) &&
                entry.tipoSol
                    .toString()
                    .toLowerCase()
                    .includes(valTipoSol.toLowerCase())
                );
                setDataSource(filteredData);
          }}

        >
            <Option value="">Todos</Option>
            <Option value="Pendiente">Pendiente</Option>
            <Option value="Denegada">Denegada</Option>
            <Option value="Declinada">Declinada</Option>
            <Option value="Aprobada">Aprobada</Option>
            <Option value="Cancelada">Cancelada</Option>
            <Option value="No completó los requisitos">No completó los requisitos</Option>
            <Option value="Registrado en otro sistema">Registrado en otro sistema</Option>
        </Select>
    </>
    ); 
    var columnasGenerales = [
        {
            title: FilterByClaveElector,
            dataIndex: "claveElec",
            key: "claveElec",
        },
        {
            title: FilterByFolio,
            dataIndex: "folio",
            key: "folio",
        },
        {
            title: FilterTipoSol,
            dataIndex: "tipoSol",
            key: "tipoSol",
        },
        {
            title: "Agrupación",
            dataIndex: "agrupacion",
            key: "agrupacion",
        },
        {
            title: FilterEstatus,
            dataIndex: "estatusGeneral",
            key: "estatusGeneral",
        },
        {
            title: "Estado de la revisión",
            dataIndex: "estatusSol",
            key: "estatusSol",
        },
        {
            title: "Edad",
            dataIndex: "edad",
            key: "edad",
        },
        {
            title: "Sexo",
            dataIndex: "sexo",
            key: "sexo",
        },
        {
            title: "Procedencia de la solicitud",
            dataIndex: "nacionalidad",
            key: "nacionalidad",
        },
        {
            title: "Entidad del domicilio",
            dataIndex: "entidadDomicilio",
            key: "entidadDomicilio",
        },
        {
            title: "Distrito del domicilio",
            dataIndex: "distritoDomicilio",
            key: "distritoDomicilio",
        },
        {
            title: "Fecha de la Solicitud",
            dataIndex: "fechaSol",
            key: "fechaSol",
        },
        {
            title: "Origen de la solicitud",
            dataIndex: "origenSol",
            key: "origenSol",
        },
        {
            title: "Modalidad del curso",
            dataIndex: "tipoCurso",
            key: "tipoCurso",
        },
        {
            title: "Curso a distancia",
            dataIndex: "distancia",
            key: "distancia",
        },
        {
            title: "Fecha que completó el curso",
            dataIndex: "fechaFinCurso",
            key: "fechaFinCurso",
        },
        {
            title: "Fecha de aprobación de la acreditación",
            dataIndex: "fechaHoraAcreditacion",
            key: "fechaHoraAcreditacion",
        },
        {
            title: "Número de sesion",
            dataIndex: "numSesion",
            key: "numSesion",
        },
        {
            title: "Fecha de la sesion",
            dataIndex: "fechaSesion",
            key: "fechaSesion",
        },
        {
            title: "Número de acuerdo",
            dataIndex: "numAcuerdo",
            key: "numAcuerdo",
        },
        {
            title: "Teléfono",
            dataIndex: "telefono",
            key: "telefono",
        },
        {
            title: "Celular",
            dataIndex: "celular",
            key: "celular",
        },
        {
            title: "Correo electrónico",
            dataIndex: "correo",
            key: "correo",
        },
    ]

    //Se definen las columnas de nivel OC
    var columnasOC = [
        {
            title: "Entidad",
            dataIndex: "nombreEstado",
            key: "nombreEstado",
            fixed: "left",
            render: (text, record) => <Link to={{
                pathname:"/admin/reportes/detalleSolicitudes",
                etiqueta: record.idEstado}}>{text}</Link>
        },
        {
            title: "Distrito",
            dataIndex: "distrito",
            key: "distrito",
        },
        {
            title: FilterByName,
            dataIndex: "nombreSol",
            key: "nombreSol",
    
        },
        
    ];

    var columnasJL = [
            {
                title: "Junta Ejecutiva",
                dataIndex: "distrito",
                key: "distrito",
                width: 200,
                fixed: "left",
                render: (text, record) => <Link to={{
                    pathname:"/admin/reportes/detalleSolicitudes",
                    registroTabla: record}}>{text}</Link>
            },        
            {
                title: FilterByName,
                dataIndex: "nombreSol",
                key: "nombreSol",
        
            },
        ];

    var columnasJD = [

        {
            title: FilterByName,
            dataIndex: "nombreSol",
            fixed: "left",
            key: "nombreSol",
    
        },
    ];

    columnasOC = columnasOC.concat(columnasGenerales);
    columnasJL = columnasJL.concat(columnasGenerales);
    columnasJD = columnasJD.concat(columnasGenerales);


    const TipoTabla = () => {
        if(registroTabla !== undefined && registroTabla !== null) {
            if(registroTabla.idDistrito !== undefined && registroTabla.idDistrito !== null) {
                if(registroTabla.idDistrito !== 0) {
                    return (
                        <Table
                            columns={columnasJD}
                            dataSource={dataSource}
                            loading={loading}
                            locale={{ emptyText: "Sin registros" }}
                            size="small"
                            scroll={{ x:true }}
                        />
                    );
                } else {
                    return (
                        <Table
                            columns={columnasJD}
                            dataSource={dataSource}
                            loading={loading}
                            locale={{ emptyText: "Sin registros" }}
                            size="small"
                            scroll={{ x:true }}
                        />
                    );
                }
            } else if(registroTabla.idEstado !== undefined && registroTabla !== null) {
                if(registroTabla.idEstado !== 0) {
                    return (
                        <Table
                            columns={columnasJL}
                            dataSource={dataSource}
                            loading={loading}
                            locale={{ emptyText: "Sin registros" }}
                            size="small"
                            scroll={{ x: true }}
                        />
                    );
                } else {
                    return (
                        <Table
                            columns={columnasOC}
                            dataSource={dataSource}
                            loading={loading}
                            locale={{ emptyText: "Sin registros" }}
                            size="small"
                            scroll={{ x: true }}
                        />
                    );
                }
            } else {
                return (
                    <Table
                        columns={columnasOC}
                        dataSource={dataSource}
                        loading={loading}
                        locale={{ emptyText: "Sin registros" }}
                        size="small"
                        scroll={{ x: true }}
                    />
                );
            }
        } else {
            if(etiqueta !== undefined && etiqueta !== null) {
                if(etiqueta !== 0) {
                    return (
                        <Table
                            columns={columnasJL}
                            dataSource={dataSource}
                            loading={loading}
                            locale={{ emptyText: "Sin registros" }}
                            size="small"
                            scroll={{ x: true }}
                        />
                    );
                } else {
                    return (
                        <Table
                            columns={columnasOC}
                            dataSource={dataSource}
                            loading={loading}
                            locale={{ emptyText: "Sin registros" }}
                            size="small"
                            scroll={{ x: true }}
                        />
                    );
                }
            } else {
                if(proceso.distrito !== undefined && proceso.distrito !== null) {
                    if(proceso.distrito.idDistrito !== 0) {
                        return (
                            <Table
                                columns={columnasJD}
                                dataSource={dataSource}
                                loading={loading}
                                locale={{ emptyText: "Sin registros" }}
                                size="small"
                                scroll={{ x: true }}
                            />
                        );
                    } else {
                        return (
                            <Table
                                columns={columnasJL}
                                dataSource={dataSource}
                                loading={loading}
                                locale={{ emptyText: "Sin registros" }}
                                size="small"
                                scroll={{ x: true }}
                            />
                        );
                    }
                } else if(proceso.estado !== undefined && proceso.estado !== null) {
                    if(proceso.estado.idEstado !== 0) {
                        return (
                            <Table
                                columns={columnasJL}
                                dataSource={dataSource}
                                loading={loading}
                                locale={{ emptyText: "Sin registros" }}
                                size="small"
                                scroll={{ x: true }}
                            />
                        );
                    } else {
                        return (
                            <Table
                                columns={columnasOC}
                                dataSource={dataSource}
                                loading={loading}
                                locale={{ emptyText: "Sin registros" }}
                                size="small"
                                scroll={{ x: true }}
                            />
                        );
                    }
                //} else if(proceso.proceso !== undefined && proceso.proceso !== null) {
                } else {
                    return (
                        <Table
                            columns={columnasOC}
                            dataSource={dataSource}
                            loading={loading}
                            locale={{ emptyText: "Sin registros" }}
                            size="small"
                            scroll={{ x: true }}
                        />
                    );
                }
            }
        }
    }

    return (
        <TipoTabla />
    );
}

export default withRouter(TableDetailRequests);