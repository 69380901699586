import React from "react";
import { message, Icon, Modal } from "antd";
import moment from "moment";
import {
  saveConvocation,
  updateConvocation,
  updateStatusConvocation,
} from "../../api/convocation";
import { MSN_GUARDAR } from "../constanst";

//Modal de confirmación al publicar la convocatoria
export function ModalConfirm(convocation, history, setScreen, setLoader) {
  const { confirm } = Modal;
  confirm({
    title: null,
    content: messageModal(convocation.estatus),
    icon: null,
    okText: convocation.estatus ? "Publicar" : "Guardar",
    cancelText: "Corregir datos",
    cancelButtonProps: { className: "ant-btn-default" },
    onOk() {     
      convocation.idConvocatoria !== undefined
        ? update(convocation, history, setScreen, setLoader)
        : save(convocation, history, setScreen, setLoader); //Se verifica si el idConvocatoria existe para guardar o modificar
    },
    onCancel() {
      setScreen((screen) => ({ ...screen, button: false }));
    },
  });
}

const messageModal = (status) => {
  return (
    <>
      {status ? (
        <>
          <p>
            La convocatoria se publicará con los datos que capturaste. Esta
            acción no se puede deshacer.
          </p>
          <p>¿Deseas publicar la convocatoria?</p>
        </>
      ) : (
        <>
          <p>La convocatoria se guardará con los datos que capturaste.</p>
          <p>¿Deseas continuar?</p>
        </>
      )}
    </>
  );
};

//Guarda la convocatoria
function save(convocation, history, setScreen, setLoader) {
  setLoader({ loading: true, message: MSN_GUARDAR }); //Se activa el loader
  saveConvocation(convocation)
    .then((res) => {
      if (res.data.code === 200) {
        setLoader({ loading: false, message: "" }); //Se desactiva el loader
        Modal.success({
          title: "Guardar convocatoria",
          content: <p>{res.data.message}</p>,
          okText: "Aceptar",
          onOk() {
            history.push("/admin/convocatoria"); //Redirecciona a la pantalla principal
          },
        });
      } else {
        setLoader({ loading: false, message: "" }); //Se desactiva el loader
        Modal.warning({
          title: "Atención",
          content: <p>{res.data.causa}</p>,
        });
        setScreen((screen) => ({ ...screen, button: false }));
      }
    })
    .catch((error) => {
      console.log("error save", error);
      setLoader({ loading: false, message: "" }); //Se desactiva el loader
    });
}
//Actualiza la convocatoria
function update(convocation, history, setButton, setLoader) {
  setLoader({ loading: true, message: MSN_GUARDAR }); //Se activa el loader
  updateConvocation(convocation)
    .then((res) => {
      if (res.data.code === 200) {
        setLoader({ loading: false, message: "" });  //Se desactiva el loader    
        Modal.success({
          title: "Actualizar convocatoria",
          content: <p>{res.data.message}</p>,
          okText: "Aceptar",
          onOk() {
            history.push("/admin/convocatoria");
          },
        });
      } else {
        setLoader({ loading: false, message: "" });  //Se desactiva el loader    
        Modal.warning({
          title: "Atención",
          content: <p>{res.data.causa}</p>,
        });
        setButton(false);
      }
    })
    .catch((error) => console.log("error update", error));
}
//Función que valida el tipo de imagen y tamaño.
export function beforeUpload(file) {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("Solo archivos en formato JPG/PNG!");
    return false;
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M || isLt2M === 0) {
    message.error("Peso maximo de 2MB!");
    return false;
  }
  const nameFile = validateName(file.name);
  if (!nameFile) {
    message.error(
      "El nombre de archivo " +
        file.name +
        " es inválido. El nombre del documento no debe contener caracteres especiales."
    );
    return false;
  }
  return isJpgOrPng && isLt2M && nameFile;
}

function validateName(e) {
  var patron = /^[0-9a-zA-Z_-\s]+.[jpg/JPG/jpeg/JPEG/png/PNG]+$/;
  if (e.match(patron)) {
    return true;
  } else {
    return false;
  }
}
//Función que muestra la imagen en el input
export function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}
//Boton que muestra la animación de cargando
export const uploadButton = (loading) => (
  <>
    <Icon type={loading ? "loading" : "plus"} />
    <div className="ant-upload-text">Cargar</div>
  </>
);
//Función datepicker que bloquea las fechas anteriores
export function disabledDate(current) {
  return current && current < moment().endOf("day");
}

//Modal de confirmación al publicar la convocatoria
export function ModalEstatus(id, setReload) {
  const { confirm } = Modal;
  confirm({
    title: "La  convocatoria se publicará con los datos que capturaste.",
    content: "¿Deseas publicar la convocatoria?",
    icon: null,
    okText: "Publicar",
    cancelText: "Cancelar",
    cancelButtonProps: { className: "ant-btn-default" },
    onOk() {
      updateStatus(id, setReload); //Se envian los valores
    },
  });
}

//Guarda la convocatoria
function updateStatus(id, setReload) {
  updateStatusConvocation(id)
    .then((res) => {
      if (res.data.code === 200) {
        Modal.success({
          title: "Actualizar convocatoria",
          content: <p>{res.data.message}</p>,
          okText: "Aceptar",
          onOk() {
            setReload(true);
          },
        });
      } else {
        Modal.warning({
          title: "Atención",
          content: <p>{res.data.causa}</p>,
        });
      }
    })
    .catch((error) => console.log("error save", error));
}
//Función que obtiene el nombre de la entidad
export function split(detalle) {
  let estado = detalle.split("-");
  return estado[2].charAt(0).toUpperCase() + estado[2].slice(1).toLowerCase();
}
export function minusculas(texto) {
  return texto.charAt(0).toUpperCase() + texto.slice(1).toLowerCase();
}
export const Ast = () => {
  return <span style={{ color: "#f5222d" }}>*</span>;
};

export const getType = (id) => {
  let type;
  switch (id) {
    case 1:
      type = "Oficinas Centrales";
      break;
    case 2:
      type = "Junta Local";
      break;
    case 3:
      type = "Junta Distrital";
      break;
    default:
      break;
  }
  return type;
};

export const getTypeUser = (user) => {
  if (user === "OC") {
    return 1;
  } else if (user === "JL") {
    return 2;
  } else {
    return 3;
  }
};

export const endTime = [13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24];
export const startTime = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
