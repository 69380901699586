import React, { useState, useEffect, useRef } from "react";
import { Row, Col } from "antd";
//Simport TitleHome from "../../../components/Admin/Home/TitleHome";
//import Courses from "../../../components/Admin/Home/Courses";
//import Promotions from "../../../components/Admin/Home/Promotions";
//import TypeChart from "../../../components/Admin/Home/TypeChart";
import RequestChart from "../../../components/Admin/Home/RequestChart";
import RequestObs from "../../../components/Admin/Home/RequestObs";
import RequestStatus from "../../../components/Admin/Home/RequestStatus";
import RequestCourses from "../../../components/Admin/Home/RequestCourses";
import RequestAcreditacion from "../../../components/Admin/Home/RequestAcreditacion";
import "./HomeAdmin.scss";
import { getEstadisticasHome } from "../../../api/estadisticas";
import { withRouter } from "react-router-dom";

const HomeAdmin = (props) => {
  const {
    proceso: { proceso, estado, distrito },
  } = props;
  const [process, setProcess] = useState({});
  const isMounted = useRef(true); //Referencia que permite verificar si el componente esta visible
  const [datos, setDatos] = useState({});

  //useEffect que valida que el componente este seleccionado de lo contrario cancela cualquier subcripción
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  //useEffect que valida que el componente este seleccionado de lo contrario cancela cualquier subcripción
  useEffect(() => {
    let idProceso, idDetalle, idEstado, idDistrito;
    if (proceso) {
      idProceso = proceso.idProcesoElectoral;
      idDetalle = proceso.idDetalleProceso;
      idEstado = estado ? estado.idEstado : 0;
      idDistrito = distrito ? distrito.idDistrito : 0;
      isMounted.current &&
        setProcess({ idProceso, idDetalle, idEstado, idDistrito });
    }
  }, [proceso, estado, distrito]);

  //Obtenemos los datos de la estadistica del portal
  useEffect(() => {
    getEstadisticasHome(process).then((res) => {
      setDatos(res.data);
    });
  }, [process]);

  return (
    <div>
      <Row>
        <Col span={8}>
          <RequestObs conteo={datos.observadores} />
        </Col>
        <Col span={16}>
          <RequestStatus conteo={datos.solRevisar} />
        </Col>
      </Row>
      <Row>
        <RequestAcreditacion
          ciudadanos={datos.ciudadanos}
          organizaciones={datos.organizaciones}
          acreditados={datos.acreditados}
        />
      </Row>
      <Row>
        <RequestCourses
          inscritos={datos.inscritosCursos}
          registrados={datos.cursosPre}
          cursosImpartidos={datos.cursosImpartidos}
          personasCapacitadas={datos.personasCapacitadas}
        />
      </Row>
      <Row>
        <h4>Demografía de las solicitudes</h4>
        <RequestChart grafica={datos.grafica} />
      </Row>
    </div>
  );
};

export default withRouter(HomeAdmin);
