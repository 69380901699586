import { basePath } from "./config";
import axios from "axios";
import { getAccessTokenApi } from "./auth";

export function getRequest(idProceso, idDetalle, idEntidad, idDistrito) {
  const url = `${basePath}/validaciones/tabla`;
  const jwt = getAccessTokenApi();
  return axios.post(
    url,
    {
      idProceso: idProceso,
      idDetalle: idDetalle,
      idEntidad: idEntidad,
      idDistrito: idDistrito,
    },
    {
      headers: { Authorization: jwt },
    }
  );
}

export function save(json) {
  const url = `${basePath}/validar`;
  const jwt = getAccessTokenApi();
  return axios.post(url, json, {
    headers: { Authorization: jwt },
  });
}

export function getObservations(idObservador) {
  const url = `${basePath}/validar/observaciones`;
  const jwt = getAccessTokenApi();
  return axios.post(
    url,
    { idObservador: idObservador },
    {
      headers: { Authorization: jwt },
    }
  );
}

export function getNameState(idEstado, idMunicipio) {
  const url = `${basePath}/estado`;
  const jwt = getAccessTokenApi();
  return axios.post(
    url,
    { idEstado: idEstado, idMunicipio: idMunicipio },
    {
      headers: { Authorization: jwt },
    }
  );
}

export function getObservationsById(idObservador, tipoObservador) {
  const url = `${basePath}/validacion/id`;
  const jwt = getAccessTokenApi();
  return axios.post(
    url,
    { idObservador: idObservador, tipoObservador: tipoObservador },
    {
      headers: { Authorization: jwt },
    }
  );
}

export function getStatus() {
  const url = `${basePath}/validacion/estatus`;
  const jwt = getAccessTokenApi();
  return axios.post(
    url,
    {},
    {
      headers: { Authorization: jwt },
    }
  );
}
