import React, { useEffect, useState } from 'react';
import { basePath } from "../../../api/config";
import axios from "axios";
import Verification from '../../../components/User/Public/Verification';

const ConfirmPass = (props) => {
    const [type, setType] = useState("");
    const [visible, setVisible] = useState(false);
    const [messageToken, setMessageToken] = useState("");

    function validateToken() {
        //Obtencion del token de la url
        const params = new URLSearchParams(props.location.search);
        const tokenParam = params.get('t'); // bar
        if (tokenParam != null || tokenParam !== '') {
            const url = `${basePath}/confirmarCorreo`;
            axios.post(url, { token: tokenParam }).then((response) => {
                const { code } = response.data;
                if (code === 400 || code === 500) {
                    //Modal con el error
                    setType(2);
                    setMessageToken(response.data.message);
                } else {
                    setType(1);
                    setVisible(true);
                    const mensaje = response.data.message;
                    setMessageToken(mensaje);
                }
            });
        } else return null;
    }


    useEffect(() => {
        validateToken(); //Se ejecuta el metodo al renderizar el componente
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            {visible ? (<Verification message={messageToken} type={type} />): (<Verification message={messageToken} type={type} />) }
        </div>
    );
}

export default ConfirmPass;