import React, { useEffect, useState } from "react";
import Viewer from "react-viewer";

export default function Viewers(props) {
  const { image, container } = props;
  // eslint-disable-next-line no-unused-vars
  const [load, setLoad] = useState({ visible: false, loaging: false });

  useEffect(() => {
    setLoad({
      loading: true,
      fixViewer: true,
    });
  }, []);

  return (
    <Viewer
      disableKeyboardSupport={true}
      noClose
      visible={true}
      images={image}
      noFooter={false}
      noNavbar={true}
      noToolbar={false}
      changeable={false}
      attribute={false}
      scalable={false}
      container={document.getElementById(container)}
      zoomSpeed={0.5}
    />
  );
}
