import React, { useState, useEffect } from "react";
import { Table, Tooltip, Icon, message, Modal } from "antd";
import { withRouter } from "react-router-dom";

function TableInformes(props) {
    const { data, setData, titulo, form, eliminarIncidentes, setEliminarIncidentes, setDatosIncidentes, datosIncidentes, disabled } = props; 
    const [dataTable, setDataTable] =  useState([]);  

    useEffect(() => {
        setDataTable(data);
    }, [data]);
    

    const modified = (record) => {
      const actual = form.getFieldValue("descripcionIncidente");
      if (actual !== undefined && actual !== null && actual.length > 0) {
        message.info( "Para poder continuar se requiere guardar el incidente actual." );
      } else {
        const datosEliminar = [];
        if (eliminarIncidentes !== undefined && eliminarIncidentes !== null && eliminarIncidentes.length > 0) {
          for (let i = 0; i < eliminarIncidentes.length; i++) {
            datosEliminar.push(eliminarIncidentes[i]);
          }
        }
        const datos = [];
        for (let i = 0; i < dataTable.length; i++) {
          if (dataTable[i].idActividad === record.idActividad && 
            dataTable[i].idIncidente === record.idIncidente) {
              dataTable[i].guardar = false;
              datosEliminar.push(dataTable[i]);
              form.setFieldsValue({
                tipoIncidente: dataTable[i].idIncidente.toString(),
                actividadIncidente: dataTable[i].idActividad.toString(),
                descripcionIncidente: dataTable[i].descripcion,
              });
              setDatosIncidentes({ ...datosIncidentes, incidente: record.incidente, idIncidente: record.idIncidente, 
                actividad: record.actividad, idActividad: record.idActividad });
          } else {
            datos.push(dataTable[i]);
          }
        }
        setDataTable(datos);
        setData(datos);
        setEliminarIncidentes(datosEliminar);
      }
    };

    function mostrarModal(record) {
      const { confirm } = Modal;
      var message = <div>
                      <p>Estás a punto de eliminar un incidente.</p>
                      <p><strong>Esta acción no se puede deshacer.</strong></p>
                      <p>¿Deseas continuar?</p>
                    </div>;
      confirm({
        title: message,
        width: "500px",
        icon: null,
        okText: "Eliminar incidente",
        cancelText: "Cancelar",
        cancelButtonProps: { className: "ant-btn-default" },
        onOk() {
          deleted(record);
        },
        onCancel(){},
     });
    }

    const deleted = (record) => {
      const datosEliminar = [];
      if (eliminarIncidentes !== undefined && eliminarIncidentes !== null && eliminarIncidentes.length > 0) {
        for (let i = 0; i < eliminarIncidentes.length; i++) {
          datosEliminar.push(eliminarIncidentes[i]);
        }
      }
        const datos = [];
        for (let i = 0; i < dataTable.length; i++) {
          if (dataTable[i].idActividad === record.idActividad && 
            dataTable[i].idIncidente === record.idIncidente &&
            dataTable[i].descripcion === record.descripcion) {
              dataTable[i].guardar = false;
              datosEliminar.push(dataTable[i]);
          } else {
            datos.push(dataTable[i]);
          }
        }
        setDataTable(datos);
        setData(datos);
        setEliminarIncidentes(datosEliminar);
    };

    const columns = [
        {
            title: "Tipo de incidente",
            dataIndex: "incidente",
            key: "incidente",
            width: 250,
        },
        {
            title: "Descripción del incidente",
            dataIndex: "descripcion",
            key: "descripcion",
        },
        {
            title: "Actividad a la que se le asignó el incidente",
            dataIndex: "actividad",
            key: "actividad",
            width: 250,
        },
        {
            title: "Acciones",
            fixed: "right",
            align: "center",
            width: 150,
            render: (record) => (
              <>
                {(
                  <Tooltip title="Eliminar">
                    <button 
                      style={{ marginLeft: "0px" }}
                      className="btn-circle btn_secundario"
                      onClick={(e) => mostrarModal(record)}
                      disabled={disabled}
                    >
                      <Icon type="delete" />
                    </button>
                  </Tooltip>
                )}{" "}
                {(
                  <Tooltip title="Modificar">
                    <button 
                      style={{ marginLeft: "0px" }}
                      className="btn-circle btn_secundario"
                      onClick={(e) => modified(record)}
                      disabled={disabled}
                    >
                      <Icon type="edit" />
                    </button>
                  </Tooltip>
                )}{" "}
              </>
            ),
        },
    ];

    return (
        <Table
            dataSource={dataTable}
            columns={columns}
            title={() => (<p>Listado de incidentes durante el/la {titulo}</p>)}
            pagination={{ pageSize: 10 }}
        />
    );
}
export default withRouter(TableInformes);