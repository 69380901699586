import React, { useEffect, useState, useRef } from "react";
import { Row, Col, Form, Select, Checkbox, Radio, Input, Button, message, Modal } from "antd";
import { Ast } from "../../utils/Information/functions";
import { required, txtTexto } from "../../utils/Information/rules";
import { getMunicipios, getDistritoFederal, getDistritoLocal, getMunicipiosJunta, getDistritoFederalJunta, getDistritoLocalJunta } from "../../api/information";
import TableInformes from "../Information/TableInformes";
import "./Information.scss";

const { Option } = Select;

export default function Components(props) {
    const { etapaSeleccionada, getFieldDecorator, form, incidentesSeleccionados, setIncidentesSeleccionados, 
        actividadesSeleccionadas, eliminarIncidentes, setActividadesSeleccionadas, setEliminarIncidentes, informe, disabled } = props;
    const isMounted = useRef(true);
    const [etapa, setEtapa] = useState();  
    const [selectEdo, setSelectEdo] = useState([]);
    const [selectMun, setSelectMun] = useState([]);
    const [selectFed, setSelectFed] = useState([]);
    const [selectLoc, setSelectLoc] = useState([]);
    const [selectInc, setSelectInc] = useState([]);
    const [selectAct, setSelectAct] = useState([]);
    const [actividades, setActividades] = useState([]); 
    const [reportarIncidentes, setReportarIncidentes] = useState(); 
    const [reportarPropuesta, setReportarPropuesta] = useState(); 
    const [reportarComentario, setReportarComentario] = useState(); 
    const [datosIncidentes, setDatosIncidentes] = useState({});
    const maxChar = 500;
    const [character, setCharacter] = useState(500);
    const [characterPro, setCharacterPro] = useState(500);
    const [characterCom, setCharacterCom] = useState(500);
    const [visibleModal, setVisibleModal] = useState(false);    // Se muenstra u oculta el Modal 
    const [actividadesElegidas, setActividadesElegidas] = useState([]); 

    useEffect(() => {
        return () => {
          isMounted.current = false;
        };
    }, []);

    useEffect(() => {
        if (isMounted.current && etapaSeleccionada !== null && etapaSeleccionada !== undefined) {
            setActividades(etapaSeleccionada.actividades);
            const actSeleccionadas = [];
            if (etapaSeleccionada.actividades !== null && etapaSeleccionada.actividades.length > 0) {
                for (let i = 0; i < etapaSeleccionada.actividades.length; i++) {
                    if (etapaSeleccionada.actividades[i].checked) {
                        actSeleccionadas.push(etapaSeleccionada.actividades[i].value);
                    }
                }
            }
            setActividadesSeleccionadas(actSeleccionadas);            
            setSelectInc(etapaSeleccionada.incidentes);
            if (etapaSeleccionada.incidentesOcurridos !== null && etapaSeleccionada.incidentesOcurridos.length > 0) {
                setReportarIncidentes(true);
            } else {
                setReportarIncidentes(false);
            }
            setIncidentesSeleccionados(etapaSeleccionada.incidentesOcurridos);
            if (etapaSeleccionada.propuesta !== null) {
                setReportarPropuesta(true);
            } else {
                setReportarPropuesta(false);
            }
            if (etapaSeleccionada.comentarios !== null) {
                setReportarComentario(true);
            } else {
                setReportarComentario(false);
            }
            setEtapa(etapaSeleccionada);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [etapaSeleccionada]);

    useEffect(() => {
        if (isMounted.current && actividadesSeleccionadas !== undefined && actividadesSeleccionadas !== null && actividadesSeleccionadas.length > 0) {
            const actIncidentes = [];
            if (etapaSeleccionada.actividades !== null && etapaSeleccionada.actividades.length > 0) {
                for (let i = 0; i < etapaSeleccionada.actividades.length; i++) {
                    etapaSeleccionada.actividades[i].checked = false;
                    for (let j = 0; j < actividadesSeleccionadas.length; j++) {
                        if (etapaSeleccionada.actividades[i].value === actividadesSeleccionadas[j]) {
                            etapaSeleccionada.actividades[i].checked = true;
                            actIncidentes.push(etapaSeleccionada.actividades[i]);
                        }
                    }
                }
            }
            setSelectAct(actIncidentes);
            if (actIncidentes.length === 0) {
                form.setFieldsValue({
                    actividadIncidente: "",
                });
            } else {
                form.setFieldsValue({
                    actividadesEtapa: actividadesSeleccionadas,
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [actividadesSeleccionadas]);

    useEffect(() => {
        if (isMounted.current && etapa !== undefined && etapa !== null) {
            setSelectEdo(informe.estados);
            if (etapa.idInformeEtapa !== null) {
                if (etapa.idEstado !== null) {
                    if (informe.medioCaptura === 0) {
                        getMunicipios(etapa.idEstado.toString()).then((res) => {
                            setSelectMun(res.data);
                        });
                        if (etapa.idMunicipio !== null) {
                            getDistritoFederal(etapa.idEstado.toString(), etapa.idMunicipio.toString()).then((res) => {
                                setSelectFed(res.data);
                            });  
                            if (etapa.idDistritoFederal !== null) {
                                getDistritoLocal(etapa.idEstado.toString(), etapa.idMunicipio.toString(), etapa.idDistritoFederal.toString()).then((res) => {
                                    setSelectLoc(res.data);
                                }); 
                            }
                        }
                    } else {
                        getMunicipiosJunta(etapa.idEstado.toString()).then((res) => {
                            setSelectMun(res.data);
                        });
                        if (etapa.idMunicipio !== null) {
                            getDistritoFederalJunta(etapa.idEstado.toString(), etapa.idMunicipio.toString()).then((res) => {
                                setSelectFed(res.data);
                            });  
                            if (etapa.idDistritoFederal !== null) {
                                getDistritoLocalJunta(etapa.idEstado.toString(), etapa.idMunicipio.toString(), etapa.idDistritoFederal.toString()).then((res) => {
                                    setSelectLoc(res.data);
                                }); 
                            }
                        }
                    }
                }
                
                form.setFieldsValue({
                    entidad: etapa.idEstado ? etapa.idEstado.toString() : "",
                    municipio: etapa.idMunicipio ? etapa.idMunicipio.toString() : "",
                    distritoFederal: etapa.idDistritoFederal ? etapa.idDistritoFederal.toString() : "",
                    distritoLocal: etapa.idDistritoLocal ? etapa.idDistritoLocal.toString() : "",
                });
            } else {
                form.setFieldsValue({
                    entidad: "",
                    municipio: "",
                    distritoFederal: "",
                    distritoLocal: "",
                });
            }
            form.setFieldsValue({
                tipoIncidente: "",
                actividadIncidente: "",
                descripcionIncidente: "",
                propuesta: etapa.propuesta,
                comentarios: etapa.comentarios,
            });
            if (etapa.propuesta !== null) {
                const charLength = maxChar - etapa.propuesta.length;
                setCharacterPro(charLength);
            }
            if (etapa.comentarios !== null) {
                const charLength = maxChar - etapa.comentarios.length;
                setCharacterCom(charLength);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [etapa]);

    const onSelectEdo = (e) => {
        if (informe.medioCaptura === 0) {
            getMunicipios(e).then((res) => {
                setSelectMun(res.data);
            });
        } else {
            getMunicipiosJunta(e).then((res) => {
                setSelectMun(res.data);
            });
        }
    };

    const onSelectMun = (e) => {
        const edo = form.getFieldValue("entidad");
        if (informe.medioCaptura === 0) {
            getDistritoFederal(edo, e).then((res) => {
                setSelectFed(res.data);
            });
        } else {
            getDistritoFederalJunta(edo, e).then((res) => {
                setSelectFed(res.data);
            });
        }
    };

    const onSelectFederal = (e) => {
        const edo = form.getFieldValue("entidad");
        const mun = form.getFieldValue("municipio");
        if (informe.medioCaptura === 0) {
            getDistritoLocal(edo, mun, e).then((res) => {
                setSelectLoc(res.data);
            });
        } else {
            getDistritoLocalJunta(edo, mun, e).then((res) => {
                setSelectLoc(res.data);
            });
        }
    };

    function onChangeGroup(checkedValues) {
        if (actividadesSeleccionadas.length < checkedValues.length) {
            var actividadSeleccionada = [];
            for (let i = 0; i < checkedValues.length; i++) {
                actividadSeleccionada.push(checkedValues[i]);
            }
            setActividadesSeleccionadas(actividadSeleccionada);
        } else {
            setActividadesElegidas(checkedValues);
            setVisibleModal(true);
        }
    }

    const onChangeIncidentes = (e) => {
        setReportarIncidentes(e.target.value);
        if (!e.target.value) {
            setIncidentesSeleccionados(etapaSeleccionada.incidentesOcurridos);
            setCharacter(maxChar);
        } 
    };

    const onChangePropuesta = (e) => {
        setReportarPropuesta(e.target.value);
        if (!e.target.value) {
            setCharacterPro(maxChar);
        }
    };

    const onChangeComentario = (e) => {
        setReportarComentario(e.target.value);
        if (!e.target.value) {
            setCharacterCom(maxChar);
        }
    };

    const handleChange = (input) => (event) => {
        const charCount = event.target.value.length;
        const charLength = maxChar - charCount;
        switch (input) {
            case "incidente":
                setCharacter(charLength);
                break;
            case "propuesta":
                setCharacterPro(charLength);
                break;
            case "comentario":
                setCharacterCom(charLength);
                break;
          default:
            break;
        }
    };

    function guardarIncidente() {
        props.form.validateFields((err, values) => {
            if (values.tipoIncidente !== undefined && values.actividadIncidente !== undefined && values.actividadIncidente !== "" 
            && values.descripcionIncidente !== undefined && values.descripcionIncidente !== "") {
                const incidente = [];
                if (incidentesSeleccionados !== null && incidentesSeleccionados.length > 0) {
                    for (let i = 0; i < incidentesSeleccionados.length; i++) {
                        incidente.push(incidentesSeleccionados[i]);
                    }
                }
                incidente.push(
                    {
                        idInformeIncidente: null,
                        idInformeActividad: null,
                        idActividad: datosIncidentes.idActividad,
                        actividad: datosIncidentes.actividad,
                        idIncidente: datosIncidentes.idIncidente,
                        incidente: datosIncidentes.incidente,
                        descripcion: values.descripcionIncidente,
                        guardar: true,
                    }
                );
                setIncidentesSeleccionados(incidente);
                form.setFieldsValue({
                    tipoIncidente: "",
                    actividadIncidente: "",
                    descripcionIncidente: "",
                });
                setCharacter(500);
            } else {
                message.info("Los datos con ( * ) son requeridos.");
            }
        });
    }

    const getCadenaIncidente = (value, event) => {
        setDatosIncidentes({ ...datosIncidentes, incidente: event.props.children, idIncidente: Number(value) });
    };
    const getCadenaActividad = (value, event) => {
        setDatosIncidentes({ ...datosIncidentes, actividad: event.props.children, idActividad: Number(value) });
    };

    const TableView = () => {
        if (incidentesSeleccionados !== null && incidentesSeleccionados.length > 0) {
            return (
                <div>
                    <Row style={{ marginTop: 20 }}>
                        <Col span={24}>
                            <TableInformes            
                            data={incidentesSeleccionados}
                            setData={setIncidentesSeleccionados}
                            titulo={etapaSeleccionada.label}
                            form={form}
                            eliminarIncidentes={eliminarIncidentes}
                            setEliminarIncidentes={setEliminarIncidentes}
                            setDatosIncidentes={setDatosIncidentes}
                            datosIncidentes={datosIncidentes}
                            disabled={disabled}
                            className="-striped -highlight"
                            style={{ height: "350px", width: "900px" }}
                            locale={{ emptyText: "Sin registros" }}
                            pagination={{ pageSize: 10 }}
                            />
                        </Col>
                    </Row>
                </div>
                );
        } else {
            return(null);
        }
    };

    function IncidentesDetectados() {
        return (
        <div>
            <Row style={{ marginTop: 20 }}>
                <Col span={6} style={{ paddingRight: "20px" }}>
                    <Ast /> Tipo de incidente
                    <Form.Item>
                        {getFieldDecorator("tipoIncidente")(
                            <Select
                            showSearch
                            style={{ width: '100%' }}
                            placeholder="Selecciona"
                            onSelect={(value, e) => getCadenaIncidente(value, e)}
                            >
                            {selectInc.map((item) => (
                                <Option key={item.idIncidente}>{item.incidente}</Option>
                            ))}
                            </Select>
                            )}
                    </Form.Item>
                </Col>
                <Col span={6} style={{ paddingRight: "20px" }}>
                    <Ast /> Actividad a la que se relaciona el incidente
                    <Form.Item>
                        {getFieldDecorator("actividadIncidente")(
                            <Select
                            showSearch
                            style={{ width: '100%' }}
                            placeholder="Selecciona"
                            onSelect={(value, e) => getCadenaActividad(value, e)}
                            >
                            {selectAct.map((item) => (
                                <Option key={item.value}>{item.label}</Option>
                            ))}
                            </Select>
                            )}
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={12} style={{ paddingRight: "20px" }}>
                    <Ast /> Describe el incidente
                    <Form.Item>
                        {getFieldDecorator("descripcionIncidente", { getValueFromEvent: (event) => {
                            return txtTexto.test(event.target.value)
                            ? event.target.value
                            : (event.target.value).slice(0,-1);
                        }, })(
                            <Input.TextArea
                                maxLength={500}
                                style={{ width: "100%" }}
                                onChange={handleChange("incidente")}
                            />
                            )}
                    </Form.Item>
                    <span style={{color: "#CDCDCD"}}>Restan {character} caracteres</span>
                </Col>
                <Col span={3}>
                    <Button onClick={() => guardarIncidente()} style={{marginTop: "20%"}} > Agregar incidente </Button>
                </Col>
            </Row>
            {<TableView />}
        </div>
        );
    };

    function Propuesta() {
        return (
        <div>
            <Row>
                <Col span={12} style={{ paddingRight: "20px", marginTop: 20 }}>
                    <Ast /> Describe tu propuesta
                    <Form.Item>
                        {getFieldDecorator("propuesta", { rules: required, initialValue: etapa.propuesta,
                        getValueFromEvent: (event) => {
                            return txtTexto.test(event.target.value)
                            ? event.target.value
                            : (event.target.value).slice(0,-1);
                        }, })(
                            <Input.TextArea
                                maxLength={500}
                                style={{ width: "100%" }}
                                onChange={handleChange("propuesta")}
                                disabled={disabled}
                            />
                            )}
                    </Form.Item>
                    <span style={{color: "#CDCDCD"}}>Restan {characterPro} caracteres</span>
                </Col>
            </Row>
        </div>
        );
    };

    function Comentario() {
        return (
        <div>
            <Row>
                <Col span={12} style={{ paddingRight: "20px", marginTop: 20 }}>
                    <Ast /> Ingresa tu comentario
                    <Form.Item>
                        {getFieldDecorator("comentarios", { rules: required, initialValue: etapa.comentarios,
                        getValueFromEvent: (event) => {
                            return txtTexto.test(event.target.value)
                            ? event.target.value
                            : (event.target.value).slice(0,-1);
                        }, })(
                            <Input.TextArea
                                maxLength={500}
                                style={{ width: "100%" }}
                                onChange={handleChange("comentario")}
                                disabled={disabled}
                            />
                            )}
                    </Form.Item>
                    <span style={{color: "#CDCDCD"}}>Restan {characterCom} caracteres</span>
                </Col>
            </Row>
        </div>
        );
    };

    function modalIncidentes() {
        return (
        <div>
            <Modal
            title={<strong>Aviso</strong>}
            visible={visibleModal}
            className="modalIncidente"
            footer={null}
            onCancel={() => mantenerActividades()}
            >
                <div>
                    <Col span={24}>
                        <p>Al desactivar la actividad se eliminarán los incidentes asociados al momento de guardar la etapa.</p>
                    </Col>
                    <Col span={12} style={{ paddingTop: "20px"}}>
                        <Form.Item style={{ textAlign: "center" }}>
                        <Button
                            type="primary"
                            htmlType="submit"
                            onClick={() => cambiarActividades()}
                        >
                            Aceptar
                        </Button>
                        </Form.Item>
                    </Col>
                    <Col span={12} style={{ paddingTop: "20px"}}>
                        <Form.Item style={{ textAlign: "center" }}>
                        <Button
                            type="primary"
                            htmlType="submit"
                            onClick={() => mantenerActividades()}
                        >
                            Cancelar
                        </Button>
                        </Form.Item>
                    </Col>
                </div>
            </Modal>
        </div>
        );
    };

    function mantenerActividades() {
        form.setFieldsValue({
            actividadesEtapa: actividadesSeleccionadas,
        });        
        setVisibleModal(false);
    };

    function cambiarActividades() {
        setActividadesSeleccionadas(actividadesElegidas);
        setVisibleModal(false);
    };

    return (
        <div>
            {isMounted.current && etapa !== undefined && etapa !== null && (
                <div>
                <Row style={{ marginTop: 50 }}>
                    <Col span={6} style={{ paddingRight: "20px" }}>
                        Entidad en la que participaste
                        <Form.Item>
                        {getFieldDecorator("entidad", { initialValue: etapa.idEstado ? etapa.idEstado.toString() : "" })(
                            <Select
                            showSearch
                            style={{ width: '100%' }}
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option.props.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            placeholder="Selecciona"
                            onSelect={onSelectEdo}
                            disabled={disabled}
                            >
                            {selectEdo.map((item) => (
                                <Option key={item.idEstado}>{item.estado}</Option>
                            ))}
                            </Select>
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={6} style={{ paddingRight: "20px" }}>
                        Delegación o Municipio
                        <Form.Item>
                        {getFieldDecorator("municipio", { initialValue: etapa.idMunicipio ? etapa.idMunicipio.toString() : "" })(
                            <Select
                            showSearch
                            style={{ width: '100%' }}
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option.props.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            placeholder="Selecciona"
                            onSelect={onSelectMun}
                            disabled={disabled}
                            >
                            {selectMun.map((item) => (
                                <Option key={item.idMunicipio}>{item.municipio}</Option>
                            ))}
                            </Select>
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={6} style={{ paddingRight: "20px" }}>
                        Distrito Federal
                        <Form.Item>
                        {getFieldDecorator("distritoFederal", { initialValue: etapa.idDistritoFederal ? etapa.idDistritoFederal.toString() : "" })(
                            <Select
                            showSearch
                            style={{ width: '100%' }}
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option.props.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            placeholder="Selecciona"
                            onSelect={onSelectFederal}
                            disabled={disabled}
                            >
                            {selectFed.map((item) => (
                                <Option key={item.idDistritoFederal}>{item.distritoFederal}</Option>
                            ))}
                            </Select>
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={6} style={{ paddingRight: "20px" }}>
                        Distrito Local
                        <Form.Item>
                        {getFieldDecorator("distritoLocal", { initialValue: etapa.idDistritoLocal ? etapa.idDistritoLocal.toString() : "" })(
                            <Select
                            showSearch
                            style={{ width: '100%' }}
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option.props.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            placeholder="Selecciona"
                            disabled={disabled}
                            >
                            {selectLoc.map((item) => (
                                <Option key={item.idDistritoLocal}>{item.distritoLocal}</Option>
                            ))}
                            </Select>
                            )}
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Ast /> Actividades en las que participaste:
                    </Col>
                    <Col span={24}>
                    <Form.Item>
                        {getFieldDecorator("actividadesEtapa", { rules: required, initialValue: actividadesSeleccionadas })(
                            <Checkbox.Group options={actividades} type="checkbox" defaultValue={actividadesSeleccionadas} onChange={onChangeGroup} disabled={disabled}/>
                        )}
                    </Form.Item>
                    {modalIncidentes()}  
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Ast /> {`¿Sucedieron incidentes durante la etapa de `} {etapa.label}{`?`}
                    </Col>
                    <Col span={24}>
                        <Radio.Group value={reportarIncidentes} onChange={onChangeIncidentes} disabled={disabled}>
                            <Radio value={true}>Sí</Radio>
                            <Radio value={false}>No</Radio>
                        </Radio.Group>
                    </Col>
                </Row>
                {(reportarIncidentes && actividadesSeleccionadas !== null && actividadesSeleccionadas !== undefined 
                    && actividadesSeleccionadas.length> 0) ? !disabled ? IncidentesDetectados() : <TableView /> : null}
                <Row style={{ marginTop: 20 }}>
                    <Col span={24}>
                        <Ast /> ¿Deseas agregar una propuesta para esta etapa?
                    </Col>
                    <Col span={24}>
                        <Radio.Group value={reportarPropuesta} onChange={onChangePropuesta} disabled={disabled}>
                            <Radio value={true}>Sí</Radio>
                            <Radio value={false}>No</Radio>
                        </Radio.Group>
                    </Col>
                </Row>
                {reportarPropuesta ? Propuesta() : null}
                {(etapa.value === 1 || etapa.value === 2) && 
                <Row style={{ marginTop: 20 }}>
                    <Col span={24}>
                        <Ast /> ¿Deseas agregar comentarios de la(s) casilla(s) que supervisaste?
                    </Col>
                    <Col span={24}>
                        <Radio.Group value={reportarComentario} onChange={onChangeComentario} disabled={disabled}>
                            <Radio value={true}>Sí</Radio>
                            <Radio value={false}>No</Radio>
                        </Radio.Group>
                    </Col>
                </Row>}
                {reportarComentario ? Comentario() : null}
                </div>
            )}
        </div>
    );
}