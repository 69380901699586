import React, {useState} from 'react';
import { Modal, Carousel } from 'antd';
import Document0 from '../../../../assets/img/Documents/docs_00.svg';
import Document1 from '../../../../assets/img/Documents/docs_01.svg';
import Document2 from '../../../../assets/img/Documents/docs_02.svg';
import Document3 from '../../../../assets/img/Documents/docs_03.png';
import { fotos } from '../../../../utils/Request/examples';

import './Requirements.scss';

const Documents = () => {
    const [photo, setPhoto] = useState(false); //Hook para mostrar el modal de ejemplos

      //Función que muestra el modal de ejemplo
  const modalPhoto = () => {
    return (
      <Modal
        title={null}
        visible={photo}
        onCancel={() => setPhoto(false)}
        footer={null}
        width={210}
      >
        <Carousel autoplay>
          {fotos.map((elem) => (
            <div key={elem.id}>
              <img
                src={elem.url}
                alt={elem.alt}
                style={{ height: "190px", marginTop: 20 }}
              />
            </div>
          ))}
        </Carousel>
      </Modal>
    );
  };
    return (
        <div style={{ padding: "10px" }}>
          <p>Los siguientes documentos te permiten registrar tu solicitud de acreditación en este portal.</p>
          <p>Durante la captura de tu solicitud, también se genera el escrito de protesta.</p>
          <p>Si deseas participar como Observadora u Observador Electoral necesitas:</p>
            <ol>
                <div className="elementoLista">
                {modalPhoto()}
                    <div>
                        <li><strong>Credencial de elector</strong></li>
                        <ul>
                            <li>Imágenes de ambos lados y que sean legibles.</li>
                            <li>
                                La credencial debe estar vigente.
                                <p><a href="https://listanominal.ine.mx/scpln/" target="_blank" rel="noopener noreferrer">Puedes consultar su vigencia aquí</a></p>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <p><img src={Document0} alt="Document0" /></p>
                        <img src={Document1} alt="Document1" />
                    </div>
                </div>
                <div className="elementoLista">
                    <div>
                        <li><strong>Fotografía de retrato.</strong></li>
                        <ul>
                            <li>Tipo de fotografía infantil.</li>
                            <li>Mirada al frente con rostro serio.</li>
                            <li>Oídos descubiertos.</li>
                            <li>Sin lentes, ni accesorios.</li>
                            <li>Fondo de color claro, preferentemente blanco.</li>
                            <li>
                              <button className="btnExample" onClick={() => setPhoto(true)}>Ver ejemplos</button>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <p><img src={Document2} alt="Document2" /></p>
                        <img src={Document3} alt="Document3" />
                    </div>
                </div>
                <div className="elementoLista">
                  <p>Cada archivo no debe superar los 5 MB. Los formatos permitidos son .png y .jpg</p>
                </div>
            </ol>
        </div>
    );
}

export default Documents;