import React, { useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import { Select, Form, Row, Col, Radio, DatePicker, TimePicker, Input, Button, message, Icon, Checkbox, Modal, Alert } from "antd";
import moment from 'moment';
import useAuth from "../../../hooks/useAuth";
import { ReactComponent as Woman } from "../../../assets/img/Courses/maleIcon.svg";
import { ReactComponent as Man } from "../../../assets/img/Courses/femaleIcon.svg";
import { ReactComponent as Total } from "../../../assets/img/Courses/groupIcon.svg";
import { required, apellidos, calleCol, cp, txtTexto, soloDigitos, nombres, numeroInt } from "../../../utils/Courses/rules";
import { Ast, sendCourse, validaApellidos } from "../../../utils/Courses/functions";
import TableParticipantes from "../../../components/Admin/Courses/TableParticipantes";
import TableForm from "../../../components/Admin/Courses/TableForm";
import { getEntidad, getMunicipio, getJuntas, getCargos, getFuncionarios, getOrganizaciones, getParticipantes, getByIdCourse, getParticipantesInscritos, getFechaLimite } from "../../../api/courses";
import { rol_captura, rol_modifica, rol_consulta, rol_cau } from "../../../utils/Courses/roles";
import { esJuntaLocal } from "../../Generals/functions";
import { ESTATUS_CURSO_INSCRITO } from "../../../utils/constanst";
import "./Components.scss";

const { Option } = Select;

function Courses(props) {
    const { history, proceso, setLoader } = props;
    const { data } = props.location;
    const { user: { user, rolUsuario } } = useAuth();
    const [disabled, setDisabled] = useState({ input: false, checkNum: false, modulo: 1 }); //Para habilidar los inputs
    const { getFieldDecorator } = props.form; //Método para validar los inputs
    const dateFormat = 'DD/MM/YYYY';
    const format = 'HH:mm';
    const [typeOrgan, setTypeOrgan] = useState({ ine: false, opl: false, org: false }); //tipo de curso
    const [selectOrg, setSelectOrg] = useState([]);  // lista de organizaciones
    const [selectCargoImp, setSelectCargoImp] = useState([]);  // lista de cargo quien imparte el curso
    const [selectCargoFun, setSelectCargoFun] = useState([]);  // lista del cargo del funcionario que pueden supervisar el curso
    const [otraSede, setOtraSede] = useState(false);  // tipo de sede: en la junta o en la sede alterna
    const [selectEntidad, setSelectEntidad] = useState([]);  // lista de las entidades
    const [selectMunicipio, setSelectMunicipio] = useState([]);  // lista de los municipios según la entidad seleccionada
    const [vocales, setVocales] = useState([]);  // lista de los funcionarios para poder guardar por separado los datos en el back
    const [participantes, setParticipantes] = useState({ mujeres: "0", hombres: "0", total: "0" }); //Para actualizar los inputs
    const [button, setButton] = useState({editar: false, guardar: false });  //Se bloquea el botón de Editar en el apartado de Consulta curso
    const [idCurso, setIdCurso] = useState(0);      // Id Curso en caso de editar un curso 
    const [idDomicilio, setIdDomicilio] = useState(0);      // Id del domicilio de la sede de la junta ejecutiva
    const [tableForm, setTableForm] = useState([]);  // lista para la vista del formulario
    const [visibleModal, setVisibleModal] = useState(false);    // Se muenstra u oculta el Modal para la selección de participantes
    const [dataParticipantes, setDataParticipantes] = useState([]); // Lista con la información de los participantes filtrado
    const [original, setOriginal] = useState([]);
    const [consultaButton, setConsultaButton] = useState(false);
    const [distancia, setDistancia] = useState(false);
    const [fechaLimite, setFechaLimite] = useState();

    useEffect(() => {
    if (proceso.distrito !== undefined && proceso.distrito !== null) {
        getFechaLimite().then((res) => {
            if (res.data !== undefined && res.data !== null) {
              setFechaLimite(res.data);
            } else {
              setFechaLimite(moment().format(dateFormat));
            }      
        });
        switch ( history.location.pathname) { //Valida los permisos que tiene el usuario 
            case "/admin/cursos/registro":
              if (!rol_captura.includes(rolUsuario)) {
                history.push("/admin/cursos");
              } 
              if (proceso.distrito === undefined) {
                message.info( "Se requiere elegir un Distrito para poder continuar el registro del curso, favor de validar." );
              }
              break;
            case "/admin/cursos/modifica":
              if (!rol_modifica.includes(rolUsuario)) {
                history.push("/admin/cursos");
              } else {
                if(data !== undefined) {
                    setDisabled({...disabled, input: false, checkNum: false, modulo: 3});
                    setIdCurso(data.idCurso);
                } else {
                  history.push("/admin/cursos");
                }
              }
              break;
            case "/admin/cursos/consulta":
              if (!rol_consulta.includes(rolUsuario)) {
                history.push("/admin/cursos");
              } else {
                if (data !== undefined) {
                  setDisabled({ ...disabled, input: true, checkNum: true, modulo: 2});
                  setIdCurso(data.idCurso);
                } else {
                  history.push("/admin/cursos");
                }
              }
              break;
            default:
              break;
        }
        if (rol_cau.includes(rolUsuario)) {
            setButton({...button, editar: true, guardar: true });
        }
        initialValues();
    }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [proceso]);

    function initialValues(){
        getEntidad().then((res) => {
            setSelectEntidad(res.data);
        });
        if (data !== undefined) {
            getByIdCourse(data.idCurso).then((res) => {
                setOriginal(res.data.curso);
                setDistancia(res.data.curso.distancia);
                props.form.setFieldsValue({
                    idProcesoElectoral: res.data.curso.idProcesoElectoral,
                    idDetalleProceso: res.data.curso.idDetalleProceso,
                    idEstadoDistrito: res.data.curso.idEstado,
                    idDistritoFederal: res.data.curso.idDistritoFederal,
                    organoImparte: res.data.curso.institucion,
                    cargoImparte: res.data.curso.ccargos.idCargo.toString(),  
                                    
                    nombreImparte: res.data.curso.nombreInstructor,
                    aPaternoImparte: res.data.curso.aPaternoInstructor 
                    ? res.data.curso.aPaternoInstructor
                    : "",
                    aMaternoImparte: res.data.curso.aMaternoInstructor
                    ? res.data.curso.aMaternoInstructor
                    : "",

                    sedeCurso: res.data.curso.sede? 1:0,
                });
                if (res.data.curso.institucion === 0) {
                    setTypeOrgan({ ine: true, opl: false, org: false });
                    getCargos(0).then((res) => {
                        setSelectCargoImp(res.data);
                    });
                }
                if (res.data.curso.institucion === 1) {
                    setTypeOrgan({ ine: false, opl: true, org: false });
                    getCargos(1).then((res) => {
                        setSelectCargoImp(res.data);
                    });
                }
                if (res.data.curso.institucion === 2) {
                    setTypeOrgan({ ine: false, opl: false, org: true });
                    getCargos(2).then((res) => {
                        setSelectCargoImp(res.data);
                    });
                    getCargos(0).then((res) => {
                        setSelectCargoFun(res.data);
                    });
                    getOrganizaciones(proceso.proceso.idProcesoElectoral, proceso.proceso.idDetalleProceso).then((res) => {
                        setSelectOrg(res.data);
                    });
                    props.form.setFieldsValue({
                        organizacion: res.data.curso.organizacion.toString(),
                    });            
                }
                if (vocales === undefined || vocales.length === 0) {
                    var funcionarios = [];
                    getFuncionarios(proceso.estado.idEstado, proceso.distrito.idDistrito).then((res) => {
                        if (res.data.integrantes !== undefined) {
                            for (let i = 0; i < res.data.integrantes.length; i++) {
                                funcionarios.push(
                                    {
                                        idIntegrante: res.data.integrantes[i].idIntegrante,
                                        primerApellido: res.data.integrantes[i].primerApellidoIntegrante 
                                        ? res.data.integrantes[i].primerApellidoIntegrante.trim() : "",
                                        segundoApellido: res.data.integrantes[i].segundoApellidoIntegrante 
                                        ? res.data.integrantes[i].segundoApellidoIntegrante.trim() : "",
                                        nombre: res.data.integrantes[i].nombreIntegrante.trim(),
                                        idCargo: res.data.integrantes[i].idPuesto,
                                        descripcionCargo: res.data.integrantes[i].descripcionPuesto,
                                    }
                                );
                            }
                        }
                    });
                    if (funcionarios !== undefined) {
                        setVocales(funcionarios);
                    }
                }
                if (res.data.curso.institucion === 0) {
                    props.form.setFieldsValue({
                        idImparte: res.data.curso.idInstructor,  
                    });
                }
                if (res.data.curso.institucion === 2) {
                    props.form.setFieldsValue({
                        cargoFuncionario:  res.data.curso.supervisor.funcionario.idCargo.toString(), 
                        idFuncionario:  res.data.curso.supervisor.funcionario.idFuncionario,
                        nombreFuncionario: res.data.curso.supervisor.funcionario.nombre,
                        aPaternoFuncionario: res.data.curso.supervisor.funcionario.apellidoPaterno 
                        ? res.data.curso.supervisor.funcionario.apellidoPaterno
                        : "",
                        aMaternoFuncionario: res.data.curso.supervisor.funcionario.apellidoMaterno
                        ? res.data.curso.supervisor.funcionario.apellidoMaterno
                        : "",  
                    });
                }
                if (res.data.curso.sede) {
                    props.form.setFieldsValue({
                        calle: res.data.curso.direccionSede.direccion.calle,
                        noExterior: res.data.curso.direccionSede.direccion.numExterior,
                        noInterior: res.data.curso.direccionSede.direccion.numInterior,
                        codigoPostal: res.data.curso.direccionSede.direccion.codigoPostal.toString(),
                        entidad: res.data.curso.direccionSede.direccion.idEstado.toString(),                        
                        colonia: res.data.curso.direccionSede.direccion.colonia
                        ? res.data.curso.direccionSede.direccion.colonia
                        : "",
                    });
                    getMunicipio(res.data.curso.direccionSede.direccion.idEstado).then((res) => {
                        setSelectMunicipio(res.data);
                    });
                    props.form.setFieldsValue({
                        municipio: res.data.curso.direccionSede.direccion.idMunicipio.toString(),
                    });
                    if (res.data.curso.direccionSede.direccion.codigoPostal.toString().length < 5) {
                        var faltanteCP = 5 - res.data.curso.direccionSede.direccion.codigoPostal.toString().length;
                        var finalCP = res.data.curso.direccionSede.direccion.codigoPostal.toString();
                        for (let i = 0; i < faltanteCP; i++) {
                          finalCP = "0" + finalCP;
                        }
                        props.form.setFieldsValue({
                          codigoPostal: finalCP,
                        });
                    }
                    setOtraSede(true);
                } else {
                    getDomicilioJunta();
                }
                if ((dataParticipantes === undefined || dataParticipantes.length === 0) && 
                (res.data.curso.cestatusCursos !== undefined && res.data.curso.cestatusCursos !== null && 
                 res.data.curso.cestatusCursos.idEstatusCurso !== ESTATUS_CURSO_INSCRITO)) {
                    getParticipantes(proceso.proceso.idProcesoElectoral, proceso.proceso.idDetalleProceso, 
                        proceso.estado.idEstado, proceso.distrito.idDistrito, null, data.idCurso).then((res) => {
                        const dataSource = [];
                        res.data.map((reg, index) => {
                            return dataSource.push({
                              key: index,
                              indice: index + 1,
                              ...reg,
                            });
                        }); 
                        setDataParticipantes(dataSource);  
                        let mujeres = 0;
                        let hombres = 0;
                        const datos = [];
                        if (dataSource !== undefined && dataSource.length !== 0) {
                            for (let i = 0; i < dataSource.length; i++) {
                                if (dataSource[i].participanteSeleccionado) {
                                    if (dataSource[i].observadores.sexo === "H") {
                                        hombres = hombres + 1;
                                    } else {
                                        mujeres = mujeres + 1;
                                    }
                                    datos.push(dataSource[i]);
                                }                
                            }
                        }
                        setParticipantes({ mujeres: mujeres.toString(), hombres: hombres.toString(), total: (hombres + mujeres).toString() });
                        setTableForm(datos);
                        setVisibleModal(false);
                    });
                } else {
                    getParticipantesInscritos(data.idCurso).then((res) => {
                        const dataSource = [];
                        res.data.map((reg, index) => {
                            return dataSource.push({
                              key: index,
                              indice: index + 1,
                              ...reg,
                            });
                        }); 
                        setDataParticipantes(dataSource);  
                        let mujeres = 0;
                        let hombres = 0;
                        const datos = [];
                        if (dataSource !== undefined && dataSource.length !== 0) {
                            for (let i = 0; i < dataSource.length; i++) {
                                if (dataSource[i].participanteSeleccionado) {
                                    if (dataSource[i].observadores.sexo === "H") {
                                        hombres = hombres + 1;
                                    } else {
                                        mujeres = mujeres + 1;
                                    }
                                    datos.push(dataSource[i]);
                                }                
                            }
                        }
                        setParticipantes({ mujeres: mujeres.toString(), hombres: hombres.toString(), total: (hombres + mujeres).toString() });
                        setTableForm(datos);
                        setVisibleModal(false);
                    });
                    setConsultaButton(true);
                }
                var momentFecha = moment(res.data.curso.fechaCurso);
                var momentInicial = moment(res.data.curso.horaInicial);
                var momentFinal = moment(res.data.curso.horaFinal);
                props.form.setFieldsValue({
                    fecha: momentFecha,
                    horaInicial: momentInicial,
                    horaFinal: momentFinal,
                  });

                if (!rol_captura.includes(rolUsuario) || rol_cau.includes(rolUsuario)) {
                    setConsultaButton(true);
                }
                if (esJuntaLocal(rolUsuario, proceso.distrito.idDistrito)) {
                    setConsultaButton(true);
                }
            }); 
        } else {
            if (esJuntaLocal(rolUsuario, proceso.distrito.idDistrito)) {
                history.push("/admin/cursos");
            }
            getDomicilioJunta();
        }
    }

    const addChanges = (event) => {
        event.preventDefault();
        props.form.validateFields((err, values) => {
            if (!err) {
                if (((values.organoImparte === 0 || values.organoImparte === 1) && !validaApellidos(values.aPaternoImparte, values.aMaternoImparte)) ||
                    ((values.organoImparte === 2) && 
                    (!validaApellidos(values.aPaternoImparte, values.aMaternoImparte) ||
                    !validaApellidos(values.aPaternoFuncionario, values.aMaternoFuncionario)))) {
                        message.info("Al menos uno de los dos apellido es obligatorio.");
                } else {
                    if (values.horaInicial >= values.horaFinal) {
                        message.info( "La hora inicial tiene que ser menor al hora final del curso." );
                    } else {
                        if (values.organoImparte === 0) {
                            for (let i = 0; i < vocales.length; i++) {
                                if (vocales[i].idCargo.toString() === values.cargoImparte) {
                                    values.idImparte = vocales[i].idIntegrante;
                                }
                            }
                        } else {
                            for (let i = 0; i < vocales.length; i++) {
                                if (vocales[i].idCargo.toString() === values.cargoFuncionario) {
                                    values.idFuncionario = vocales[i].idIntegrante;
                                }
                            }
                        }
    
                        if (proceso.distrito !== undefined) {
                            values.idProcesoElectoral = proceso.proceso.idProcesoElectoral;
                            values.idDetalleProceso = proceso.proceso.idDetalleProceso;
                            values.idEstadoDistrito = proceso.estado.idEstado;
                            values.idDistritoFederal = proceso.distrito.idDistrito;
                        } 
                        
                        values.usuario = user.sub;  
                        values.participantes = dataParticipantes;
                        /*var dataFilter = [];
                        if (dataParticipantes !== undefined) {
                            for (let i = 0; i < dataParticipantes.length; i++) {
                                if (dataParticipantes[i].participanteSeleccionado || dataParticipantes[i].idObsCurso !== null) {
                                    dataFilter.push(dataParticipantes[i]);
                                }
                            }
                        }
                        values.participantes = dataFilter;*/
                        ////////////////////////////////////////////////
                        values.idDomicilio = idDomicilio;
                        if (data !== undefined) {
                            values.original = original;
                        }
                        values.nombreImparte = values.nombreImparte.trim();
                        if (values.aPaternoImparte !== undefined && values.aPaternoImparte !== null) {
                            values.aPaternoImparte = values.aPaternoImparte.trim();
                        }
                        if (values.aMaternoImparte !== undefined && values.aMaternoImparte !== null) {
                            values.aMaternoImparte = values.aMaternoImparte.trim();
                        }
                        if (values.aPaternoFuncionario !== undefined && values.aPaternoFuncionario !== null) {
                            values.aPaternoFuncionario = values.aPaternoFuncionario.trim();
                        }
                        if (values.aMaternoFuncionario !== undefined && values.aMaternoFuncionario !== null) {
                            values.aMaternoFuncionario = values.aMaternoFuncionario.trim();
                        }
                        if (values.nombreFuncionario !== undefined && values.nombreFuncionario !== null) {
                            values.nombreFuncionario = values.nombreFuncionario.trim();
                        }
                        if (values.nombreImparte !== undefined && values.nombreImparte !== null) {
                            values.nombreImparte = values.nombreImparte.trim();
                        }
                        if (values.municipio === "0") {
                            values.muncipioJunta =  selectMunicipio[0].municipio;
                        }
                        console.log(proceso.proceso.idProcesoElectoral);
                        console.log(proceso.proceso.idDetalleProceso);
                        console.log(proceso.estado.idEstado);
                        console.log(proceso.distrito.idDistrito);
                        console.log(user.sub);
                        console.log(values);
                        sendCourse( values, history, setButton, participantes.total, setLoader ); //Se envian los valores
                    }
                }
            } else {
                message.info("Los datos con ( * ) son requeridos.");
            }
        });
    };

    ////////////////////////////////////// ---- FUNCIONES ---- //////////////////////////////////////////////////////////////

    function disabledDateRegister(current) {
        return current > moment(fechaLimite, dateFormat);
    }

    function getDomicilioJunta() {
        if (proceso.distrito !== undefined) {
            getJuntas(proceso.estado.idEstado, proceso.distrito.idDistrito).then((res) => {
                if (res.data.domicilios !== undefined) {
                    var municipioJunta = [];
                    municipioJunta.push({
                        idMunicipio: "0",
                        municipio: res.data.domicilios[0].nombreMunicipio,
                    });
                    setSelectMunicipio(municipioJunta);
                    props.form.setFieldsValue({
                        calle: res.data.domicilios[0].calle,
                        codigoPostal: res.data.domicilios[0].codigoPostal,
                        entidad: res.data.domicilios[0].idEstado.toString(),
                        colonia: res.data.domicilios[0].colonia,
                        noExterior: res.data.domicilios[0].numeroExterior,
                        noInterior: res.data.domicilios[0].numeroInterior,
                        municipio: "0",
                    });
                    if (res.data.domicilios[0].idDomicilio !== undefined && res.data.domicilios[0].idDomicilio !== null) {
                        setIdDomicilio(res.data.domicilios[0].idDomicilio);
                    } else {
                        setIdDomicilio(0);
                    }
                }
            });
        }
    }

    const onChangeDistancia = (e) => {
        setDistancia(e.target.checked);
    };

    const onChangeOrgano = (e) => {
        if (selectCargoImp !== undefined && selectCargoImp.length > 0) {
            props.form.setFieldsValue({ cargoImparte: "" });
        }
        if (e.target.value === 0) {
            setTypeOrgan({ ine: true, opl: false, org: false });
            getCargos(0).then((res) => {
                setSelectCargoImp(res.data);
            });
        }
        if (e.target.value === 1) {
            setTypeOrgan({ ine: false, opl: true, org: false });
            getCargos(1).then((res) => {
                setSelectCargoImp(res.data);
            });
            getCargos(0).then((res) => {
                setSelectCargoFun(res.data);
            });
        }
        if (e.target.value === 2) {
            setTypeOrgan({ ine: false, opl: false, org: true });
            getCargos(2).then((res) => {
                setSelectCargoImp(res.data);
            });
            getCargos(0).then((res) => {
                setSelectCargoFun(res.data);
            });
            getOrganizaciones(proceso.proceso.idProcesoElectoral, proceso.proceso.idDetalleProceso).then((res) => {
                setSelectOrg(res.data);
            });            
        }
        if (vocales === undefined || vocales.length === 0) {
            var funcionarios = [];
            getFuncionarios(proceso.estado.idEstado, proceso.distrito.idDistrito).then((res) => {
                if (res.data.integrantes !== undefined) {
                    for (let i = 0; i < res.data.integrantes.length; i++) {
                        funcionarios.push(
                            {
                                idIntegrante: res.data.integrantes[i].idIntegrante,
                                primerApellido: res.data.integrantes[i].primerApellidoIntegrante 
                                ? res.data.integrantes[i].primerApellidoIntegrante.trim() : "",
                                segundoApellido: res.data.integrantes[i].segundoApellidoIntegrante 
                                ? res.data.integrantes[i].segundoApellidoIntegrante.trim() : "",
                                nombre: res.data.integrantes[i].nombreIntegrante.trim(),
                                idCargo: res.data.integrantes[i].idPuesto,
                                descripcionCargo: res.data.integrantes[i].descripcionPuesto,
                            }
                        );
                    }
                }
            });
            if (funcionarios !== undefined) {
                setVocales(funcionarios);
            }
        }
        props.form.setFieldsValue({
            aPaternoImparte: "",
            aMaternoImparte: "",
            nombreImparte: "",
        }); 
        if ((dataParticipantes === undefined || dataParticipantes.length === 0)) {
            getParticipantes(proceso.proceso.idProcesoElectoral, proceso.proceso.idDetalleProceso, 
                proceso.estado.idEstado, proceso.distrito.idDistrito, null, idCurso).then((res) => {
                const dataSource = [];
                res.data.map((reg, index) => {
                    return dataSource.push({
                      key: index,
                      indice: index + 1,
                      ...reg,
                    });
                }); 
                setDataParticipantes(dataSource);
            });
        }
    };

    const onChangeHour = (e) => {
        if (e !== null) {
            let horaInicio = props.form.getFieldValue("horaInicial");
            if (horaInicio !== undefined) {
                if (e < horaInicio) {
                    message.error( "La hora inicial tiene que ser menor al hora final del curso." );
                }                
            }
        }              
    };

    const onChangeSede = (e) => {
        if (e.target.value === 0) {
            setOtraSede(false);
            getDomicilioJunta();
        } else {
            setOtraSede(true);
            props.form.setFieldsValue({
                calle: "",
                codigoPostal: "",
                entidad: "",
                municipio: "",
                colonia: "",
                noExterior: "",
                noInterior: "",
            });
            setSelectMunicipio([]);
        }
    };

    const onChangeSinNum = (e) => {
        if (e.target.checked) {
          setDisabled({ checkNum: true });
          props.form.setFieldsValue({ noExterior: "" });
          props.form.setFieldsValue({ noInterior: "" });
        } else {
          setDisabled({ checkNum: false });
        }
    };

    const onChangeEntidad = (e) => {
        getMunicipio(e).then((res) => {
            setSelectMunicipio(res.data);
        });
    };

    const onSelectCargoImp = (e) => {
        if (typeOrgan.ine && vocales.length > 0) {
            for (let i = 0; i < vocales.length; i++) {
                if (vocales[i].idCargo === Number(e)) {
                    props.form.setFieldsValue({
                        aPaternoImparte: vocales[i].primerApellido,
                        aMaternoImparte: vocales[i].segundoApellido,
                        nombreImparte: vocales[i].nombre,
                    });
                    break;
                }
                if ((i+1) === vocales.length) {
                    props.form.setFieldsValue({
                        aPaternoImparte: "",
                        aMaternoImparte: "",
                        nombreImparte: "",
                    });
                }
            }
        }
    };

    const onSelectCargoFun = (e) => {
        if (vocales.length > 0) {
            for (let i = 0; i < vocales.length; i++) {
                if (vocales[i].idCargo === Number(e)) {
                    props.form.setFieldsValue({
                        aPaternoFuncionario: vocales[i].primerApellido,
                        aMaternoFuncionario: vocales[i].segundoApellido,
                        nombreFuncionario: vocales[i].nombre,
                    });
                    break;
                }
                if ((i+1) === vocales.length) {
                    props.form.setFieldsValue({
                        aPaternoFuncionario: "",
                        aMaternoFuncionario: "",
                        nombreFuncionario: "",
                    });
                }
            }
        }
    };

    ////////////////////////////////////// ---- COMPONENTES ---- //////////////////////////////////////////////////////////////

    const Organizaciones = () => (
        <div id="organizaciones">
          {!disabled.input && <Ast />} Organización
          <Form.Item>
            {getFieldDecorator("organizacion", { rules: required })(
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                placeholder="Selecciona"
                disabled={disabled.input}
                style={{ width: 315 }}
              >
                {selectOrg.map((item) => (
                    <Option key={item.idOrganizacion}>{item.organizacion}</Option>
                ))}
              </Select>
            )}
          </Form.Item>
        </div>
    );

    const Funcionario = () => (
        <div id="funcionario">
          <h5>Funcionario asignado a la supervisión del curso</h5>
          <Col span={24} style={{ marginTop: "10px", paddingRight: "20px" }}>
            {!disabled.input && <Ast />} Cargo
            <Form.Item>
                {getFieldDecorator("cargoFuncionario", { rules: required })(
                    <Select disabled={disabled.input} placeholder="Selecciona" style={{ width: 321 }} onSelect={onSelectCargoFun} >
                        {selectCargoFun.map((item) => (
                            <Option key={item.idCargo}>{item.descripcion}</Option>
                        ))}
                    </Select>
                )}
            </Form.Item>
          </Col>
          <Col span={24} style={{ marginBottom: "20px", paddingRight: "20px" }}>
            {!disabled.input && <Alert
                          message={<p style={{ marginBottom: "0px" }}>
                            Al menos uno de los apellidos es obligatorio</p>}
                          type="info"
                          showIcon
                        />}
          </Col>
          <Col span={8} style={{ paddingRight: "20px" }}>
            Apellido paterno
                <Form.Item>
                    {getFieldDecorator("aPaternoFuncionario", { rules: apellidos,
                    getValueFromEvent: (event) => {
                        return txtTexto.test(event.target.value)
                          ? event.target.value
                          : (event.target.value).slice(0,-1);
                    }, })(
                        <Input disabled={true} maxLength={40} />
                    )}
                </Form.Item>
          </Col>     
          <Col span={8} style={{ paddingRight: "20px" }}>
            Apellido materno
                <Form.Item>
                    {getFieldDecorator("aMaternoFuncionario", { rules: apellidos,
                    getValueFromEvent: (event) => {
                        return txtTexto.test(event.target.value)
                          ? event.target.value
                          : (event.target.value).slice(0,-1);
                    }, })(
                        <Input disabled={true} maxLength={40} />
                    )}
                </Form.Item>
          </Col> 
          <Col span={8} style={{ paddingRight: "20px" }}>
            {!disabled.input && <Ast />} Nombre(s)
                <Form.Item>
                    {getFieldDecorator("nombreFuncionario", { rules: nombres,
                    getValueFromEvent: (event) => {
                        return txtTexto.test(event.target.value)
                          ? event.target.value
                          : (event.target.value).slice(0,-1);
                    }, })(
                        <Input disabled={true} maxLength={100} />
                    )}
                </Form.Item>
          </Col>    
        </div>
    );

    function OtraSede (){
        return (
            <div>
                <Row>
                    <h5>Dirección</h5>
                    <Col span={6} style={{ paddingRight: "20px", paddingTop: "10px" }}>
                        {!disabled.input && <Ast />} Calle
                        <Form.Item>
                        {getFieldDecorator("calle", { rules: calleCol })(
                            <Input disabled={disabled.input || !otraSede} maxLength={50} />
                        )}
                        </Form.Item>
                    </Col>
                    <Col span={6} style={{ paddingRight: "20px", paddingLeft: "20px", paddingTop: "30px", }} >
                        <Form.Item>
                        {getFieldDecorator("sinNumero", {
                            valuePropName: "checked",
                            initialValue: false,
                        })(
                            <Checkbox disabled={disabled.input || !otraSede} onChange={onChangeSinNum}>
                            Sin número
                            </Checkbox>
                        )}
                        </Form.Item>
                    </Col>
                    <Col span={3} style={{ paddingRight: "20px", paddingTop: "10px" }}>
                        No. exterior
                        <Form.Item>
                        {getFieldDecorator("noExterior", { 
                        getValueFromEvent: (event) => {
                            return numeroInt.test(event.target.value)
                            ? event.target.value
                            : (event.target.value).slice(0,-1);
                        }, })(
                            <Input maxLength={20} disabled={disabled.checkNum || !otraSede} />
                        )}
                        </Form.Item>
                    </Col>
                    <Col span={3} style={{ paddingRight: "20px", paddingTop: "10px" }}>
                        No. interior
                        <Form.Item>
                        {getFieldDecorator("noInterior", { 
                        getValueFromEvent: (event) => {
                            return numeroInt.test(event.target.value)
                            ? event.target.value
                            : (event.target.value).slice(0,-1);
                        }, })(
                            <Input maxLength={20} disabled={disabled.checkNum || !otraSede} />
                        )}
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={3} style={{ paddingRight: "20px" }}>
                        {!disabled.input && <Ast />} Código postal
                        <Form.Item>
                        {getFieldDecorator("codigoPostal", { rules: cp, 
                        getValueFromEvent: (event) => {
                            return soloDigitos.test(event.target.value)
                              ? event.target.value
                              : (event.target.value).slice(0,-1);
                        }, })(
                            <Input disabled={disabled.input || !otraSede} maxLength={5} />
                        )}
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={8} style={{ paddingRight: "20px" }}>
                        {!disabled.input && <Ast />} Entidad
                        <Form.Item>
                        {getFieldDecorator("entidad", { rules: required })(
                            <Select
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option.props.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            disabled={disabled.input || !otraSede}
                            placeholder="Selecciona"
                            onChange={onChangeEntidad}
                            >
                            {selectEntidad.map((item) => (
                                <Option key={item.id.idEstado}>{item.nombreEstado}</Option>
                            ))}
                            </Select>
                        )}
                        </Form.Item>
                    </Col>
                    <Col span={8} style={{ paddingRight: "20px" }}>
                        {!disabled.input && <Ast />} Municipio o Alcaldía
                        <Form.Item>
                        {getFieldDecorator("municipio", { rules: required })(
                            <Select
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option.props.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            disabled={disabled.input || !otraSede}
                            placeholder="Selecciona"
                            >
                            {selectMunicipio.map((item) => (
                                <Option key={item.idMunicipio}>{item.municipio}</Option>
                            ))}
                            </Select>
                        )}
                        </Form.Item>
                    </Col>
                    <Col span={8} style={{ paddingRight: "20px" }}>
                        {!disabled.input && <Ast />} Colonia
                        <Form.Item>
                        {getFieldDecorator("colonia", { rules: calleCol })(
                            <Input disabled={disabled.input || !otraSede} maxLength={40} />
                        )}
                        </Form.Item>
                    </Col>
                </Row>
            </div>
        );
    };

    const ButtonsPage = () => {
        if (disabled.modulo === 2) {
          return (
            <div>
              <Col span={24} style={{ paddingTop: "20px", paddingRight: "20px" }}>
                <Form.Item style={{ textAlign: "center" }}>
                  <Button
                    disabled={button.editar || consultaButton}
                    type="primary"
                    onClick={() => {
                      setDisabled({ ...disabled, input: false, checkNum: false, modulo: 3, });
                      window.scrollTo(0, 0);
                    }}
                  >
                    Modificar
                  </Button>
                </Form.Item>
              </Col>
            </div>
          );
        } else {
          return (
            <div>
              <Col span={12} style={{ paddingTop: "20px", paddingRight: "20px" }}>
                <Form.Item style={{ textAlign: "right" }}>
                  <Button type="default">
                    <Link to={"/admin/cursos"}>Cancelar</Link>
                  </Button>
                </Form.Item>
              </Col>
              <Col span={12} style={{ paddingTop: "20px", paddingRight: "20px" }}>
                <Form.Item style={{ textAlign: "left" }}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    disabled={button.guardar}
                    onClick={() => {}}
                  >
                    Guardar
                  </Button>
                </Form.Item>
              </Col>
            </div>
          );
        }
    };

    function modalParticipantes() {
        return (
        <div>
            <Modal
            title={<strong>Solicitudes validadas asignadas a la Junta</strong>}
            visible={visibleModal}
            footer={null}
            className="modalCourses"
            onCancel={() => setVisibleModal(false)}
            >
            <div>
                <Row>
                    <TableParticipantes            
                    data={dataParticipantes}
                    className="-striped -highlight"
                    style={{ height: "350px", width: "900px" }}
                    locale={{ emptyText: "Sin registros" }}
                    pagination={{ pageSize: 10 }} 
                    scroll={{ x: 900, y: 250 }}
                    />
                </Row>
                <Row>
                <Col span={12} style={{ paddingTop: "20px", paddingRight: "20px" }}>
                    <Form.Item style={{ textAlign: "right" }}>
                    <Button type="default" onClick={() => setVisibleModal(false)}>
                        Cancelar
                    </Button>
                    </Form.Item>
                </Col>
                <Col span={12} style={{ paddingTop: "20px", paddingRight: "20px" }}>
                    <Form.Item style={{ textAlign: "left" }}>
                    <Button
                        type="primary"
                        htmlType="submit"
                        onClick={() => inscribirParticipantes()}
                        disabled={disabled.input}
                    >
                        Inscribir al curso
                    </Button>
                    </Form.Item>
                </Col>
                </Row>
            </div>
            </Modal>
        </div>
        );
    };

    function inscribirParticipantes() {
        let mujeres = 0;
        let hombres = 0;
        const datos = [];
        if (dataParticipantes !== undefined && dataParticipantes.length !== 0) {
            for (let i = 0; i < dataParticipantes.length; i++) {
                if (dataParticipantes[i].participanteSeleccionado) {
                    if (dataParticipantes[i].observadores.sexo === "H") {
                        hombres = hombres + 1;
                    } else {
                        mujeres = mujeres + 1;
                    }
                    datos.push(dataParticipantes[i]);
                }                
            }
        }
        setParticipantes({ mujeres: mujeres.toString(), hombres: hombres.toString(), total: (hombres + mujeres).toString() });
        setTableForm(datos);
        setVisibleModal(false);
    };

    const TableView = () => {
        if (tableForm.length > 0) {
            return (
                <div>
                    <TableForm            
                        data={dataParticipantes}
                        total={participantes}
                        modulo={disabled.modulo}
                        className="-striped -highlight"
                        locale={{ emptyText: "Sin registros" }}
                    />
                </div>
                );
        } else {
            return(null);
        }
    };

    ////////////////////////////////////// ---- FORMULARIO ---- //////////////////////////////////////////////////////////////
    return (
        <div style={{ marginLeft: "20px", marginTop: "30px" }}>
            <div style={{ marginBottom: "20px" }}>
                <Link to={"/admin/cursos"} className="back">
                    <Icon type="play-circle" theme="filled" rotate="180" /> Volver a "Registrar cursos"
                </Link>
            </div>
            {disabled.modulo === 2 && <h1>Consultar curso</h1>}
            {disabled.modulo === 1 && (<>
                <h1>Nuevo curso</h1>
                <small>Los datos con ( <Ast /> ) son requeridos.</small>
            </>)}
            {disabled.modulo === 3 && (<>
                <h1>Editar curso</h1>
                <small>Los datos con ( <Ast /> ) son requeridos.</small>
            </>)}
            <Form onSubmit={addChanges}>
                <Row style={{ marginTop: "30px" }}>
                    <Col span={8}>
                        {!disabled.input && <Ast />} Curso impartido por
                        <Form.Item>
                            {getFieldDecorator("organoImparte", { rules: required })(
                            <Radio.Group disabled={disabled.input} onChange={onChangeOrgano}>
                                <Radio value={0}>INE</Radio>
                                <Radio value={1}>OPL</Radio>
                                <Radio value={2}>Organización</Radio>
                            </Radio.Group>
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={16}>
                        {typeOrgan.org ? <Organizaciones /> : null}
                    </Col>
                </Row>
                <Row>
                    <Col span={8}>
                        <Form.Item>
                            {getFieldDecorator("cursoDistancia", {initialValue:distancia})(
                                <Checkbox disabled={disabled.input} checked={distancia} 
                                onChange={onChangeDistancia}>Curso a distancia</Checkbox> )}
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <h5>Periodo de impartición</h5>
                    <Col span={8} style={{ marginTop: "10px" }}>
                        {!disabled.input && <Ast />} Fecha de realización
                        <Form.Item>
                            {getFieldDecorator("fecha", { rules: required })(
                                <DatePicker disabled={disabled.input} style={{ width: 315 }} format={dateFormat} disabledDate={disabledDateRegister} />
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={4} style={{ marginTop: "10px" }}>
                        {!disabled.input && <Ast />} Hora de inicio
                        <Form.Item>
                            {getFieldDecorator("horaInicial", { rules: required })(
                                <TimePicker disabled={disabled.input} style={{ width: 150 }} defaultOpenValue={moment('00:00', format)} format={format} />
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={4} style={{ marginTop: "10px" }}>
                        {!disabled.input && <Ast />} Hora de término
                        <Form.Item>
                            {getFieldDecorator("horaFinal", { rules: required })(
                                <TimePicker disabled={disabled.input} style={{ width: 150 }} onChange={onChangeHour} defaultOpenValue={moment('00:00', format)} format={format} />
                            )}
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <h5>Datos del responsable de impartir el curso</h5>
                    <Col span={8} style={{ marginTop: "10px", paddingRight: "20px" }}>
                        {!disabled.input && <Ast />} Cargo
                        <Form.Item>
                            {getFieldDecorator("cargoImparte", { rules: required }, )(
                                <Select disabled={disabled.input} placeholder="Selecciona" onSelect={onSelectCargoImp} >
                                    {selectCargoImp.map((item) => (
                                        <Option key={item.idCargo}>{item.descripcion}</Option>
                                    ))}
                                </Select>
                            )}
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24} style={{ marginBottom: "20px", paddingRight: "20px" }}>
                        {!disabled.input && <Alert
                                    message={<p style={{ marginBottom: "0px" }}>
                                        Al menos uno de los apellidos es obligatorio</p>}
                                    type="info"
                                    showIcon
                                    />}
                    </Col>
                    <Col span={8} style={{ paddingRight: "20px" }}>
                        Apellido paterno
                        <Form.Item>
                            {getFieldDecorator("aPaternoImparte", { rules: apellidos,
                            getValueFromEvent: (event) => {
                                return txtTexto.test(event.target.value)
                                  ? event.target.value
                                  : (event.target.value).slice(0,-1);
                            }, })(
                                <Input disabled={typeOrgan.ine || disabled.input} maxLength={100} />
                            )}
                        </Form.Item>
                    </Col>     
                    <Col span={8} style={{ paddingRight: "20px" }}>
                        Apellido materno
                        <Form.Item>
                            {getFieldDecorator("aMaternoImparte", { rules: apellidos,
                            getValueFromEvent: (event) => {
                                return txtTexto.test(event.target.value)
                                  ? event.target.value
                                  : (event.target.value).slice(0,-1);
                            }, })(
                                <Input disabled={typeOrgan.ine || disabled.input} maxLength={100} />
                            )}
                        </Form.Item>
                    </Col> 
                    <Col span={8} style={{ paddingRight: "20px" }}>
                        {!disabled.input && <Ast />} Nombre(s)
                        <Form.Item>
                            {getFieldDecorator("nombreImparte", { rules: nombres,
                            getValueFromEvent: (event) => {
                                return txtTexto.test(event.target.value)
                                  ? event.target.value
                                  : (event.target.value).slice(0,-1);
                            }, })(
                                <Input disabled={typeOrgan.ine || disabled.input} maxLength={50} />
                            )}
                        </Form.Item>
                    </Col>               
                </Row>
                <Row>
                    {(typeOrgan.org) ? <Funcionario /> : null}
                </Row>
                <Row>
                    <h5>Lugar de impartición</h5>
                    <Col span={24}>
                        {!disabled.input && <Ast />} Sede del curso
                        <Form.Item>
                            {getFieldDecorator("sedeCurso", { rules: required, initialValue: 0 } )(
                            <Radio.Group disabled={disabled.input} onChange={onChangeSede}>
                                <Radio value={0} defaultChecked={true}>Domicilio de la Junta</Radio>
                                <Radio value={1} defaultChecked={false}>Sede alternativa</Radio>
                            </Radio.Group>
                            )}
                        </Form.Item>
                    </Col>
                </Row>
                {OtraSede()}
                <Row >
                    <h5>Personas inscritas al curso</h5>
                    <Col span={24} style={{ marginBottom: "20px", paddingRight: "20px" }}>
                        {!disabled.input && <Alert
                                    message={<p style={{ marginBottom: "0px" }}>
                                        Puedes inscribir personas al curso posteriormente</p>}
                                    type="info"
                                    showIcon
                                    />}
                    </Col>
                    <Col span={6} style={{ paddingRight: "20px", paddingTop: "20px", }}>
                        <Man style={{ marginRight: "10px" }} /><strong>{participantes.mujeres} Mujeres </strong>
                    </Col>
                    <Col span={6} style={{ paddingRight: "20px", paddingTop: "20px", }}>
                        <Woman style={{ marginRight: "10px" }} /><strong>{participantes.hombres} Hombres </strong>
                    </Col>
                    <Col span={12} style={{ paddingRight: "20px", paddingTop: "20px", }}>
                        <Total style={{ marginRight: "10px" }} /><strong>{participantes.total} Total </strong>
                    </Col>
                    <Col span={12} style={{ paddingRight: "20px", paddingTop: "30px", }}>
                        <Button onClick={() => setVisibleModal(true)} disabled={disabled.modulo === 2} >
                            <Icon type="user-add" /> Inscribir personas
                        </Button>
                        {modalParticipantes()}                      
                    </Col>
                </Row>
                <Row>
                    <Col span={18} style={{ paddingRight: "20px", paddingTop: "20px", }}>
                        {<TableView />}
                    </Col>
                </Row>
                <Row style={{marginTop: "20px"}}>
                    {<ButtonsPage />}
                </Row>
            </Form>                   
        </div>
    );
}
const CoursesForm = Form.create()(Courses);
export default withRouter(CoursesForm);