import React from "react";
import { useSelector } from "react-redux";
import { useLesson8 } from "../../../../hooks/useLesson8";
import { GuiaMaterial, MatApoyo } from "../Generals";

export default function Lesson8({ idLesson, data, setLoading }) {
  const { temario } = useSelector((state) => state.menu);
  const { idEntidad } = useSelector((state) => state.entidad);
  const {
    material,
    temarioMat,
    entidadMat: { idEntidad: id },
  } = useSelector((state) => state.material);
  const { diputacion, ley, tribunal } = useLesson8(material ? id : idEntidad);

  //Función que obtiene el índice del titulo de la lección y así poder mostrar el número de la lección
  const getIndex = () => {
    let array = material ? temarioMat : temario;
    let index = array.findIndex((obj) => obj.idLeccion === idLesson);
    if (index + 1 === 7) {
      return "VII ";
    } else {
      return "VIII ";
    }
  };

  //Se obtiene el id de la entidad de acuerdo a la pantalla en la que se encuentre
  //Pantallas: Material de capacitación / Recibir capacitación
  const getIdEntity = () => {
    return material ? id : idEntidad;
  };

  const getNumber = () => {
    let array = material ? temarioMat : temario;
    let index = array.findIndex((obj) => obj.idLeccion === idLesson);
    return index + 1;
  };

  const getLecciones = (id) => {
    var texto ="";
    if(id === 5){
      texto ="";
    }else if(id === 13 ){
      texto ="la elección de"
    }else{
      texto = "las elecciones de";
    }
    return texto;
  };

  // const getIdEntity = () => {
  //   return material ? id : idEntidad;
  // };

  return (
    <div className="texto_parrafo">
      <p>¿Sabes qué son los medios de impugnación? </p>
      <p className="bold">
        Son el conjunto de recursos y juicios que tienen derecho a interponer
        las y los ciudadanos, los partidos políticos y las candidaturas
        independientes en contra de los actos y resoluciones de las autoridades
        electorales, cuando estos actos, a criterio de quienes se inconforman,
        presentan deficiencias o errores.
      </p>
      <p>
        Estos sirven para modificar, revocar o anular los actos y las
        resoluciones en materia electoral que no se apeguen a las normas
        jurídicas.
      </p>
      <p>Y tienen por objeto garantizar:</p>
      <ul>
        <li>
          Que todos los actos y resoluciones de las autoridades electorales se
          sujeten a los <b>principios de constitucionalidad y legalidad.</b>
        </li>
        <li>
          La <b>definitividad</b> de los distintos actos y etapas de los
          procesos electorales.
        </li>
        <li>
          La <b>protección de los derechos político-electorales</b> de las y los
          ciudadanos de votar y ser votados, de asociación y de afiliación libre
          y pacífica.
        </li>
      </ul>

      <p>
        El <b>Sistema Federal de Medios de Impugnación</b> se encuentra previsto
        en la Ley General del Sistema de Medios de Impugnación en Materia
        Electoral (LGSMIME) y se integra por diversos medios.
      </p>
      <p>
        Revisa el siguiente material de apoyo para conocer los medios de
        impugnación en materia electoral a nivel federal.
      </p>
      {/* <MatApoyo
        array={[{ titulo: "Medios de impugnación en materia electoral " }]}
      /> */}
      {data.length && <MatApoyo array={[data[0]]} />}
      <p>
        Por otra parte, la <b>nulidad</b> es la sanción derivada de la violación
        a la legislación en materia electoral, teniendo distintas consecuencias
        jurídicas por lo que, atendiendo a estos efectos, la <b>nulidad</b>{" "}
        puede darse en dos niveles:
      </p>
      <p>
        1. <b>Nulidad de la votación recibida en la casilla</b>: La votación de
        la casilla anulada no es tomada en cuenta para el cómputo final de los
        votos, las causas tienen que ver con el incumplimiento de los
        procedimientos establecidos en la ley respecto de la instalación de la
        casilla, recepción de la votación, cómputo de los votos y el traslado de
        los paquetes electorales. Dichas causales se establecen en el{" "}
        <b>artículo 75 de la LGSMIME.</b>
      </p>
      <p>
        A continuación, puedes consultar las causas de nulidad de la votación en
        las casillas en el material de apoyo.{" "}
      </p>
      {/* <MatApoyo array={[{ titulo: "Causales de nulidad de la votación" }]} /> */}
      {data.length && <MatApoyo array={[data[1]]} />}
      <p>
        2. <b>Nulidad de una elección</b>: Implica que la elección pierde
        validez y tendrá que reponerse con la celebración de una elección
        extraordinaria y se refieren a irregularidades que se han presentado
        durante cualquiera de las etapas del proceso electoral y que constituyen
        violaciones a alguno de los principios constitucionales.
      </p>
      <p>
        Las causales de nulidad de la elección de diputaciones de mayoría
        relativa a nivel federal se establecen en el artículo{" "}
        <b>76, de la LGSMIME</b>.
        <br /> <br />A continuación, puedes consultar las causas de nulidad de
        la elección en el material de apoyo.
      </p>
      {/* <MatApoyo array={[{ titulo: "Causales de nulidad de la elección" }]} /> */}
      {data.length && <MatApoyo array={[data[2]]} />}

      <p>
        La nulidad de la elección federal y {getIdEntity() === 13 ? "local":"locales"} se establece en el artículo{" "}
        <b>78 bis</b>, de la <b>LGSMIME</b> y de manera complementaria lo
        señalado en la{" "}
        <b>Base VI del artículo 41 de la Constitución federal:</b>
      </p>
      <p>
        La elección federal o {getIdEntity() === 13 ? "local":"locales"} serán nulas por
        <b> violaciones graves, dolosas y determinantes</b> y se puede presentar
        en los siguientes casos:
      </p>
      <ul>
        <li>
          Se exceda el gasto de campaña en un cinco por ciento del monto total
          autorizado;
        </li>
        <li>
          Se compre o adquiera cobertura informativa o tiempos en radio y
          televisión, fuera de los supuestos previstos en la ley;
        </li>
        <li>
          Se reciban o utilicen recursos de procedencia ilícita o recursos
          públicos en las campañas.
        </li>
      </ul>
      <p>
        Revisa el siguiente material de apoyo para conocer que son las
        violaciones graves, dolosas y determinantes.
      </p>
      {/* <MatApoyo
        array={[{ titulo: "Violaciones graves, dolosas y determinantes" }]}
      /> */}
      {data.length && <MatApoyo array={[data[3]]} />}
      <p>
        Por otra parte, los medios locales de impugnación que tienen por objeto
        resolver las controversias que surjan respecto de{" "}
        {getLecciones(getIdEntity())} {diputacion}, se encuentran regulados en{" "}
        {ley}y se resuelven ante el {tribunal}.
      </p>
      <p className="bold">
        Para profundizar en los temas de las causales de nulidad de la elección
        y las causales de nulidad de la votación recibida en las casillas
        consulta el{" "}
        <GuiaMaterial setLoading={setLoading}>
          CAPÍTULO {getIndex()} DE TU GUÍA TEMÁTICA PARA CAPACITAR A LAS Y LOS
          OBSERVADORES ELECTORALES
        </GuiaMaterial>{" "}
        y el material de apoyo.{" "}
      </p>
      <b>
        A continuación, revisa el siguiente material de apoyo para reforzar lo
        que aprendiste.
      </b>
      {/* <MatApoyo
        array={[
          {
            titulo: `Presentación ${getNumber()}: Medios de impugnación en materia electoral`,
          },
        ]}
      /> */}
      {data.length && (
        <MatApoyo
          array={[data[4]]}
          number={true}
          getNumber={`Presentación ${getNumber()}.`}
        />
      )}
      <p>Recuerda responder la actividad autoevaluable de esta lección. </p>
    </div>
  );
}
