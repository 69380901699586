import React, { useState, useEffect } from "react";
import { Icon, Tooltip, Table } from "antd";
import { withRouter } from "react-router-dom";
import moment from "moment";
import "./Components.scss";

function TableParticipantes(props) {
    const {
        data, //Se recibe el array de los participantes
        total,
        modulo,
    } = props; //Variables que se reciben por los props
    const [dataTable, setDataTable] =  useState([]);   // Lista de participantes filtrado
    const [participantes, setParticipantes] = useState({ mujeres: "0", hombres: "0", total: "0" }); //Para actualizar los inputs

    useEffect(() => {
        var dataFilter = [];
        if (data !== undefined) {
            for (let i = 0; i < data.length; i++) {
                if (data[i].participanteSeleccionado) {
                    dataFilter.push(data[i]);
                }
            }
        }
        setDataTable(dataFilter);
        setParticipantes(total);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    const deleted = (record, e) => {
        let mujeres = 0;
        let hombres = 0;
        const datos = [];
        for (let i = 0; i < dataTable.length; i++) {
            if (dataTable[i].observadores.idObservador === record.observadores.idObservador) {
                dataTable[i].participanteSeleccionado = false;
            } else {
                datos.push(dataTable[i]);
                if (dataTable[i].participanteSeleccionado) {
                    if (dataTable[i].observadores.sexo === "H") {
                        hombres = hombres + 1;
                    } else {
                        mujeres = mujeres + 1;
                    }
                }
            }            
        }
        participantes.hombres = hombres.toString();
        participantes.mujeres = mujeres.toString();
        participantes.total = (hombres + mujeres).toString();
        setDataTable(datos);
    };

    const columns = [
        {
            title: "Nombre",
            dataIndex: "observadores.nombreCompleto",
            key: "nombreCompleto",
            width: 280,
        },
        {
            title: "Clave de Elector",
            dataIndex: "observadores.claveElector",
            key: "claveElector",
            width: 280,
        },
        {
            title: "Folio",
            dataIndex: "observadores.folio",
            key: "folio",
            width: 120,
            align: "center",
        },
        {
            title: "Fecha de Validación",
            dataIndex: "observadores.fechaEstatus",
            key: "fechaEstatus",
            width: 180,
            align: "center",
            render: (record) => moment(record).format("DD/MM/YYYY"), //Formato a la fecha
        },
        {
            title: "",
            align: "center",
            width: 50,
            render: (record) => (
                <>
                  {(
                    <Tooltip title="Eliminar persona inscrita">
                      <button 
                        style={{ marginLeft: "0px" }}
                        className="btn-circle btn_secundario"
                        onClick={(e) => deleted(record, e)}
                        disabled={modulo === 2}
                      >
                        <Icon type="delete" />
                      </button>
                    </Tooltip>
                  )}
                </>
              ),
        },
    ];

    return (
        <Table
            dataSource={dataTable}
            columns={columns}
            title={() => 'Listado de personas inscritas'}
            pagination={{ pageSize: 10 }} 
            scroll={{ y: 500 }}
        />
    );
}
export default withRouter(TableParticipantes);