//Constantes para almacenar el token en el localStorage
export const ACCESS_TOKEN = "accessToken";
export const REFRESH_TOKEN = "refreshToken";
export const ID_SISTEMA = "218";
export const VERSION_SISTEMA = "11.0";
export const ACCESS_TOKEN_CI = "accessTokenCi";
export const DETALLE_PROCESO_CI = "listaDetalle";
export const ID_CUESTIONARIOS = "correo";
//Formato de fecha
export const formatDate = "DD/MM/YYYY";
export const placeholderDate = "dd/mm/aaaa";
//Tipos de documentos
export const ID_DOC_CRED_FRENTE = 1;
export const ID_DOC_CRED_VUELTA = 2;
export const ID_DOC_FOTOGRAFIA = 3;
export const ID_DOC_COMPROBANTE = 4;
export const ID_DOC_ACREDITACION = 5;
export const ID_DOC_CONSTANCIA = 6;
export const ID_DOC_INFORME = 8;
export const ID_DOC_GAFETE = 21;
export const ID_DOC_ACREDITACION_OBS = 22;
// Estatus tabla C_ESTATUS
export const ESTATUS_SIN_REVISION = 1;
export const ESTATUS_OBSERVACIONES_ATENDIDAS = 2;
export const ESTATUS_CON_OBSERVACIONES = 3;
export const ESTATUS_VALIDADO = 4;
export const ESTATUS_ELIMINADO = 5;
// Estatus para las pantallas del portal Público
export const PAGE_SIN_REVISION = 1;
export const PAGE_OBSERVACIONES_ATENDIDAS = 2;
export const PAGE_CON_OBSERVACIONES = 3;
export const PRESENCIAL_SIN_ASIGNAR = 4;
export const PRESENCIAL_SIN_ASISTENCIA = 5;
export const CURSO_FINALIZADO = 6;
export const CURSO_LINEA_PENDIENTE = 7;
export const PAGE_ACREDITADO = 8;
export const CURSO_LINEA_TERMINADO = 9;
export const PAGE_INFORMES = 10;
// Tipos de Observaciones tabla C_OBSERVACIONES
export const OBS_CRED_FRENTE = 1;
export const OBS_CRED_VUELTA = 2;
export const OBS_FOTOGRAFIA = 3;
export const OBS_DIF_PERSONA = 4;
export const OBS_NOMBRE = 5;
export const OBS_CLAVE_ELEC = 6;
export const OBS_DIRECCION = 7;
export const OBS_CRED_VIG = 8;
//Sede para el servicio de juntas
export const TIPO_SEDE = 1;
// Origen de la solicitud
export const ORIGEN_PORTAL_PUBLICO = 3;
export const ORIGEN_JUNTA = 4;
// TIPO DE OBSERVADOR
export const OBSERVADOR_NACIONAL = 0;
export const OBSERVADOR_EXTRANJERO = 1;
//Usuario CAU
export const USUARIO_CAU = "OBSERVADORES11.CAU.OC";
// Estatus Curso
export const ESTATUS_CURSO_INSCRITO = 4;
//Mensajes para bloquear la pantalla
export const MSN_LOGIN = "Cargando...";
export const MSN_GUARDAR = "Procesando...";
// Puesto del Funcionario
export const FUNCIONARIO_CONSEJERO = 1;
//Cursos en línea
export const MODALIDAD_LINEA = 1;
export const ACT_FINAL = 10;
export const CAL_MIN = 7;
export const ACT_VALORACION = 11;
export const MAT_CONSULTA = 12;
export const MSG_GUIA = "Guía Temática para Capacitar a las y los Observadores Electorales";
// ID PUESTO
export const ID_PUESTO_PRESIDENTE = 1;
export const ID_PUESTO_SECRETARIO = 2;
