import React, { useState } from "react";
import { Icon, Row, Modal } from "antd";
import "./Verification.scss";
import Login from "../Login/Login";

const Verification = ({ type, message }) => {
  const [visibleSession, setVisibleSession] = useState(false);
  const success = (
    <div>
      <p>
        Ahora puedes{" "}
        <button
          onClick={(e) => {
            setVisibleSession(true);
          }}
          className="btnExample"
        >
          iniciar sesión
        </button>
        y elaborar tu solicitud
      </p>
      <Modal
        title="Iniciar Sesión"
        visible={visibleSession}
        onOk={(e) => {
          setVisibleSession(false);
        }}
        onCancel={(e) => {
          setVisibleSession(false);
        }}
        footer={null}
      >
        <Login visibleSession={visibleSession} />
         
      </Modal>
      <Icon type="mail" style={{ fontSize: "80px", color: "#e149a4" }} />
    </div>
  );
  const fail = (
    <div>
      <Icon type="notification" style={{ fontSize: "80px", color: "#e149a4" }} />
    </div>
  );

  return (
    <div className="verification">
      <Row>
        <h1>{message}</h1>
        <div>{type === 1 ? success : fail}</div>
      </Row>
    </div>
  );
};

export default Verification;
