import React from "react";
import { Tooltip, Icon, Table, message } from "antd";
import { withRouter, Link } from "react-router-dom";
import moment from "moment";
import "moment/locale/es";
import { getType, ModalEstatus } from "../../../utils/Convocation/functions";
import { downloadConvocation } from "../../../api/convocation";
import { USUARIO_CAU } from "../../../utils/constanst";

const TableConvocation = (props) => {
  const { proceso, data, loading, versionUsuario, setReload, disabled } = props; //Se obtien el arreglo de convocatorias
  const { rolModulo, rolUsuario } = props;
  //Función que permite descargar la convocatoria
  const download = (con) => {
    const json = {
      idTipo: con.tipo, //Se obtiene el tipo de la convocatoria
      idConvocatoria: con.idConvocatoria, //Id
    };
    downloadConvocation(json)
      .then((response) => {
        if (response.data) {
          const link = document.createElement("a");
          link.href = URL.createObjectURL(
            new Blob([response.data], { type: "application/pdf" })
          );
          link.setAttribute("download", `Convocatoria.pdf`);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      })
      .catch((error) => message.error("El archivo no se encuentra disponible"));
  };

  //Para publicar la convocatoria desde la tabla recibe el Id convocatoria
  const share = (id, e) => {
    e.preventDefault();
    ModalEstatus(id, setReload); //Se envian los datos al modal
  };

  const columns = [
    {
      title: `${proceso ? proceso : ""}`,
      children: [
        {
          title: "#",
          dataIndex: "indice",
          key: "indice",
          align: "left",
          width: 100,
        },
        {
          title: "Cierre de recepción de solicitudes",
          dataIndex: "fecha",
          key: "fecha",
          align: "center",
          render: (record) => moment(record).format("DD-MMMM-YYYY"), //Formato a la fecha
        },
        {
          title: "Estado de publicación",
          dataIndex: "estatus",
          key: "estatus",
          align: "center",
          render: (row) => (row ? "Publicada" : "No Publicada"),
        },
        {
          title: "Convocatoria realizada por",
          dataIndex: "tipo",
          key: "tipo",
          align: "center",
          render: (row) => getType(row),
        },
        {
          title: "Acciones",
          align: "center",
          key: "actions",
          render: (row) => (
            <>
              {versionUsuario === "OC" && row.tipo === 1 && (
                <>
                  <ButtonsUpdateAndShow row={row} />
                  {!row.estatus &&
                    rolModulo.captura &&
                    rolUsuario !== USUARIO_CAU && (
                      <Tooltip title="Publicar">
                        <button
                          className="btn-circle btn_secundario"
                          onClick={(e) => share(row.idConvocatoria, e)}
                        >
                          <Icon type="share-alt" />
                        </button>
                      </Tooltip>
                    )}
                </>
              )}
              {versionUsuario !== "OC" && row.tipo !== 1 && (
                <ButtonsUpdateAndShow row={row} />
              )}
              {row.estatus && row.tipo === 1 && <ButtonDownloas row={row} />}
              {row.tipo !== 1 && <ButtonDownloas row={row} />}
            </>
          ),
        },
      ],
    },
  ];
  //Componente que muestra el botón de descarga
  const ButtonDownloas = ({ row }) => {
    return (
      <Tooltip title="Descargar">
        <button
          className="btn-circle btn_secundario"
          onClick={(e) => download(row, e)}
        >
          <Icon type="download" />
        </button>
      </Tooltip>
    );
  };
  //Componente que muestra los botones de visualizar y modificar
  const ButtonsUpdateAndShow = ({ row }) => {
    return (
      <>
        <Tooltip title="Visualizar">
          <Link
            to={{
              pathname: "/admin/convocatoria/modificar",
              con: row,
              status: true,
            }}
          >
            <button className="btn-circle btn_secundario">
              <Icon type="eye" theme="filled" />
            </button>
          </Link>
        </Tooltip>{" "}
        {rolModulo.modifica && (
          <Tooltip title="Modificar">
            <Link
              to={{
                pathname: "/admin/convocatoria/modificar",
                con: row,
                status: false,
              }}
              disabled={disabled}
            >
              <button className="btn-circle btn_secundario" disabled={disabled}>
                <Icon type="edit" theme="filled" />
              </button>
            </Link>
          </Tooltip>
        )}
      </>
    );
  };

  return (
    <Table
      columns={columns}
      dataSource={data}
      loading={loading}
      locale={{ emptyText: "Sin registros" }}
      scroll={{ x: "calc(100vh - 4em)" }}
    />
  );
};

export default withRouter(TableConvocation);
