import React, { useState } from "react";
import { Form, Button, Modal, Radio } from "antd";
//Componentes Generales
// import TypeRequest from "../../../../components/User/Steps/TypeReq";
import Documents from "../../../../components/User/Steps/Documents";
import Data from "../../../../components/User/Steps/Data";
import Adress from "../../../../components/User/Steps/Adress";
import Additional from "../../../../components/User/Steps/Additional";
//Componentes Extranjero
import Resident from "../../../../components/User/Steps/Foreign/Resident";
import ForAdress from "../../../../components/User/Steps/Foreign/Adress";
//Funciones
import { ModalConfirm, Ast } from "../../../../utils/Request/functions";
import { generaFolio } from "../../../../utils/Request/functions";
import useAuth from "../../../../hooks/useAuth";
import { lastName } from "../../../../utils/Request/functions";
import { Redirect, withRouter } from "react-router-dom";
import { Entities } from "../../../../components/User/Steps/Entities";
import { radioButton } from "../../../../utils/rules";
import "../../../../components/User/Steps/Steps.scss";
function Request(props) {
  const {
    history,
    setLoader,
    location: { state },
  } = props; //Para utilizar la función push
  const [datos, setDatos] = useState({}); //Hook(Principal) para almacenar todos los datos del formulario
  const [files, setFiles] = useState({}); //Hook para almacenar las imagenes
  const { getFieldDecorator } = props.form; //Método para validar los inputs
  const [disabled, setDisabled] = useState({ num: false, btnSave: false }); //Hook para desactivar los campos de número exterior e interior y mostrar el modal de las fotografias
  const {
    ciudadano: { ciudadano, detalle },
  } = useAuth();
  const [juntas, setJuntas] = useState({ juntas: [], estados: [] });

  //Se valida que acceda desde la pantalla principal
  if (state === undefined) {
    return (
      <>
        <Redirect to="/ciudadano/inicio" />
      </>
    );
  }
  //Función que va almacenando la información de cada input
  const handleChange = (input) => (event) => {
    if (input === "fechaNac") {
      event != null
        ? setDatos({ ...datos, [input]: event.format("YYYY-MM-DD") })
        : setDatos({ ...datos, [input]: null });
    } else {
      setDatos({ ...datos, [input]: event.target.value }); //Se almacena la información en el hook
    }
  };

  //Función que limpia el hook datos cada vez que se cambia el lugar de residencia
  const handleChangeType = (input) => (event) => {
    setDatos({});
    setFiles({});
    setJuntas({ juntas: [], estados: [] });
    props.form.resetFields();
    setDatos({ [input]: event.target.value }); //Se almacena la información en el hook
  };

  //Función que alamacena la información de lo select
  const changeSelect = (input) => (event) => {
    setDatos({ ...datos, [input]: event });
  };

  //Función que almacena la información de los checkbox
  const changeCheckbox = (input) => (event) => {
    setDatos({ ...datos, [input]: event.target.checked });
    if (input === "numero") {
      //Valida si el el checkbox llamado numero
      if (event.target.checked) {
        props.form.setFieldsValue({
          //Limpia los inputs de numero exterior e interior
          numeroExt: (datos.numeroExt = ""),
          numeroInt: (datos.numeroInt = ""),
        }); //Obtiene la información y la almacena
        setDatos({ ...datos, [input]: event.target.checked });
        setDisabled({ num: true }); //Bloquea los inputs
      } else {
        setDisabled({ num: false }); //Habilita los inputs
      }
    }
  };
  //Función que regresa a la pagina de tipo de solicitud
  const back = () => {
    history.push("/ciudadano/inicio");
  };
  //Función que obtiene los datos del formulario
  const saveRequest = () => {    
    props.form.validateFields((err, values) => {
      if (values.codigoPostal !== undefined && values.codigoPostal !== null && values.codigoPostal !== "" && Number(values.codigoPostal) === 0) {
        Modal.warning({
          title: "Atención",
          content: "Para poder continuar se requiere ingresar la sección electoral, favor de validar.",
        });
      } else {
        if (!err) {
          let name = lastName(datos.paterno, datos.materno);
          if (!name) {
            //Se verifica que al menos tenga un apellido
            Modal.warning({
              title: "Atención",
              content: "Al menos un apellido es obligatorio.",
            });
            return;
          }
          if (!files.credAnverso || !files.credReverso || !files.foto) {
            Modal.warning({
              title: "Atención",
              content: "Documentación personal requerida.",
            });
            return;
          } else {
            if (datos.entObservar === 0) {
              datos.entidades = [datos.idEntidad];
            }
            datos.idCuenta = ciudadano.idCuenta; //Se obtie el idCuenta del ciudadano
            datos.correo = ciudadano.sub; //Se obtie el correo del ciudadano
            datos.idCuenta = ciudadano.idCuenta; //Se obtie el idCuenta del ciudadano
            datos.correo = ciudadano.sub; //Se obtie el correo del ciudadano
            datos.elector = datos.elector.toUpperCase();
            getTipoObservador(values);
            getProcess(); //Se obtiene los datos de la función
            setDisabled({ btnSave: true, loading: true }); //Se deshabilita el botón de guardar
            //console.log(JSON.stringify(datos));
            ModalConfirm(datos, files, history, setDisabled, setLoader); //Se muestra el alert
          }
        }
      }
    });
  };
  //Función temporal que obtiene el proceso, detalle y el folio
  const getProcess = () => {
    datos.idProceso = detalle[0].idProcesoElectoral;
    datos.idDetalle = detalle[0].idDetalleProceso;
    datos.nombreProceso = detalle[0].nombreProceso;
    datos.folio = generaFolio(detalle[0]);
  };
  //Función que le asigna los valores al hook
  const getTipoObservador = (values) => {
    datos.paterno = values.paterno && values.paterno.trim();
    datos.materno = values.materno && values.materno.trim();
    datos.nombre = values.nombre.trim();
    datos.ocupacion = values.ocupacion && values.ocupacion.trim();
    datos.celular = values.celular;
    datos.telefono = values.telefono;
    //Extranjero
    if (datos.tipoObservador === 1) {
      datos.estadoExt = values.estadoExt && values.estadoExt.trim();
      datos.ciudad = values.ciudad && values.ciudad.trim();
    }
  };
  //Componente que renderiza los botones de Guardar y regresar
  const ButtonSave = () => {
    return (
      <Form.Item style={{ textAlign: "center" }}>
        <Button
          type="default"
          onClick={() => back()}
          disabled={disabled.btnSave}
        >
          Regresar
        </Button>{" "}
        <Button
          type="primary"
          onClick={() => saveRequest()}
          //style={{ width: 350 }}
          className="btnSave"
          disabled={!datos.compromiso || !datos.comCorreo || disabled.btnSave}
        >
          Guardar y enviar a validación
        </Button>
      </Form.Item>
    );
  };

  return (
    <div style={{ marginTop: 50 }}>
      <h1>Solicitud</h1>
      <hr />
      <p style={{ marginTop: 30 }}>
        Los datos marcados con ( <Ast /> ) son obligatorios
      </p>
      <h3>
        <Ast /> Lugar de residencia
      </h3>
      <Form.Item>
        {getFieldDecorator("tipoObservador", {
          rules: radioButton,
          initialValue: datos.tipoObservador,
        })(
          <Radio.Group onChange={handleChangeType("tipoObservador")}>
            <Radio value={0}>Territorio mexicano</Radio>
            <Radio value={1}>Extranjero</Radio>
          </Radio.Group>
        )}
      </Form.Item>
      {/**Se habilita el tipo de formulario si es nacional o extranjero  */}
      {datos.tipoObservador === 0 && (
        <>
          {/* <TypeRequest
            handleChange={handleChange} //Se envia la funcion
            datos={datos} //se manda el hook de los valores
            getFieldDecorator={getFieldDecorator} //Se envia para validar los inputs
            form={props.form}
            setDatos={setDatos}
          /> */}
          <Data
            handleChange={handleChange} //Se envia la funcion
            datos={datos} //se manda el hook de los valores
            getFieldDecorator={getFieldDecorator} //Se envia para validar los inputs
            changeSelect={changeSelect} //Función del select
            form={props.form}
            setDatos={setDatos}
          />
          <Adress
            handleChange={handleChange} //Se envia la funcion
            datos={datos} //se manda el hook de los valores
            getFieldDecorator={getFieldDecorator} //Se envia para validar los inputs
            changeCheckbox={changeCheckbox}
            disabled={disabled} //Para habilitar los inputs
            setDatos={setDatos}
            form={props.form}
            setJuntas={setJuntas}
            juntas={juntas}
          />
          <Additional
            handleChange={handleChange}
            datos={datos}
            getFieldDecorator={getFieldDecorator}
            changeSelect={changeSelect}
            form={props.form}
            juntas={juntas}
            setJuntas={setJuntas}
          />
          <Entities
            handleChange={handleChange}
            datos={datos}
            getFieldDecorator={getFieldDecorator}
            setDatos={setDatos}
            form={props.form}
          />
          <Documents
            setFiles={setFiles}
            files={files}
            getFieldDecorator={getFieldDecorator}
            datos={datos}
            changeCheckbox={changeCheckbox}
            setDisabled={setDisabled}
            formVal={props.form}
          />
          {/* Boton de Guardar-Regresar - Se reutiliza el componente  */}
          <ButtonSave />
        </>
      )}

      {props.form.getFieldValue("tipoObservador") === 1 && (
        <>
          {/*Componente solo para extrnjeros */}
          <Resident
            handleChange={handleChange} //Se envia la funcion
            datos={datos} //se manda el hook de los valores
            getFieldDecorator={getFieldDecorator} //Se envia para validar los inputs
            changeSelect={changeSelect} //Función del select
            setDatos={setDatos}
          />
          {/* Data - Se reutiliza el componente  */}
          <Data
            handleChange={handleChange} //Se envia la funcion
            datos={datos} //se manda el hook de los valores
            getFieldDecorator={getFieldDecorator} //Se envia para validar los inputs
            changeSelect={changeSelect} //Función del select
            setDatos={setDatos}
            form={props.form}
          />
          {/*Componente solo para extranjeros-Dirección */}
          <ForAdress
            setJuntas={setJuntas}
            handleChange={handleChange} //Se envia la funcion
            datos={datos} //se manda el hook de los valores
            getFieldDecorator={getFieldDecorator} //Se envia para validar los inputs
            changeCheckbox={changeCheckbox}
            disabled={disabled} //Para habilitar los inputs
          />
          {/*  Additional- Se reutiliza el componente  */}
          <Additional
            handleChange={handleChange}
            datos={datos}
            getFieldDecorator={getFieldDecorator}
            changeSelect={changeSelect}
            form={props.form}
            juntas={juntas}
            setJuntas={setJuntas}
          />
          {/*  Entities- Se reutiliza el componente  */}
          <Entities
            handleChange={handleChange}
            datos={datos}
            getFieldDecorator={getFieldDecorator}
            setDatos={setDatos}
            form={props.form}
          />
          {/*  Documents - Se reutiliza el componente  */}
          <Documents
            setFiles={setFiles}
            files={files}
            getFieldDecorator={getFieldDecorator}
            datos={datos}
            changeCheckbox={changeCheckbox}
            setDisabled={setDisabled}
            formVal={props.form}
          />
          {/* Boton de Guardar-Regresar - Se reutiliza el componente  */}
          <ButtonSave />
        </>
      )}
    </div>
  );
}

export default withRouter(Form.create()(Request));
