export const rol_captura = [
  /*
  "OBSERVADORES11.ADMIN.PARAM.CAPTURA.OC",
  "OBSERVADORES11.ADMIN.CAPTURA.OC",
  "OBSERVADORES11.CAU.OC", //Cau
  */
];

export const rol_consulta = [
  "OBSERVADORES11.ADMIN.PARAM.CAPTURA.OC",
  "OBSERVADORES11.ADMIN.CAPTURA.OC",
  "OBSERVADORES11.CONSEJERO.OC",
  "OBSERVADORES11.CONSULTA.OC",
  "OBSERVADORES11.CAU.OC", //Cau
];

export const rol_modifica = [
  /*
  "OBSERVADORES11.ADMIN.PARAM.CAPTURA.OC",
  "OBSERVADORES11.ADMIN.CAPTURA.OC",
  "OBSERVADORES11.CAU.OC", //Cau
  */
];

export const rol_borrar = [
  /*
  "OBSERVADORES11.ADMIN.PARAM.CAPTURA.OC",
  "OBSERVADORES11.ADMIN.CAPTURA.OC",
  "OBSERVADORES11.CAU.OC", //Cau
  */
];
